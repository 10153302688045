/*
|--------------------------------------------------------------------------
| Fonts
|
| Include @font-face font file imports here. Also, include a variable to
| easily use a font-stack across the site.
|--------------------------------------------------------------------------
*/

/*
 * Webfonts by Paulo Goode
 *
 *
 * The fonts included in this stylesheet are subject to the End User Licence Agreement you
 * purchased in this package. The fonts are protected under domestic and international
 * trademark and copyright law. You are prohibited from modifying, reverse engineering,
 * duplicating, or distributing this font software.
 *
 * © Copyright 2016 Paulo Goode
 *
 *
 */
$woodford-font-path: '../fonts/woodfordbournepro/';
$helvetica-font-path: '../fonts/helveticaneueetext/';

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-black-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-black-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-black-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-black-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-black-webfont.svg#woodford_bourne_problack") format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-blackitalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-blackitalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-blackitalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-blackitalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-blackitalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-blackitalic-webfont.svg#woodford_bourne_proBlkIt") format("svg");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-bold-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-bold-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-bold-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-bold-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-bold-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-bold-webfont.svg#woodford_bourne_probold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-bolditalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-bolditalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-bolditalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-bolditalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-bolditalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-bolditalic-webfont.svg#woodford_bourne_proBdIt") format("svg");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-extralight-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-extralight-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-extralight-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-extralight-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-extralight-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-extralight-webfont.svg#woodford_bourne_proextralight") format("svg");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-extralightitalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-extralightitalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-extralightitalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-extralightitalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-extralightitalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-extralightitalic-webfont.svg#woodford_bourne_proXLtIt") format("svg");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-italic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-italic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-italic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-italic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-italic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-italic-webfont.svg#woodford_bourne_proitalic") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-light-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-light-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-light-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-light-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-light-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-light-webfont.svg#woodford_bourne_prolight") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-lightitalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-lightitalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-lightitalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-lightitalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-lightitalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-lightitalic-webfont.svg#woodford_bourne_proLtIt") format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-medium-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-medium-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-medium-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-medium-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-medium-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-medium-webfont.svg#woodford_bourne_promedium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-mediumitalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-mediumitalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-mediumitalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-mediumitalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-mediumitalic-webfont.svg#woodford_bourne_proMdIt") format("svg");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-regular-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-regular-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-regular-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-regular-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-regular-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-regular-webfont.svg#woodford_bourne_proregular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-semibold-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-semibold-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-semibold-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-semibold-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-semibold-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-semibold-webfont.svg#woodford_bourne_prosemibold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-semibolditalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-semibolditalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-semibolditalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-semibolditalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-semibolditalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-semibolditalic-webfont.svg#woodford_bourne_proSBdIt") format("svg");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-thin-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-thin-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-thin-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-thin-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-thin-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-thin-webfont.svg#woodford_bourne_prothin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-thinitalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-thinitalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-thinitalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-thinitalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-thinitalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-thinitalic-webfont.svg#woodford_bourne_proThIt") format("svg");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-ultra-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-ultra-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-ultra-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-ultra-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-ultra-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-ultra-webfont.svg#woodford_bourne_proultra") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WoodfordBournePro';
    src: url("#{$woodford-font-path}woodfordbournepro-ultraitalic-webfont.eot");
    src: url("#{$woodford-font-path}woodfordbournepro-ultraitalic-webfont.eot?#iefix") format("embedded-opentype"),
         url("#{$woodford-font-path}woodfordbournepro-ultraitalic-webfont.woff2") format("woff2"),
         url("#{$woodford-font-path}woodfordbournepro-ultraitalic-webfont.woff") format("woff"),
         url("#{$woodford-font-path}woodfordbournepro-ultraitalic-webfont.ttf") format("truetype"),
         url("#{$woodford-font-path}woodfordbournepro-ultraitalic-webfont.svg#woodford_bourne_proultraIt") format("svg");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face{
    font-family:"Neue Helvetica eText";
    src:url("#{$helvetica-font-path}e0781a75-0ecb-464e-b6e9-85780ddc0921.eot?#iefix");
    src:url("#{$helvetica-font-path}e0781a75-0ecb-464e-b6e9-85780ddc0921.eot?#iefix") format("eot"),url("#{$helvetica-font-path}b8765d4b-d9a3-48b9-ac65-560e7517cf0e.woff2") format("woff2"),url("#{$helvetica-font-path}d7d2e6c6-fc3a-41a5-9b52-648e12e215b2.woff") format("woff"),url("#{$helvetica-font-path}cb64744b-d0f7-4ef8-a790-b60d6e3e4f21.ttf") format("truetype"),url("#{$helvetica-font-path}ccde919e-a712-41ef-b90f-9efc47661659.svg#ccde919e-a712-41ef-b90f-9efc47661659") format("svg");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Neue Helvetica eText";
    src:url("#{$helvetica-font-path}fc780ce0-5b33-4e1d-b89b-66111a4d0ed8.eot?#iefix");
    src:url("#{$helvetica-font-path}fc780ce0-5b33-4e1d-b89b-66111a4d0ed8.eot?#iefix") format("eot"),url("#{$helvetica-font-path}0381d699-73ef-4558-9e61-38385c9fc5da.woff2") format("woff2"),url("#{$helvetica-font-path}2731e338-d92f-4492-8480-8b55ae1139f6.woff") format("woff"),url("#{$helvetica-font-path}40e43cb5-d6b2-4ceb-b369-527d1007a2c7.ttf") format("truetype"),url("#{$helvetica-font-path}c1993d56-4077-454a-8b19-635fa5a39532.svg#c1993d56-4077-454a-8b19-635fa5a39532") format("svg");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
@font-face{
    font-family:"Neue Helvetica eText";
    src:url("#{$helvetica-font-path}706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix");
    src:url("#{$helvetica-font-path}706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix") format("eot"),url("#{$helvetica-font-path}dc50c02f-3f77-4e75-b89c-e3f9bb4752e6.woff2") format("woff2"),url("#{$helvetica-font-path}ce6f5b38-1cb5-4a27-8182-583aa68b2436.woff") format("woff"),url("#{$helvetica-font-path}ff06cde7-e06a-4cf0-af4d-5b2f737bf544.ttf") format("truetype"),url("#{$helvetica-font-path}958b12cf-2908-4254-8cb9-9b12c3aa151e.svg#958b12cf-2908-4254-8cb9-9b12c3aa151e") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Neue Helvetica eText";
    src:url("#{$helvetica-font-path}5935d076-bd6d-4ca4-b995-d739ffa09754.eot?#iefix");
    src:url("#{$helvetica-font-path}5935d076-bd6d-4ca4-b995-d739ffa09754.eot?#iefix") format("eot"),url("#{$helvetica-font-path}56e66f56-3016-44fd-84b2-8e40d9743656.woff2") format("woff2"),url("#{$helvetica-font-path}4d65bb6f-e667-4bd0-8e92-34e67bf6ac8b.woff") format("woff"),url("#{$helvetica-font-path}86631d82-6e27-4db2-988a-6b79a6c0d8ed.ttf") format("truetype"),url("#{$helvetica-font-path}205c950e-e47f-4d97-9738-0a68edc4258d.svg#205c950e-e47f-4d97-9738-0a68edc4258d") format("svg");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face{
    font-family:"Neue Helvetica eText";
    src:url("#{$helvetica-font-path}a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix");
    src:url("#{$helvetica-font-path}a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix") format("eot"),url("#{$helvetica-font-path}6dfa3ef5-ebc5-4d23-a631-b3d746c2ad39.woff2") format("woff2"),url("#{$helvetica-font-path}588c9ba8-1d28-4ad6-bf18-7128152d84c4.woff") format("woff"),url("#{$helvetica-font-path}55334dfb-9c72-4147-8de0-21239f17ff25.ttf") format("truetype"),url("#{$helvetica-font-path}62455047-c81f-44f1-8938-7739fa0f0e8d.svg#62455047-c81f-44f1-8938-7739fa0f0e8d") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Neue Helvetica eText";
    src:url("#{$helvetica-font-path}a48e33d0-3542-482d-9284-e41d31c4d583.eot?#iefix");
    src:url("#{$helvetica-font-path}a48e33d0-3542-482d-9284-e41d31c4d583.eot?#iefix") format("eot"),url("#{$helvetica-font-path}15ed0ff2-0715-4aca-af0c-00840c3a4dd7.woff2") format("woff2"),url("#{$helvetica-font-path}9b77b336-b221-497e-b255-7833856bb4a9.woff") format("woff"),url("#{$helvetica-font-path}5c9a7798-4870-4139-ab84-7a300a7dc3e1.ttf") format("truetype"),url("#{$helvetica-font-path}3b59e91c-919a-4e7a-9ada-8c73b3378f03.svg#3b59e91c-919a-4e7a-9ada-8c73b3378f03") format("svg");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}


$font-regular: "Neue Helvetica eText", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
               Helvetica, "Segoe UI", Arial, sans-serif;
$font-headings: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
