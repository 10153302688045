.community-gallery {

    &__list {
        @include clearfix;
        @extend %list-clean;
        @include make-row();
        display: block;
    }

    &__item {
        @extend %list-clean;
        @include make-col-ready();

        float: left;
        margin: 0 0 $gallery-gutter-width-base/2 0;
        padding-left: 5px;
        padding-right: 5px;

        width: 50%;
        &--lg {
            width: 100%;
        }

        @include media-breakpoint-up(md){
            width: 25%;
            margin: 0 0 $gallery-gutter-width-base 0;
            padding-left: 10px;
            padding-right: 10px;

            &--lg {
                width: 50%;
            }
        }
    }
    &__link {
        cursor: zoom-in;
    }
}
