.automowers-landing-form-wrapper {

  width: 100%;

  @include media-breakpoint-up(lg) {
    position: relative;

    margin-top: -220px;

    background-color: #fff;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    margin-top: -400px;
  }
}