.careers-form {

    &__heading {
        font-size: 50px;
        font-weight: bold;
    }

    &__img-wrap,
    &__img-wrap--second,
    &__img-wrap--flower {
        z-index:  2;

        height: 440px;
        position: absolute;
        bottom: 0;
        left: 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            height: 540px;
        }
    }

    &__img-wrap {

        height: 430px;
        left: -80px;

        @include media-breakpoint-up(lg) {
            height: 500px;
            left: -50px;
        }
    }

    &__img-wrap--second {
        z-index:  1;

        height: 497px;
        left: 90px;

        @include media-breakpoint-up(lg) {
            height: 601px;
            left: 130px;
        }
        @include media-breakpoint-up(xl) {
            left: 210px;
        }
    }

    &__img-wrap--flower {
        height: 180px;
        left: -95px;
        z-index: 3;

        @include media-breakpoint-up(lg) {
            bottom: 35px;
        }
        @include media-breakpoint-up(xl) {
            left: -125px;
        }

    }

    &__img-wrap--opposite {
        @extend .careers-form__img-wrap;

        height: 410px;
        left: -85px;

        @include media-breakpoint-up(lg) {
            height: 500px;
            left: -30px;
        }

        @include media-breakpoint-up(xl) {
            left: 30px;
        }
    }
    &__img-wrap--second--opposite {
        @extend .careers-form__img-wrap--second;

        @include media-breakpoint-up(lg) {
            left: 200px;
        }

        @include media-breakpoint-up(xl) {
            left: 250px;
        }
    }

    &__img-wrap--flower--opposite {
        @extend .careers-form__img-wrap--flower;

        left:  auto;
        right: -60px;
        bottom: 10px;

        @include media-breakpoint-up(lg) {
            right: -30px;
        }
    }

    &__img {
        height:  100%;
        max-width:  none;
        width:  auto;
        max-height: 100%;
    }

    &__form {
        margin-bottom: 40px;
        z-index: 10;
    }

}