.cta {
    @include antialias;

    position: relative;

    color: #fff;
    background-color: $color-teal-light;
    text-align: center;

    padding: $spacer*4 0;

    overflow: hidden;

    &__icon {
        position: absolute;
        right: 10px;
        top: 0;

        max-width: 350px;
        opacity: 0.2;

        transform: translateY(-25%);
    }

    &__buttons {
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-around;
        }
    }

    &__button {
        margin-bottom: $spacer;
    }

    h1, h2, h3 {
        color: inherit;
        margin: 0 auto;
        max-width: 740px;
    }

    h2 {
        margin-bottom: $spacer * 1.5;
    }

    p {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

}
