img {
    height: auto;
    max-width: 100%;
}

.rounded-edge {
    border-radius: 10px;
}

.shadow-edge {
    box-shadow: 0px 4px 25px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 4px 25px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 4px 25px 0px rgba(0,0,0,0.15);
}