.careers-post {

    &__email-link {

        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 1.875rem;
        }
    }

    &__heading {
        font-size: 24px;
        font-weight: bold;
    }

    &__closing {
        font-size: 12px;
        font-weight: 200;
    }
}
