.intake-form-intro {

    position: relative;

    padding-top: calc(60px + 8vh);

    background-position: center bottom;
    background-size: cover;

    &:before {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        content: '';

        background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(40, 167, 69, 0) 75%);
        mix-blend-mode: multiply;
    }

    &:after {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        content: '';

        background-image: linear-gradient(to top, rgba(#fff, 1), rgba(#fff, 1) 195px, rgba(#fff, 0) 195px);
    }

    &__branch {
        position: absolute;
        right: 0;
        top: 0;

        display: none;
        height: 200px;
        overflow: hidden;
        width: 300px;

        transform: scale(.5) rotate(185deg) translate(-45%, 150%);

        @include media-breakpoint-up(md) {
            display: block;
        }

        @include media-breakpoint-up(lg) {
            transform: scale(.8) rotate(180deg) translate(-40%, 112%);
        }

        @include media-breakpoint-up(xl) {
            transform: scale(.9) rotate(180deg) translate(-40%, 105%);
        }
    }

    &__branch-img {
        left: 0;
        position: absolute;
        bottom: 0;

        max-width: auto;
    }

    &__p {
        margin: 0 auto;
        max-width: 680px;
    }

    &__fields {
        position: relative;
        z-index: 2;

        margin: 0 auto;
        max-width: 1340px;
        padding-top: 50px;

        background-color: rgba(#fff, .8);
        background-image: linear-gradient(to top, rgba(#fff, 1), rgba(#fff, 1) 195px, rgba(#fff, 0) 295px);

        @include media-breakpoint-up(xl) {

            padding-top: 70px;
        }
    }
}