.plan-card {
    width: 100%;
    text-align: center;
    // border: solid 1px $color-gray-border;
    padding: 0;
    border-radius: 10px;
    color: $color-gray;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
    &__image {
        width: 100%;
        padding-bottom: 50%;
        border-radius: 10px 10px 0 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        .number {
            font-family: $font-headings;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #fff;
            font-size: 3em;
            font-weight: 700;
            line-height: 1;
            @include media-breakpoint-down(md){
                font-size: 2em;
            }
            @include media-breakpoint-down(sm){
                font-size: 3em;
            }
            span {
                display: block;
                opacity: 0.8;
                margin: 0 0 5px 0;
                font-size: 1.2em;
            }
        }
        &.blue {
            background-color: $color-teal-light;
        }
        &.green {
            background-color: $color-green;
        }
        &.peach {
            background-color: $color-orange;
        }
    }
    &__content {
        font-family: $font-headings;
        line-height: 1.8;
        padding: 15px 30px;
        font-size: 18px;
    }
}