.carousel {
    padding-left: 60px;
    padding-right: 60px;

    margin-bottom: $grid-gutter-width-base;

    &__list {
        @extend %list-clean;
        @include make-row;
        @include clearfix;
        display: block;

        overflow: hidden;
    }

    &__item {
        @extend %list-clean;
        @include make-col-ready;
        float: left;
    }

    &__arrow {
        border-bottom: 1px solid #9C9C9C;
        border-left: 1px solid #9C9C9C;
        width: 63px;
        height: 63px;

        position: absolute;
        top: 50%;

        transform: translateY(-50%);

        &:hover {
            border-color: darken(#9C9C9C, 20%);
        }

        &--prev {
            @extend .carousel__arrow;

            left: -40px;
            transform: translateY(-50%) rotate(45deg);
        }
        &--next {
            @extend .carousel__arrow;

            right: -40px;
            transform: translateY(-50%) rotate(-135deg);
        }
    }
}
