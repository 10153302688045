.article-box {
    position: relative;

    // text-align: right;

    margin-top: $spacer*2;

    @include media-breakpoint-up(md) {
        margin-bottom: 75px;
    }

    &__wrap {
        background-color: $color-gray-lighter;
        display: inline-block;

        padding: $spacer*2 $spacer*1.5;

        text-align: left;

        @include media-breakpoint-up(sm) {
            padding-right: $spacer*5;
        }
    }

    &__title {
        margin-bottom: $spacer;
    }

    &__list {
        margin-left: 0;
        padding-left: 1.25em;

        font-size: $font-size-lg;
    }

    &__icon {
        display: none;

        max-width: 50%;
        position: absolute;
        bottom: 0;
        right: $spacer*1.5;
        transform: translateY(50%);

        @include media-breakpoint-up(md) {
            display: block;
        }

        &--gloves {

            @include media-breakpoint-up(lg) {
                bottom: -30px;
            }

            @include media-breakpoint-up(xl) {
                bottom: -50px;
            }
        }
    }
}
