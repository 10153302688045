/* CAREER PATHS */
.career-paths {
    padding-bottom: 50px;
    &__list {
        position: relative;
        top: 0;
        padding:  0;
        display:  flex;
        flex-direction:  row;
        flex-wrap: wrap;
        @media (max-width: 640px) {
            display: grid;
            grid-template-columns: 1fr;
        }
        
    }
    
    &__item {
        flex: 1;
        margin: 0 8px;
        position: relative;
        cursor: pointer;
        transition: all ease-in .25s;
        &:hover {
            opacity: 0.95;
        }
        padding:  0;
        margin: 0 20px 40px;
        list-style-type: none;

        width: calc(50% - 40px);

        text-align:  center;

        @include media-breakpoint-down(xs) {
            margin: 0 0px 20px;
            width: calc(50% - 10px);
        }
        @include media-breakpoint-up(lg) {
            // width: auto;
        }
        &-link {
            display: block;
            background-color: white;
            overflow: hidden;
            &--image {
                position: relative;
                padding-bottom: 60%;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                }
            }
            &--content {
                background-color: white;
                color: $color-teal;
                padding: 20px;
                h4 {
                    color: $color-gray;
                    margin: 0 0 10px;
                    font-size:  24px;
                    font-weight:  bold;
                }
            }
        }
    }
    &__link {
        text-transform:  uppercase;
        font-size:  12px;
        line-height:  1;
    }
}
    

/* CAREER PATHS POPUP STAGE */
.career-paths-stage {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background: #3376b7;
    background: linear-gradient(0deg,#3376b7 0%, #124185 80%);
    background: -webkit-linear-gradient(0deg,#3376b7 0%, #124185 80%);
    background: -moz-linear-gradient(0deg,#3376b7 0%, #124185 80%);
    color: #fff;
    display: none;
    @media (min-width: 1000px) {
          min-height: 720px;
          overflow: auto;
    }
    &__close {
        position: absolute;
        top: 20px;
        right: 8px;
        z-index: 1000;
        display: block;
        height: 1em;
        width: 1em;
        color: rgba(255, 255, 255, 0);
        cursor: pointer;
        text-decoration: none;
        font-size: 30px;
        opacity: 0.95;
        transition: 0.2s all ease-out;
        @media (min-width: 768px) {
            right: 14px;
            top: 35px;
        }
        @media (min-width: 1200px) {
            right: 30px;
            top: 35px;
            font-size: 40px;
        }
        &:hover {
            opacity: 1;
            color: transparent;
        }
        &:after, 
        &:before {
            left: 0;
            position: absolute;
            top: 0;
            content: '';
            height: 3px;
            width: 100%;
            background-color: $color-teal;
        }
        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
    &__video-overlay {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        mix-blend-mode: multiply;
        background-color: rgba(0, 0, 0, 0.8);
    }
    &__video-overlay-2 {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.25);
    }
    &__video {
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

/* CAREER PATHS DETAIL CARD */

.career-path-detail-card {
    position: relative;
    z-index: 2000;
    margin: 40px 0;
    padding: 20px;
    min-height: 114px;
    height: 114px;
    width: 172px;
    overflow: hidden;
    background-color: $color-blue;
    border: solid 2px $color-blue;
    text-align: left;
    transition: border-color 0.2s ease-out;
    @media (min-width: 1000px) {
        min-height: 170px;
        height: 170px;
        min-width: 260px;
        width: 260px;
        font-size: 20px;
    }
    &:hover {
        background-color: $color-teal;
        border-color: $color-teal;
    }
    .btn {
        margin-top: 20px;
    }
    .sr-hidden {
        display: none;
    }
    &__group {
        position: relative;
        padding-left: 35px;
        background-image: url(../img/career-path-connector-1-to-1.svg);
        background-position: center center;
        background-size: 1px 100%;
        background-repeat: repeat-x;
        &:first-of-type {
            padding-left: 0;
        }
        &--two-cards {
            padding: 20px 35px;
            /* background-image: url(../img/career-path-connector-1-to-2.svg), url(../img/career-path-connector-2-to-1.svg);
            background-size: auto;
            background-repeat: no-repeat;
            background-position: 0 center, right center; */
            &:first-of-type {
                padding-left: 0;
                /* background-image: url(../img/career-path-connector-2-to-1.svg);
                background-position: right center; */
            }
            & + .career-path-detail-card__group {
                padding-left: 0;
            }
        }
    }
    &__link {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding: 10px;
        color: #fff;
        text-decoration: none;
        text-align: center;
        transition: border-color 0.2s ease-out;
        &-text {
            font-weight: bold;
            font-size: 1.3em;
            transition: all 0.2s ease-out;
        }
        &:hover {
            color: white;
        }
    }
    &--active {
        border-color: white;
        color: black;
        .career-path-detail-card__link {
            display: none;
            z-index: 1;
        }
        .career-path-detail-card__link-text {
            opacity: 0;
        }
    }
    &__position-card-neighbour-group .career-path-detail-card:first-of-type {
        margin-bottom: 30px;
    }
    &__content {
        position: relative;
        z-index: 2;
        display: none;
        background-color: rgba(255, 255, 255, 0.9);
    }
    &__close {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1000;
        display: block;
        opacity: 0.65;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;
        &:hover {
            opacity: 1;
        }
    }
    &__inner {
        height: calc(100vh - 80px);
        max-height: 600px;
        overflow: scroll;
    }
    &__image {
        max-width: 100%;
        min-height: 360px;
        background-size: cover;
        background-position: center;
    }
    &__expand-icon {
        position: absolute;
        right: 4px;
        transform: rotate(-5deg);
        top: 5px;
        opacity: 0.95;
        transform: all 2s ease-out;
        &:hover {
            opacity: 1;
        }
    }
    &__copy {
        min-height: 150px;
        padding: 30px 20px;
        font-size: 14px;
        line-height: 1.5;
        h3 {
            margin-bottom: 0.25em;
            font-size: 30px;
        }
        ul {
            padding: 12px 0 12px 20px;
        }
        li {
            position: relative;
            margin-bottom: 0.5em;
            &:before {
                left: -16px;
                position: absolute;
                top: 6px;
                content: '';
                display: block;
                width: 0.5em;
                height: 0.5em;
                background-color: #8CC63F;
                border-radius: 50%;
                opacity: 0.5;
            }
        }
        p {
            margin-bottom: 0.5em;
        }
    }
    &__border{
        width: 100%;
        height: 1px;
        background-color: #8CC63F;
        margin: 20px 0;
    }
    &__expectations {
        margin-top: 20px;
        padding-top: 20px;
    }
    &__actions {
        position: relative;
        padding: 5px 20px 12px;
        @media (min-width: 768px) {
            margin-left: -20px;
        }
        @media (min-width: 768px) {
            margin-left: -20px;
        }
    }
    &__prev, 
    &__next {
        position: absolute;
        right: 8px;
        top: -28px;
        width: 20px;
        overflow: hidden;
        opacity: 0.25;
        transition: all 0.2s ease-out;
        transform: scale(1.5, 0.85);
        &:hover {
            opacity: 1;
        }
        &.career-path-detail-card__arrow {
            transform: rotate(-45deg);
        }
    }
    &__prev {
        right: 50px;
        &.career-path-detail-card__arrow {
            right: -10px;
        }
    }
    &__next { 
        &.career-path-detail-card__arrow {
            left: -10px;
        }
    }
    &__arrow {
        position: relative;
        display: block;
        height: 20px;
        width: 20px;
        margin: 0.5em 0;
        background-color: #607B87;
    }
}

/* CAREER PATHS POPUP DETAIL */
  
.career-path-detail {
    position: relative;
    z-index: 3;
    display: none;
    height: 100%;
    min-height: 100vh;
    text-align: center;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-color: $color-teal-lighter;
    &--active {
        display: flex;
    }
    &__positions {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        overflow: scroll;
        user-select: none;
        cursor: pointer;
        &.active {
            cursor: grabbing;
            cursor: -webkit-grabbing;
        }
        &-inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-height: 100%;
        }
    }
    &__heading {
        padding: 30px;
        color: $color-teal;
        font-size: 35px;
        @media (max-width: 600px) {
            font-size: 20px!important;
        }
    }
}