// Primary colors
$color-green: #b9c882;
$color-gray-dark: #464342;
$color-blue: #006a7c;

// Secondary colors
$color-gray-light: #bbbdc0;
$color-green-dark: #8fb04e;
$color-teal: #50928b;
$color-teal-light: #7fc3ba;
$color-teal-lighter: #d8ece9;
$color-orange: #eca688;
$color-red: #d3636b;
$color-yellow: #eadf6e;
$color-purple: #88788E;

$color-black: #353535;
$color-gray-medium: #575755; /* base font-color */
$color-gray: #939393;
$color-gray-lighter: #f4f4f4;
$color-gray-border: #e7e7e7;

$color-blue-dark: #272f39;
$color-brown: #786F6C;

$selectable-colors: (
  'green': $color-green,
  'blue': $color-blue,
  'blue-dark': $color-blue-dark,
  'green-dark': $color-green-dark,
  'teal': $color-teal,
  'teal-light': $color-teal-light,
  'orange': $color-orange,
  'red': $color-red,
  'yellow': $color-yellow,
  'black': $color-black,
  'gray-dark': $color-gray-dark,
  'gray': $color-gray,
  'gray-medium': $color-gray-medium,
  'gray-light': $color-gray-light,
  'gray-lighter': $color-gray-lighter
);
