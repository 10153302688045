.maintenance-items {

    &__wrap {
        @include media-breakpoint-up(lg) {
            padding-bottom: 30px;
            padding-top: 280px;
            margin-top: -250px;

            background-image: linear-gradient(to right, lighten($color-gray-light, 60) 50%, white 50%);
        }
    }

    @include media-breakpoint-up(lg){
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 60px;
    }

    &--included {

        h2 {
             color: $color-teal;
        }
    }

    &--included &__copy {
        @include media-breakpoint-up(lg) {
            padding-right: 30px;
        }
    }

    &--included &__aside {
        @include media-breakpoint-up(xl) {
            height: 500px;
            max-height: 500px;
        }
    }

    &--included &__image-wrap {
        @include media-breakpoint-up(xl) {
            height: 500px;
            max-height: 500px;
        }
    }

    &--addons {

        h2 {
             color: $color-red;
        }

    }

    &--addons &__copy {
        @include media-breakpoint-up(lg) {
            padding-left: 30px;
            order: 2;
        }
    }

    &--addons &__aside {
        order: 1;

        @include media-breakpoint-up(xl) {
            height: 350px;
        }
    }

    &__copy {

        @include media-breakpoint-up(lg) {
            width: 45%;
        }

        p {
            margin-bottom: 16px;
            font-size: 110%;
        }


        li {
            position: relative;

            list-style: none;

            &:before {
                left: -10px;
                position: absolute;
                top: 8px;

                content: '';

                height: 8px;
                width: 8px;

                background-color: $color-green;
                border-radius: 50%;
            }
        }

        strong {
            font-family: $font-headings;
        }
    }

    &__aside {

        position: relative;

        margin-bottom: 40px;

        max-height: 400px;
        max-width: 615px;

        @include media-breakpoint-up(lg) {
            width: 55%;
            height: 320px;
        }

    }

    &__image-wrap {

        position: relative;

        overflow: hidden;
        max-height: 400px;

    }

    &__image {
        width: 100%;
    }

    &__flower {
        bottom: 0;
        position: absolute;

        &--left {
            width: 250px;

            left: 0;
            transform: translate(-70%, 30%) rotate(60deg);

            @include media-breakpoint-up(lg) {
                left: 60px;
            }
        }

        &--right {
            right: 0;

            max-width: 330px;

            transform: translate(50%, 33%);
        }
    }
}