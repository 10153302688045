.service-area-callout {
  padding: 80px 0 160px;

  background-image: url('../img/atlanta-sky-shot@2x.jpg');
  background-position: center;
  background-size: cover;

  @include media-breakpoint-up(xl) {
    padding: 80px 0 220px;
  }
}
