/*
|--------------------------------------------------------------------------
| Main
|
| This is the main scss file that pulls in all the other files.
|--------------------------------------------------------------------------
*/
@import 'settings/colors.scss';
@import 'settings/container.scss';
@import 'settings/fonts.scss';
@import 'settings/gallery.scss';

/*
|--------------------------------------------------------------------------
| Vendor files
|
| Try and use NPM to manage all vendor files if possible.
|--------------------------------------------------------------------------
*/

// Core variables and mixins
@import 'bootstrap-custom';
@import '../../node_modules/bootstrap/scss/bootstrap';

// Magnific Popup
@import '../../node_modules/magnific-popup/src/css/main';

/*
|--------------------------------------------------------------------------
| Mixins
|
|
|--------------------------------------------------------------------------
*/
@import 'mixins/antialias.scss';
@import 'mixins/aspect-ratio.scss';
@import 'mixins/clearfix.scss';
@import 'mixins/em.scss';
@import 'mixins/make-color-modifiers.scss';

/*
|--------------------------------------------------------------------------
| Extends
|
| Objects that can be extended for use inside of blocks
|--------------------------------------------------------------------------
*/
@import 'extends/light.scss';
@import 'extends/list-checks.scss';
@import 'extends/list-clean.scss';
@import 'extends/overlap-banner.scss';

/*
|--------------------------------------------------------------------------
| Base
|
| Basic building blocks, these are similar to blocks, but used more
| globally.
|--------------------------------------------------------------------------
*/
@import 'base/body.scss';
@import 'base/buttons.scss';
@import 'base/debug.scss';
@import 'base/html.scss';
@import 'base/icons.scss';
@import 'base/images.scss';
@import 'base/links.scss';
@import 'base/tables.scss';
@import 'base/typography.scss';

/*
|--------------------------------------------------------------------------
| Blocks
|
| Each block should be saved in it’s own file.
|--------------------------------------------------------------------------
*/
@import 'blocks/about.scss';
@import 'blocks/aftercare.scss';
@import 'blocks/application-sent.scss';
@import 'blocks/article-box.scss';
@import 'blocks/article.scss';
@import 'blocks/automower-faq.scss';
@import 'blocks/automower-value.scss';
@import 'blocks/automowers-landing-form-wrapper.scss';
@import 'blocks/awards.scss';
@import 'blocks/backyard.scss';
@import 'blocks/bg-color.scss';
@import 'blocks/blog-article-card.scss';
@import 'blocks/blog-article.scss';
@import 'blocks/blog-aside.scss';
@import 'blocks/blog-header.scss';
@import 'blocks/blog-listing.scss';
@import 'blocks/blog-post-list.scss';
@import 'blocks/blog-post.scss';
@import 'blocks/blog-wrap.scss';
@import 'blocks/blueridge-services.scss';
@import 'blocks/career-gallery.scss';
@import 'blocks/careers__banner.scss';
@import 'blocks/careers-callout.scss';
@import 'blocks/careers-form.scss';
@import 'blocks/careers-listing-page.scss';
@import 'blocks/careers-listing.scss';
@import 'blocks/careers-page.scss';
@import 'blocks/careers-path.scss';
@import 'blocks/careers-post.scss';
@import 'blocks/carousel.scss';
@import 'blocks/community-gallery.scss';
@import 'blocks/community-partners.scss';
@import 'blocks/community-section.scss';
@import 'blocks/community.scss';
@import 'blocks/contact-info.scss';
@import 'blocks/contact-page.scss';
@import 'blocks/cta.scss';
@import 'blocks/design-package-content.scss';
@import 'blocks/design-packages.scss';
@import 'blocks/design-steps.scss';
@import 'blocks/employee-detail.scss';
@import 'blocks/employees.scss';
@import 'blocks/feature-cards.scss';
@import 'blocks/featured-projects-callout.scss';
@import 'blocks/featured-projects.scss';
@import 'blocks/flower.scss';
@import 'blocks/fluid-container.scss';
@import 'blocks/footer-nav.scss';
@import 'blocks/form.scss';
@import 'blocks/front-yard.scss';
@import 'blocks/fw-bg.scss';
@import 'blocks/gallery.scss';
@import 'blocks/get-started-page.scss';
@import 'blocks/guarantee.scss';
@import 'blocks/home.scss';
@import 'blocks/homepage-blog-articles.scss';
@import 'blocks/image-edge.scss';
@import 'blocks/inner-value-stack.scss';
@import 'blocks/intake-form-gallery.scss';
@import 'blocks/intake-form-intro.scss';
@import 'blocks/intake-form-recommended-design-packages.scss';
@import 'blocks/intake-form-save-and-continue-dialog.scss';
@import 'blocks/intake-form-scope-selection.scss';
@import 'blocks/intake-form.scss';
@import 'blocks/internship.scss';
@import 'blocks/intro.scss';
@import 'blocks/irrigation-packages.scss';
@import 'blocks/landscape-detail-page.scss';
@import 'blocks/landscapes-listing.scss';
@import 'blocks/main-nav.scss';
@import 'blocks/maintenance-add-ons.scss';
@import 'blocks/maintenance-callout.scss';
@import 'blocks/maintenance-electric.scss';
@import 'blocks/maintenance-garden-gloves.scss';
@import 'blocks/maintenance-items.scss';
@import 'blocks/maintenance-landing.scss';
@import 'blocks/maintenance-packages.scss';
@import 'blocks/maintenance.scss';
@import 'blocks/map.scss';
@import 'blocks/menu-icon.scss';
@import 'blocks/nourish.scss';
@import 'blocks/other-products.scss';
@import 'blocks/package-table.scss';
@import 'blocks/packages.scss';
@import 'blocks/page-banner.scss';
@import 'blocks/page-hero.scss';
@import 'blocks/plan-card.scss';
@import 'blocks/product-price.scss';
@import 'blocks/products-listing-v2.scss';
@import 'blocks/products-listing.scss';
@import 'blocks/project-header.scss';
@import 'blocks/projects-listing.scss';
@import 'blocks/promo-callout.scss';
@import 'blocks/promo-page.scss';
@import 'blocks/referral-offer-list.scss';
@import 'blocks/referrals-page.scss';
@import 'blocks/regular-stack-item.scss';
@import 'blocks/service-area-callout.scss';
@import 'blocks/site-credit.scss';
@import 'blocks/site-footer.scss';
@import 'blocks/site-header.scss';
@import 'blocks/site-logo.scss';
@import 'blocks/site-main.scss';
@import 'blocks/smart-landscapes.scss';
@import 'blocks/social-profile-links.scss';
@import 'blocks/testimonial.scss';
@import 'blocks/testimonials.scss';
@import 'blocks/thank-you-page.scss';
@import 'blocks/uniform.scss';
@import 'blocks/value-stack-item.scss';
@import 'blocks/video-testimonial.scss';
@import 'utilities/branch.scss';
@import 'utilities/visually-hidden.scss';
