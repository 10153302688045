.fw-bg {
    background-color: #edeeee;

    &--light-grey {
        background-color: #fafafa;
        position: relative;
    }

    &--green {
        background-color: #50928D;
    }
}