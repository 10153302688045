.community-sections {
    text-align: center;

    &__section {
        padding-top: calc(#{$spacer*2} + 5vh);
        padding-bottom: calc(#{$spacer*2} + 3vh);
        &:nth-child(2n + 1) {
            background: $color-gray-lighter;
        }
    }

    &__intro {
        margin-bottom: $spacer*3;
        max-width: 560px;
        margin: auto;
    }

    &__gallery {
        margin-bottom: $spacer*3;
    }
}
