.maintenance-electric {
    background-color: $color-orange;
    border-radius: 10px;
    padding: 80px 20px;
    color: #fff;
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        bottom: -60px;
        opacity: 0.3;
        left: 30px;
        width: 280px;
        height: 240px;
        transform: rotate(74deg);
        background-image: url(../img/icons/branches/1.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    h3 {
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
    }
}