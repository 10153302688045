.article {

    &__image {


    }

    &__body {

        color: $color-gray-dark;

        padding-top: calc(#{$spacer} + 4vh);
        margin-bottom: calc(#{$spacer*2} + 5vh);

        @include media-breakpoint-up(md) {
            padding-top: calc(#{$spacer * 3} + 4vh);
        }
    }

    &__pl40 {
        padding-left: 0px;
        @include media-breakpoint-up(md) {
            padding-left: 40px;
        }
    }

    &__category {
        font-size: $font-size-lg;
        font-weight: 400;
        text-transform: uppercase;

        color: $color-black;
    }

    &__intro {
        font-size: $font-size-lg;
    }
}
