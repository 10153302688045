.site-footer {
    @include antialias;
    background: $color-blue;
    color: white;
    padding: 40px 0 0;
    .footer-flex {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        .footer-menu {
            display: flex;
            width: 68%;
            margin-right: 5%;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-right: 0;
                flex-direction: column;
            }
            .logos {
                img {
                    width: 150px;
                    max-height: 70px;
                    margin-right: 60px;
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
            }
        }
        .footer-contact {
            display: flex;
            flex-direction: column;
            width: 24%;
            padding-left: 3%;
            border-left: solid 1px #fff;
            @include media-breakpoint-down(md) {
                width: 100%;
                padding-left: 0;
                border-left: none;
                border-top: solid 1px #fff;
                padding-top: 40px;
                margin-bottom: 20px;
            }
            &__phone {
                @include media-breakpoint-up(lg) {
                    grid-template-columns: repeat(1, 1fr);
                }
                @include media-breakpoint-down(md) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr;
                    grid-column-gap: 40px;
                    grid-row-gap: 0px;
                }
                @include media-breakpoint-down(xs) {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
            .phone-label {
                font-size: 0.7em;
                text-transform: uppercase;
            }
            .phone {
                font-size: 1.7em;
                font-weight: bold;
                color: $color-green;
                &.mb10 {
                    margin-bottom: 20px;
                }
            }
            .phone,
            .email {
                margin-bottom: 15px;
            }
        }
    }
}
