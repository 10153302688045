.design-package-content {

    text-align: center;

    ul {
        @extend %list-clean;
    }

    &__block-1, &__block-2 {
        position: relative;

        padding-bottom: 40px;
        padding-top: 60px;
    }

    &__block-1 {
        @include media-breakpoint-up(md) {
            padding-bottom: 100px;
            padding-top: 80px;
        }

        background-image:
        linear-gradient(#fff, rgba(#fff,0) 10%),
        url(/assets/img/landscapes/design-packages-upgrade-background.jpg);
        background-position: center center;
        background-size: cover;
        background-color: $color-gray-lighter;

        h2 {
            color: $color-teal-light;
        }

        h4 + p {
            color: lighten($color-gray, 10);
        }

        ul {
            margin-bottom: 40px !important;
            margin: 0 auto;
            max-width: 760px;

            font-size: 20px;
        }

        li {
            padding-bottom: 20px;

            border-bottom: solid 2px rgba(#fff,.5);
        }
    }

    &__block-2 {
        background-color: lighten($color-gray-light, 45);

        ul {
            max-width: 700px;
            margin: 30px auto 15px;

            @include media-breakpoint-up(md) {
                display: flex;
                justify-content: space-between;
            }
        }

        li {
            padding-bottom: 20px;

            @include media-breakpoint-up(md) {
                width: 30%;
            }
        }
    }

    &__flower {
        position: absolute;

        z-index: 1;

        &--1 {
            left: -150px;
            bottom: -270px;

            max-width: 35%;

            transform: scale(.85);

            @include media-breakpoint-up(md) {
            }
        }
    }
}