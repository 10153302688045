.page-banner {
    @include antialias;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    line-height: 1.75em;
    background: rgba(0,106,124,0.1);
    background: linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(244,244,244,0) 100%);
    background: -webkit-linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(244,244,244,0) 100%);
    background: -moz-linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(244,244,244,0) 100%);

    @include media-breakpoint-down(xs) {
        margin-bottom: $spacer;
    }

    .fluid-container {
        max-width: 1400px; 
        margin: 0 auto;
        padding: 0;
    }

    &__flower {
        position: absolute;
        left: -107px;
        top: -57px;
        width: 291px;
        transform: rotate(-5deg);

        @include media-breakpoint-down(md) {
            left: -47px;
            top: -50px;
            width: 201px;
        }
        @include media-breakpoint-down(xs) {
            left: -47px;
            top: 10px;
        }
    }
    &__flower-right {
        position: absolute;
        right: -67px;
        top: -97px;
        width: 291px;
        transform: rotate(38deg);
        @include media-breakpoint-down(md) {
            right: -47px;
            top: -70px;
            width: 201px;
        }
        @include media-breakpoint-down(xs) {
            right: -47px;
            top: -70px;
        }
    }

    &__space {
        padding: 130px 0;
        @include media-breakpoint-down(md) {
            padding: 60px 0 0;
        }
    }
    &__space-sm {
        padding: 20px 0 0 0;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
    &__box {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: center;
        width: 50%;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        &-image {
            position: relative;
            width: 100%;
            display: block;
            &:after {
                content: "";
                display: block;
                padding-bottom: 80%;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                @media only screen and (max-width: 1500px) {
                    border-radius: 10px 0 0 10px;
                }
                @include media-breakpoint-down(md) {
                    border-radius:0;
                }
            }
        }
        &-content {
            padding: 70px 15px 70px 15px;
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            @include media-breakpoint-down(lg) {
                padding: 70px 15px 70px 15px;
            }
            @include media-breakpoint-down(md) {
                text-align: center;
                padding: 70px;
            }
            @include media-breakpoint-down(sm) {
                text-align: center;
                padding: 70px 20px 20px;
            }
        }
        &-full {
            width: 100%;
        }
        .width-sm {
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
        }
        .btn {
            margin-top: 20px;
        }
    }
      
    &__play-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        width: 150px;
        height: 150px;
        display: inline-block;
        transform: translate(-50%, -50%);
    }

    &__heading {
        color: $color-teal;
        font-size: 4em;
        @include media-breakpoint-down(md) {
            font-size: 3.5em;
        }
        max-width: 1000px;
        margin: 0 auto 20px auto;
    }

    &__intro {
        color: $color-gray;
        padding-right: 60px;
        @include media-breakpoint-down(md) {
            padding-right: 0;
        }
        
    }
}

.vimeo-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.vimeo-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}
.vimeo-close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    &:hover,
    &:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
}