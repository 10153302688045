.inner__value {
    padding-top: 100px;
    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
    .inner-stack-item {
        padding: 0 0 150px 0;
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        .container {
            max-width: 1400px;
        }
        &__flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
        &__box {
            position: relative;
            flex-direction: column;
            display: flex;
            justify-content: center;
            &.image {
                width: 50%;
                @include media-breakpoint-down(md) {
                    width: 70%;
                    margin: 0 auto;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            &.content {
                width: 50%;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            &-image {
                position: relative;
                width: 100%;
                display: block;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            &-content {
                position: relative;
                z-index: 2;
                padding: 70px 0 70px 120px;
                @include media-breakpoint-down(lg) {
                    padding: 70px 0 70px 70px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                    padding: 40px 0 0;
                }
            }
        }

        &--1 .flower {
            position: absolute;
            z-index: 2;
            content: '';
            bottom: -137px;
            left: -170px;
            width: 393px;
            height: 377px;
            transform: rotate(186deg) !important;
            background-image: url(../img/icons/flowers/2.svg);
            background-repeat: no-repeat;
            background-size: cover;
            @include media-breakpoint-down(md) {
                bottom: -45px;
                left: -100px;
                width: 253px;
                height: 245px;
            }
        }

        &--2 .flower {
            position: absolute;
            z-index: 2;
            content: '';
            bottom: -200px;
            right: -110px;
            width: 373px;
            height: 408px;
            background-image: url(../img/icons/flowers/3.svg);
            background-repeat: no-repeat;
            background-size: cover;
            @include media-breakpoint-down(md) {
                bottom: -100px;
                right: -90px;
                width: 223px;
                height: 248px;
            }
        }


        &--2 .image{
            @media (min-width: 768px) {
                order: 2;
            }
        }

        &--2 .content {
            @media (min-width: 768px) {
                order: 1;
            }
            .inner-stack-item__box-content {
                padding: 70px 120px 70px 0px;
                @include media-breakpoint-down(lg) {
                    padding: 70px 70px 70px 0px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                    padding: 40px 0;
                }
            }
        }

        &__play-btn {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 2;
            display: inline-block;
            transform: translate(-50%, -50%);
        }
    }
}
