.referrals-page {
    &__form-wrap {
        background: $color-gray-lighter;

        padding-top: calc(#{$spacer} + 5vh);
        padding-bottom: calc(#{$spacer} + 2vh);

        position: relative;

        @include media-breakpoint-down(sm) {
            margin-top: 20%; /* space for flower */
        }
    }

    &__icon-wrap {
        position: absolute;
        top: 0;
        right: 0;

        max-width: 50%;

        transform: translateY(-60%);

        svg {
            max-width: 100%;
            height: auto;
        }
    }
}
