.about {

    &__section {

        &--main {
            font-size: $font-size-lg;

            margin-bottom: $spacer*2;

            h2 {
                color: $color-teal;
            }

            h4 {
                font-size: inherit;
                font-weight: bold;
                color: $color-teal-light;
                text-transform: uppercase;
            }
        }

        &--guarantee {

            h2 {
                padding-top: $spacer*4;
            }

            h3 {
                font-weight: bold;
            }

            ul {
                @extend %list-clean;
                @include make-row;

                > li {
                    @extend %list-clean;
                    @include make-col-ready;
                    @include make-col(12);

                    margin-bottom: $spacer*1.5;

                    @include media-breakpoint-up(sm) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(4);
                    }

                    strong {
                        font-family: $font-headings;
                        font-size: $font-size-h5;
                        color: $color-blue;
                        display: block;
                    }
                }
            }
        }

        &--custom {
            background: $color-gray-lighter;

            padding-bottom: $spacer*2;
        }

        &--our-team {
            padding-top: calc(#{$spacer*3} + 4vh);
            margin-bottom: calc(#{$spacer*2} + 2vh);
        }

        &--refer-a-friend {
            background-color: $color-teal-light;
            color: $color-black;

            h3 {
                margin-bottom: $spacer;
            }

            a {
                @extend .btn;
                @extend .btn--black;
            }
        }
    }

    &__content-wrap {
        padding-top: calc(#{$spacer} + 4vh);
        margin-bottom: calc(#{$spacer} + 2vh);
    }
    &__section--refer-a-friend &__content-wrap {
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: right;
        }
    }
    &__section--guarantee &__content-wrap {
        position: relative;
    }

    &__flower {
        position: absolute;
        max-width: 50%;
        &--1 {
            bottom: 0;
            right: 0;

            transform: translate(20%, 55%);
        }

        &--2 {
            position: relative;

            margin-top: -37%;

            @include media-breakpoint-up(md) {
                max-width: 275px;
                margin-top: -55%;
            }
        }

        &--3 {
            top: 0;
            right: 0;

            transform: translate(20%, -42%);

            z-index: 2;
        }
    }

    &__branch {
        width: 320px;
        max-width: 50%;
        position: absolute;
        bottom: 0;
        right: 0;

        transform: translate(-23%, 62%);

        z-index: -1;

        @include media-breakpoint-up(md) {
            max-width: 75%;
        }

        @include media-breakpoint-up(xl) {
            transform: translate(-50%, 62%);
        }
    }
}
