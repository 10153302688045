// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.

// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark: #373a3c;
$gray: #55595c;
$gray-light: #818a91;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;

$brand-primary: $color-green;
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-inverse: $gray-dark;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: false;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer
    ),
    2: (
      $spacer * 1.5
    ),
    3: (
      $spacer * 3
    ),
    4: (
      $spacer * 4
    ),
    5: (
      $spacer * 5
    ),
    6: (
      $spacer * 6
    ),
    7: (
      $spacer * 7
    ),
    8: (
      $spacer * 8
    ),
    9: (
      $spacer * 9
    ),
    10: (
      $spacer * 10
    )
  ),
  $spacers
);
$border-width: 1px;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff;
$body-color: $color-gray-medium;

// Links
//
// Style anchor elements.

$link-color: $brand-primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1190px
);
@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width-base: 30px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base/2,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: $font-regular;
$font-family-serif: $font-headings;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 16px;

$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.75rem;

$line-height-base: 1.5;

$h1-font-size: 3.75rem;
$h2-font-size: 3.125rem;
$h3-font-size: 2.5rem;
$h4-font-size: 1.875rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1rem;

// for old version of bootstrap
$font-size-h1: 3.75rem;
$font-size-h2: 3.125rem;
$font-size-h3: 2.5rem;
$font-size-h4: 1.875rem;
$font-size-h5: 1.5rem;
$font-size-h6: 1rem;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;

$lead-font-size: 1.25rem;
$lead-font-weight: 400;

$small-font-size: 75%;

$text-muted: $gray-light;

$abbr-border-color: $gray-light;

$blockquote-small-color: $gray-light;
$blockquote-font-size: ($font-size-base * 1.25);
$blockquote-border-color: $gray-lighter;
$blockquote-border-width: 0.25rem;

$hr-border-color: rgba(0, 0, 0, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
$nested-kbd-font-weight: bold;

$list-inline-padding: 5px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: (4 / 3);
$line-height-sm: 1.5;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$component-active-color: #fff;
$component-active-bg: $brand-primary;

$caret-width: 0.3em;
$caret-width-lg: $caret-width;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem;
$table-sm-cell-padding: 0.3rem;

$table-bg: transparent;
$table-bg-accent: rgba(0, 0, 0, 0.05);
$table-bg-hover: rgba(0, 0, 0, 0.075);
$table-bg-active: $table-bg-hover;

$table-border-width: $border-width;
$table-border-color: $gray-lighter;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x: 1.5rem;
$btn-padding-y: 0.7rem;
$btn-padding-y-bottom: $btn-padding-y;
$btn-padding-y-top: $btn-padding-y;
$btn-line-height: 1.25;
$btn-font-weight: normal;
$btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
$btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $btn-primary-bg;

$btn-secondary-color: #fff;
$btn-secondary-bg: $gray;
$btn-secondary-border: $gray;

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: $btn-info-bg;

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: $btn-success-bg;

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: $btn-warning-bg;

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: $btn-danger-bg;

$btn-link-disabled-color: $gray-light;

$btn-padding-x-sm: 1.75rem;
$btn-padding-y-sm: $btn-padding-y;

$btn-padding-x-lg: 5rem;
$btn-padding-y-lg: 0.75rem;

$btn-block-spacing-y: 0.5rem;
$btn-toolbar-margin: 0.5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 38px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

// Forms

$input-padding-x: 0.75rem;
$input-padding-y: 0.5rem;
$input-line-height: 1.25;

$input-bg: #fff;
$input-bg-disabled: $gray-lighter;

$input-color: $gray;
$input-border-color: rgba(0, 0, 0, 0.15);
$input-btn-border-width: 0;
$input-box-shadow: 0;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-bg-focus: $input-bg;
$input-border-focus: $gray;
$input-box-shadow-focus: $input-box-shadow, 0 0 8px rgba(102, 175, 233, 0.6);
$input-color-focus: $input-color;

$input-color-placeholder: #999;

$input-padding-x-sm: 0.5rem;
$input-padding-y-sm: 0.25rem;

$input-padding-x-lg: 1.5rem;
$input-padding-y-lg: 0.75rem;

$input-height: (($font-size-base * $line-height-base) + ($input-padding-y * 2));
$input-height-lg: (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2));
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2));

$form-group-margin-bottom: $spacer-y;

$input-group-addon-bg: $gray-lighter;
$input-group-addon-border-color: $input-border-color;

$cursor-disabled: not-allowed;

$custom-control-gutter: 1.5rem;
$custom-control-spacer-x: 1rem;
$custom-control-spacer-y: 0.25rem;

$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: #ddd;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);

$custom-control-disabled-cursor: $cursor-disabled;
$custom-control-disabled-indicator-bg: #eee;
$custom-control-disabled-description-color: #767676;

$custom-control-checked-indicator-color: #fff;
$custom-control-checked-indicator-bg: #0074d9;
$custom-control-checked-indicator-box-shadow: none;

$custom-control-focus-indicator-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;

$custom-control-active-indicator-color: #fff;
$custom-control-active-indicator-bg: #84c6ff;
$custom-control-active-indicator-box-shadow: none;

$custom-checkbox-radius: $border-radius;
$custom-checkbox-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

$custom-checkbox-indeterminate-bg: #0074d9;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color;
$custom-checkbox-indeterminate-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E");
$custom-checkbox-indeterminate-box-shadow: none;

$custom-radio-radius: 50%;
$custom-radio-checked-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E");

$custom-select-padding-x: 0.75rem;
$custom-select-padding-y: 0.375rem;
$custom-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-light;
$custom-select-bg: #fff;
$custom-select-disabled-bg: $gray-lighter;
$custom-select-bg-size: 8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: #333;
$custom-select-indicator: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;

$custom-select-focus-border-color: #51a7e8;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(81, 167, 232, 0.5);

$custom-select-sm-padding-y: 0.2rem;
$custom-select-sm-font-size: 75%;

$custom-file-height: 2.5rem;
$custom-file-width: 14rem;
$custom-file-focus-box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;

$custom-file-padding-x: 0.5rem;
$custom-file-padding-y: 1rem;
$custom-file-line-height: 1.5;
$custom-file-color: #555;
$custom-file-bg: #fff;
$custom-file-border-width: $border-width;
$custom-file-border-color: #ddd;
$custom-file-border-radius: $border-radius;
$custom-file-box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: #eee;

// Form validation icons
$form-icon-success-color: $brand-success;
$form-icon-success: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-success-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");

$form-icon-warning-color: $brand-warning;
$form-icon-warning: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$form-icon-warning-color}' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");

$form-icon-danger-color: $brand-danger;
$form-icon-danger: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-icon-danger-color}' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-margin-top: 0.125rem;
$dropdown-bg: #fff;
$dropdown-border-color: rgba(0, 0, 0, 0.15);
$dropdown-border-width: $border-width;
$dropdown-divider-bg: #e5e5e5;
$dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);

$dropdown-link-color: $gray-dark;
$dropdown-link-hover-color: darken($gray-dark, 5%);
$dropdown-link-hover-bg: #f5f5f5;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: $gray-light;

$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $gray-light;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop: 990;
$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-navbar-sticky: 1030;
$zindex-modal-bg: 1040;
$zindex-modal: 1050;

// Navbar

$navbar-border-radius: $border-radius;
$navbar-padding-x: $spacer;
$navbar-padding-y: ($spacer / 2);

$navbar-brand-padding-y: 0.25rem;

$navbar-divider-padding-y: 0.425rem;

$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-padding-y: 0.5rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-padding-y: 0.5rem;

$navbar-dark-color: rgba(255, 255, 255, 0.5);
$navbar-dark-hover-color: rgba(255, 255, 255, 0.75);
$navbar-dark-active-color: rgba(255, 255, 255, 1);
$navbar-dark-disabled-color: rgba(255, 255, 255, 0.25);
$navbar-dark-toggler-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
$navbar-dark-toggler-border: rgba(255, 255, 255, 0.1);

$navbar-light-color: rgba(0, 0, 0, 0.5);
$navbar-light-hover-color: rgba(0, 0, 0, 0.7);
$navbar-light-active-color: rgba(0, 0, 0, 0.9);
$navbar-light-disabled-color: rgba(0, 0, 0, 0.3);
$navbar-light-toggler-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
$navbar-light-toggler-border: rgba(0, 0, 0, 0.1);

// Navs

$nav-item-margin: 0.2rem;
$nav-item-inline-spacer: 1rem;
$nav-link-padding: 0.5em 1em;
$nav-link-hover-bg: $gray-lighter;
$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;
$nav-disabled-link-hover-bg: transparent;

$nav-tabs-border-color: #ddd;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-lighter;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-border-color: #ddd;
$nav-tabs-justified-link-border-color: #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-active-link-color: $component-active-color;
$nav-pills-active-link-bg: $component-active-bg;

// Pagination

$pagination-padding-x: 0.75rem;
$pagination-padding-y: 0.5rem;
$pagination-padding-x-sm: 0.75rem;
$pagination-padding-y-sm: 0.275rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-padding-y-lg: 0.75rem;

$pagination-color: $link-color;
$pagination-bg: #fff;
$pagination-border-width: $border-width;
$pagination-border-color: #ddd;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: #ddd;

$pagination-active-color: #fff;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: #ddd;

// Jumbotron

$jumbotron-padding: 2rem;
$jumbotron-bg: $gray-lighter;

// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken($state-success-bg, 5%);

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken($state-info-bg, 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$mark-bg: $state-warning-bg;
$state-warning-border: darken($state-warning-bg, 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken($state-danger-bg, 5%);

// Cards
$card-spacer-x: 1.25rem;
$card-spacer-y: 0.75rem;
$card-border-width: 1px;
$card-border-radius: $border-radius;
$card-border-color: rgba(0, 0, 0, 0.125);
$card-border-radius-inner: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: #f5f5f5;
$card-bg: #fff;

$card-link-hover-color: #fff;

$card-img-overlay-padding: 1.25rem;

$card-deck-margin: 0.625rem;

$card-columns-sm-up-column-gap: 1.25rem;

// Tooltips

$tooltip-max-width: 200px;
$tooltip-color: #fff;
$tooltip-bg: #000;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 3px;
$tooltip-padding-x: 8px;
$tooltip-margin: 3px;

$tooltip-arrow-width: 5px;
$tooltip-arrow-color: $tooltip-bg;

// Popovers

$popover-inner-padding: 1px;
$popover-bg: #fff;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba(0, 0, 0, 0.2);
$popover-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

$popover-title-bg: darken($popover-bg, 3%);
$popover-title-padding-x: 14px;
$popover-title-padding-y: 8px;

$popover-content-padding-x: 14px;
$popover-content-padding-y: 9px;

$popover-arrow-width: 10px;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-width: ($popover-arrow-width + 1px);
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Tags

$tag-default-bg: $gray-light;
$tag-primary-bg: $brand-primary;
$tag-success-bg: $brand-success;
$tag-info-bg: $brand-info;
$tag-warning-bg: $brand-warning;
$tag-danger-bg: $brand-danger;

$tag-color: #fff;
$tag-link-hover-color: #fff;
$tag-font-size: 75%;
$tag-font-weight: bold;
$tag-padding-x: 0.4em;
$tag-padding-y: 0.25em;

$tag-pill-padding-x: 0.6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$tag-pill-border-radius: 10rem;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem;

$modal-dialog-margin: 10px;
$modal-dialog-sm-up-margin-y: 30px;

$modal-title-padding: 15px;
$modal-title-line-height: $line-height-base;

$modal-content-bg: #fff;
$modal-content-border-color: rgba(0, 0, 0, 0.2);
$modal-content-border-width: $border-width;
$modal-content-xs-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
$modal-content-sm-up-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

$modal-backdrop-bg: #000;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: #e5e5e5;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-x: 1.25rem;
$alert-padding-y: 0.75rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: bold;
$alert-border-width: $border-width;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

// Progress bars

$progress-bg: #eee;
$progress-bar-color: #0074d9;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);

$progress-bar-bg: $brand-primary;
$progress-bar-success-bg: $brand-success;
$progress-bar-warning-bg: $brand-warning;
$progress-bar-danger-bg: $brand-danger;
$progress-bar-info-bg: $brand-info;

// List group

$list-group-bg: #fff;
$list-group-border-color: #ddd;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-hover-bg: #f5f5f5;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border: $list-group-active-bg;
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

$list-group-disabled-color: $gray-light;
$list-group-disabled-bg: $gray-lighter;
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: #555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333;

$list-group-item-padding-x: 1.25rem;
$list-group-item-padding-y: 0.75rem;
$list-group-item-heading-margin-bottom: 5px;

// Image thumbnails

$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: #ddd;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);

// Figures

$figure-caption-font-size: 90%;

// Breadcrumbs

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-bg: $gray-lighter;
$breadcrumb-divider-color: $gray-light;
$breadcrumb-active-color: $gray-light;
$breadcrumb-divider: '/';

// Media objects

$media-margin-top: 15px;
$media-heading-margin-bottom: 5px;
$media-alignment-padding-x: 10px;

// Carousel

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

$carousel-control-color: #fff;
$carousel-control-width: 15%;
$carousel-control-sm-up-size: 30px;
$carousel-control-opacity: 0.5;
$carousel-control-font-size: 20px;

$carousel-indicators-width: 60%;

$carousel-indicator-size: 10px;
$carousel-indicator-active-size: 12px;
$carousel-indicator-active-bg: #fff;
$carousel-indicator-border-color: #fff;

$carousel-caption-width: 70%;
$carousel-caption-sm-up-width: 60%;
$carousel-caption-color: #fff;

$carousel-icon-width: 20px;

// Close

$close-font-weight: bold;
$close-color: #000;
$close-text-shadow: 0 1px 0 #fff;

// Code

$code-font-size: 90%;
$code-padding-x: 0.4rem;
$code-padding-y: 0.2rem;
$code-color: #bd4147;
$code-bg: #f7f7f9;

$kbd-color: #fff;
$kbd-bg: #333;

$pre-bg: #f7f7f9;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;
