.contact-info {
    @extend %list-clean;
    margin-left: 0;

    margin-bottom: calc(#{$spacer * 2} + 4vh);

    &__data {
        @extend %list-clean;

        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
        }

        padding-left: 2em;
        margin-bottom: $spacer;

        position: relative;

        &:before {
            @include icon;

            font-size: 1.2em;
            line-height: 0.833em;

            position: absolute;
            left: 0;
            top: 0.25em;
        }

        &--phone:before {
            content: $icon-phone;
            margin-left: 4px;
        }

        &--address:before {
            content: $icon-map-pin;
            font-size: 1.2em;
        }

        &--email:before {
            content: $icon-mail;
        }
    }
}
