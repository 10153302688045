.maintenance-add-ons {
    @include make-row;

    color: $color-gray-dark;

    padding-bottom: calc(#{$spacer} + 3vh);

    &__title {
        @include make-col-ready;
        @include make-col(12);

        margin-top: $spacer*2;
        margin-bottom: $spacer*2;

        h4 {
            margin-bottom: 0.5rem;
        }

        small {
            font-family: $font-regular;
            font-size: 0.75rem;
            font-weight: 300;
        }

        @include media-breakpoint-up(lg) {
            @include make-col(3);
        }
    }

    &__list-wrap {
        @include make-col-ready;
        @include make-col(12);

        @include media-breakpoint-up(lg) {
            @include make-col(9);
        }
    }

    &__list {
        @extend %list-checks-grid;
        @extend %list-checks-grid--lg;

        > li {
            @include make-col(12);

            margin-top: $spacer/2;
            margin-bottom: $spacer/2;

            @include media-breakpoint-up(sm) {
                @include make-col(6);

                margin-top: $spacer*2;
                margin-bottom: $spacer*2;
            }

            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }

            &:before {
                content: '+';

                color: $color-blue;
                border: 0;
                font-size: 3rem;
                line-height: 1.4rem;

                transform: none;
            }

            strong {
                font-size: 1rem;
                display: block;
            }

            > :last-child {
                margin-bottom: 0;
            }
        }

    }
}
