.smart-landscapes {
    &__gradient-top {
        background: rgb(221,204,227);
        background: linear-gradient(180deg, rgba(221,204,227,1) 0%, rgba(216,206,219,1) 22%, rgba(255,255,255,0) 100%);
    }
    &__purple {
        background: rgb(221,204,227);
    }
    &__gradient-bottom {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(225,225,226,0.5032387955182073) 25%, rgba(234,234,235,1) 50%, rgba(246,246,246,0.5032387955182073) 75%, rgba(255,255,255,0) 100%);
    }
    &__banner {
        position: relative;
        text-align: center;
        &-leaf,
        &-splash,
        &-dashes {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                background-size: contain;
                background-position: top right;
                background-repeat: no-repeat;
            }
        }
        &-leaf {
            &:before {
                width: 350px;
                height: 370px;
                right: -60px;
                top: -140px;
                background-image: url(/site/themes/main/img/leaf-purple.svg);
            }
        }
        &-splash {
            &:before {
                width: 630px;
                height: 370px;
                right: -110px;
                top: -10px;
                background-image: url(/site/themes/main/img/hydrate-splash.svg);
            }
        }
        &-dashes {
            &:before {
                width: 450px;
                height: 470px;
                right: -20px;
                top: -30px;
                background-image: url(/site/themes/main/img/automower-dashes.svg);
            }
        }
        .page-hero__icon {
            display: block;
            position: relative;
            top: 0;
            margin-bottom: 30px;
            max-height: 140px;
        }
        
        &-wrapper {
            position: relative;
            max-width: 800px;
            margin: 0 auto;
            padding: 80px 0;
            z-index: 10;
            &.automower {
                text-align: left;
                margin: 0;
                max-width: 45%;
            }
            .connect-logo {
                max-width:400px;
                margin: 0 auto 40px auto;
            }
        }
        h1 {
            color: $color-purple;
        }
        h2 {
            color: $color-gray-dark;
        }
        .btn {
            margin-top: 30px;
        }
    }
    &__intro {
        margin-bottom: 100px;
        .inner-stack-item {
            .container {
                max-width: 1400px;
            }
            &__flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
            &__box {
                position: relative;
                flex-direction: column;
                display: flex;
                justify-content: center;
                &.image {
                    width: 50%;
                    @include media-breakpoint-down(md) {
                        width: 70%;
                        margin: 0 auto;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
                &.content {
                    width: 50%;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
                &-image {
                    position: relative;
                    width: 100%;
                    display: block;
                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 70%;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
                &-content {
                    position: relative;
                    z-index: 2;
                    padding: 90px 0 90px 120px;
                    @include media-breakpoint-down(lg) {
                        padding: 70px 0 70px 70px;
                    }
                    @include media-breakpoint-down(md) {
                        margin-top: 20px;
                        padding: 40px 0 0;
                    }
                    h3 {
                        color: $color-gray-dark;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
                }
            }
    
            .flower {
                position: absolute;
                z-index: 2;
                content: '';
                bottom: -137px;
                left: -140px;
                width: 238px;
                height: 257px;
                transform: rotate(-4deg) !important;
                background-image: url(../img/icons/flowers/3.svg);
                background-repeat: no-repeat;
                background-size: cover;
                @include media-breakpoint-down(md) {
                    bottom: -80px;
                    left: -100px;
                    width: 171px;
                    height: 193px;
                }
            }
        }
    }
    &__services {
        padding: 0;
        .services-card__image {
            padding-bottom: 65%;
        }
    }

    &__audio {
        position: relative;
        &-notes {
            position: absolute;
            top: -280px;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/music-notes.svg);
            background-repeat: no-repeat;
            background-size: 105% auto;
            background-position: top center;
            opacity: 0.3;
            @include media-breakpoint-down(lg) {
                top: -200px;
            }
            @include media-breakpoint-down(md) {
                top: -100px;
            }
        }
        &-img {
            position: relative;
            width: 100%;
            padding-bottom: 30%;
            .image {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
    }
    &__lighting {
        padding-bottom: 20%;
        position: relative;
        &-gradient {
            position: absolute;
            width: 100%;
            height: 60%;
            top: 0;
            left: 0;
            z-index: 1;
            background: rgb(221,204,227);
            background: linear-gradient(180deg, rgba(221,204,227,1) 0%, rgba(255,255,255,0) 100%);
            @include media-breakpoint-down(md) {
                height: 75%;
            }
        }
        &-img {
            position: absolute;
            width: 100%;
            height: 70%;
            bottom: 0;
            left: 0;
            padding-bottom: 30%;
            background-color: #ccc;
            @include media-breakpoint-down(md) {
                height: 60%;
            }
            .image {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
    }
    &__product {
        background-color: $color-gray-lighter;
        &-grid {
            display: grid;
            gap: 40px;
            grid-template-columns: 1fr 1fr;
            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
            }
            .image {
                position: relative;
                margin-bottom: -80px;
                .product {
                    position: absolute;
                    left: 0;
                    top: -140px;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    @include media-breakpoint-down(md) {
                        position: relative;
                        top: -90px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        @include media-breakpoint-down(md) {
                            height: 400px;
                        }
                    }
                }
            }
            .content {
                padding: 80px 20px;
                @include media-breakpoint-down(md) {
                    padding: 0 0 80px 0;
                }
                h3 {
                    color: $color-gray;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__value {
        &-item {
            display: grid;
            gap: 40px;
            grid-template-columns: 1fr 1fr;
            grid-column: 1 / -1;
            padding: 60px 0;
            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
            }
            &--left {
                .image {
                    order: 2;
                }
                .content {
                    order: 1;
                }
                @include media-breakpoint-down(md) {
                    .image {
                        order: 1;
                    }
                    .content {
                        order: 2;
                    }
                }
            }
            &--right {
                .image {
                    order: 1;
                }
                .content {
                    order: 2;
                }
            }
            .image {
                position: relative;
                @include media-breakpoint-down(md) {
                    padding-bottom: 70%;
                }
                .full {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .product {
                    position: absolute;
                    right: 20px;
                    bottom: -60px;
                    width: 190px;
                    height: 130px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                    @include media-breakpoint-up(md) {
                        right: -50px;
                        bottom: -70px;
                        width: 290px;
                        height: 150px;
                    }
                }
            }
            .content {
                padding: 100px 30px;
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
                h3 {
                    color: $color-gray;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__form {
        position: relative;
        padding: 70px 15px;
        &:after {
            content: '';
            position: absolute;
            width: 500px;
            height: 430px;
            left: 0%;
            bottom: -140px;
            background-image: url(/site/themes/main/img/leaf-green.svg);
            background-size: contain;
            background-position: bottom left;
            background-repeat: no-repeat;
        }
        &-content {
            position: relative;
            z-index: 10;
            text-align: center;
            max-width: 600px;
            margin: 0 auto;
            h3 {
                color: $color-gray;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }
    }
}
