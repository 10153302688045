.menu-icon {
    width: 30px;
    height: 24px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $color-black;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        transform-origin: left center;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 9px;
        }

        &:nth-child(3) {
            top: 18px;
        }
    }
}

:checked ~ * .menu-icon span {
    background: $color-blue;

    &:nth-child(1) {
        transform: rotate(45deg);
        top: 0px;
        left: 5px;
    }

    &:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    &:nth-child(3) {
        transform: rotate(-45deg);
        top: 21px;
        left: 5px;
    }
}
