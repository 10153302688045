.value-stack-item {
  @media (min-width: 768px) {
    min-height: 400px;
  }

  &__radius {
    border-radius: 10px;
  }

  &__image-wrap {
    @media (min-width: 768px) {
      margin-bottom: 40px;
      padding-right: 20px;
      width: 50%;
    }
    @media (min-width: 1024px) {
      margin-bottom: 60px;
    }
  }

  &__content-wrap {
    @media (min-width: 768px) {
      min-height: 565px;
      padding-top: 40px;
      width: 50%;
    }
    h2 {
      color: $color_red;
    }
  }

  &--1 {
    background-image: url(../img/icons/flowers/10.svg);
    background-repeat: no-repeat;
    background-position: calc(100% + 250px) calc(100% + 150px);

    @media (min-width: 1450px) {
      background-position: calc(50% + 574px) calc(100% + 150px);
    }
  }

  &--1 {
    p {
      max-width: 570px;
    }
  }

  &--2 &__image-wrap {
    @media (min-width: 768px) {
      order: 2;
      padding-left: 20px;
      padding-right: 0;
    }
  }

  &--2 &__content-wrap {
    @media (min-width: 768px) {
      order: 1;
    }
  }

  &--3 {
    background-image: url(../img/icons/branches/5.svg);
    background-repeat: no-repeat;
    background-position: calc(50% + 700px) calc(100% + 100px);
    background-size: 380px auto;

    h2 {
      max-width: 450px;
    }
  }

  &__play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;

    display: inline-block;

    transform: translate(-50%, -50%);
  }
}
