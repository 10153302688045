.awards {

    &__list {
        margin: 0;
        padding:  0;
        list-style-type:  none;
        display:  flex;
        flex-direction:  row;
        flex-wrap: wrap;
        align-items:  center;
        justify-content:  center;
    }

    &__item {
        padding:  0;
        margin: 0 20px 15px;
    }

    &__heading {
        text-align:  center;
        font-weight: bold;
        font-size:  24px;
    }

}