.guarantee {
    padding: 60px 0;
    background-color: lighten($color-gray-light, 60);
    background-repeat: no-repeat;
    background-position: top center;
    text-align: center;

    @include media-breakpoint-up(md) {
        background-position: calc(50% - 250px) top;
    }

    .link {
        font-weight: bold;
    }

    &__image {
        margin-bottom: 20px;
    }

    &__copy {
        margin: 0 auto;
        max-width: 700px;
    }
}