/*
|--------------------------------------------------------------------------
| Main Nav
|
| Using the label and input as for the mobile version.
|--------------------------------------------------------------------------
*/
#olark-wrapper .olark-launch-button {
    width: 60px !important;
    height: 60px !important;
}
.main-nav {
    display: none;
    background-color: #fff;
    text-align: left;
    font-size: 18px;

    @include media-breakpoint-down(xs){
        display: block;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 3000;
        height: 60px;
        width: 60px;
    }

    &__inner {
        @include media-breakpoint-up(sm) {
            margin-left:  -10px;
            margin-right: -10px;
        }

        @include media-breakpoint-up(lg) {
            margin-left:  -20px;
            margin-right: -20px;
        }
    }

    &__list {
        @extend %list-clean;
        margin: 0;
        //text-align: center;
        background-color: #fff;

        @include media-breakpoint-down(xs) {
            position: fixed;
            // margin-top: 60px;
            margin-top: -12px;
            z-index: 1000;
            width: 100vw;
            height: 100vh;
            overflow-y: scroll;
            transition: transform .25s ease-out;
            transform: scaleY(0);
            transform-origin: 75% top;
        }

        @include media-breakpoint-between(sm, md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include media-breakpoint-up(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(lg){
            margin-bottom: -15px;
            background-color: transparent;
        }
    }

    &__item {
        @extend %list-clean;
        display: block;

        @include media-breakpoint-down(xs) {
            &:not(:last-child) {
                border-bottom: 1px solid $color-gray-light;
                
            }
        }

        @include media-breakpoint-up(sm) {
            display: inline-block;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 $spacer;
        }

        &-parent {
            padding-bottom: 10px;
        }
        
        &-child {
            list-style: none;
            margin: 0;
            padding: 0 0 0 24px;
            li {
                font-size: 14px;
                padding: 0;
            }
        }
        
    }

    &__link {
        @include link($color-gray-dark, $color-gray);

        display: inline-block;
        width: 100%;
        padding: 10px $spacer*1.5;

        @include media-breakpoint-up(sm) {
            width: auto;
            font-size: 16px;
            padding: 10px 5px;
        }

        @include media-breakpoint-up(md) {
            font-size: inherit;
        }
    }

    &__item--active &__link {

        color: $color-green;
    }

    &__trigger {
        position: relative;
        margin: 0;
        padding: 20px 15px;
        width: 100%;

        @include media-breakpoint-up(sm){
            display: none;
        }

    }

    &__bullet {
        left: -99999px;
        position: absolute;
        top: -99999px;

        appearance: none;
        outline: 0 !important;

        @include media-breakpoint-up(sm){
            display: none;
        }
    }

    &__bullet:checked ~ &__list {
        display: block;
        transform: scaleY(1);
    }
}

.main-nav-mega {
    font-family: $font-headings;
    width: 100%;
    position: absolute;
    bottom: 30px;
    @include media-breakpoint-down(md) {
        z-index: 900;
        background-color: #fff;
        bottom: 0px;
    }
    @include media-breakpoint-down(xs) {
        display: none;
    }
    nav {
        text-align:center;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 60px;
        //position:relative;
        ul {
            list-style:none;
            padding:0 20px;
            margin: 0;
            height: 60px;
            li {
                display: inline-block;
                padding-bottom: 6px;
                font-size: 18px;
                padding: 0 8px;
                transition: all ease .5s;
                a {
                    color:$color-gray-dark;
                    padding:0px 40px;
                    text-decoration:none;
                    height: 60px;
                    line-height: 60px;
                    @include media-breakpoint-down(md) {
                        padding:0px 20px;
                    }
                    &:after {
                        content: "›";
                        position: absolute;
                        transform: rotate(90deg);
                        margin-left: 10px;
                        font-size: 22px;
                        font-family: auto;
                    }
                }
                &:hover {
                    color: $color-gray;
                    transition: all ease .5s;
                }
                &:hover > a{
                    color:$color-gray;
                }
                &:hover > ul {
                    visibility: visible;
                    opacity: 1;
                    transition: all ease .5s;
                    z-index: 900;
                }
            }
            ul {
                background: #ffffff;
                padding:0;
                text-align: center;
                visibility: hidden;
                opacity: 0;
                transition: all ease .5s;
                width: 100%;
                position: absolute;
                top: 50px;
                left: 0px;
                height: auto;
                @include media-breakpoint-down(md) {
                    top: 60px;
                }
                &.menu-dropdown {
                    display: flex;
                    padding: 40px;
                    box-shadow: -1px 17px 14px 4px rgba(0,0,0,0.05);
                    -webkit-box-shadow: -1px 17px 14px 4px rgba(0,0,0,0.05);
                    -moz-box-shadow: -1px 17px 14px 4px rgba(0,0,0,0.05);
                    @include media-breakpoint-down(md) {
                        padding: 20px;
                    }
                }
                li {
                    flex: 1 1 0%;
                    a {
                        width: 100%;
                        padding: 0;
                        &:after {
                            content: none;
                        }
                    }
                    .menu-card {
                        width: 100%;
                        text-align: center;
                        border: solid 1px $color-gray-border;
                        padding: 0;
                        border-radius: 10px;
                        background-color: #fff;
                        &:hover {
                            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);
                            transition: all ease .2s;
                            transform: translateY(-1px);
                            cursor: pointer;
                        }
                        &__image {
                            position: relative;
                            width: 100%;
                            padding-bottom: 50%;
                            background-color: $color-teal-light;
                            border-radius: 10px 10px 0 0;
                            // background-size: cover;
                            // background-position: center;
                            // background-repeat: no-repeat;
                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        &__content {
                            font-family: $font-headings;
                            line-height: 1.8;
                            padding: 15px;
                            font-size: 15px;
                            span {               
                                display: block;                 
                                font-size: 20px;
                                font-weight: 600;
                            }
                        }
                        &.blue {
                            background-color: $color-teal-light;
                        }
                        &.green {
                            background-color: $color-green;
                        }
                        &.peach {
                            background-color: $color-orange;
                        }
                    }
                }
            }
        }
    }
}