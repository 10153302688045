// allow images to fill the width of the screen, with content still contained within the container's space

.fluid-container {
    @extend .container;
    max-width: 2030px; /* temporary, until I can think of a better fix for large screens */

    @include media-breakpoint-up(md) {
        width: 100% !important;
    }

    @each $breakpoint in (md, lg, xl) {
        @include media-breakpoint-up($breakpoint) {
            // Full width images
            $gutter: map-get($grid-gutter-widths, $breakpoint);
            .col-md:first-child &__image-wrap {
                margin-left: -$gutter/2;
                text-align: right;
            }
            .col-md:last-child &__image-wrap {
                margin-right: -$gutter/2;
            }
            .col-md:first-child &__image {
                left: auto;
                right: 0;
            }

            // Restrict content width
            $max-width: map-get($container-max-widths, $breakpoint)/2 - map-get($grid-gutter-widths, $breakpoint);
            .col-md &__content-wrap {
                max-width: $max-width;
            }
            .col-md:first-child &__content-wrap {
                margin-left: auto;
            }
            .col-md:last-child &__content-wrap {
                margin-right: auto;
            }

            @for $i from 1 through $grid-columns {
                // Full width images
                $gutter: map-get($grid-gutter-widths, $breakpoint);
                .col-md-#{$i}:first-child &__image-wrap {
                    margin-left: -$gutter/2;
                    text-align: right;
                }
                .col-md-#{$i}:last-child &__image-wrap {
                    margin-right: -$gutter/2;
                }
                .col-md-#{$i}:first-child &__image {
                    left: auto;
                    right: 0;
                }

                // Restrict content width
                $max-width: map-get($container-max-widths, $breakpoint) * $i/$grid-columns - map-get($grid-gutter-widths, $breakpoint);
                .col-md-#{$i} &__content-wrap {
                    max-width: $max-width;
                }
                .col-md-#{$i}:first-child &__content-wrap {
                    margin-left: auto;
                }
                .col-md-#{$i}:last-child &__content-wrap {
                    margin-right: auto;
                }
            }
        }
    }

    &__image-wrap {
        overflow: hidden;

        position: relative;

        &--match-height {
            @include media-breakpoint-up(md) {
                height: 100%;
            }
        }

        @include media-breakpoint-down(sm) {
            $gutter: map-get($grid-gutter-widths, sm);
            margin-left: -$gutter/2;
            margin-right: -$gutter/2;
        }

        @include media-breakpoint-down(xs) {
            $gutter: map-get($grid-gutter-widths, xs);
            margin-left: -$gutter/2;
            margin-right: -$gutter/2;
        }
    }

    &__image-wrap--match-height &__image {
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            left: 0;

            max-height: 100%;
            max-width: none;
            height: 100%;
            width: auto;
        }
    }
    &__mt40 {
        margin-top: 40px;
    }
}


// allow images to fill the width of the screen, with content still contained within the container's space

.fluid-container {

    @each $breakpoint in (md, lg, xl) {
        @include media-breakpoint-up($breakpoint) {
            // Full width images
            $gutter: map-get($grid-gutter-widths, $breakpoint);
            .col-md:last-child &__image-wrap {
                margin-left: -$gutter/2;
                text-align: right;
            }
            .col-md:first-child &__image-wrap {
                margin-right: -$gutter/2;
            }
            .col-md:last-child &__image {
                left: auto;
                right: 0;
            }

            // Restrict content width
            $max-width: map-get($container-max-widths, $breakpoint)/2 - map-get($grid-gutter-widths, $breakpoint);
            .col-md &__content-wrap {
                max-width: $max-width;
            }
            .col-md:last-child &__content-wrap--reversed {
                margin-left: auto;
                margin-right: 40px;
            }
            .col-md:first-child &__content-wrap--reversed {
                margin-right: auto;
                margin-left: 40px;
            }

            @for $i from 1 through $grid-columns {
                // Full width images
                $gutter: map-get($grid-gutter-widths, $breakpoint);
                .col-md-#{$i}:last-child &__image-wrap--reversed {
                    margin-left: -$gutter/2;
                    margin-right: 40px;
                    text-align: right;
                }
                .col-md-#{$i}:first-child &__image-wrap--reversed {
                    margin-right: -$gutter/2;
                }
                .col-md-#{$i}:last-child &__image--reversed {
                    left: auto;
                    right: 0;
                }

                // Restrict content width
                $max-width: map-get($container-max-widths, $breakpoint) * $i/$grid-columns - map-get($grid-gutter-widths, $breakpoint);
                .col-md-#{$i} &__content-wrap--reversed {
                    max-width: $max-width;
                }
                .col-md-#{$i}:last-child &__content-wrap--reversed {
                    margin-left: auto;
                    margin-right: 40px;
                }
                .col-md-#{$i}:first-child &__content-wrap--reversed {
                    margin-left: 40px;
                    margin-right: auto;
                }
            }
        }
    }
}
