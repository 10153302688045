.employees {

    &__intro {
        text-align: center;
        max-width: 560px;
        margin: auto;
        margin-bottom: $spacer*3;
    }

    &__heading {
        color: $color-teal;
        font-weight: bold;
        margin-bottom: 20px;
    }
    &__subheading {
        font-weight: bold;
    }

    &__list {

        @extend %list-clean;
        @include make-row();

        @include media-breakpoint-up(md){
        }
    }

    &__employee {

        @include make-col-ready();
        @include make-col(12);

        margin-bottom: $spacer*4;

        @include media-breakpoint-up(sm){
            @include make-col(6);
        }

        @include media-breakpoint-up(md){
            @include make-col(4);
        }

        @include media-breakpoint-up(lg){

            @include make-col(3);
        }
    }

    &__image-wrap {
        margin-bottom: $spacer;
    }

    &__image {
        width: 100%;
    }

    &__name {
        margin-bottom: 0;
    }

    &__position {
        margin-bottom: 0;
        line-height: 1.5;
    }

    &__email {
        margin-bottom: 0;
        line-height: 1.5;
    }
}
