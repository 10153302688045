.package-table {
  margin-bottom: 60px;
  border-width: 0;

  border: none;
  border-spacing: 0;
  background-color: #fff;

  width: 100%;

  .bg-color--blue {
    background-color: #006a7c!important;
    }

    &__width-1 {
        width: 34%;
    }
    &__width-2 {
        width: 23%;
    }
    &__no-border-bottom {
        border-bottom: none!important;
    }
    &__no-padding {
        padding: 0!important;
    }

  * {
    border-color: #fff;
  }

  h2 {
    font-size: 30px;
  }

  thead, tbody {
    background-color: #fff;
  }

  tr {
    background-color: #fff;
    border-bottom: #ebeaea;
    border-width: 2px;
    border-style: solid;
  }

  td {

    border-bottom: 0;
    max-width: 300px;
    padding: 8px;
    text-align: center;
    vertical-align: center;
  }

  td:first-of-type {
    max-width: 400px;

    text-align: left;

    p {

      margin-bottom: auto;
      margin-top: auto;

      vertical-align: center;
      padding-bottom: 0;
      padding-right: 20px;
    }
  }

  td:nth-child(2)  {
    background-color: #f4f4f4;
  }

  td:nth-child(4)  {
    background-color: #f4f4f4;
  }

  tr:last-of-type td {
    padding: 0;
  }
  tr:last-of-type {
    border: none;
  }
}