.project-header {
  @include antialias;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.75em;
  background: rgba(0,106,124,0.1);
  background: linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(244,244,244,0) 100%);
  background: -webkit-linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(244,244,244,0) 100%);
  background: -moz-linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(244,244,244,0) 100%);

  &__wrapper {
    @extend .page-hero__wrapper;
    padding-bottom: 0;
    padding-top: 50px;

    @include media-breakpoint-down(xs) {
      padding-bottom: 2rem;
    }

    @include media-breakpoint-up(md) {
      padding-top: 65px;
    }
  }
  &__label {
    text-align: center;
    margin-bottom: 1rem;
  }
  &__heading {
    @extend .page-hero__heading;
    color: $color-teal;
    text-align: center;
    padding-bottom: 1.625rem;
  }
  &__icon {
    opacity: 0.2;
    position: absolute;
    right: 0;
    width: 250px;

    transform: translate(40%, -40%) rotateY(180deg) rotate(65deg);
  }
  &__buttons {
    position: absolute;
    bottom: $spacer;
    right: $spacer;
    text-align: right;

    @include media-breakpoint-down(xs) {
      bottom: auto;
      top: 100%;
      right: 0;
      width: 100%;
      padding-top: 10px;
      text-align: center;
    }
    .btn  {
      position: relative;
      padding-left: 55px;
      padding-right: 30px;
      font-size: 14px;
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 32px;
        transform: translateY(-48%);
        width: 15px;
        height: 15px;
        background-image: url(/site/themes/main/img/icon-photo.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &__video-btn {
    left: 50%;
    position: absolute;
    top: 50%;

    max-width: 25%;

    transform: translate(-50%, -50%);

    svg {
      max-width: 100%;
    }
  }

  &__awards {
    position: absolute;
    bottom: $spacer/2;
    left: $spacer/2;

    display: flex;

    @include media-breakpoint-up(md) {
      bottom: $spacer;
      left: $spacer;
    }
  }

  &__award {
    margin-right: $spacer/2;

    img {
      height: 40px;
      width: auto;

      @include media-breakpoint-up(md) {
        height: 71px;
      }
    }
  }

  &__banner-image-wrap {
    // @include aspect-ratio(12,4);
    // margin-bottom: calc(#{$spacer} + 2vh);
    background-color: $gray-lighter;
    position: relative;
    z-index: 2;
    border-radius: 10px;
    overflow: hidden;
  }

  &__banner-image {
  }
}
.project-detail {
  @extend .container;
  padding-bottom: calc(#{$spacer} + 4vh);
  margin-bottom: calc(#{$spacer} + 9vh);
  

  &-wrap {
    // background-color: $color-gray-lighter;
    position: relative;
    padding-top: calc(#{$spacer} + 4vh);
    background: rgba(244,244,244,0);
  background: linear-gradient(180deg,rgba(244,244,244,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
  background: -webkit-linear-gradient(180deg,rgba(244,244,244,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
  background: -moz-linear-gradient(180deg,rgba(244,244,244,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
  }
  &__video {
    position: relative;
  }
  &__leaves {
    position: absolute;
    left: 0;
    top: -70px;
    z-index: -1;
    &__image {
      width: 275px;
      height: auto;
    }
  }

  &__title {
  }

  &__content {
    margin-bottom: $spacer;
    @include make-col-ready;
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    h2, h3, h4, h5 {
      font-weight: bold;
      color: $color-teal;
    }
  }
  &__galleries {
    padding-top: $spacer;
    @include make-col-ready;
    @include make-col(12);
    @include media-breakpoint-up(md) {
      padding-top: 0;
      @include make-col(6);
    }
  }
  &__gallery {
    @include media-breakpoint-up(md) {
      padding-left: 20px;
    }
    &__title {
      margin-left: -10px;
      margin-top: 30px;

      font-size: 0.9rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    &__list {
      @extend %list-clean;
      @include make-row();
    }
    &__item {
      margin: 0;
      padding: 5px;
      width: 50%;
    }
    &__link {
      display: block;
    }
    &__image-wrap {
    }
    &__image {
      width: 100%;
      border-radius: 5px;
    }
  }

  &__finished-photos {
    @extend .container;
    @include clearfix;

    margin-bottom: calc(#{$spacer} + 2vh);

    &__title {
      text-align: center;
      color: $color-teal;
      margin-bottom:3rem;
    }
    &__list {
      @extend %list-clean;
    }

    &__item {
      padding-left: 0;
      @include make-col-ready();

      float: left;
      margin: 0 0 $gallery-gutter-width-base/2 0;
      padding-left: 5px;
      padding-right: 5px;

      width: 50%;
      &--lg {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        width: 25%;
        margin: 0 0 $gallery-gutter-width-base 0;

        padding-left: 10px;
        padding-right: 10px;

        &--lg {
          width: 50%;
        }
        &:nth-child(6),
        &:nth-child(5n + 11) {
          clear: left;
        }
        &:nth-child(8),
        &:nth-child(10n + 8) {
          clear: both;
        }
        // pull every 10th item up halfway
        &:nth-child(10n + 10) {
          margin-top: -25%;
        }
      }
    }
    &__link {
      cursor: zoom-in;
    }
    &__image-wrap {
    }
    &__image {
      border-radius: 5px;
    }
  }
}
