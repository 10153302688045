.career-gallery {

    &__intro {
        color: #F6F6F6;
        margin: auto;
        max-width: 930px;
        text-align:  center;
    }

    &__intro h1 {
        color: #F6F6F6;
        margin: 30px auto 60px;
        max-width: 600px;
    }

    &__gallery {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__more {
        display: block;
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }
    
    &__img {
        width: calc(25% - 15px);
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            width: calc(50% - 10px);
        }

        &:first-of-type {
            width: 100%;
        }
    }
    &__img-link {
        outline: none;
    }

    &__img.hidden {
        display: none;
    }

    &__intro-bg {
        position:  absolute;
        top: 0;
        left: 50%;
        transform:  translateX(-50%);
        width: 250px;

        @include media-breakpoint-up(lg) {
            top: -30px;
        }
        @include media-breakpoint-up(xl) {
            width: 350px;
        }
    }
}

#career-gallery__more {
    display: inline;
    position: relative;
    color: #6BC2BB;
    text-transform: uppercase;
    cursor: pointer;

    &.less {
        margin: 1.875em 0 0;
    }
    &.less::before {
        content: "";
        display: inline-block;
    
        width: 18px;
        height: 18px;
        border-bottom: 3px solid #6BC2BB;
        border-left: 3px solid #6BC2BB;
        position: absolute;
        left: 50%;
        top: -15px;
        transform: translateX(-50%) rotate(135deg);
    }
    &.less .span-more {
        display: none;
    }
    &.more {
        margin: 0 0 1.875em;
    }
    &.more::before {
        content: "";
        display: inline-block;
    
        width: 18px;
        height: 18px;
        border-bottom: 3px solid #6BC2BB;
        border-left: 3px solid #6BC2BB;
        position: absolute;
        left: 50%;
        top: 25px;
        transform: translateX(-50%) rotate(-45deg);
    }
    &.more .span-less {
        display: none;
    }
}