/*
|--------------------------------------------------------------------------
| Icons
|
| Icons are generated using https://icomoon.io/app
| If you want to add more icons, you can generate a new set of font files
| from the icomoon app.
|--------------------------------------------------------------------------
*/


@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?8c0e9r');
  src:  url('../fonts/icomoon.eot?8c0e9r#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?8c0e9r') format('truetype'),
    url('../fonts/icomoon.woff?8c0e9r') format('woff'),
    url('../fonts/icomoon.svg?8c0e9r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'contact_form';
  src:url('../fonts/contact_form.eot?-6n3m7o');
  src:url('../fonts/contact_form.eot?#iefix-6n3m7o') format('embedded-opentype'),
    url('../fonts/contact_form.woff?-6n3m7o') format('woff'),
    url('../fonts/contact_form.ttf?-6n3m7o') format('truetype'),
    url('../fonts/contact_form.svg?-6n3m7o#contact_form') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@mixin icon {
    font-family: "Icomoon";
    font-style: normal;
    font-size: 1.5em;
    font-weight: normal;
    font-variant: normal;
    font-display: swap;
    speak: none;
    text-transform: none;
    text-decoration: none;
    text-align: center;
    @include antialias;
    transition: transform .1s ease-out;
    transform: translateZ(0);

    &:hover {

      color: inherit;
      transform: scale(1.1);
      @include antialias;
      text-decoration: none;
    }
}

$icon-mail:       "\e906";
$icon-mail-o:     "\e907";
$icon-map-pin:    "\e908";
$icon-phone:      "\e90a";

$icon-facebook:   "\e904";
$icon-googleplus: "\e901";
$icon-houzz:      "\e905";
$icon-linkedin:   "\e903";
$icon-pinterest:  "\e902";
$icon-twitter:    "\e608";
$icon-youtube:    "\e900";
$icon-instagram:  "\e909";
