.design-packages {
    font-family: $font-headings;
    color: $color-gray-dark;

    @include media-breakpoint-only(md) {
        padding-top: 4px;
    }

    &__banner-heading {
        h2.page-banner__heading {
            max-width:800px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__list {
        @extend %list-clean;
        @include make-row;

        display: flex;

        @include media-breakpoint-only(md) {
            padding: 0;
        }
    }

    &__item {
        @extend %list-clean;
        @include make-col-ready;
        @include make-col(12);

        display: flex;
        margin-bottom: $spacer;

        text-align: center;

        @include media-breakpoint-down(md) {
            padding: 0;
            display: block!important;
        }

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }

    &__highlight {
        width: 100%;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        background-color: darken($color-orange, 10);
        color: #fff;
        letter-spacing: .1em;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
            width: 100%;
            transform: translateY(-100%);
        }

        position: relative;

    }

    &__inner {
        position: relative;
        @include make-color-modifiers(background-color);
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 1px 0px 28px 0px rgba(82,116,133,0.2);
        transition: transform 0.2s;
        &--noradius {
            border-radius: 0;
        }
    }

    &__inner--teal,
    &__inner--green,
    &__inner--orange {
        padding: 20px 30px;
    }
    &__inner--teal,
    &__inner--green {
        border-radius: 10px 10px 0 0;
    }

    &__inner--teal {
        color: #fff;
    }

    &__header {
        border-radius: 20px 20px 0 0;
        &--noradius {
            border-radius: 0;
        }
    }

    &__title {
        @include antialias;
        color: #fff;
        line-height: 1.3;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 0;

        @include media-breakpoint-only(md) {
            // font-size: 2.75rem;
        }
    }

    &__subtitle {
        font-size: 20px;
        line-height: 1.4;
        margin: 10px 0;
    }

    &__details {
        font-size: .9375rem;
        padding: 20px 30px;
    }

    &__details-wrap {
        text-align: left;
        color: $color-gray-medium;
        &:first-of-type {
            margin-bottom: 20px;
            padding-bottom: 20px;
            padding-top: 20px;
        }

        ul {
            // @extend %list-clean;
        }
    }

    &__number {
        font-size: 26px;
        margin-bottom: 20px;
        @include media-breakpoint-up(xl) {
            font-size: 30px;
        }
        &--teal {
            color: $color-teal;
        }
        &--green {
            color: $color-green;
        }
        &--orange {
            color: $color-orange;
        }
    }

    &__price {
        @include antialias;
        margin-top: auto;
        padding-top: 30px;
        padding-bottom: 40px;
        line-height: 1.1;
        border-top: solid 2px $color-gray-border;
        background-color: #f8f8f8;
        small {
            font-size: 0.875rem;
        }
    }

    &__item--highlighted &__price {
        font-size: 30px;

        @include media-breakpoint-up(xl) {
            font-size: 36px;
        }
    }

    &__button {
        margin: 0 auto;
        display: inline-block;
        padding-left:  2.5rem;
        padding-right: 2.5rem;
        border: solid 2px;
        &--teal {
            border-color: $color-teal;
        }
        &--green {
            border-color: $color-green;
        }
        &--orange {
            border-color: $color-orange;
        }
    }

    &__view-gallery-button {
        @include antialias;
        display: inline-block;
        margin-top: 15px;
        border: solid 1px $color-gray;
        padding: 10px 20px 6px 40px;

        font-weight: bold;

        background-image: url(../img/icons/magnifying-glass-black.svg);
        background-repeat: no-repeat;
        background-position: 12px center;
        text-transform: uppercase;
        opacity: .8;

        &:hover {
            color: inherit;
            opacity: 1;
        }
    }

    &__inner--teal &__view-gallery-button {
        background-image: url(../img/icons/magnifying-glass-white.svg);
    }

}
