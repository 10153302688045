.projects-listing {
  // @extend .container;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px 60px;

  @media (min-width: 1450px) {
    padding: 0;
  }

  &__list {
    @extend %list-clean;

    @include media-breakpoint-up(md) {
      display: grid;
      column-gap: 30px;
    }
  }

  &__list--3-cols {
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__list--2-cols {
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;
    }
  }

  &__link {
    background-position: center center;
    background-size: cover;
    display: block;
    margin-bottom: 1rem;
    padding: 0;
    border-radius: 10px;
    color: $color-gray;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
  }

  &__link:hover {
    color: $color-gray-dark;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    transition: all ease .2s;
    transform: translateY(-1px);
    cursor: pointer;
  }
  &__image {
    margin-bottom: 16px;
    border-radius: 10px 10px 0 0;
    border-bottom: 8px solid $color_gray;
    transition: all 0.25s ease-out;
  }

  &__item {
    padding-left: 0;
  }

  &__item:nth-child(3n + 1) &__image {
    border-color: $color-red;
  }

  &__item:nth-child(3n + 2) &__image {
    border-color: $color-green-dark;
  }

  &__item:nth-child(3n + 3) &__image {
    border-color: $color-teal-light;
  }

  &__title-card {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 15px;
    }
    &__title-label {
        color: $color-gray;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 0 10px;
    }

    &--blueridge {
      .projects-listing__title-card {
        color: $color-gray-medium;
      }
    }
}
