.site-logo {
  position: absolute;
  top: -5px;
  left: 15px;
  z-index: 9000;
  max-width: 150px;
  width: 100%;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  @include media-breakpoint-down(xs) {
    margin-left: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 105px;
  }

  &__leaf {
    @include media-breakpoint-down(xs) {
      opacity: 0;
    }
  }
}
