.testimonial {
    padding-left: 75px;
    background: url('/site/themes/main/img/icons/quote.svg') no-repeat left 10px;
    margin-bottom: calc(#{$spacer} + 4vh);
    margin-left: auto; margin-right: auto;

    @include media-breakpoint-up(md){
        width: 75%;
    }

    &__content {
        display: block;
        font-size: 1.375rem;
        p:last-child { margin-bottom: 0; }

        @include media-breakpoint-down(md) {
            font-size: calc(1.375rem/3 + 2vw);
        }
    }
    &__client-name {
        display: block;
        font-size: 1rem;
        text-align: right;
        &:before {
            content: '— '
        }
    }
}
