.maintenance-packages {
    //@extend %overlap-banner;

    font-family: $font-headings;
    color: $color-gray-dark;

    margin-bottom: calc(#{$spacer} + 4vh);

    &__list {
        @extend %list-clean;
        @include make-row;
    }

    &__item {
        position: relative;

        @extend %list-clean;
        @include make-col-ready;
        @include make-col(12);
        text-align: center;

        margin-bottom: $spacer;

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }

    }

    &__highlight {

        padding: 10px;

        background-color: darken($color-teal-light, 25);
        color: lighten($color-teal-light, 30);
        letter-spacing: .1em;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            left: 15px;
            position: absolute;
            right: 15px;
            top: 0;

            margin: 0;
            width: calc(100% - 30px);

            transform: translateY(-100%);
        }
    }

    &__link {
        @include make-color-modifiers(background-color);

        display: block;

        padding: 30px 15px;

        transition: transform 0.2s;
    }

    &__title {
        @include antialias;
        color: #fff;
        margin-left: -15px;
        margin-right: -15px;

        line-height: 1;

        @include media-breakpoint-only(lg) {
            font-size: 2.75rem;
        }
    }

    &__details {
        font-size: .9375rem;

        width: 100%;
        display: table;

        color: $color-gray-dark;

        @include media-breakpoint-up(sm) {
            // height: 175px;
        }

        @include media-breakpoint-up(xl) {
            height: 130px;
        }

        h5 {
            font-size: 1.5rem;
        }
    }
    &__link--blue &__details {
        @include antialias;
        color: #80B4BD;
    }

    &__details-wrap {
        display: table-cell;
        vertical-align: middle;

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__price {
        @include antialias;
        font-size: 26px;
        line-height: 1.1;

        color: #fff;

        margin-bottom: $spacer*1.5;

        @include media-breakpoint-up(xl) {
            font-size: 30px;
        }

        small {
            font-size: 0.875rem;
        }
    }

    &__button {
        padding-left:  2.5rem;
        padding-right: 2.5rem;
    }

}
