.footer-nav {
    font-family: $font-headings;
    padding: $spacer 0;
    // width: 100%;
    font-weight: 600;
    @include media-breakpoint-down(md) {
        padding-bottom: 40px;
    }

    &__list {
        @extend %list-clean;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 0px;
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-breakpoint-down(xs) {
            display: inline;
            grid-template-columns: none;
        }
    }

    &__item {
        @extend %list-clean;
        padding: 5px 0;
        @include media-breakpoint-down(xs) {
            display: inline-block;
        }
    }

    &__link {
        @include media-breakpoint-down(xs) {
            padding: 0px 10px;
        }
    }
}
