
.parallax {
    .pSection-1,
    .pSection-2,
    .pSection-3 {
        position: relative;
    }   
}

.home {
  &__main-flower-wrap {
    position: relative;

    z-index: -1;

    @include media-breakpoint-down(xs) {
      padding-bottom: 10%;
    }
  }

  &__main-flower {
    position: absolute;
    bottom: 0;
    right: 0;

    max-width: 50%;

    @include media-breakpoint-up(md) {
      transform: translateX(10%);
    }
  }
}

.home__value {
    padding-top: 100px;
    @include media-breakpoint-down(md) {
        padding-top: 20px;
    }
    //background: rgba(244,244,244,0);
    //background: linear-gradient(180deg,rgba(244,244,244,0) 0%, rgba(225,225,226,0.5) 30%, rgba(225,225,226,0.5) 70%, rgba(0,106,124,0.05) 100%);
    //background: -webkit-linear-gradient(180deg,rgba(244,244,244,0) 0%, rgba(225,225,226,0.5) 30%, rgba(225,225,226,0.5) 70%, rgba(0,106,124,0.05) 100%);
    //background: -moz-linear-gradient(180deg,rgba(244,244,244,0) 0%, rgba(225,225,226,0.5) 30%, rgba(225,225,226,0.5) 70%, rgba(0,106,124,0.05) 100%);
    .home-stack-item {
        padding: 0 0 150px 0;
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        .container {
            max-width: 1400px;
        }
        &__flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
        &__box {
            position: relative;
            flex-direction: column;
            display: flex;
            justify-content: center;
            &.image {
                width: 50%;
                @include media-breakpoint-down(md) {
                    width: 70%;
                    margin: 0 auto;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            &.content {
                width: 50%;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            &-image {
                position: relative;
                width: 100%;
                display: block;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            &-content {
                margin-top: -20px;
                padding: 0px 0 70px 120px;
                @include media-breakpoint-down(lg) {
                    padding: 0px 0 70px 70px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                    padding: 40px 0;
                }
            }
        }

        &--2 .flower-parallax {
            position: absolute;
            z-index: 2;
            content: '';
            bottom: -350px;
            right: -110px;
            width: 373px;
            height: 408px;
            background-image: url(../img/icons/flowers/3.svg);
            background-repeat: no-repeat;
            background-size: cover;
            @include media-breakpoint-down(md) {
                bottom: -100px;
                right: -90px;
                width: 223px;
                height: 248px;
            }
        }
    
    
        &--2 .image{
            @media (min-width: 768px) {
                order: 2;
            }
        }
    
        &--2 .content {
            @media (min-width: 768px) {
                order: 1;
            }
            .home-stack-item__box-content {
                margin-top: -20px;
                padding: 0px 120px 70px 0px;
                @include media-breakpoint-down(lg) {
                    padding: 70px 70px 70px 0px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                    padding: 40px 0;
                }
            }
        }
    
        &__play-btn {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 2;
            display: inline-block;
            transform: translate(-50%, -50%);
        }
    }
}

.title-space {
    padding: 60px 0;
    &.center {
        text-align: center;
    }
    &.pt {
        padding-top: 0;
    }
    &.pb {
        padding-bottom: 0;
    }
}

.home__services {
    // padding: 100px 0;
    padding: 60px 0 100px 0;
    text-align: center;
    //background-color: rgba(0, 106, 124, .05);
    .container {
        max-width: 1400px;
    }
    &-section {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 20px;
        padding: 40px 0px;
        @include media-breakpoint-down(md){
            grid-template-columns: none;
            padding: 0;
        }
    }
    .services-card {
        width: 100%;
        text-align: center;
        //border: solid 1px $color-gray-border;
        padding: 0;
        border-radius: 10px;
        color: $color-gray;
        background-color: #fff;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
        &:hover {
            color: $color-gray-dark;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
            transition: all ease .2s;
            transform: translateY(-1px);
            cursor: pointer;
        }
        &__image {
            width: 100%;
            padding-bottom: 50%;
            background-color: $color-teal-light;
            border-radius: 10px 10px 0 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &__content {
            font-family: $font-headings;
            line-height: 1.8;
            padding: 15px;
            font-size: 15px;
            span {               
                display: block;                 
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
}

.home__projects {
    padding-top: 80px;
    @include media-breakpoint-down(md){
        padding-top: 60px;
    }
    .testimonial-box {
        padding: 30px 0;
        &__quote {
            font-family: $font-headings;
            color: $color-teal;
            font-style: italic;
            font-size: 1.5em;
            font-weight: 500;
            cite {
                display: block;
                color: $color-gray;
                padding: 10px 0;
            }
        }
    }
}

body {
    transition: background-color 1s ease;
}

.panel {
    min-height: 100vh;
}
.color-white {
    background-color: #ffffff;
}
.color-ligray {
    background-color: rgba(225, 225, 226, .1);
}
.color-liblue {
    background-color: rgba(0, 106, 24, .1);
}
.color-mdblue {
    background-color: rgba(124,232,224,0.2);
}
.color-beige {
    background-color: rgba(185, 200, 130, .25);
}

.gradient-1 {
    background: #ffffff;
    background: linear-gradient(180deg,#ffffff 0%, rgba(0,106,124,0.05) 10%, rgba(0,106,124,0.05) 90%, #f4f4f4 100%);
    background: -webkit-linear-gradient(180deg,#ffffff 0%, rgba(0,106,124,0.05) 10%, rgba(0,106,124,0.05) 90%, #f4f4f4 100%);
    background: -moz-linear-gradient(180deg,#ffffff 0%, rgba(0,106,124,0.05) 10%, rgba(0,106,124,0.05) 90%, #f4f4f4 100%);
}

.gradient-2 {
    background: #f4f4f4;
    background: linear-gradient(180deg,#f4f4f4 0%, rgba(185,200,130,0.1) 10%, rgba(185,200,130,0.1) 100%);
    background: -webkit-linear-gradient(180deg,#f4f4f4 0%, rgba(185,200,130,0.1) 10%, rgba(185,200,130,0.1) 100%);
    background: -moz-linear-gradient(180deg,#f4f4f4 0%, rgba(185,200,130,0.1) 10%, rgba(185,200,130,0.1) 100%);
}

.home__plan {
    padding-bottom: 60px;
    @include media-breakpoint-down(md){
        padding-bottom: 0px;
    }
    .container {
        max-width: 1400px;
    }
    .title-space {
        h3 {
            margin-bottom: 30px;
        }
        .body-text {
            max-width: 750px;
            margin: 0 auto;
        }
    }
    &-cta {
        text-align: center;
        padding: 40px 0;
    }
    &-section {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 20px;
        @include media-breakpoint-down(sm){
            grid-template-columns: none;
        }
    }
}

.home__blog {
    .container {
        max-width: 1400px;
    }
    &-section {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 20px;
        @include media-breakpoint-down(sm){
            grid-template-columns: none;
        }
    }
}

.home__careers {
    padding-top: 100px;
    @include media-breakpoint-down(md){
        padding-top: 50px;
    }
    .regular-stack-item {
        padding: 0;
        &__box.image {
            order: 1;
            @include media-breakpoint-down(md){
                order: 2;
            }        
        }
        &__box.content {
            order: 2;
            @include media-breakpoint-down(md){
                order: 1;
                text-align: center;
            }
        }
    }
}

.home__serving {
    background-color: $color-teal-light;
    position: relative;
    .image-edge__flower.right {
        right: -153px;
        top: -230px;
        width: 373px;
        transform: rotate(183deg);
        @include media-breakpoint-down(md){
            right: -57px;
            top: -120px;
            width: 223px;
        }
    }
    &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        @include media-breakpoint-down(md){
            grid-template-columns: repeat(1, 1fr);
        }
        &--map {
            margin: 15px auto;
            padding: 0 50px;
            order: 2;
            @include media-breakpoint-down(lg){
                padding: 60px 0 0 0;
                order: 1;
            }
        }
    }
    .title-space {
        max-width: 750px;
        margin: 0 auto;
        padding: 100px 0;
        color: #fff;
        order: 1;
        @include media-breakpoint-down(md){
            order: 2;
            text-align: center;
            padding: 20px 0 60px;
        }
        h3 {
            color: #fff;
            margin-bottom: 30px;
        }
        .body-text {
            color: #fff;
        }
    }
}

.home__blueridge {
    .regular-stack-item {
        padding: 0 0 150px 0;
        @include media-breakpoint-down(md){
            padding: 150px 0 50px;
        }
    }
}