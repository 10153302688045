.home-callout {
    position: relative; 
    z-index: 2;
    @include media-breakpoint-down(xs) {
        position: absolute;
        width: 100%
    }
}

.promo-callout {
    @include antialias;
    background: $color-red;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    &__content {
        padding: $spacer 0;
        font-family: $font-headings;

        p {
            line-height: 1.15em;
        }
        a {
            color: #fff;
            margin: 0.5em $spacer;
            position: relative;
            &:after {
                content: "›";
                padding-left: 3px;
            }
            &:hover {
                opacity: .75;
            }
        }
        > :last-child {
            margin-bottom: 0;
        }
    }
}

