.maintenance-garden-gloves {
    padding: 80px 20px;

    background-color: $color-green;
    background-image: url(../img/icons/branches/6.png);
    background-repeat: no-repeat;
    background-position: -20px -60px;
    background-size: 295px auto;
    color: $color-black;
    text-align: center;
    border-radius: 10px;

    h2 {
        color: #fff;
    }

    p {
        margin-bottom: 18px;
        line-height: 1.75;
    }

    &__content-wrap {
        margin: 0 auto;
        max-width:700px;
    }
}