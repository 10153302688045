.product-price {
    display: inline-block;
    vertical-align: middle;

    text-transform: uppercase;

    background: $color-teal-light;
    color: white;
    font-size: 1rem;
    font-weight: normal;

    padding: 10px 15px;

    &--orange {
        background: $color-orange;
    }

    @include make-color-modifiers(background-color);
}
