.form {
  margin-bottom: 0;

  font-size: $font-size-lg;

  &__form {
    width: 100%;
  }

  &__heading {
    width: 100%;
    font-weight: bold;
    color: $color-teal;
  }

  &__field-list {
    @extend %list-clean;

    width: 100%;

    margin-bottom: $spacer;
  }

  &__form-group {
    @extend %list-clean;

    position: relative;

    padding-top: 20px;
  }

  &__form-group--select {
    position: relative;

    font-size: 1.375rem;
    font-family: $font-headings;

    select {
      position: relative;
      z-index: 2;

      padding-right: 40px;
    }

    &:after {
      position: absolute;
      right: 18px;
      top: 31px;
      z-index: 1;

      content: '';
      display: block;

      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid;
      border-bottom: 8px solid;

      transform: rotate(-45deg);
    }
  }

  &__form-group--select &__field {
    border-color: $color-green;
    border-width: 2px;
    color: $color-gray-dark;

    height: auto !important;

    &:focus {
      border-color: darken($color-green, 10%);
    }

    font-weight: bold;
  }

  &__label {
    left: $input-padding-x;
    position: absolute;
    top: 20px;

    color: $gray;
    opacity: 0;
    font-size: 0.6875rem;
    transition: top 0.3s ease, opacity 0.3s ease;
    text-transform: uppercase;

    margin-bottom: 0;
  }

  &__label--visible {
    opacity: 1;
    top: 10px;

    padding: 2px 4px;
    margin-left: -4px;

    background-color: $color-gray-lighter;
  }

  &--get-started &__label--visible {
    background-color: #fff;
  }

  &__field {
    appearance: none;

    border: 1px solid $color-gray-dark;
    background-color: transparent;

    font-size: inherit;

    &:focus {
      border-color: $color-gray;
      background-color: transparent;
    }

    &::placeholder {
      color: inherit;
    }
  }

  &__field--textarea {
    height: 120px;
    padding: $spacer;

    border: 1px solid $gray;
  }

  &__submit {
    display: inline-block;
    width: 100%;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__field--comment {
    // This is the honeypot
    position: absolute;
    left: -1000000px;

    opacity: 0.001;
    height: 1px;
    width: 1px;
    padding: 0 !important;
    margin: 0;
  }

  &__errors {
    margin-bottom: 5px;
    padding: 10px;

    background-color: $brand-warning;
    color: #fff;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__label--resume {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    position: relative;
    width: 100%;

    background: #464342;
    color: white;
    font-size: 16px;
    font-weight: 300;
    padding-right: 20px;
    text-align: left;

    cursor: pointer;
  }

  &__field--resume {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;

    z-index: -1;
  }

  &-field__display {
    background: white;
    border: 1px solid;
    border-right: none;
    color: #55595b;
    content: ' ';
    display: inline-block;
    height: 50px;
    width: calc(100% - 115px);

    font-weight: 500;
    font-size: 20px;
    padding: 10px 0 10px 20px;
  }

  &__form-group--design-package {
    position: relative;

    padding-right: 50px;

    &:after {
      right: 65px;
    }
  }

  // design package info

  &__design-info-button {
    position: absolute;
    right: 0;
    top: 20px;

    align-items: center;
    justify-content: center;
    display: flex;
    height: 45px;
    width: 40px;

    background-color: $color-gray;
    color: #fff;
    font-size: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;

    &:hover,
    &:active {
      background-color: $color-gray-light;
      color: white;
    }
  }

  &__design-info-list {
    display: none;

    list-style: none;
    margin: 14px -50px 0 0;
    padding: 10px;

    background-color: $color-gray-lighter;
    border-radius: 2px;

    &:after {
      content: '';
      display: block;

      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid $color-gray-lighter;
      border-bottom: 8px solid $color-gray-lighter;

      position: absolute;
      right: 10px;
      top: 73px;

      transform: rotate(135deg);
    }
  }

  &__design-info-item {
    margin: 0;
    padding: 10px;

    font-size: 14px;
  }

  &--automowers-landing {
    padding-top: 24px;

    @include media-breakpoint-up(lg) {
      padding: 24px;
    }
  }
}
