/* ==========================================================================
   Responsivie Debugging
   ========================================================================== */

   body.development:after {
    bottom: 2px;
    position: fixed;
    right: 2px;
    z-index: 3000;

    padding: 2px 8px;

    background: #000;
    color: #FFF;
    font-size: 12px;
    font-weight: bold;
    opacity: .5;
    text-align: center;

    content: 'xs';

    @include media-breakpoint-up(sm) {
      content: 'sm';
    }

    @include media-breakpoint-up(md) {
      content: 'md';
    }

    @include media-breakpoint-up(lg) {
      content: 'lg';
    }

    @include media-breakpoint-up(xl) {
      content: 'xl';
    }
  }



