.blueridge__section.featured-projects-callout {

  padding-top: 60px;

  .page-hero__category {
    color: $color-gray-medium;
    font-size: 14px;
    text-shadow: none;
  }

  #services-subsection h2 {
    color: #d3636b;
    padding-bottom: 25px;
  }

}