.site-header {
    position: relative;
    padding: 30px 0 1px;
    text-align: center;
    background: #fff;
    box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.1);

    @include media-breakpoint-down(xs) {
        height: 60px;
        position: fixed;
        top: 0;
        z-index: 2000;
        width: 100%;
        padding: 0;
    }

    &__inner {
        position: relative;
        min-height: 102px;
        @include media-breakpoint-down(md) {
            min-height: 142px;
        }
        .container {
            max-width: 1400px;
            
        }
    }

    &__contact-info {
        text-align: right;
        margin-right: 20px;
        display:none;
        @include media-breakpoint-down(xs) {   
            position: absolute;
            display: block;
            z-index: 2;
            right: 0;
        }
    }

    &__location {
        @extend %light;
        font-size: 12px;
        display: none;
        @include media-breakpoint-up(sm) {   
            display: inline-block;
        }
    }

    &__phone-link {
        @include media-breakpoint-down(xs) {
            position: relative;
            z-index: 10;
            display: block;
            height: 60px;
            width: 60px;
            background-image: url(../img/phone-icon.svg);
            background-size: auto 60%;
            background-repeat: no-repeat;
            background-position: center center;
        }
        @include media-breakpoint-up(lg) {
            display: inline-block;
        }
    }

    &__phone-digits {
        display: none;
        @include media-breakpoint-up(sm) {
            @include link($color-blue);
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            line-height: 1;
        }
    }

    &__get-started {
        float: right;
        position: relative;
        z-index: 9000;
        margin-top: 15px;
        margin-bottom: 15px;
        .phone {
            margin-top: 20px;
            margin-bottom: 10px;
            @include media-breakpoint-down(sm) {
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }
        @include media-breakpoint-down(xs) {   
            display: none;
        }
        &--phone {
            position: absolute;
            top: -33px;
            width: 100%;
            text-align: center;
            left: 0;
            @include media-breakpoint-down(sm) {
                position: relative;
                display: inline;
                top: 0;
                margin-right: 15px;
            }
            img {
                transform: rotate(110deg);
            }
            a {
                // font-weight: 600;
                color: $color-gray-dark;
                &:hover {
                    color: $color-gray;
                }
            }
        }
    }
}
