.blog-article-card {
    position: relative;
    display: block;
    height: 100%;
    background-color: #fff;
    text-align: center;
    border-radius: 10px;
    transition: all .2s ease-in;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
    &:hover {
        color: $color-gray-dark;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
        transition: all ease .2s;
        transform: translateY(-1px);
        cursor: pointer;
    }

    &__feature-image-wrap {
        position: relative;
        display: block;
        img {
            border-radius: 10px 10px 0 0;
        }
    }

    &__author-photo {
        bottom: 0;
        left: 50%;
        position: absolute;
        width: 80px;
        border-radius: 50%!important;
        transform: translate(-50%, 50%);
    }

    &__title {
        color: $color-gray-medium;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5;
        transition: all .1s ease-in;
        padding-left: 20px;
        padding-right: 20px;
        a:hover {
            color: $color-gray;
        }
    }

    &__metadata {
        color: $color-gray-medium;
        font-family: $font-headings;
        font-size: 16px;
        padding-top: 58px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__content {
        padding: 20px;
    }
    
}