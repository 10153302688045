.image-edge {
    padding: 50px 0 150px 0;
    @include media-breakpoint-down(md){
        padding: 0;
    }
    .fluid-container {
        max-width: 1400px; 
        margin: 0 auto;
        padding: 0;
    }

    &__flower {
        position: absolute;
        z-index: 2;
        &.left {
            left: -220px;
            bottom: -160px;
            width: 430px;
            transform: rotate(-5deg);
            @include media-breakpoint-down(md) {
                left: -137px;
                bottom: -100px;
                width: 300px;
            }
        }
        &.right {
            right: -110px;
            bottom: -210px;
            width: 373px;
            transform: rotate(-5deg);
            @include media-breakpoint-down(md) {
                right: -57px;
                bottom: -120px;
                width: 223px;
            }
        }
    }


    &__intro {
        color: $color-gray;
        padding-bottom: 30px;
    }

    &__space {
        padding: 0 0 60px 0;
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
    &__box {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: center;
        width: 50%;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        &-image {
            position: relative;
            width: 100%;
            display: block;
            &:after {
                content: "";
                display: block;
                padding-bottom: 80%;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                margin: auto;
                @media only screen and (max-width: 1500px) {
                    border-radius: 10px 0 0 10px;
                }
                @include media-breakpoint-down(md) {
                    border-radius:0;
                }
                &.left {
                    @media only screen and (max-width: 1500px) {
                        border-radius: 0 10px 10px 0;
                    }
                    @include media-breakpoint-down(md) {
                        border-radius:0;
                    }
                }
            }
        }
        &-content {
            padding: 70px 130px 70px 70px;
            @include media-breakpoint-down(lg) {
                padding: 70px 100px 70px 70px;
            }
            @include media-breakpoint-down(md) {
                text-align: center;
                padding: 70px;
            }
            @include media-breakpoint-down(xs) {
                text-align: center;
                padding: 70px 20px;
            }
            &.right {
                padding: 70px 70px 70px 130px;
                @include media-breakpoint-down(lg) {
                    padding: 70px 70px 70px 100px;
                }
                @include media-breakpoint-down(md) {
                    text-align: center;
                    padding: 70px;
                }
                @include media-breakpoint-down(xs) {
                    text-align: center;
                    padding: 70px 20px;
                }
            }
        }
    }
}