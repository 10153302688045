.video-testimonial {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px 40px;
  text-align: center;

  @media (min-width: 1450px) {
    padding-left: 0;
    padding-right: 0;
  }

  &__heading {
    margin-bottom: 40px;
    color: $color-teal;
    font-weight: bold;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  }

  &__image-wrap {
    position: relative;
  }

  .d-md-flex {
    align-items: center;
    @include media-breakpoint-down(md) {
      align-items: start;
    }
  }

  &__play-btn {
    left: 50%;
    top: 50%;
    position: absolute;

    max-width: 25%;

    transform: translate(-50%, -50%);

    svg {
      max-width: 100%;
    }
  }
  &__img {
    width: 49%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  &__image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  &__quote {
    display: block;
    max-width: 1400px;

    font-family: $font-headings;
    font-style: italic;
    font-size: 1.875rem;
    color: $color-teal;
    font-weight: 500;
    padding: 0 0 30px 0;
    @include media-breakpoint-down(sm) {
      padding-top: 40px;
    }

    cite {
      margin-top: 20px;
      display: block;
      color: $color-gray;
      font-size: 24px;
    }
  }

  &__copy {
    text-align: left;
    padding-left: 40px;
    width: 50%;
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
      width: 100%;
    }
  }
}
