.intake-form-scope-selection {

    background-color: #EEEEEE;
    text-align: center;

    &__icon {
        position: absolute;
        left: 50%;
        top: -50px;
        z-index: 1;

        opacity: .75;

        transform: translate(-50%, 0);

        @include media-breakpoint-up(lg) {
            top: 0;
        }
    }

    &__heading {
        position: relative;
        z-index: 10;
    }

    &__card {

        align-self: stretch;
        display: flex;
        height: 100%;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 500px;
        justify-content: center;
        padding: $spacer*2;

        background-color: rgba(#fff, .95);
        transition: .2s background-color ease-out;

        &.deactivated {
            background-color: rgba(#fff, .5);
        }

        &:hover {
            color: inherit;
        }

        &--teal &-heading {
            color: $color_teal;
        }

        &--teal.selected &-heading {
            color: #fff;
        }

        &--teal.selected &-description {
            color: #fff;
        }

        &--teal {

            &.selected {
                background-color: $color_teal;
            }
        }

        &--green &-heading {
            color: $color_green;
        }

        &--green.selected &-heading {
            color: #fff;
        }

        &--green.selected &-description {
            // color: #fff;
        }

        &--green {

            &.selected {
                background-color: $color_green;
            }
        }

        &--orange &-heading {
            color: $color_orange;
        }

        &--orange.selected &-heading {
            color: #fff;
        }

        &--orange.selected &-description {
            // color: #fff;
        }

        &--orange {

            &.selected {
                background-color: $color_orange;
            }
        }
    }

    &__btn {
        color: #fff;
        transition: none;
    }

    &__card.selected &__btn {
        padding: 0;
        font-size: 24px;
    }

    &__card--teal &__btn {
        background: $color_teal;
    }

    &__card--green &__btn {
        background: $color_green;
    }

    &__card--orange &__btn {
        background: $color_orange;
    }

    &--heading {
        font-size: 32px;
    }
}