.blog-post-list {

    background-color: #fff;

    &__article {

        // margin-bottom: calc(#{$spacer} + 2vh);
    }

    &__title {
    }

    &__link {

        display: block;
        // padding-bottom: $spacer;

        // border-bottom: 1px solid $gray-100;
        text-decoration: none;

        &:hover, &:focus {

            outline: 0;
            text-decoration: none;
        }
    }

    &__date {

        font-size: 12px;
    }
}