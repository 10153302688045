.intake-form {

    position: relative;

    margin-bottom: 0;

    font-size: $font-size-lg;

    &__assessment-heading {
        display: none;
    }

    &__section {

        position: relative;

        margin: 0 auto;
        max-width: 1340px;

        display: none;

        &--red {
            color: $color-red;
        }

        &--orange {
            color: $color-orange
        }

        &--teal {
            color: $color-teal;
        }

        &--green-dark {
            color: $color-green-dark;
        }

        &--blue {
            color: $color-blue;
        }

        &--black {
            color: $color-black;
        }
    }

    &__section-heading {

        position: relative;
        z-index: 2;

        font-weight: 700;
        text-transform: uppercase;

        &--small {
            margin-bottom: 0;

            color: $color-black;
            font-size: 12px;
        }

        &--large {
            position: sticky;

            padding: 10px;
        }

        &--red {
            background-color: $color-red;
        }

        &--orange {
            background-color: $color-orange;
        }

        &--teal {
            background-color: $color-teal;
        }

        &--green-dark {
            background-color: $color-green-dark;
        }

        &--blue {
            background-color: $color-blue;
        }

        &--black {
            background-color: $color-black;
        }
    }

    &__form {

        width: 100%;
    }

    &__heading {

        width: 100%;
    }

    // decoration

    &__field-wrap-site_plan_exists {

        &:before {
            position: absolute;
            left: -190px;
            top: -80px;
            z-index: -1;

            content: '';
            height: 200px;
            width: 300px;

            background-image: url(../img/icons/branches/4.png);
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(61deg);
        }
    }

    &__field-wrap-pets {

        &:before {
            position: absolute;
            left: -100px;
            bottom: -80px;
            z-index: -1;

            content: '';
            height: 200px;
            width: 300px;

            background-image: url(../img/icons/branches/4.png);
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(5deg);

            @include media-breakpoint-up(xxl) {
                bottom: -110px;
                left: -150px;
            }
        }
    }

    &__field-wrap-kitchen_and_dining {

        &:before {
            position: absolute;
            left: -150px;
            top: -170px;
            z-index: -1;

            content: '';
            height: 270px;
            width: 450px;

            background-image: url(../img/icons/branches/2.png);
            background-size: contain;
            background-repeat: no-repeat;
            transform: scaleX(-1) rotate(215deg);

            @include media-breakpoint-up(xl) {
                bottom: -110px;
                left: -150px;
            }
        }
    }

    &__field-wrap-fire {

        &:before {
            position: absolute;
            left: auto;
            right: -100px;
            bottom: -120px;
            z-index: -1;

            content: '';
            height: 270px;
            width: 450px;

            background-image: url(../img/icons/branches/2.png);
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(-110deg);

            @media (min-width: 1600px) {
                right: -140px;
            }
        }
    }

    &__field-wrap-water_management {

        &:before {
            position: absolute;
            left: -150px;
            top: -170px;
            z-index: -1;

            content: '';
            height: 270px;
            width: 450px;

            background-image: url(../img/icons/branches/1.png);
            background-size: contain;
            background-repeat: no-repeat;
            transform: scaleX(-1) rotate(200deg);

            @include media-breakpoint-up(xl) {
                top: -180px;
                left: -225px;
            }
        }
    }

    &__aside {
        position: absolute;
        right: 0;
        top: 0;

        z-index: 1;

        &--site-details {
            right: 0;
            top: -20px;

            width: 150px;

            @include media-breakpoint-up(sm) {
                top: 20px;
            }

            @include media-breakpoint-up(md) {
                top: -20px;

                width: 250px;

                transform: scaleX(-1) rotate(-50deg);
            }

            @include media-breakpoint-up(xl) {
                right: -100px;
                top: -70px;

                width: 350px;
                transform: scaleX(-1) rotate(-55deg);
            }

            @media (min-width: 1500px) {
                right: -100px;
            }
        }

        &--lifestyle {
            top: 30px;

            width: 300px;

            @include media-breakpoint-up(lg) {
                top: 90px;

                width: 445px;
            }

            @include media-breakpoint-up(xl) {
                right: -80px;

            }
        }

        &--design-style {

            right: -50px;
            top: -80px;

            height: 200px;
            width: 200px;

            background-image: url(../img/icons/flowers/9.svg);
            background-position: 0 0;
            background-size: contain;
            background-repeat: no-repeat;

            @include media-breakpoint-up(md) {
                height: 250px;
                width: 250px;
            }

            @include media-breakpoint-up(lg) {
                top: -100px;

                height: 300px;
                width: 320px;
            }

            @include media-breakpoint-up(xl) {
                right: -80px;
                top: -100px;
            }

            @media (min-width: 1600px) {
                left: -280px;
                right: auto;
                z-index: -1;

                height: 400px;
                width: 280px;

                background-size: 380px auto;
            }
        }

        &--wishlist {
            top: 300px;

            @include media-breakpoint-up(lg) {
                right: -100px;

                transform: scale(.75);
            }

            @include media-breakpoint-up(xl) {
                top: 670px;

                transform: scale(1);
            }

            @media (min-width: 1600px) {
                right: -100px;
            }
        }

        &--plantings {

            right: -100px;

            transform: scale(.5);

            @include media-breakpoint-up(lg) {
                right: -100px;
                top: 100px;

                transform: scale(.75);
            }

            @include media-breakpoint-up(xl) {
                top: 150px;

                transform: scale(1);
            }

            @media (min-width: 1600px) {
                right: -100px;
            }
        }

        &--budget {
            right: 0;
            top: -20px;

            width: 150px;
            transform: rotate(-50deg);

            @include media-breakpoint-up(sm) {
                top: 20px;
            }

            @include media-breakpoint-up(md) {
                right: 50px;
                top: -20px;

                width: 350px;
            }

            @include media-breakpoint-up(lg) {
                right: 80px;
                top: -70px;

                width: 350px;
            }

            @include media-breakpoint-up(xl) {
                right: 200px;
                top: -130px;

                width: 450px;
            }

            @media (min-width: 1500px) {
                right: 150px;
            }
        }
    }

    &__aside-img {
        position: relative;
        z-index: 101;
    }

    &__aside-branch {
        position: absolute;
        right: 0;
        top: 0;
        // z-index: -1;

        &--lifestyle {

            bottom: -140px;
            top: auto;
            z-index: 100;

            width: 280px;

            transform: scaleX(-1) rotate(-10deg);

            @include media-breakpoint-up(xl) {
                right: 0px;
                bottom: -250px;

                width: 350px;
            }
        }

        &--lifestyle {

            bottom: -140px;
            top: auto;
            z-index: 100;

            width: 280px;

            transform: scaleX(-1) rotate(-10deg);

            @include media-breakpoint-up(xl) {
                right: 0px;
                bottom: -250px;

                width: 350px;
            }
        }

        &--wishlist {

            bottom: -140px;
            top: auto;
            z-index: 100;

            width: 280px;

            transform: scaleX(-1) rotate(-10deg);

            @include media-breakpoint-up(xl) {
                right: 0px;
                bottom: -150px;

                width: 280px;
            }
        }

        &--plantings {

            bottom: -140px;
            top: auto;
            z-index: 100;

            width: 280px;

            transform: scaleY(-1) rotate(80deg);

            @include media-breakpoint-up(xl) {
                right: 0px;
                bottom: -220px;

                width: 300px;
            }
        }

        &--plantings-flower {
            right: -180px;
            top: -120px;
            z-index: 400;

            transform: rotate(-15deg);
        }
    }

    &__field-list {

        @extend %list-clean;

        width: 100%;

        margin-bottom: $spacer;
    }

    &__form-group {
        @extend %list-clean;

        position: relative;

        padding-top: 20px;

        // color: #fff;
        font-family: $font-family-serif;
        font-weight: 200;

        &--select {
            &:after {
                content: "";
                display: block;

                border-top: 8px solid transparent;
                border-right: 8px solid transparent;
                border-left: 8px solid;
                border-bottom: 8px solid;

                position: absolute;
                right: 18px;
                bottom: 24px;

                transform: rotate(-45deg);
            }
        }

        &--focus {
            color: $gray;
        }
    }

    &__label {

        left: $input-padding-x;
        position: absolute;
        top: 26px;

        color: inherit;
        opacity: 0;
        font-size: 9px;
        letter-spacing: .5px;
        transition: top 0.3s ease, opacity 0.3s ease;
        text-transform: uppercase;

        margin-bottom: 0;
    }

    &__label--visible {
        opacity: 1;
    }

    &__label-standard {
        color: $gray;
    }

    &__large-label {
        font-weight: 700;
        font-family: $font-family-serif;
        font-size: 18px;

        &--design-style {
            color: $color-orange;
        }
    }

    &__field {
        appearance: none;
        padding: 20px 14px 10px;

        border: none;
        background-color: $gray;
        color: inherit;

        font-size: 20px;
        font-weight: 200;
        transition: all 0.15s ease;

        &:focus {
            background-color: #fff;
            box-shadow: 4px 4px 14px 0 rgba(121,123,127,0.32);
        }

        &::placeholder {
            color: #ccc;
        }

        &--select {

            position: relative;

            height: 55px !important;
            padding-bottom: 5px;
            padding-top: 5px;

            // font-size: 16px;
        }

        &--textarea {
            height: 120px;
            // padding: $spacer;

            border: none;
            color: #fff;
        }
    }

    &__field-simple {
        appearance: none;
        padding: 20px 14px 10px;

        border: none;
        background-color: $gray;
        color: #fff;

        font-size: 18px;
        font-family: $font-family-serif;
        font-weight: 200;
        transition: all 0.15s ease;

        &::placeholder {
            color: #ccc;
        }

        &--textarea {
            height: 120px;
            // padding: $spacer;

            border: none;
            color: #fff;
        }
    }


    &__input-other-details {
        // display: none;
    }


    &__conclusion {
        display: none;

        background-color: $color-blue;
        background-image: url(../img/icons/branches/4.png);
        background-size: 200px auto;
        background-blend-mode: multiply;
        background-position: right -20px;
        background-repeat: no-repeat;

        @include media-breakpoint-up(lg) {
            background-size: 300px auto;
            background-position: calc(50% + 500px) 0;
        }

        @media (min-width: 1350px) {
            margin-top: -120px;
            padding-top: 120px;

            background-position: calc(50% + 500px) 70px;
        }

        * {
            color: #fff;
        }
    }

    &__conclusion-message {

    }

    &__conclusion-btn {
        padding: 15px 30px;

        background-color: $color-green-dark;

        &:hover {
            background-color: lighten($color-green-dark, 10);
            color: #fff;
        }
    }

    &__label-question {
        position: relative;
        right: -6px;
        top: -6px;

        display: inline-flex;
        align-items: center;
        padding: 0;
        height: 1.65em;
        width: 1.8em;

        background-color: $color-gray;
        border-radius: 50%;
        color: #fff;
        font-size: 10px;
        line-height: 1.1;
        text-align: center;

        &:hover {
            background-color: $color-green;
            color: #fff;
        }

        span {
            display: block;
            width: 100%;
        }
    }
}
