.gallery {
    margin-bottom: calc(#{$spacer} + 4vh);

    &__list {

        @extend %list-clean;
        @include make-row();
    }

    &__item {

        @include make-col-ready();
        @include make-col(6);
        padding-left: 5px;
        padding-right: 5px;

        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            @include make-col(6);
        }

        @include media-breakpoint-up(md) {
            @include make-col(3);
        }

        @include media-breakpoint-up(xl) {
            @include make-col(3);
            margin-bottom: 20px;
        }

        &--extra {
            display: none;
        }
    }

    &__link {
        display: block;
        cursor: zoom-in;

        &:focus {
            outline: 0;
        }

        &--video {
            position: relative;

            &:before {
                content: "▶";
                color: #fff;
                background: $color-gray-dark;
                border-radius: 50%;
                font-size: 1.5625rem;
                text-align: center;
                line-height: 60px;

                width: 60px;
                height: 60px;

                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                transition: transform 0.2s;
            }

            &:hover:before {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }

    &__image-wrap {

        @include aspect-ratio(2,1);

        background-color: $gray-lighter;
    }

    &__image {

        height: auto;
        max-width: 100%;
    }

    &__show-more {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #D8D8D8;
        margin: auto;

        position: relative;

        transition: background-color 0.2s;

        &:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-left: 2px solid white;
            border-bottom: 2px solid white;

            position: absolute;
            top: 15px;
            left: 50%;

            transform: translateX(-50%) rotate(-45deg);
            transition: all 0.2s;
        }

        &:hover {
            background-color: darken(#D8D8D8, 10%);

            &:before {
                transform: translateX(-50%) rotate(-45deg) scale(1.2);
            }
        }
        span {
            display: none;
        }

        &--open {
            &:before {
                top: 18px;
                transform: translateX(-50%) rotate(135deg);
            }
            &:hover:before {
                transform: translateX(-50%) rotate(135deg) scale(1.2);
            }
        }
    }
}
