/*
|--------------------------------------------------------------------------
| Base Typography
|
| These styles are used to set a base style for all elements. Only use
| these properties here:
|
| color:
| font-family:
| font-size:
| line-height:
| margin:
|
|--------------------------------------------------------------------------
*/

h1, h2, h3, h4, h5 {
  font-family: $font-headings;
  &.blue {
    color: $color-teal;
    }
    &.blue-light {
        color: $color-teal-light;
    }
    &.pink {
        color: $color-red;
    }
}

h1, .h1 {
    font-weight: 800;
    color: $color-gray-dark;

    margin-bottom: 1rem;

    @include media-breakpoint-down(lg) {
        font-size: calc(#{$font-size-h1}/1.75 + 2vw); // approximately 42px at 320
    }
}

h2, .h2 {
    font-weight: bold;
    color: $color-gray-dark;

    margin-bottom: 1rem;

    @include media-breakpoint-down(lg) {
        font-size: calc(#{$font-size-h2}/1.3 + 1.5vw); // approximately 44px at 320
    }
}

h3, .h3{
    font-weight: normal;

    @include media-breakpoint-down(lg) {
        font-size: calc(#{$font-size-h3}/2 + 1vw);
    }
}

h4, .h4 {
    font-weight: normal;

    margin-bottom: 0.5em;

    @include media-breakpoint-down(lg) {
        font-size: calc(#{$font-size-h4}/1.325 + 1vw); // approximately 26px at 320
    }
}

h5, .h5 {
    font-weight: bold;

    @include media-breakpoint-down(lg) {
        font-size: calc(#{$font-size-h5}/1.4 + 1vw); // approximately 20px at 320
    }
}

h6, .h6 {
    font-weight: bold;
}

p {

    line-height: 1.875em;
    margin-bottom: 2em;

}

ul {
    margin-bottom: 1.5em;
    margin-left: 0;
    padding-left: 1.1em;
}

ol {

}

li {
    margin-bottom: 0.5em;
    padding-left: 0.75em;
}

b, strong {

}

i, em {
    font-size: .9375%;
    font-weight: 300;
}

quote, blockquote {

}

.font-bold {
    font-weight: bold !important;
}

.label {
    font-family: $font-headings;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    &.blue {
        color: $color-teal-light;
    }
    &.blue-dark {
        color: $color-teal;
    }
    &.gray {
        color: $color-gray;
    }
    &.red {
        color: $color-red;
    }
    &.orange {
        color: $color-orange;
    }
}

.heading {
    font-weight: bolder;
    line-height: 1.1;
    font-size: 3.5em;
    @include media-breakpoint-down(md){
        font-size: 3em;
    }
    &.lg {
        font-size: 3em;
    }
    &.blue {
        color: $color-teal;
    }
    &.blue-light {
        color: $color-teal-light;
    }
    &.pink {
        color: $color-red;
    }
    &.gray {
        color: $color-gray;
    }
}

.body-text {
    font-family: $font-headings;
    color: $color-gray;
    font-size: 1.2em;
}