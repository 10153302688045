.products-listing {
    @extend .container;
    @include antialias;

    color: white;

    @include media-breakpoint-down(xs) {
        @include make-row;
    }

    &__flower {
        position: absolute;
        right: 20px;
        top: 0;

        max-width: 25%;

        transform: translateY(-40%);
        z-index: 2;

        @include media-breakpoint-down(xs) {
            right: 0;
            transform: translateY(-50%);
        }
    }

    &__list {
        @extend %list-clean;
        @extend %overlap-banner;
    }

    &__item {
        @extend %list-clean;

        position: relative;

        margin-bottom: $spacer*3;

        @include make-color-modifiers(background-color);

        @include media-breakpoint-up(lg) {
            margin-bottom: $spacer*2;
        }

        &--with-features {
            @include media-breakpoint-up(lg) {
                padding-bottom: 505/1164 * 100%;
            }
        }
    }

    &__background {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        overflow: hidden;
        background-size: cover;
        background-position: center;

        z-index: 0;

        &:before {
            left: 0;
            position: absolute;
            top: 0;

            content: '';
            display: block;
            height: 100%;
            width: 100%;

            // background-color: rgba(#000, .1);
            background-image: linear-gradient(to bottom, rgba(#000, 0), rgba(#000, .5));
        }
    }

    &__additional-image {
        height: 50%;
        width: auto;
        position: absolute;
        top: 0;
        right: 0;

        border-left: 2px solid #fff;
        box-sizing: content-box;

        &:last-child {
            top: auto;
            bottom: 0;

            border-top: 2px solid #fff;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__content-wrap {
        padding: 8.5%;
        padding-top: $spacer*2;

        position: relative;

        z-index: 1;
    }
    &__item--with-features &__content-wrap {
        padding-bottom: $spacer;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 10%;
            left: 0;
        }
        @include media-breakpoint-up(xl) {
            top: 25%;
        }
    }
    &__item--with-features:nth-child(2n) &__content-wrap {
        @include media-breakpoint-up(lg) {
            left: 25% - 8.5%;
        }
    }

    &__title {
        color: inherit;
        text-shadow: 0 0 15px rgba(#000, .25), 0 0 40px rgba(#000, .125);

        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
    }

    &__starting-at {

    }

    &__content {
        
        p {
            line-height: 1.5;
            text-shadow: 0 0 5px rgba(#000, .5), 0 0 20px rgba(#000, .25);
        }

        @include media-breakpoint-up(md) {
            width: 60%;
        }
    }

    &__features {
        @extend %list-clean;

        width: 100%;
        padding-left: 8.5%;
        padding-right: $spacer*2;

        background-color: inherit;

        position: relative;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: 0;
            left: 0;

            width: 75%;
        }
    }

    &__features--with-2-children {

        @include media-breakpoint-up(lg) {
            max-width: 50%;
        }
    }

    &__item:nth-child(2n) &__features {
        @include media-breakpoint-up(lg) {
            left: auto;
            right: 0;

            padding-right: 8.5%;
            padding-left: $spacer*2;
        }
    }
    &__item--blue-dark &__features {
        color: #9CB0CA;
    }
    &__item--yellow &__features {
        color: $color-gray-dark;
    }

    &__feature {
        @extend %list-clean;

        position: relative;

        padding-top: $spacer;
        padding-bottom: $spacer;

        @include media-breakpoint-up(md) {
            width: 1/3 * 100%;
        }
    }

    &__features--with-2-children &__feature {

        @include media-breakpoint-up(md) {
            width: 1/2 * 100%;
        }
    }

    &__feature-image-wrap {
        display: block;

        border-radius: 50%;
        overflow: hidden;

        width: 50px;
        height: 50px;

        @include media-breakpoint-up(md) {
            width: 70px;
            height: 70px;
        }

        @include media-breakpoint-up(xl) {
            width: 90px;
            height: 90px;
        }
    }

    &__feature-image {
        width: 100%;
    }

    &__feature-title {
        display: inline-block;
        vertical-align: middle;

        font-size: 1rem;
        font-weight: bold;
        line-height: 1.3;
        text-transform: uppercase;

        margin-left: 10px;
        padding-left: 50px;
        padding-right: 10px;

        position: absolute;
        left: 0;
        top: 50%;

        transform: translateY(-50%);

        @include media-breakpoint-up(md) {
            padding-left: 70px;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 90px;
        }
    }

    &__link {
        font-family: $font-headings;
        font-size: 18px;
        color: $body-color;

        padding: 5px 8.5%;

        position: absolute;
        top: 100%;
        left: 0;

        z-index: 1;

        @include media-breakpoint-up(lg) {
            padding: $spacer $spacer*1.5;

            top: auto;
            bottom: 0;

            color: #fff;
        }
    }
    &__item--with-features:nth-child(2n+1) &__link {
        @include media-breakpoint-up(lg) {
            left: auto;
            right: 0;
        }
    }
}
