.blog-header {

	position: relative;

	display: block;
	margin: 0 auto;
	max-width: 1400px;
	width: 100%;

	background: #ccc;
	color: #fff;
	text-decoration: none;
	text-align: center;

	@include media-breakpoint-up(md) {
		margin-bottom: 180px;
	}

	&__image-wrap {

		overflow: hidden;

		@include media-breakpoint-up(sm) {
			max-height: calc(90vh - 150px);
			min-height: 260px;
		}
	}

	&__image {

		display: block;
		height: auto;
		margin-bottom: 0;
		max-width: 100%;
	}

	&__copy {
		position: relative;
        border-radius: 10px 10px 0 0;
		background-color: rgba(#fff, .9);
		background-image: linear-gradient(to top, rgba(#fff, .9) 100px, rgba(#fff, 0));
		border-top: 6px solid lighten($gray-light, 50);

		@include media-breakpoint-up(md) {
			bottom:  -180px;
			left: 50%;
			position: absolute;
			z-index: 2;

			transform: translateX(-50%);

			width: 690px;
		}

		@include media-breakpoint-up(lg) {
			width: 930px;
		}

		@include media-breakpoint-up(xl) {
			width: 1160px;
		}
	}

	&__heading {

		margin-bottom: 20px;
		margin-top: 14px;
		width: 100%;

		line-height: 1.2;
		transition: all .2s ease-out;

		a:hover {
			color: $color-green;
		}
	}

	&__button {

		margin: 0 auto;

		background-color: $color-gray;
		color: #f8f8f8;

		&:hover, &:focus {
			background-color: lighten($color-gray, 20);
			color: #fff;
		}
	}

    &__category {
        position: relative;
        z-index: 2;

        display: block;
        padding-bottom: 8px;
        padding-top: 10px;

		background-color: #dfe9e8;
		color: $color-black;
        font-size: 10px;
        text-align: center;
        text-transform: uppercase;
    }

	&__author-name {
		padding-right: 20px;

		font-size: 12px;
		text-transform: uppercase;
		color: $gray-dark;
	}

    &__author-photo {

		left: 50%;
		position: absolute;
		top: -50px;
		z-index: 2;

        width: 100px;

		font-size: 12px;
		border-radius: 50%;
		transform: translateX(-50%);
    }

    &__metadata {

		position: relative;

		margin-top: -16px;
        padding-bottom: 20px;
        padding-top: 58px;

        background-image: url(../img/icons/branches/small-branch@2x.png);
        background-repeat: no-repeat;
        background-position: center calc(50% + 14px);
        background-size: 128px auto;
        color: $color-black;
        font-family: $font-headings;
        font-size: 10px;
		text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			margin-top: -36px;
		}
    }
}