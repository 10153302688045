.map {

    margin-bottom: calc(#{$spacer} + 2vh);

    &__iframe {

        max-width: 100%;
    }

    &__address {

    }

    &__address-title {

    }

    &__address-button {

    }
}