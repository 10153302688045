.thank-you-page {
    position: relative;

    overflow: hidden;

    &__wrap {
        font-size: $font-size-lg;
        text-align: center;

        padding-top: calc(#{$spacer*2} + 10vh);
        padding-bottom: calc(#{$spacer} + 5vh);

        max-width: 560px;
        margin: auto;
    }

    &__icon-wrap {
        position: absolute;
        top: 0;
        right: 0;

        max-width: 50%;

        transform: translateY(-35%);

        z-index: -1;
    }

    &__link {
        font-family: $font-headings;
        font-size: 1.625rem;

        &:before {
            @include icon;
            content: "\e904";

            color: #39579A;

            vertical-align: -15%;
            margin-right: $spacer;
        }

        &:after {
            content: "›";
            margin-left: $spacer/2;
        }
    }
}
