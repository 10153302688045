.social-profile-links {
    width: 100%;

    &__item {
        position: relative;
        display: inline-block;
    }

    &__link {
        display: block;
        padding: 0;
        margin: 0 10px 0 0;
        width: 20px;
        height: 20px;
        line-height: 30px;
        color: inherit;
        font-family: "Icomoon";
        font-style: normal;
        font-size: 20px;
        font-weight: normal;
        font-variant: normal;
        speak: none;
        text-transform: none;
        text-decoration: none;
        text-align: center;
        @include antialias;
        transition: transform .1s ease-out;
        transform: translateZ(0);
        &:hover {
            color: inherit;
            transform: scale(1.1);
            @include antialias;
            text-decoration: none;
        }
    }

    &__link--facebook:before { content: $icon-facebook; }
    &__link--googleplus:before { content: $icon-googleplus; }
    &__link--houzz:before { content: $icon-houzz; }
    &__link--linkedin:before { content: $icon-linkedin; }
    &__link--pinterest:before { content: $icon-pinterest; }
    &__link--twitter:before { content: $icon-twitter; }
    &__link--youtube:before { content: $icon-youtube; }
    &__link--instagram:before { content: $icon-instagram; }

    &__text {
        display: inline-block;
        text-indent: -5000em;
    }

    &__item--pinterest,
    &__item--houzz {
        top: 0;
    }
}
