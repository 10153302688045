.page-hero {
  @include antialias;

  position: relative;

  // margin-bottom: calc(#{$spacer} + 5vh);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;

  font-size: 1.25rem;
  line-height: 1.75em;

  text-align: center;

  @include media-breakpoint-down(xs) {
    margin-bottom: $spacer;
  }

  &--colored-background {
    color: $color-gray-dark;
  }

  &__wrapper {
    // padding: calc(#{$spacer} + 5vh) 0;
    padding-top: 190px;
  }
  &--overlap-none &__wrapper {
    padding-bottom: 80px;
  }
  &--overlap-sm &__wrapper {
    padding-bottom: 10%;
  }
  &--overlap-md &__wrapper {
    padding-bottom: 15%;
  }
  &--overlap-lg &__wrapper {
    padding-bottom: 20%;
  }

  &__icon {
    position: absolute;
    top: 50px;
    left: 50%;

    max-height: 90px;

    transform: translateX(-50%);
  }

  &__category {
    font-size: inherit;
    text-transform: uppercase;
    text-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);

    color: white;

    margin-bottom: 8px;
  }
  &--colored-background &__category {
    text-shadow: none;
  }

  &--careers-post &__category {
    text-shadow: none;
  }

  &__heading {
    color: white;

    max-width: 640px;

    margin: auto;
    margin-bottom: $spacer;
  }
  &--homepage &__heading {
    position: relative;
    z-index: 2;

    margin-bottom: $spacer * 2;

    font-style: italic;
    text-shadow: 0 2px 34px rgba(0, 0, 0, 0.5);

    @include media-breakpoint-down(xs) {
      font-size: 32px;
      text-shadow: 0 2px 15px rgba(0, 0, 0, 0.5); /* fixes bug on Android chrome browsers that bumps the shadow down to a new line if the shadow is too big for the container */
    }
  }

  &--homepage &__wrapper {
    @include media-breakpoint-up(xl) {
      padding-bottom: 355px;
      padding-top: 245px;
    }
  }

  &--projects &__heading {
    margin-top: -8%;
  }

  &__intro {
    max-width: 640px;
    p {
      line-height: 1.3;
    }
    margin: auto;
  }

  &__flower--homepage {
    width: 200px;
    left: -50px;
    top: -50px;

    position: absolute;

    @include media-breakpoint-up(md) {
      left: -200px;
      top: -100px;

      width: auto;
    }

    @include media-breakpoint-up(xl) {
    }
  }

  &--landscapes,
  &--blueridge {
    background-color: $color-blue;
    color: white;
  }

  &--landscapes &__heading {
    max-width: 500px;
  }

  &--design-packages {
    color: #fff;
  }

  &--design-packages &__intro {
    margin-bottom: 160px;
  }

  // CAREERS POST PAGE
  &--careers-post .page-hero__wrapper {
    padding-top: 120px;
    padding-bottom: 30px;
    text-align: left;

    @include media-breakpoint-down(xs) {
      padding-top: 80px;
    }
  }

  &--careers-post .page-hero__category {
    text-transform: none;
    font-size: 30px;
  }

  &--careers-post .page-hero__heading {
    margin: 10px 0 0;
    max-width: 700px;
  }

  &--pay {
    padding-bottom: 70px;
    padding-top: 70px;

    @include media-breakpoint-up(lg) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  &--pay &__heading {
    margin-bottom: 28px;
  }

  &--pay &__wrapper {
    padding-top: 0;
  }

  &--irrigation {
    background-color: #006a7c;

    * {
      color: #fff;
    }
  }

  &--irrigation &__category {
    text-shadow: none;
  }
  &--irrigation &__icon {
    top: 70px;
  }

  &--irrigation &__wrapper {
    padding-bottom: 6%;
    padding-top: calc(4% + 140px);
  }

  &--irrigation-landing {
    background-color: #006a7c;

    * {
      color: #fff;
    }
  }

  &--irrigation-landing &__category {
    text-shadow: none;
  }

  &--irrigation-landing &__icon {
    top: 70px;
  }

  &--irrigation-landing &__wrapper {
    padding-bottom: 20%;
    padding-top: calc(4% + 140px);
  }

  &--automowers-landing {
    position: relative;

    background-color: #000;
    background-position: -50px 60px;

    @include media-breakpoint-up(sm) {
      background-position: center 60px;
    }

    @include media-breakpoint-up(md) {
      background-position: center center;
    }

    @media (min-width: 1750px) {
      background-position: center calc(50% - 75px);
    }

    @media (min-width: 2200px) {
      background-position: center calc(50% - 150px);
    }

    &:before {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 60px;

      content: '';
      display: block;

      background-image: linear-gradient(rgba(#000, 1), rgba(#000, 0));
      mix-blend-mode: multiply;

      @include media-breakpoint-up(md) {
        top: 0;
      }
    }
  }

  &--automowers {
    background-color: #006a7c;
  }

  &--automowers &__wrapper,
  &--irrigation &__wrapper {
    padding-bottom: 5%;
  }

  &--automowers-landing &__wrapper {
    padding-bottom: 40%;
    padding-top: 60px;

    @include media-breakpoint-up(md) {
      padding-top: 120px;
    }
  }

  &--automowers-landing &__icon {
    max-width: 320px;

    @include media-breakpoint-up(lg) {
      left: 0;
      transform: translateX(0);
    }
  }

  &--automowers-landing &__heading {
    @include media-breakpoint-up(lg) {
      margin-left: 0;
      line-height: 1;
      text-align: left;
    }
  }

  &--automowers-landing &__intro {
    p {
      max-width: 500px;

      color: #fff;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 700;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;

      text-align: left;
    }
  }
}
