.regular-stack-item {
    padding: 0 0 200px 0;
    @include media-breakpoint-down(md) {
        padding: 60px 0;
    }
    .container {
        max-width: 1400px;
    }
    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    &__box {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: center;
        &.image {
            width: 50%;
            @include media-breakpoint-down(md) {
                width: 70%;
                margin: 0 auto;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
        &.content {
            width: 50%;
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
        &-image {
            position: relative;
            width: 100%;
            display: block;
            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
            }
        }
        &-content {
            padding: 70px 0 70px 120px;
            @include media-breakpoint-down(lg) {
                padding: 70px 0 70px 70px;
            }
            @include media-breakpoint-down(md) {
                padding: 40px 0;
            }
        }
    }
}