.referral-offer-list {
    @extend %list-checks;

    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        color: $color-blue;
        font-weight: bold;
    }
}
