.community-partners {
    max-width: 700px;
    margin: auto;

    &__title {
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: bold;

        margin-bottom: $spacer*2;
    }

    &__list {
        @extend %list-clean;

        @include make-row;

        text-align: left;
    }

    &__item {
        @extend %list-clean;

        @include make-col-ready;
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
    }
}
