.maintenance-callout {

    align-self: stretch;

    &__inner {
        text-align: center;
        border-radius: 10px;
    }

    &--1 {
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    &--1 &__inner {
        background-color: $color-teal;
        background-image: url(../img/hydrate-bg@2x.png);
        background-size: cover;
        background-position: center;
    }

    &--1 &__image {
        margin-bottom: 20px;
        max-width: 233px;
    }

    &--2 &__inner {
        background-color: $color-teal-light;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.3;
            width: 100%;
            height: 100%;
            background-image: url(../img/billy-dashes.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        .btn {
            position: relative!important;
            z-index: 2!important;
        }
    }

    &--2 &__image {
        max-width: 240px;
    }

    h3 {
        font-size: 28px;
    }

    p {
        margin-bottom: 10px;
        line-height: 1.5;

        @include media-breakpoint-up(md) {
            max-width: 320px;
            padding-bottom: 0;
            width: 75%;
        }
    }

    .link {
        font-weight: bold;
    }

    color: white;

    &__inner {
        height: 100%;
        padding: 40px;

        background-size: cover;
        background-position: bottom right;

    }
}