.featured-projects {

    &__branch {
        position: absolute;

        top: 0;
        left: 30px;

        z-index: 0;

        @include media-breakpoint-down(xs) {
            width: 50%;
            transform: translate(-48%, 10%) rotate(10deg);
        }
    }

    &__flower {
        position: absolute;

        top: 0;
        right: 0;

        max-width: 50%;

        transform: translateY(-50%);
        z-index: 1;
    }

    &__heading {
        padding-top: calc(#{$spacer} + 5vh);
        margin-bottom: calc(#{$spacer} + 3vh);
        position: relative;

        z-index: 2;

        @include media-breakpoint-down(xs) {
            padding-top: $spacer*5;
        }
    }
}
