.intake-form-gallery {

    &__card {
        position: relative;

        height: 300px;

        background-size: cover;
        transition: box-shadow .2s ease;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            content: '';

            height: 100%;
            width: 100%;
            opacity: .9;

            background-image: linear-gradient(#fff, transparent 40%);
        }

        &:hover {
            box-shadow: 4px 4px 14px -1px rgba(72,73,75,0.72);
        }
    }

    &__card-title {
        position: relative;
        z-index: 2;

        padding-top: 10px;

        color: $color-black;
        font-size: 24px;
        font-weight: 700;
    }

    &__card-btn {
        position: relative;
        z-index: 2;

        margin: 0 auto;
        padding: 10px 20px;

        background-color: rgba(#fff, .75);
        color: $color-black;
        text-align: center;
        transition: background .2s ease;

        &:hover {
            background-color: rgba(#fff, 1);
            color: $color-black;
        }
    }

    &__indicator {
        left: 0;
        position: absolute;
        top: 0;
        z-index: 3;

        height: 80px;
        width: 70px;

        background-image: linear-gradient(-50deg, transparent 50%, #797B7F 50%);
        opacity: .7;
        transition: opacity .2s ease;

        &--on {
            background-image: linear-gradient(-50deg, transparent 50%, $color-red 50%);
        }

        &:hover {
            opacity: .9;
        }
    }

    &__indicator-off {
        left: 10px;
        position: absolute;
        top: 10px;

        height: 22px;
        width: 22px;

        background-color: #fff;
        border-radius: 50%;
    }

    &__indicator-on {
        left: 14px;
        position: absolute;
        top: 8px;

        display: none;
        height: 28px;
        width: 18px;

        border-right: 5px solid #fff;
        border-bottom: 5px solid #fff;

        transform: rotate(40deg);
    }
}