.careers__banner {

    height:  440px;
    background-image: linear-gradient(150deg, transparent, $color-teal 120%), url(../img/careers/careers-banner-bg@2x.jpg);
    background-size: cover;
    position:  relative;

    @include media-breakpoint-up(sm) {
        height:  650px;
    }
    @include media-breakpoint-up(xl) {
        height:  800px;
    }

    &__heading.initial {
        opacity: 0;
    }

    &__heading {
        position: relative;
        bottom: 127px;
        height: 243px;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            height: 400px;
        }
        @include media-breakpoint-up(xl) {
            height: 500px;
        }
    }

    &__img {
        height: 390px;
        position:  absolute;
        bottom:  0;
        left: 50%;
        transform: translateX(-155px);
        transition: opacity 0.2s ease-out;
        
        @include media-breakpoint-up(sm) {
            height: 550px;
            transform: translateX(-225px);
        }
        @include media-breakpoint-up(xl) {
            height: 670px;
            transform: translateX(-270px);
        }
    }

    &__img.hidden {
        opacity: 0;
    }

    &__img img {
        max-height:  100%;
        width:  auto;
        max-width:  none;
    }
    &__img-text {
        position:  absolute;
        bottom:  0;
        left: 50%;
        transform:  translateX(-50%);
    }
    &__h1 {
        color: #F2F2F2;
        font-size: 120px;
        line-height: 0.8;
        height: 90px;        
        width: 300px;
        
        position:  absolute;
        left: 50%;

        text-transform:  uppercase;
        text-align:  left;
        transform: translateX(-50%);
        transition: opacity 0.3s ease-in;
        
        @include media-breakpoint-up(sm) {
            font-size: 200px;
            height: 160px;
            width:  490px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 220px;
            width: 560px;
        }
    }

    &__h1.hidden {
        opacity: 0;
    }

    &__h1--small {
        color: #F2F2F2;
        
        font-size: 60px;
        line-height: 0.8;

        text-transform:  uppercase;
        text-align:  center;

        height: 96px;
        
        @include media-breakpoint-up(sm) {
            font-size: 110px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 130px;
        }
    }

   
    &__h1 .h1--first-span {
        margin-left: 70px;

        @include media-breakpoint-up(sm) {
            margin-left: 110px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: 144px;
        }
    }
    &__h1--small.h1--second {
        width: 290px;
        
        position:  absolute;
        top: 105px;
        
        left:  50%;
        transform:  translateX(-50%);
        text-align:  left;

        @include media-breakpoint-down(xs) {
            font-size: 68px;
        }
        @include media-breakpoint-up(sm) {
            top: 175px;
            width: 470px;
        }
        @include media-breakpoint-up(xl) {
            width: 555px;
        }
    }
    &__h1--small.typewrite--repeat {
        // text-align:  left;
        position:  absolute;
        top: 170px;
        
        left: 50%;
        transform:  translateX(-50%);

        @include media-breakpoint-up(sm) {
            top: 285px;
        }
    }
    &__h1--small.typewrite--repeat.hidden {
        opacity: 0;
    }

    &__h1--small.typewrite--repeat .repeat--line1,
    &__h1--small.typewrite--repeat .repeat--line2,
    &__h1--small.typewrite--repeat .repeat--line3 {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &__btn-link {
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);

        cursor: pointer;
        outline: none;

        @include media-breakpoint-up(sm) {
            bottom: 60px;
        }
        @include media-breakpoint-up(xl) {
            bottom: 120px;
        }
    }

    &__btn-link.hidden {
        opacity: 0;
    }
    &__btn {
        background: #d3636a;
        border: none;
        border-radius:  38px;
        color:  white;
        font-family: $font-headings;
        font-size:  16px;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 10px 28px 6px;
        text-transform:none;

        cursor: pointer;

        &:hover,
        &:focus {
            background: #b6333d;
            outline: 0;
        }

        @include media-breakpoint-up(sm) {
            font-size: 22px;
            padding: 10px 50px 6px;
        }
    }
}

.letter-span,
.letter-repeat {
    opacity: 1;

    transition: opacity .4s ease-out;

    &.hidden {
        opacity: 0;
    }
}
