.careers-listing {

    &__title {

        color:  #50928D;
        text-align:  center;
    }

    #BambooHR-Footer {
        display: none !important;
    }

    .BambooHR-ATS-board {


        color: #575855;
        font-size:  22px;
        font-weight:  bold;
        margin-bottom: 10px;

        h2 {
            display: none !important;
        }

        @include media-breakpoint-down(md) {
            font-size:  20px;
        }
    }

    .BambooHR-ATS-Department-Header {
        font-size: 20px;
    }

    .BambooHR-ATS-Department-Item {
        margin: 0;
        padding: 0;
    }

    .BambooHR-ATS-Jobs-List {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .BambooHR-ATS-Jobs-Item {
        @extend %list-clean;
        border-top: 1px solid $color-gray;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .BambooHR-ATS-Jobs-Item a {

        display: inline-block;
        padding: $spacer 0;

        text-decoration: none;

        color: #006A7C;
        font-size: 18px;
    }

    .BambooHR-ATS-Location {

    }

    &__aside {
        margin-top:  0;

        @include media-breakpoint-up(lg) {
            margin-left: 50px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: 100px;
        }
    }

    &__aside-wrap {
        background-color: #006A7C;
        color:  white;

        @include media-breakpoint-down(xs) {
            padding: 2rem 1rem;
        }
        @include media-breakpoint-up(md) {
            padding-right: 2em;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 5em;
        }
    }

    &__aside-img {
        width:  165px;
        position:  absolute;
        top: -10px;
        right: -80px;

        @include media-breakpoint-down(xs) {
            width:  140px;
        }
        @include media-breakpoint-down(sm) {
            right: -50px;
        }
        @include media-breakpoint-up(lg) {
            width:  200px;
        }
    }

    &__aside-wrap h3 {
        margin: 25px 10px;

        @include media-breakpoint-down(xs) {
            margin-right: 70px;
        }
        @include media-breakpoint-up(md) {
            margin: 25px 50px 25px 10px;
        }
        @include media-breakpoint-up(lg) {
            margin: 25px 40px 25px 10px;
        }
    }

    &__aside-wrap ul {
        margin-left: 16px;

        @include media-breakpoint-down(xs) {
            margin-left: 0;
        }
    }

    &__aside-wrap li {
        font-weight: 200;
        padding-left: 5px;
    }
}


