.backyard {
    h3.heading {
        color: $color-orange;
    }
    &__gallery {
        text-align: center;

        color: $color-black;

        padding-top: calc(#{$spacer*2} + 2vh);
        margin-bottom: calc(#{$spacer} + 2vh);
    }

    &__gallery-title {
        margin-bottom: calc(#{$spacer} + 2vh);
    }
}
