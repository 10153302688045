.testimonials {

    @include media-breakpoint-up(sm) {
        padding-bottom: 40px;
    }

    &__header {
        font-size: 40px;
        text-align: center;

        @include media-breakpoint-down(xs) {
            margin-bottom: 40px !important;
            font-size: 35px;
        }
    }

    &__list {
        @extend %list-clean;
        @include make-row;
        @include clearfix;
        display: block;
        position: relative;
    }

    &__item {
        @extend %list-clean;
        // @include make-col-ready;

        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        float: left;
    }

    &__dots {
        @include clearfix;

        position: relative;
        top: 80px;
        height: 40px;

        @include media-breakpoint-down(xs) {
            top: 40px;
        }
    }

    &__content {
        position:  relative;

        @include media-breakpoint-up(md) {
            width: 500px;
        }
        @include media-breakpoint-up(lg) {
            width: 600px;
        }
        @include media-breakpoint-up(xl) {
            width: 800px;
        }
    }

    &__name {
        color: #363636;
        font-size:  12px;
        font-weight: 400;
        margin-top: 30px;
        text-transform:  uppercase;
    }

    &__quote {
        line-height:  1.8;
        font-size:  16px;
        font-style: italic;
        font-family: Helvetica Neue;
    }

    &__title {
        font-size:  10px;
        text-transform:  uppercase;
        color: #363636;
    }
    &__img {
        position: absolute;
        margin-left: 500px;
        bottom: -461px;
        width: 260px;

        opacity: 0;
        z-index: 1;

        @include media-breakpoint-down(sm) {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            margin-left: 650px;
            bottom: -433px;
        }
        @include media-breakpoint-up(xl) {
            margin-left: 900px;
            bottom: -377px;
        }
    }

    &__item.slick-slide.slick-current.slick-active .testimonials__img {
        opacity: 1;
    }
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 22px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: .5;
    color: black;
}

.slick-slide {
    opacity: 0;
}
.slick-active {
    opacity: 1;
}

.dark-bg {
    background: #E1E1E2;
    height:  40px;
}
