.front-yard {
    &-page-banner {
        margin-bottom: 70px;
        &__box-content {
            padding: 70px 15px 0px 15px;
        }
        .body-text {
            max-width: 800px;
            margin: 0 auto;
            color: $color-gray-medium;
        }
        &__image {
            position: relative;
            width: 100%;
            padding-bottom: 40%;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
            &--gradient {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 50%;
                z-index: 10;
                background: rgba(244,244,244,0.1);
                background: linear-gradient(180deg,rgba(244,244,244,1) 0%,rgba(244,244,244,0) 100%);
            }
        }
    }
    h3.heading {
        color: $color-red;
    }
    &__gallery {
        text-align: center;

        color: $color-black;

        padding-top: calc(#{$spacer*2} + 2vh);
        margin-bottom: calc(#{$spacer} + 2vh);
    }

    &__gallery-title {
        margin-bottom: calc(#{$spacer} + 2vh);
    }
}
