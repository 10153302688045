/*
|--------------------------------------------------------------------------
| Links
|
| Basic link class that can be used across the site
|--------------------------------------------------------------------------
*/

@mixin link($color: $color-black, $hover-color: darken($color, 15%), $active-color: darken($hover-color, 15%)) {
    color: $color;
    transition: color 0.2s;

    &:hover, &:focus {
        color: $hover-color;
        outline: 0;
    }

    &:active {
        color: $active-color;
    }
}

a {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
}

.link {

    &:after {
        content: '›';
        display: inline-block;

        margin-left: 5px;
    }

    &--email {
        &:before {
            @include icon;
            content: $icon-mail-o;

            display: inline-block;
            vertical-align: top;
            margin-right: $spacer;
        }
        &:after {
            content: "";
        }
    }
}
