.get-started-page {

    &__form-col {
        @include media-breakpoint-up(md) {
            order: 2;
        }
    }

    .article__heading {
        color: $color-teal;
    }
    .article__intro {
        h4 {
            font-weight: bold;
            font-size: 1.5em;
            color: $color-gray-medium;
        }
    }

    &__overlay {
        width: 100%;

        background: $color-teal-light;
        color: #fff;

        position: absolute;
        bottom: 0;
        left: 0;

        @media (min-width: 2000px) {
            left: auto;
            right: 0;
        }
    }

    &__overlay-text {
        @include antialias;

        margin: $spacer 0;
        margin-right: 55%;

        text-align: right;

        h5, p {
            margin-bottom: 0;
            line-height: 1;
        }
    }

    &__overlay-image {
        width: 55%;

        position: absolute;
        bottom: 0;
        right: 0;
    }

    &__icon-wrap {
        position: relative;

        margin-left: -$grid-gutter-width-base;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;

        z-index: -1;
    }

    &__sidebar-wrap {
        text-align: right;
    }

    &__sidebar {
        display: inline-block;
        text-align: left;

        margin-top: $spacer*3;

        @include media-breakpoint-up(md) {
            max-width: 65%;
            padding-right: $spacer;
        }

        a {
            @include link($color-blue);
        }

        h3, h4, h5 {
            font-weight: bold;
            line-height: 1.5;
            color: $color-gray-medium;
        }
    }

    &__guarantee {
        color: #A7A9AC;

        margin-top: $spacer*3;
    }

    &__form-wrap {
        margin-bottom: $spacer * 3;
    }
}
