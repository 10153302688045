.maintenance {
    &__services {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 40px;
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        &-box {
            display: grid;
            padding: 50px;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
            @include media-breakpoint-down(md) {
                padding: 30px;
            }
            &.blue {
                background-color: $color-teal-lighter;
            }
            h3 {
                font-weight: bold;
            }
            ul {
                margin-bottom: 0;
                list-style: none;
                li::before {
                    content: "\2022";
                    color: $color-green;
                    font-weight: bold;
                    display: inline-block; 
                    font-size: 16px;
                    width: 1.75em;
                    margin-left: -1.7em;
                }
            }
        }
        &-button {
            padding: 60px 0;
            text-align: center;
        }
    }


}
