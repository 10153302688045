.landscapes-listing {
    @extend %overlap-banner;

    position: relative;

    padding-bottom: calc(#{$spacer} + 4vh);

    &__wrap {
        background-image: url('../img/landscapes/background.jpg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
    }

    &__list {
        @extend %list-clean;

        position: relative;
    }

    &__item {
        @extend %list-clean;

        margin-bottom: 20px;

        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        min-height: 517px;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }

        &--design {
            min-height: 0;
            display: block;
        }
    }

    &__content {
        text-align: center;

        padding: $spacer*2;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        order: 2;

        width: 100%;

        @include media-breakpoint-up(md) {
            width: 60%;
            order: 1;
        }
    }
    &__item--design-packages &__content {
        color: white;
        background: $color-green;
    }
    &__item--frontyards &__content {
        color: white;
        background: $color-teal-light;
    }
    &__item--backyards &__content {
        color: white;
        background: $color-orange;
    }
    &__item--design &__content {
        width: 100%;
        max-width: 675px;
        margin: auto;
    }

    &__item--design-packages &__icon {
        max-width: 150px;
    }

    &__category {
        width: 100%;

        font-size: 18px;

        margin-top: $spacer*2;
        margin-bottom: $spacer;
    }

    &__link {
        @extend .btn--cta;

        text-decoration: none;
    }

    &__title {
        color: $color-gray-dark;
        font-weight: bold;
        margin-bottom: 0.6em;
        font-size: calc(#{$font-size-h1}/1.75 + 2vw);
    }

    &__image-wrap {

        @include aspect-ratio(6,3);

        background-color: $gray-lighter;

        overflow: hidden;

        width: 100%;
        order: 1;

        @include media-breakpoint-up(md) {
            width: 50%;
            order: 2;
        }
    }

    &__image {
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        height: auto;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

}
