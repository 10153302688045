.uniform {
    &__gradient-top {
        background: rgb(221,204,227);
        background: linear-gradient(180deg, rgba(221,204,227,1) 0%, rgba(216,206,219,1) 22%, rgba(255,255,255,0) 100%);
    }
    &__purple {
        background: rgb(221,204,227);
    }
    &__gradient-bottom {
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(225,225,226,0.5032387955182073) 25%, rgba(234,234,235,1) 50%, rgba(246,246,246,0.5032387955182073) 75%, rgba(255,255,255,0) 100%);
    }
    &__banner {
        position: relative;
        text-align: center;
        &-leaf {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 350px;
                height: 370px;
                right: -60px;
                top: -140px;
                background-image: url(/site/themes/main/img/leaf-purple.svg);
                background-size: contain;
                background-position: top right;
                background-repeat: no-repeat;
            }
        }
        
        &-wrapper {
            position: relative;
            max-width: 800px;
            margin: 0 auto;
            padding: 80px 0;
            z-index: 10;
            &.automower {
                text-align: left;
                margin: 0;
                max-width: 45%;
            }
        }
        h1 {
            color: $color-purple;
        }
        h2 {
            color: $color-gray-dark;
        }
        .btn {
            margin-top: 30px;
        }
    }
    &__products {
        background: rgba(0,106,124,0.1);
        background: linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -webkit-linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -moz-linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        padding-bottom: 60px;
        &-list {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 40px;
            list-style: none;
            margin: 0;
            @include media-breakpoint-down(lg) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
        &-box {
            background-color: white;
            overflow: hidden;
            transition: all ease-in .2s;
            &--img {
                position: relative;
                width: 100%;
                padding-bottom: 90%;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            } 
            &--content {
                padding: 30px;
                h4 {
                    font-size: 2em;
                    font-weight: 600;
                    color: $color-blue;
                }
                .quantity {
                    display: flex;
                    align-items: center;
                    strong {
                        margin-right: 8px;
                    }
                }
            }   
        }
    }
    &__form {
        padding: 40px 0 60px;
        background: rgba(0,106,124,0.1);
        &-box {
            max-width: 600px;
            margin: 0 auto;
            background-color: white;
            padding: 40px;
            border-radius: 5px;
            h3 {
                color: $color-blue;
                font-weight: 600;
            }
        }
        .form-flex {
            display: flex;
            strong {
                display: block;
                margin-bottom: 6px;
            }
            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }
        }
        .w-2-5 {
            width: 56%;
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin-bottom: 3px;
            }
        }
        .w-1-5 {
            width: 20%;
            margin-left: 2%;
            @include media-breakpoint-down(sm) {
                width: 50%;
                margin-left: 0;
                //margin-right: 2%;
            }
        }
        .form-placeholder {
            ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                color:    #ccc;
            }
            :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
               color:    #ccc;
               opacity:  1;
            }
            ::-moz-placeholder { /* Mozilla Firefox 19+ */
               color:    #ccc;
               opacity:  1;
            }
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
               color:    #ccc;
            }
            ::-ms-input-placeholder { /* Microsoft Edge */
               color:    #ccc;
            }
            
            ::placeholder { /* Most modern browsers support this now. */
               color:    #ccc;
            }
            .dollar {
                padding: 0 5px;
            }
        }
        .form__form-group--select .form__field {
            font-weight: normal;
            font-size: 0.8em;
        }
        .form__form-group--select:after {
            top: 15px;
            border-top: 4px solid transparent;
            border-right: 4px solid transparent;
            border-left: 4px solid;
            border-bottom: 4px solid;
        }
        .form-control:disabled, 
        .form-control[readonly] {
            //background-color: transparent;
            border: none;
            position: relative;
            min-width: 80px;
            &::before {
                content:'$';
            }
        }
        .price-flex {
            display: flex;
            align-items: center;
        }
        .price-total {
            float: right;
            justify-content: end;
            margin-bottom: 10px;
            span {
                padding: 0 5px;
            }
        }
    }
   
}
