.nourish-banner {
    @include antialias;
    position: relative;
    overflow: hidden;
    line-height: 1.75em;
    padding: 70px 0 70px 0;
    background-color: $color-green;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 450px;
        height: 500px;
        background-image: url(../img/nourish-grass.svg);
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .fluid-container {
        max-width: 1400px; 
        margin: 0 auto;
        padding: 0;
        z-index: 1;
    }
    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(md) {
            display: block;
            padding: 0 10px;
        }
    }
    &__box {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: center;
        width: 50%;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        &-content {
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-full {
            width: 100%;
        }
        .width-sm {
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
        }
        .btn {
            margin-top: 30px;
        }
    }

    &__logo {
        width: 250px;
        margin-bottom: 20px;
    }

    &__heading {
        color: white;
        font-size: 4em;
        @include media-breakpoint-down(md) {
            font-size: 3.5em;
        }
        max-width: 1000px;
        margin: 0 auto 20px auto;
    }

    &__intro {
        color: white;
        font-size: 1.25rem;
        line-height: 1.5;
    }
}

// add extra flower to value stack
.nourish-flower {
    .inner-stack-item--3 {
        .flower {
            position: absolute;
            z-index: 2;
            content: '';
            bottom: -207px;
            left: -230px;
            width: 493px;
            height: 437px;
            transform: rotate(227deg) !important;
            background-image: url(../img/icons/flowers/4.svg);
            background-repeat: no-repeat;
            background-size: cover;
            @include media-breakpoint-down(md) {
                bottom: -113px;
                left: -200px;
                width: 343px;
                height: 245px;
            }
        }
    }
    .inner-stack-item {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
}

.nourish-blue-box {
    padding: 80px 20px;

    background-color: $color-blue;
    background-image: url(../img/icons/branches/6.png);
    background-repeat: no-repeat;
    background-position: -20px -60px;
    background-size: 295px auto;
    color: $color-black;
    text-align: center;
    border-radius: 10px;

    h2 {
        color: #fff;
        font-size: 2.5rem;
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 18px;
        line-height: 1.75;
    }

    &__content-wrap {
        margin: 0 auto;
        max-width:1000px;
    }
}
