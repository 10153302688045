
.maintenance-landing {
    &__header {
        position: relative;
        z-index:30;
        height: auto!important;
    }
    &__site-logo {
        svg {
            position: relative;
            width: 150px;
            margin-bottom: 20px;
        }
    }
    &__page-banner {
        padding-top: 0px;
        @include media-breakpoint-down(sm) {
            padding-top: 90px;
        }
    }
    &__banner {
        display: flex;
        gap: 1em;
        @include media-breakpoint-down(md) {
            display: block;
        }
        & > * {
            flex: 1;
        }
        &-flower {
            position: absolute;
            right: -67px;
            top: -28px;
            width: 187px;
            transform: rotate(28deg);
            z-index: 20;
            @include media-breakpoint-down(md) {
                right: -40px;
                top: -59px;
                width: 154px;
            }
            @include media-breakpoint-down(sm) {
                top: -80px;
            }
        }
        &-content {

        }
        &-image {
            position: relative;
            text-align: center;
            img {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 100%;
                height: 85%;
                object-fit: cover;
                @include media-breakpoint-down(md) {
                    position: relative;
                    width: 93%;
                    height: auto;
                    margin: 0 auto 40px;
                    top: 0;
                    transform: translateY(0%);
                }
            }
        }
    }
    &__form {
        max-width: 550px;
        padding: 30px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
        @include media-breakpoint-down(md) {
            margin: 0 auto;
        }
    }
    &__packages {
        margin-top: 80px;
        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
        &-heading {
            text-align: center;
            margin-bottom: 40px;
        }
    }
    &__plan {
        @include media-breakpoint-down(md) {
            padding: 40px 0;
        }
        &-card {
            padding: 15px 20px!important;
            font-size: 16px!important;
        }
    }
    &__gradient-3 {
        background: #fff;
        background: linear-gradient(180deg,rgba(185,200,130,0.1) 0%, rgba(185,200,130,0.1) 90%, #fff 100%);
        background: -webkit-linear-gradient(180deg,rgba(185,200,130,0.1) 0%, rgba(185,200,130,0.1) 90%, #fff 100%);
        background: -moz-linear-gradient(180deg,rgba(185,200,130,0.1) 0%, rgba(185,200,130,0.1) 90%, #fff 100%);
    }
    &__nurture {
        h2 {
            color: $color-teal;
        }
    }
    &__cta {
        padding-top: 140px;
        padding-bottom: 20px;
        overflow: visible;
        background: #fff;
        background: linear-gradient(180deg,#fff 0%, rgba(185,200,130,0.1) 30%, rgba(185,200,130,0.1) 100%);
        background: -webkit-linear-gradient(180deg,#fff 0%, rgba(185,200,130,0.1) 30%, rgba(185,200,130,0.1) 100%);
        background: -moz-linear-gradient(180deg,#fff 0%, rgba(185,200,130,0.1) 30%, rgba(185,200,130,0.1) 100%);
    }
    &__form-bottom {
        text-align: center;
        margin-top: -50px;
    }
    &__logo {
        width: 100px;
        margin: 40px auto;
    }
    &__flower {
        @include media-breakpoint-down(md) {
            width: 143px!important;
            height: 155px!important;
        }
    }
}