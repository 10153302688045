.featured-projects-callout {
  padding: 80px 0 60px;

  background-color: $color-gray-lighter;
  text-align: center;

  &__heading {
    margin-bottom: 40px;

    color: $color-gray;
    text-transform: uppercase;
  }
}
