.blog-wrap {
    background-image:
    linear-gradient(#fff 0px, rgba($color-teal , .8) 0px, lighten($color-teal, 30)),
    url(../img/icons/branches/4.png),
    linear-gradient(#fff 0px, $color-teal 0px, lighten($color-teal, 30))
    ;
    // background-image:
    // linear-gradient(#fff 150px, rgba($color-teal , .8) 150px, lighten($color-teal, 30)),
    // url(../img/icons/branches/4.png),
    // linear-gradient(#fff 150px, $color-teal 150px, lighten($color-teal, 30))
    // ;
    background-repeat: no-repeat;
    background-blend-mode: normal screen normal;

    background-position: center top, calc(50% - 300px) 380px, center top;

    @include media-breakpoint-up(xl) {
        background-position: center top, calc(50% - 700px) 640px, center top;
    }
    &__radius {
        border-radius: 10px;
    }
    &__white {
        background-color: white;
    }
}