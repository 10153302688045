@charset "UTF-8";
/*
|--------------------------------------------------------------------------
| Main
|
| This is the main scss file that pulls in all the other files.
|--------------------------------------------------------------------------
*/
/* base font-color */
.container, .fluid-container, .products-listing, .project-detail, .project-detail__finished-photos {
  position: relative; }

/*
|--------------------------------------------------------------------------
| Fonts
|
| Include @font-face font file imports here. Also, include a variable to
| easily use a font-stack across the site.
|--------------------------------------------------------------------------
*/
/*
 * Webfonts by Paulo Goode
 *
 *
 * The fonts included in this stylesheet are subject to the End User Licence Agreement you
 * purchased in this package. The fonts are protected under domestic and international
 * trademark and copyright law. You are prohibited from modifying, reverse engineering,
 * duplicating, or distributing this font software.
 *
 * © Copyright 2016 Paulo Goode
 *
 *
 */
@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-black-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-black-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-black-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-black-webfont.svg#woodford_bourne_problack") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-blackitalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-blackitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-blackitalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-blackitalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-blackitalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-blackitalic-webfont.svg#woodford_bourne_proBlkIt") format("svg");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-bold-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-bold-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-bold-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-bold-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-bold-webfont.svg#woodford_bourne_probold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-bolditalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-bolditalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-bolditalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-bolditalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-bolditalic-webfont.svg#woodford_bourne_proBdIt") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-extralight-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-extralight-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-extralight-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-extralight-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-extralight-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-extralight-webfont.svg#woodford_bourne_proextralight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-extralightitalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-extralightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-extralightitalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-extralightitalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-extralightitalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-extralightitalic-webfont.svg#woodford_bourne_proXLtIt") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-italic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-italic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-italic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-italic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-italic-webfont.svg#woodford_bourne_proitalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-light-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-light-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-light-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-light-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-light-webfont.svg#woodford_bourne_prolight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-lightitalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-lightitalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-lightitalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-lightitalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-lightitalic-webfont.svg#woodford_bourne_proLtIt") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-medium-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-medium-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-medium-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-medium-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-medium-webfont.svg#woodford_bourne_promedium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-mediumitalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-mediumitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-mediumitalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-mediumitalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-mediumitalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-mediumitalic-webfont.svg#woodford_bourne_proMdIt") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-regular-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-regular-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-regular-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-regular-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-regular-webfont.svg#woodford_bourne_proregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-semibold-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-semibold-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-semibold-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-semibold-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-semibold-webfont.svg#woodford_bourne_prosemibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-semibolditalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-semibolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-semibolditalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-semibolditalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-semibolditalic-webfont.svg#woodford_bourne_proSBdIt") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-thin-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-thin-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-thin-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-thin-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-thin-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-thin-webfont.svg#woodford_bourne_prothin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-thinitalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-thinitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-thinitalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-thinitalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-thinitalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-thinitalic-webfont.svg#woodford_bourne_proThIt") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-ultra-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-ultra-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-ultra-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-ultra-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-ultra-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-ultra-webfont.svg#woodford_bourne_proultra") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'WoodfordBournePro';
  src: url("../fonts/woodfordbournepro/woodfordbournepro-ultraitalic-webfont.eot");
  src: url("../fonts/woodfordbournepro/woodfordbournepro-ultraitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/woodfordbournepro/woodfordbournepro-ultraitalic-webfont.woff2") format("woff2"), url("../fonts/woodfordbournepro/woodfordbournepro-ultraitalic-webfont.woff") format("woff"), url("../fonts/woodfordbournepro/woodfordbournepro-ultraitalic-webfont.ttf") format("truetype"), url("../fonts/woodfordbournepro/woodfordbournepro-ultraitalic-webfont.svg#woodford_bourne_proultraIt") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Neue Helvetica eText";
  src: url("../fonts/helveticaneueetext/e0781a75-0ecb-464e-b6e9-85780ddc0921.eot?#iefix");
  src: url("../fonts/helveticaneueetext/e0781a75-0ecb-464e-b6e9-85780ddc0921.eot?#iefix") format("eot"), url("../fonts/helveticaneueetext/b8765d4b-d9a3-48b9-ac65-560e7517cf0e.woff2") format("woff2"), url("../fonts/helveticaneueetext/d7d2e6c6-fc3a-41a5-9b52-648e12e215b2.woff") format("woff"), url("../fonts/helveticaneueetext/cb64744b-d0f7-4ef8-a790-b60d6e3e4f21.ttf") format("truetype"), url("../fonts/helveticaneueetext/ccde919e-a712-41ef-b90f-9efc47661659.svg#ccde919e-a712-41ef-b90f-9efc47661659") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Neue Helvetica eText";
  src: url("../fonts/helveticaneueetext/fc780ce0-5b33-4e1d-b89b-66111a4d0ed8.eot?#iefix");
  src: url("../fonts/helveticaneueetext/fc780ce0-5b33-4e1d-b89b-66111a4d0ed8.eot?#iefix") format("eot"), url("../fonts/helveticaneueetext/0381d699-73ef-4558-9e61-38385c9fc5da.woff2") format("woff2"), url("../fonts/helveticaneueetext/2731e338-d92f-4492-8480-8b55ae1139f6.woff") format("woff"), url("../fonts/helveticaneueetext/40e43cb5-d6b2-4ceb-b369-527d1007a2c7.ttf") format("truetype"), url("../fonts/helveticaneueetext/c1993d56-4077-454a-8b19-635fa5a39532.svg#c1993d56-4077-454a-8b19-635fa5a39532") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Neue Helvetica eText";
  src: url("../fonts/helveticaneueetext/706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix");
  src: url("../fonts/helveticaneueetext/706ec8e5-fe4a-4518-91a6-7aba4d3d333f.eot?#iefix") format("eot"), url("../fonts/helveticaneueetext/dc50c02f-3f77-4e75-b89c-e3f9bb4752e6.woff2") format("woff2"), url("../fonts/helveticaneueetext/ce6f5b38-1cb5-4a27-8182-583aa68b2436.woff") format("woff"), url("../fonts/helveticaneueetext/ff06cde7-e06a-4cf0-af4d-5b2f737bf544.ttf") format("truetype"), url("../fonts/helveticaneueetext/958b12cf-2908-4254-8cb9-9b12c3aa151e.svg#958b12cf-2908-4254-8cb9-9b12c3aa151e") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Neue Helvetica eText";
  src: url("../fonts/helveticaneueetext/5935d076-bd6d-4ca4-b995-d739ffa09754.eot?#iefix");
  src: url("../fonts/helveticaneueetext/5935d076-bd6d-4ca4-b995-d739ffa09754.eot?#iefix") format("eot"), url("../fonts/helveticaneueetext/56e66f56-3016-44fd-84b2-8e40d9743656.woff2") format("woff2"), url("../fonts/helveticaneueetext/4d65bb6f-e667-4bd0-8e92-34e67bf6ac8b.woff") format("woff"), url("../fonts/helveticaneueetext/86631d82-6e27-4db2-988a-6b79a6c0d8ed.ttf") format("truetype"), url("../fonts/helveticaneueetext/205c950e-e47f-4d97-9738-0a68edc4258d.svg#205c950e-e47f-4d97-9738-0a68edc4258d") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Neue Helvetica eText";
  src: url("../fonts/helveticaneueetext/a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix");
  src: url("../fonts/helveticaneueetext/a95d2895-fa71-4ce7-bc1e-631169924be3.eot?#iefix") format("eot"), url("../fonts/helveticaneueetext/6dfa3ef5-ebc5-4d23-a631-b3d746c2ad39.woff2") format("woff2"), url("../fonts/helveticaneueetext/588c9ba8-1d28-4ad6-bf18-7128152d84c4.woff") format("woff"), url("../fonts/helveticaneueetext/55334dfb-9c72-4147-8de0-21239f17ff25.ttf") format("truetype"), url("../fonts/helveticaneueetext/62455047-c81f-44f1-8938-7739fa0f0e8d.svg#62455047-c81f-44f1-8938-7739fa0f0e8d") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Neue Helvetica eText";
  src: url("../fonts/helveticaneueetext/a48e33d0-3542-482d-9284-e41d31c4d583.eot?#iefix");
  src: url("../fonts/helveticaneueetext/a48e33d0-3542-482d-9284-e41d31c4d583.eot?#iefix") format("eot"), url("../fonts/helveticaneueetext/15ed0ff2-0715-4aca-af0c-00840c3a4dd7.woff2") format("woff2"), url("../fonts/helveticaneueetext/9b77b336-b221-497e-b255-7833856bb4a9.woff") format("woff"), url("../fonts/helveticaneueetext/5c9a7798-4870-4139-ab84-7a300a7dc3e1.ttf") format("truetype"), url("../fonts/helveticaneueetext/3b59e91c-919a-4e7a-9ada-8c73b3378f03.svg#3b59e91c-919a-4e7a-9ada-8c73b3378f03") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap; }

/*
|--------------------------------------------------------------------------
| Vendor files
|
| Try and use NPM to manage all vendor files if possible.
|--------------------------------------------------------------------------
*/
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Neue Helvetica eText", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
  --font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Neue Helvetica eText", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #575755;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #b9c882;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #9bb04d;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #818a91;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 3.75rem; }

h2, .h2 {
  font-size: 3.125rem; }

h3, .h3 {
  font-size: 2.5rem; }

h4, .h4 {
  font-size: 1.875rem; }

h5, .h5 {
  font-size: 1.5rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 400; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 75%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 75%;
  color: #818a91; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 90%;
  color: #bd4147;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 0; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  font-size: 90%;
  color: #373a3c; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .fluid-container, .products-listing, .project-detail, .project-detail__finished-photos,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .fluid-container, .products-listing, .project-detail, .project-detail__finished-photos, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .fluid-container, .products-listing, .project-detail, .project-detail__finished-photos, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .fluid-container, .products-listing, .project-detail, .project-detail__finished-photos, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .fluid-container, .products-listing, .project-detail, .project-detail__finished-photos, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1190px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #575755;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #eceeef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eceeef; }
  .table tbody + tbody {
    border-top: 2px solid #eceeef; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #eceeef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eceeef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #575755;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #eceeef; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #55595c; }
  .form-control:focus {
    color: #55595c;
    background-color: #fff;
    border-color: #ebefdb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(185, 200, 130, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #55595c;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25; }

.col-form-label-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.33333; }

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.25;
  color: #575755;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: 1.8125rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.form-control-lg {
  height: 3.16667rem;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.33333;
  border-radius: 0; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #818a91; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.25em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.25rem) center;
  background-size: calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.25em + 1rem);
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.625em + 0.5rem) calc(0.625em + 0.5rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.25em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.25rem) center;
  background-size: calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.25em + 1rem);
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.625em + 0.5rem) calc(0.625em + 0.5rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .about__section--refer-a-friend a, .careers-callout__content-wrap a {
  display: inline-block;
  font-weight: normal;
  color: #575755;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 38px; }
  .btn:hover, .about__section--refer-a-friend a:hover, .careers-callout__content-wrap a:hover {
    color: #575755;
    text-decoration: none; }
  .btn:focus, .about__section--refer-a-friend a:focus, .careers-callout__content-wrap a:focus, .btn.focus, .about__section--refer-a-friend a.focus, .careers-callout__content-wrap a.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(185, 200, 130, 0.25); }
  .btn.disabled, .about__section--refer-a-friend a.disabled, .careers-callout__content-wrap a.disabled, .btn:disabled, .about__section--refer-a-friend a:disabled, .careers-callout__content-wrap a:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .about__section--refer-a-friend a:not(:disabled):not(.disabled), .careers-callout__content-wrap a:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled, .about__section--refer-a-friend a.disabled, .careers-callout__content-wrap a.disabled,
fieldset:disabled a.btn,
fieldset:disabled .about__section--refer-a-friend a,
.about__section--refer-a-friend fieldset:disabled a,
fieldset:disabled .careers-callout__content-wrap a,
.careers-callout__content-wrap fieldset:disabled a {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #b9c882;
  text-decoration: none; }
  .btn-link:hover {
    color: #9bb04d;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #818a91;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .about__section--refer-a-friend .btn-group-lg > a, .careers-callout__content-wrap .btn-group-lg > a {
  padding: 0.75rem 5rem;
  font-size: 1.25rem;
  line-height: 1.33333;
  border-radius: 38px; }

.btn-sm, .btn-group-sm > .btn, .about__section--refer-a-friend .btn-group-sm > a, .careers-callout__content-wrap .btn-group-sm > a {
  padding: 0.7rem 1.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 38px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #575755;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #2b2d2f;
    text-decoration: none;
    background-color: #f5f5f5; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #b9c882; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #818a91;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #818a91;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #373a3c; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .about__section--refer-a-friend .btn-group > a, .careers-callout__content-wrap .btn-group > a,
  .btn-group-vertical > .btn,
  .about__section--refer-a-friend .btn-group-vertical > a,
  .careers-callout__content-wrap .btn-group-vertical > a {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .about__section--refer-a-friend .btn-group > a:hover, .careers-callout__content-wrap .btn-group > a:hover,
    .btn-group-vertical > .btn:hover,
    .about__section--refer-a-friend .btn-group-vertical > a:hover,
    .careers-callout__content-wrap .btn-group-vertical > a:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .about__section--refer-a-friend .btn-group > a:focus, .careers-callout__content-wrap .btn-group > a:focus, .btn-group > .btn:active, .about__section--refer-a-friend .btn-group > a:active, .careers-callout__content-wrap .btn-group > a:active, .btn-group > .btn.active, .about__section--refer-a-friend .btn-group > a.active, .careers-callout__content-wrap .btn-group > a.active,
    .btn-group-vertical > .btn:focus,
    .about__section--refer-a-friend .btn-group-vertical > a:focus,
    .careers-callout__content-wrap .btn-group-vertical > a:focus,
    .btn-group-vertical > .btn:active,
    .about__section--refer-a-friend .btn-group-vertical > a:active,
    .careers-callout__content-wrap .btn-group-vertical > a:active,
    .btn-group-vertical > .btn.active,
    .about__section--refer-a-friend .btn-group-vertical > a.active,
    .careers-callout__content-wrap .btn-group-vertical > a.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .about__section--refer-a-friend .btn-group > a:not(:first-child), .careers-callout__content-wrap .btn-group > a:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .about__section--refer-a-friend .btn-group > a:not(:last-child):not(.dropdown-toggle), .careers-callout__content-wrap .btn-group > a:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.about__section--refer-a-friend .btn-group > .btn-group:not(:last-child) > a,
.careers-callout__content-wrap .btn-group > .btn-group:not(:last-child) > a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .about__section--refer-a-friend .btn-group > a:not(:first-child), .careers-callout__content-wrap .btn-group > a:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.about__section--refer-a-friend .btn-group > .btn-group:not(:first-child) > a,
.careers-callout__content-wrap .btn-group > .btn-group:not(:first-child) > a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .about__section--refer-a-friend .btn-group-sm > a + .dropdown-toggle-split, .careers-callout__content-wrap .btn-group-sm > a + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .about__section--refer-a-friend .btn-group-lg > a + .dropdown-toggle-split, .careers-callout__content-wrap .btn-group-lg > a + .dropdown-toggle-split {
  padding-right: 3.75rem;
  padding-left: 3.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .about__section--refer-a-friend .btn-group-vertical > a,
  .careers-callout__content-wrap .btn-group-vertical > a,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .about__section--refer-a-friend .btn-group-vertical > a:not(:first-child), .careers-callout__content-wrap .btn-group-vertical > a:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .about__section--refer-a-friend .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle), .careers-callout__content-wrap .btn-group-vertical > a:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .about__section--refer-a-friend .btn-group-vertical > .btn-group:not(:last-child) > a,
  .careers-callout__content-wrap .btn-group-vertical > .btn-group:not(:last-child) > a {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .about__section--refer-a-friend .btn-group-vertical > a:not(:first-child), .careers-callout__content-wrap .btn-group-vertical > a:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .about__section--refer-a-friend .btn-group-vertical > .btn-group:not(:first-child) > a,
  .careers-callout__content-wrap .btn-group-vertical > .btn-group:not(:first-child) > a {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .about__section--refer-a-friend .btn-group-toggle > a, .careers-callout__content-wrap .btn-group-toggle > a,
.btn-group-toggle > .btn-group > .btn,
.about__section--refer-a-friend .btn-group-toggle > .btn-group > a,
.careers-callout__content-wrap .btn-group-toggle > .btn-group > a {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .about__section--refer-a-friend .btn-group-toggle > a input[type="radio"], .careers-callout__content-wrap .btn-group-toggle > a input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .about__section--refer-a-friend .btn-group-toggle > a input[type="checkbox"],
  .careers-callout__content-wrap .btn-group-toggle > a input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .about__section--refer-a-friend .btn-group-toggle > .btn-group > a input[type="radio"],
  .careers-callout__content-wrap .btn-group-toggle > .btn-group > a input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .about__section--refer-a-friend .btn-group-toggle > .btn-group > a input[type="checkbox"],
  .careers-callout__content-wrap .btn-group-toggle > .btn-group > a input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: 0; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .about__section--refer-a-friend a, .about__section--refer-a-friend .input-group-prepend a, .input-group-prepend .careers-callout__content-wrap a, .careers-callout__content-wrap .input-group-prepend a,
  .input-group-append .btn,
  .input-group-append .about__section--refer-a-friend a,
  .about__section--refer-a-friend .input-group-append a,
  .input-group-append .careers-callout__content-wrap a,
  .careers-callout__content-wrap .input-group-append a {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .about__section--refer-a-friend a:focus, .about__section--refer-a-friend .input-group-prepend a:focus, .input-group-prepend .careers-callout__content-wrap a:focus, .careers-callout__content-wrap .input-group-prepend a:focus,
    .input-group-append .btn:focus,
    .input-group-append .about__section--refer-a-friend a:focus,
    .about__section--refer-a-friend .input-group-append a:focus,
    .input-group-append .careers-callout__content-wrap a:focus,
    .careers-callout__content-wrap .input-group-append a:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .about__section--refer-a-friend a + .btn, .about__section--refer-a-friend .input-group-prepend a + .btn, .input-group-prepend .careers-callout__content-wrap a + .btn, .careers-callout__content-wrap .input-group-prepend a + .btn, .input-group-prepend .about__section--refer-a-friend .btn + a, .about__section--refer-a-friend .input-group-prepend .btn + a, .input-group-prepend .about__section--refer-a-friend a + a, .about__section--refer-a-friend .input-group-prepend a + a, .input-group-prepend .careers-callout__content-wrap .about__section--refer-a-friend a + a, .about__section--refer-a-friend .careers-callout__content-wrap .input-group-prepend a + a, .input-group-prepend .careers-callout__content-wrap .btn + a, .careers-callout__content-wrap .input-group-prepend .btn + a, .input-group-prepend .about__section--refer-a-friend .careers-callout__content-wrap a + a, .careers-callout__content-wrap .input-group-prepend .about__section--refer-a-friend a + a, .about__section--refer-a-friend .input-group-prepend .careers-callout__content-wrap a + a, .careers-callout__content-wrap .about__section--refer-a-friend .input-group-prepend a + a, .input-group-prepend .careers-callout__content-wrap a + a, .careers-callout__content-wrap .input-group-prepend a + a,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .about__section--refer-a-friend a + .input-group-text,
  .about__section--refer-a-friend .input-group-prepend a + .input-group-text,
  .input-group-prepend .careers-callout__content-wrap a + .input-group-text,
  .careers-callout__content-wrap .input-group-prepend a + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .about__section--refer-a-friend .input-group-text + a,
  .about__section--refer-a-friend .input-group-prepend .input-group-text + a,
  .input-group-prepend .careers-callout__content-wrap .input-group-text + a,
  .careers-callout__content-wrap .input-group-prepend .input-group-text + a,
  .input-group-append .btn + .btn,
  .input-group-append .about__section--refer-a-friend a + .btn,
  .about__section--refer-a-friend .input-group-append a + .btn,
  .input-group-append .careers-callout__content-wrap a + .btn,
  .careers-callout__content-wrap .input-group-append a + .btn,
  .input-group-append .about__section--refer-a-friend .btn + a,
  .about__section--refer-a-friend .input-group-append .btn + a,
  .input-group-append .about__section--refer-a-friend a + a,
  .about__section--refer-a-friend .input-group-append a + a,
  .input-group-append .careers-callout__content-wrap .about__section--refer-a-friend a + a,
  .about__section--refer-a-friend .careers-callout__content-wrap .input-group-append a + a,
  .input-group-append .careers-callout__content-wrap .btn + a,
  .careers-callout__content-wrap .input-group-append .btn + a,
  .input-group-append .about__section--refer-a-friend .careers-callout__content-wrap a + a,
  .careers-callout__content-wrap .input-group-append .about__section--refer-a-friend a + a,
  .about__section--refer-a-friend .input-group-append .careers-callout__content-wrap a + a,
  .careers-callout__content-wrap .about__section--refer-a-friend .input-group-append a + a,
  .input-group-append .careers-callout__content-wrap a + a,
  .careers-callout__content-wrap .input-group-append a + a,
  .input-group-append .btn + .input-group-text,
  .input-group-append .about__section--refer-a-friend a + .input-group-text,
  .about__section--refer-a-friend .input-group-append a + .input-group-text,
  .input-group-append .careers-callout__content-wrap a + .input-group-text,
  .careers-callout__content-wrap .input-group-append a + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .about__section--refer-a-friend .input-group-text + a,
  .about__section--refer-a-friend .input-group-append .input-group-text + a,
  .input-group-append .careers-callout__content-wrap .input-group-text + a,
  .careers-callout__content-wrap .input-group-append .input-group-text + a {
    margin-left: 0; }

.input-group-prepend {
  margin-right: 0; }

.input-group-append {
  margin-left: 0; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #55595c;
  text-align: center;
  white-space: nowrap;
  background-color: #eceeef;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: 3.16667rem; }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.about__section--refer-a-friend .input-group-lg > .input-group-prepend > a,
.careers-callout__content-wrap .input-group-lg > .input-group-prepend > a,
.input-group-lg > .input-group-append > .btn,
.about__section--refer-a-friend .input-group-lg > .input-group-append > a,
.careers-callout__content-wrap .input-group-lg > .input-group-append > a {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.33333;
  border-radius: 0; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: 1.8125rem; }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.about__section--refer-a-friend .input-group-sm > .input-group-prepend > a,
.careers-callout__content-wrap .input-group-sm > .input-group-prepend > a,
.input-group-sm > .input-group-append > .btn,
.about__section--refer-a-friend .input-group-sm > .input-group-append > a,
.careers-callout__content-wrap .input-group-sm > .input-group-append > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .about__section--refer-a-friend .input-group > .input-group-prepend > a, .careers-callout__content-wrap .input-group > .input-group-prepend > a,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.about__section--refer-a-friend .input-group:not(.has-validation) > .input-group-append:not(:last-child) > a,
.careers-callout__content-wrap .input-group:not(.has-validation) > .input-group-append:not(:last-child) > a,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.about__section--refer-a-friend .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > a,
.careers-callout__content-wrap .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > a,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.about__section--refer-a-friend .input-group > .input-group-append:last-child > a:not(:last-child):not(.dropdown-toggle),
.careers-callout__content-wrap .input-group > .input-group-append:last-child > a:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .about__section--refer-a-friend .input-group > .input-group-append > a, .careers-callout__content-wrap .input-group > .input-group-append > a,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.about__section--refer-a-friend .input-group > .input-group-prepend:not(:first-child) > a,
.careers-callout__content-wrap .input-group > .input-group-prepend:not(:first-child) > a,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.about__section--refer-a-friend .input-group > .input-group-prepend:first-child > a:not(:first-child),
.careers-callout__content-wrap .input-group > .input-group-prepend:first-child > a:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #b9c882;
    background-color: #b9c882; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(185, 200, 130, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ebefdb; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fefffe;
    border-color: #fefffe; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ddd;
    border: #adb5bd solid 0; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #b9c882;
  background-color: #b9c882; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 3.25rem; }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: 0.25rem;
    left: -3.25rem;
    width: 1rem;
    height: 1rem;
    background-color: #adb5bd;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ddd;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #55595c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #51a7e8;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(81, 167, 232, 0.5); }
    .custom-select:focus::-ms-value {
      color: #55595c;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #818a91;
    background-color: #eceeef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #55595c; }

.custom-select-sm {
  height: 1.8125rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: 3.16667rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.5rem;
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #ebefdb;
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.5rem;
  padding: 1rem 0.5rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.25em + 1rem);
    padding: 1rem 0.5rem;
    line-height: 1.5;
    color: #555;
    content: "Browse";
    background-color: #eee;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(185, 200, 130, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(185, 200, 130, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(185, 200, 130, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #b9c882;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fefffe; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #b9c882;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:active {
      background-color: #fefffe; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #b9c882;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:active {
      background-color: #fefffe; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #eceeef; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #b9c882; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container, .navbar .fluid-container, .navbar .products-listing, .navbar .project-detail, .navbar .project-detail__finished-photos,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.5rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 38px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .fluid-container, .navbar-expand-sm > .products-listing, .navbar-expand-sm > .project-detail, .navbar-expand-sm > .project-detail__finished-photos,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container, .navbar-expand-sm > .fluid-container, .navbar-expand-sm > .products-listing, .navbar-expand-sm > .project-detail, .navbar-expand-sm > .project-detail__finished-photos,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .fluid-container, .navbar-expand-md > .products-listing, .navbar-expand-md > .project-detail, .navbar-expand-md > .project-detail__finished-photos,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, .navbar-expand-md > .fluid-container, .navbar-expand-md > .products-listing, .navbar-expand-md > .project-detail, .navbar-expand-md > .project-detail__finished-photos,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .fluid-container, .navbar-expand-lg > .products-listing, .navbar-expand-lg > .project-detail, .navbar-expand-lg > .project-detail__finished-photos,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container, .navbar-expand-lg > .fluid-container, .navbar-expand-lg > .products-listing, .navbar-expand-lg > .project-detail, .navbar-expand-lg > .project-detail__finished-photos,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .fluid-container, .navbar-expand-xl > .products-listing, .navbar-expand-xl > .project-detail, .navbar-expand-xl > .project-detail__finished-photos,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container, .navbar-expand-xl > .fluid-container, .navbar-expand-xl > .products-listing, .navbar-expand-xl > .project-detail, .navbar-expand-xl > .project-detail__finished-photos,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, .navbar-expand > .fluid-container, .navbar-expand > .products-listing, .navbar-expand > .project-detail, .navbar-expand > .project-detail__finished-photos,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container, .navbar-expand > .fluid-container, .navbar-expand > .products-listing, .navbar-expand > .project-detail, .navbar-expand > .project-detail__finished-photos,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: white; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: white; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0 0 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f5f5f5;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0 0; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-deck .card {
  margin-bottom: 0.625rem; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 0.625rem;
      margin-bottom: 0;
      margin-left: 0.625rem; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eceeef;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #818a91;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #818a91; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #b9c882;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:hover {
    z-index: 2;
    color: #9bb04d;
    text-decoration: none;
    background-color: #eceeef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(185, 200, 130, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #b9c882;
  border-color: #b9c882; }

.page-item.disabled .page-link {
  color: #818a91;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.33333; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.pagination-sm .page-link {
  padding: 0.275rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0; }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .about__section--refer-a-friend a .badge, .careers-callout__content-wrap a .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef;
  border-radius: 0; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #eee;
  border-radius: 0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #0074d9;
  text-align: center;
  white-space: nowrap;
  background-color: #b9c882; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f5f5f5; }
  .list-group-item-action:active {
    color: #575755;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #ddd; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #818a91;
    pointer-events: none;
    background-color: #eceeef; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #b9c882;
    border-color: #b9c882; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transform: translate(0, -50px); }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 20px); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 20px);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 20px); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 20px);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e5e5e5;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 3px;
  font-family: "Neue Helvetica eText", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 2.5px 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 5px; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 2.5px 0.4rem 2.5px 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 2.5px 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 5px; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 2.5px 0 2.5px 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Neue Helvetica eText", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 0.5rem;
    margin: 0 0; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 5px 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 5px 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 10px;
    margin: 0 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 5px 0.5rem 5px 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 5px 0.5rem 5px 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 5px 0.5rem 5px;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 5px 0.5rem 5px;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 10px;
    margin-left: -5px;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 10px;
    margin: 0 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 5px 0 5px 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 5px 0 5px 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #575755; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0; }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5; }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0 !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-lg {
  border-radius: 0 !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 1rem !important; }

.mt-1,
.my-1 {
  margin-top: 1rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1rem !important; }

.m-2 {
  margin: 1.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 1.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1.5rem !important; }

.m-3 {
  margin: 3rem !important; }

.mt-3,
.my-3 {
  margin-top: 3rem !important; }

.mr-3,
.mx-3 {
  margin-right: 3rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 3rem !important; }

.ml-3,
.mx-3 {
  margin-left: 3rem !important; }

.m-4 {
  margin: 4rem !important; }

.mt-4,
.my-4 {
  margin-top: 4rem !important; }

.mr-4,
.mx-4 {
  margin-right: 4rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 4rem !important; }

.ml-4,
.mx-4 {
  margin-left: 4rem !important; }

.m-5 {
  margin: 5rem !important; }

.mt-5,
.my-5 {
  margin-top: 5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 5rem !important; }

.m-6 {
  margin: 6rem !important; }

.mt-6,
.my-6 {
  margin-top: 6rem !important; }

.mr-6,
.mx-6 {
  margin-right: 6rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 6rem !important; }

.ml-6,
.mx-6 {
  margin-left: 6rem !important; }

.m-7 {
  margin: 7rem !important; }

.mt-7,
.my-7 {
  margin-top: 7rem !important; }

.mr-7,
.mx-7 {
  margin-right: 7rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 7rem !important; }

.ml-7,
.mx-7 {
  margin-left: 7rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8,
.my-8 {
  margin-top: 8rem !important; }

.mr-8,
.mx-8 {
  margin-right: 8rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 8rem !important; }

.ml-8,
.mx-8 {
  margin-left: 8rem !important; }

.m-9 {
  margin: 9rem !important; }

.mt-9,
.my-9 {
  margin-top: 9rem !important; }

.mr-9,
.mx-9 {
  margin-right: 9rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 9rem !important; }

.ml-9,
.mx-9 {
  margin-left: 9rem !important; }

.m-10 {
  margin: 10rem !important; }

.mt-10,
.my-10 {
  margin-top: 10rem !important; }

.mr-10,
.mx-10 {
  margin-right: 10rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 10rem !important; }

.ml-10,
.mx-10 {
  margin-left: 10rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 1rem !important; }

.pt-1,
.py-1 {
  padding-top: 1rem !important; }

.pr-1,
.px-1 {
  padding-right: 1rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1rem !important; }

.pl-1,
.px-1 {
  padding-left: 1rem !important; }

.p-2 {
  padding: 1.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 1.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 1.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 1.5rem !important; }

.p-3 {
  padding: 3rem !important; }

.pt-3,
.py-3 {
  padding-top: 3rem !important; }

.pr-3,
.px-3 {
  padding-right: 3rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 3rem !important; }

.pl-3,
.px-3 {
  padding-left: 3rem !important; }

.p-4 {
  padding: 4rem !important; }

.pt-4,
.py-4 {
  padding-top: 4rem !important; }

.pr-4,
.px-4 {
  padding-right: 4rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 4rem !important; }

.pl-4,
.px-4 {
  padding-left: 4rem !important; }

.p-5 {
  padding: 5rem !important; }

.pt-5,
.py-5 {
  padding-top: 5rem !important; }

.pr-5,
.px-5 {
  padding-right: 5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 5rem !important; }

.pl-5,
.px-5 {
  padding-left: 5rem !important; }

.p-6 {
  padding: 6rem !important; }

.pt-6,
.py-6 {
  padding-top: 6rem !important; }

.pr-6,
.px-6 {
  padding-right: 6rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 6rem !important; }

.pl-6,
.px-6 {
  padding-left: 6rem !important; }

.p-7 {
  padding: 7rem !important; }

.pt-7,
.py-7 {
  padding-top: 7rem !important; }

.pr-7,
.px-7 {
  padding-right: 7rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 7rem !important; }

.pl-7,
.px-7 {
  padding-left: 7rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8,
.py-8 {
  padding-top: 8rem !important; }

.pr-8,
.px-8 {
  padding-right: 8rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 8rem !important; }

.pl-8,
.px-8 {
  padding-left: 8rem !important; }

.p-9 {
  padding: 9rem !important; }

.pt-9,
.py-9 {
  padding-top: 9rem !important; }

.pr-9,
.px-9 {
  padding-right: 9rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 9rem !important; }

.pl-9,
.px-9 {
  padding-left: 9rem !important; }

.p-10 {
  padding: 10rem !important; }

.pt-10,
.py-10 {
  padding-top: 10rem !important; }

.pr-10,
.px-10 {
  padding-right: 10rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 10rem !important; }

.pl-10,
.px-10 {
  padding-left: 10rem !important; }

.m-n1 {
  margin: -1rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -1rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -1rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -1rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -1rem !important; }

.m-n2 {
  margin: -1.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -1.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -1.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -1.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -1.5rem !important; }

.m-n3 {
  margin: -3rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -3rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -3rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -3rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -3rem !important; }

.m-n4 {
  margin: -4rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -4rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -4rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -4rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -4rem !important; }

.m-n5 {
  margin: -5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -5rem !important; }

.m-n6 {
  margin: -6rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -6rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -6rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -6rem !important; }

.m-n7 {
  margin: -7rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -7rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important; }

.m-n8 {
  margin: -8rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -8rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important; }

.m-n9 {
  margin: -9rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -9rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important; }

.m-n10 {
  margin: -10rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -10rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -10rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -10rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -10rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 1rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important; }
  .m-sm-2 {
    margin: 1.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.5rem !important; }
  .m-sm-3 {
    margin: 3rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 3rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 3rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 3rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 3rem !important; }
  .m-sm-4 {
    margin: 4rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 4rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 4rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 4rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 4rem !important; }
  .m-sm-5 {
    margin: 5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5rem !important; }
  .m-sm-6 {
    margin: 6rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important; }
  .m-sm-7 {
    margin: 7rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important; }
  .m-sm-8 {
    margin: 8rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important; }
  .m-sm-9 {
    margin: 9rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important; }
  .m-sm-10 {
    margin: 10rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 1rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important; }
  .p-sm-2 {
    padding: 1.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.5rem !important; }
  .p-sm-3 {
    padding: 3rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 3rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 3rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 3rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 3rem !important; }
  .p-sm-4 {
    padding: 4rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 4rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 4rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 4rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 4rem !important; }
  .p-sm-5 {
    padding: 5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important; }
  .p-sm-7 {
    padding: 7rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important; }
  .p-sm-8 {
    padding: 8rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important; }
  .p-sm-9 {
    padding: 9rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important; }
  .p-sm-10 {
    padding: 10rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10rem !important; }
  .m-sm-n1 {
    margin: -1rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1rem !important; }
  .m-sm-n2 {
    margin: -1.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1.5rem !important; }
  .m-sm-n3 {
    margin: -3rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -3rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -3rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -3rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -3rem !important; }
  .m-sm-n4 {
    margin: -4rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -4rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -4rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -4rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -4rem !important; }
  .m-sm-n5 {
    margin: -5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5rem !important; }
  .m-sm-n6 {
    margin: -6rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6rem !important; }
  .m-sm-n7 {
    margin: -7rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important; }
  .m-sm-n8 {
    margin: -8rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important; }
  .m-sm-n9 {
    margin: -9rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important; }
  .m-sm-n10 {
    margin: -10rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 1rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important; }
  .m-md-2 {
    margin: 1.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.5rem !important; }
  .m-md-3 {
    margin: 3rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 3rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 3rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 3rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 3rem !important; }
  .m-md-4 {
    margin: 4rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 4rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 4rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 4rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 4rem !important; }
  .m-md-5 {
    margin: 5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5rem !important; }
  .m-md-6 {
    margin: 6rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important; }
  .m-md-7 {
    margin: 7rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important; }
  .m-md-8 {
    margin: 8rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important; }
  .m-md-9 {
    margin: 9rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important; }
  .m-md-10 {
    margin: 10rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 1rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important; }
  .p-md-2 {
    padding: 1.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.5rem !important; }
  .p-md-3 {
    padding: 3rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 3rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 3rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 3rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 3rem !important; }
  .p-md-4 {
    padding: 4rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 4rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 4rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 4rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 4rem !important; }
  .p-md-5 {
    padding: 5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important; }
  .p-md-7 {
    padding: 7rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important; }
  .p-md-8 {
    padding: 8rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important; }
  .p-md-9 {
    padding: 9rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important; }
  .p-md-10 {
    padding: 10rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10rem !important; }
  .m-md-n1 {
    margin: -1rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1rem !important; }
  .m-md-n2 {
    margin: -1.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1.5rem !important; }
  .m-md-n3 {
    margin: -3rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -3rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -3rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -3rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -3rem !important; }
  .m-md-n4 {
    margin: -4rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -4rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -4rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -4rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -4rem !important; }
  .m-md-n5 {
    margin: -5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5rem !important; }
  .m-md-n6 {
    margin: -6rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6rem !important; }
  .m-md-n7 {
    margin: -7rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important; }
  .m-md-n8 {
    margin: -8rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important; }
  .m-md-n9 {
    margin: -9rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important; }
  .m-md-n10 {
    margin: -10rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 1rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important; }
  .m-lg-2 {
    margin: 1.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.5rem !important; }
  .m-lg-3 {
    margin: 3rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 3rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 3rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 3rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 3rem !important; }
  .m-lg-4 {
    margin: 4rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 4rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 4rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 4rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 4rem !important; }
  .m-lg-5 {
    margin: 5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5rem !important; }
  .m-lg-6 {
    margin: 6rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important; }
  .m-lg-7 {
    margin: 7rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important; }
  .m-lg-8 {
    margin: 8rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important; }
  .m-lg-9 {
    margin: 9rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important; }
  .m-lg-10 {
    margin: 10rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 1rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important; }
  .p-lg-2 {
    padding: 1.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.5rem !important; }
  .p-lg-3 {
    padding: 3rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 3rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 3rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 3rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 3rem !important; }
  .p-lg-4 {
    padding: 4rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 4rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 4rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 4rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 4rem !important; }
  .p-lg-5 {
    padding: 5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important; }
  .p-lg-7 {
    padding: 7rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important; }
  .p-lg-8 {
    padding: 8rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important; }
  .p-lg-9 {
    padding: 9rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important; }
  .p-lg-10 {
    padding: 10rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10rem !important; }
  .m-lg-n1 {
    margin: -1rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1rem !important; }
  .m-lg-n2 {
    margin: -1.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1.5rem !important; }
  .m-lg-n3 {
    margin: -3rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -3rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -3rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -3rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -3rem !important; }
  .m-lg-n4 {
    margin: -4rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -4rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -4rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -4rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -4rem !important; }
  .m-lg-n5 {
    margin: -5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5rem !important; }
  .m-lg-n6 {
    margin: -6rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6rem !important; }
  .m-lg-n7 {
    margin: -7rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important; }
  .m-lg-n8 {
    margin: -8rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important; }
  .m-lg-n9 {
    margin: -9rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important; }
  .m-lg-n10 {
    margin: -10rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 1rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1rem !important; }
  .m-xl-2 {
    margin: 1.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.5rem !important; }
  .m-xl-3 {
    margin: 3rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 3rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 3rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 3rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 3rem !important; }
  .m-xl-4 {
    margin: 4rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 4rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 4rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 4rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 4rem !important; }
  .m-xl-5 {
    margin: 5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5rem !important; }
  .m-xl-6 {
    margin: 6rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6rem !important; }
  .m-xl-7 {
    margin: 7rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important; }
  .m-xl-8 {
    margin: 8rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important; }
  .m-xl-9 {
    margin: 9rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important; }
  .m-xl-10 {
    margin: 10rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 1rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1rem !important; }
  .p-xl-2 {
    padding: 1.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.5rem !important; }
  .p-xl-3 {
    padding: 3rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 3rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 3rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 3rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 3rem !important; }
  .p-xl-4 {
    padding: 4rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 4rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 4rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 4rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 4rem !important; }
  .p-xl-5 {
    padding: 5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6rem !important; }
  .p-xl-7 {
    padding: 7rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important; }
  .p-xl-8 {
    padding: 8rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important; }
  .p-xl-9 {
    padding: 9rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important; }
  .p-xl-10 {
    padding: 10rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10rem !important; }
  .m-xl-n1 {
    margin: -1rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1rem !important; }
  .m-xl-n2 {
    margin: -1.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1.5rem !important; }
  .m-xl-n3 {
    margin: -3rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -3rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -3rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -3rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -3rem !important; }
  .m-xl-n4 {
    margin: -4rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -4rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -4rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -4rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -4rem !important; }
  .m-xl-n5 {
    margin: -5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5rem !important; }
  .m-xl-n6 {
    margin: -6rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6rem !important; }
  .m-xl-n7 {
    margin: -7rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important; }
  .m-xl-n8 {
    margin: -8rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important; }
  .m-xl-n9 {
    margin: -9rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important; }
  .m-xl-n10 {
    margin: -10rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #575755 !important; }

.text-muted {
  color: #818a91 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, .fluid-container, .products-listing, .project-detail, .project-detail__finished-photos {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #eceeef; }
  .table .thead-dark th {
    color: inherit;
    border-color: #eceeef; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/*
|--------------------------------------------------------------------------
| Mixins
|
|
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| Antialias
|
| Clean up fonts for webkit and OSX as needed. Typicall this is to be used
| sparingly.
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| Aspect Ratio
|
| Use for images and iframes to work with a fluid width and set height.
| This is pulled directly from http://getbootstrap.com
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| Clearfix
|
| Use this to clear containers. Need for containers of the block-grid elements
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| EM
|
| Function for calcuating pixels to ems quickly.
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| Extends
|
| Objects that can be extended for use inside of blocks
|--------------------------------------------------------------------------
*/
.site-header__location {
  color: #939393; }

.maintenance-add-ons__list, .referral-offer-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 0;
  margin-bottom: 1rem; }
  .maintenance-add-ons__list > li, .referral-offer-list > li {
    padding-left: 30px;
    position: relative; }
    .maintenance-add-ons__list > li:before, .referral-offer-list > li:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 12px;
      border-bottom: 3px solid #b9c882;
      border-left: 3px solid #b9c882;
      position: absolute;
      left: 0;
      top: 5px;
      transform: rotate(-45deg); }

.maintenance-add-ons__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .maintenance-add-ons__list > li {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  .maintenance-add-ons__list > li {
    padding-left: 37.5px; }
    .maintenance-add-ons__list > li:before {
      left: 7.5px; }
  .maintenance-add-ons__list > li {
    padding-left: 52.5px; }
  @media (min-width: 576px) {
    .maintenance-add-ons__list > li {
      padding-left: 45px; }
      .maintenance-add-ons__list > li:before {
        left: 15px; }
    .maintenance-add-ons__list > li {
      padding-left: 60px; } }
  @media (min-width: 768px) {
    .maintenance-add-ons__list > li {
      padding-left: 45px; }
      .maintenance-add-ons__list > li:before {
        left: 15px; }
    .maintenance-add-ons__list > li {
      padding-left: 60px; } }
  @media (min-width: 992px) {
    .maintenance-add-ons__list > li {
      padding-left: 45px; }
      .maintenance-add-ons__list > li:before {
        left: 15px; }
    .maintenance-add-ons__list > li {
      padding-left: 60px; } }
  @media (min-width: 1200px) {
    .maintenance-add-ons__list > li {
      padding-left: 45px; }
      .maintenance-add-ons__list > li:before {
        left: 15px; }
    .maintenance-add-ons__list > li {
      padding-left: 60px; } }

/*
|--------------------------------------------------------------------------
| List Clean
|
| Pull this into blocks and elements as needed. Cleans default styling
| for lists.
|--------------------------------------------------------------------------
*/
.about__section--guarantee ul, .about__section--guarantee ul > li, .careers-listing .BambooHR-ATS-Jobs-Item, .careers-page__team .testimonials__list, .careers-page__team .testimonials__item, .carousel__list, .carousel__item, .community-gallery__list, .community-gallery__item, .community-partners__list, .community-partners__item, .contact-info, .contact-info__data, .design-package-content ul, .design-packages__list, .design-packages__item, .employees__list, .feature-cards__list, .feature-cards__item, .footer-nav__list, .footer-nav__item, .form__field-list, .form__form-group, .gallery__list, .intake-form__field-list, .intake-form__form-group, .irrigation-packages__list, .irrigation-packages__item, .landscapes-listing__list, .landscapes-listing__item, .main-nav__list, .main-nav__item, .maintenance-packages__list, .maintenance-packages__item, .products-listing__list, .products-listing__item, .products-listing__features, .products-listing__feature, .project-detail__gallery__list, .project-detail__finished-photos__list, .projects-listing__list, .site-credit__list, .site-credit__item, .testimonials__list, .testimonials__item {
  list-style: none;
  margin: 0;
  padding: 0; }

@media (min-width: 576px) {
  .feature-cards, .landscapes-listing, .products-listing__list, .promo-page__main {
    position: relative;
    margin-top: -20%;
    padding-top: 20px; }
    .feature-cards:before, .landscapes-listing:before, .products-listing__list:before, .promo-page__main:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding-bottom: 20.5%;
      margin-left: -20px;
      margin-right: -20px;
      background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 100%);
      z-index: 0; }
    .feature-cards > :first-child, .landscapes-listing > :first-child, .products-listing__list > :first-child, .promo-page__main > :first-child {
      position: relative;
      z-index: 1; } }

@media (min-width: 576px) {
  .feature-cards {
    margin-top: -10%; } }

/*
|--------------------------------------------------------------------------
| Base
|
| Basic building blocks, these are similar to blocks, but used more
| globally.
|--------------------------------------------------------------------------
*/
body {
  margin: 0 auto; }

/*
|--------------------------------------------------------------------------
| Buttons
|
| Various button styles that can be used. Apply this class directly
| in HTML. Then BEM syntax can override if needed.
|
| Example:
|
| <div class="block">
|     <a class="block__button button" href=""></a>
| </div>
|
|--------------------------------------------------------------------------
*/
.btn, .about__section--refer-a-friend a, .careers-callout__content-wrap a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.75px; }
  @media (max-width: 374px) {
    .btn, .about__section--refer-a-friend a, .careers-callout__content-wrap a {
      padding-left: 16px;
      padding-right: 16px;
      letter-spacing: 0;
      font-size: 15px; } }
  @media (max-width: 575.98px) {
    .btn, .about__section--refer-a-friend a, .careers-callout__content-wrap a {
      white-space: initial; }
      .btn-lg, .btn-group-lg > .btn, .about__section--refer-a-friend .btn-group-lg > a, .careers-callout__content-wrap .btn-group-lg > a {
        padding-left: 20px;
        padding-right: 20px;
        display: block; } }
  .btn--green {
    background-color: #b9c882;
    border: 2px solid #b9c882;
    color: #464342;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s; }
    .btn--green:hover, .btn--green:focus {
      background-color: #afc070;
      border-color: #afc070;
      outline: 0;
      color: #464342; }
    .btn--green:active {
      background-color: #a5b85f;
      border-color: #a5b85f;
      color: #464342; }
  .btn--red {
    background-color: #d3636b;
    border: 2px solid #d3636b;
    color: white;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s; }
    .btn--red:hover, .btn--red:focus {
      background-color: #cd4f58;
      border-color: #cd4f58;
      outline: 0;
      color: white; }
    .btn--red:active {
      background-color: #c83b45;
      border-color: #c83b45;
      color: white; }
  .btn--cta, .landscapes-listing__link {
    background-color: #d3636b;
    border: 2px solid #d3636b;
    color: white;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s; }
    .btn--cta:hover, .landscapes-listing__link:hover, .btn--cta:focus, .landscapes-listing__link:focus {
      background-color: #cd4f58;
      border-color: #cd4f58;
      outline: 0;
      color: white; }
    .btn--cta:active, .landscapes-listing__link:active {
      background-color: #c83b45;
      border-color: #c83b45;
      color: white; }
  .btn--black, .about__section--refer-a-friend a, .careers-callout__content-wrap a {
    background-color: #464342;
    border: 2px solid #464342;
    color: #fff;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s; }
    .btn--black:hover, .about__section--refer-a-friend a:hover, .careers-callout__content-wrap a:hover, .btn--black:focus, .about__section--refer-a-friend a:focus, .careers-callout__content-wrap a:focus {
      background-color: #393636;
      border-color: #393636;
      outline: 0;
      color: #fff; }
    .btn--black:active, .about__section--refer-a-friend a:active, .careers-callout__content-wrap a:active {
      background-color: #2c2a29;
      border-color: #2c2a29;
      color: #fff; }
  .btn--white {
    background-color: #fff;
    border: 2px solid #fff;
    color: #464342;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s; }
    .btn--white:hover, .btn--white:focus {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
      outline: 0;
      color: #464342; }
    .btn--white:active {
      background-color: #e6e6e6;
      border-color: #e6e6e6;
      color: #464342; }
  .btn--black-outline {
    background-color: transparent;
    border: 2px solid #353535;
    color: #353535;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s;
    line-height: 0.8;
    padding-top: 1.015rem;
    padding-bottom: 1.015rem; }
    .btn--black-outline:hover, .btn--black-outline:focus {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: #282828;
      outline: 0;
      color: #353535; }
    .btn--black-outline:active {
      background-color: rgba(0, 0, 0, 0.4);
      border-color: #1c1c1c;
      color: #353535; }
  .btn--white-outline {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s;
    line-height: 0.8;
    padding-top: 1.015rem;
    padding-bottom: 1.015rem; }
    .btn--white-outline:hover, .btn--white-outline:focus {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: #f2f2f2;
      outline: 0;
      color: #fff; }
    .btn--white-outline:active {
      background-color: rgba(0, 0, 0, 0.4);
      border-color: #e6e6e6;
      color: #fff; }
  .btn--blue-outline {
    background-color: transparent;
    border: 2px solid #006a7c;
    color: #006a7c;
    transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s;
    line-height: 0.8;
    padding-top: 1.015rem;
    padding-bottom: 1.015rem; }
    .btn--blue-outline:hover, .btn--blue-outline:focus {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: #005463;
      outline: 0;
      color: #006a7c; }
    .btn--blue-outline:active {
      background-color: rgba(0, 0, 0, 0.4);
      border-color: #003e49;
      color: #006a7c; }

/* ==========================================================================
   Responsivie Debugging
   ========================================================================== */
body.development:after {
  bottom: 2px;
  position: fixed;
  right: 2px;
  z-index: 3000;
  padding: 2px 8px;
  background: #000;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  opacity: .5;
  text-align: center;
  content: 'xs'; }
  @media (min-width: 576px) {
    body.development:after {
      content: 'sm'; } }
  @media (min-width: 768px) {
    body.development:after {
      content: 'md'; } }
  @media (min-width: 992px) {
    body.development:after {
      content: 'lg'; } }
  @media (min-width: 1200px) {
    body.development:after {
      content: 'xl'; } }

/*
|--------------------------------------------------------------------------
| Icons
|
| Icons are generated using https://icomoon.io/app
| If you want to add more icons, you can generate a new set of font files
| from the icomoon app.
|--------------------------------------------------------------------------
*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?8c0e9r");
  src: url("../fonts/icomoon.eot?8c0e9r#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?8c0e9r") format("truetype"), url("../fonts/icomoon.woff?8c0e9r") format("woff"), url("../fonts/icomoon.svg?8c0e9r#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'contact_form';
  src: url("../fonts/contact_form.eot?-6n3m7o");
  src: url("../fonts/contact_form.eot?#iefix-6n3m7o") format("embedded-opentype"), url("../fonts/contact_form.woff?-6n3m7o") format("woff"), url("../fonts/contact_form.ttf?-6n3m7o") format("truetype"), url("../fonts/contact_form.svg?-6n3m7o#contact_form") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

img {
  height: auto;
  max-width: 100%; }

.rounded-edge {
  border-radius: 10px; }

.shadow-edge {
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15); }

/*
|--------------------------------------------------------------------------
| Links
|
| Basic link class that can be used across the site
|--------------------------------------------------------------------------
*/
a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s; }
  a:hover, a:focus, a:active {
    text-decoration: none; }

.link:after {
  content: '›';
  display: inline-block;
  margin-left: 5px; }

.link--email:before {
  font-family: "Icomoon";
  font-style: normal;
  font-size: 1.5em;
  font-weight: normal;
  font-variant: normal;
  font-display: swap;
  speak: none;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: transform .1s ease-out;
  transform: translateZ(0);
  content: "";
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem; }
  .link--email:before:hover {
    color: inherit;
    transform: scale(1.1);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none; }

.link--email:after {
  content: ""; }

table th {
  padding: 0.5rem;
  border-bottom: 1px solid #5bc0de; }

table td {
  padding: 0.5rem;
  border-bottom: 1px solid #5bc0de; }

/*
|--------------------------------------------------------------------------
| Base Typography
|
| These styles are used to set a base style for all elements. Only use
| these properties here:
|
| color:
| font-family:
| font-size:
| line-height:
| margin:
|
|--------------------------------------------------------------------------
*/
h1, h2, h3, h4, h5 {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif; }
  h1.blue, h2.blue, h3.blue, h4.blue, h5.blue {
    color: #50928b; }
  h1.blue-light, h2.blue-light, h3.blue-light, h4.blue-light, h5.blue-light {
    color: #7fc3ba; }
  h1.pink, h2.pink, h3.pink, h4.pink, h5.pink {
    color: #d3636b; }

h1, .h1 {
  font-weight: 800;
  color: #464342;
  margin-bottom: 1rem; }
  @media (max-width: 1199.98px) {
    h1, .h1 {
      font-size: calc(3.75rem/1.75 + 2vw); } }

h2, .h2 {
  font-weight: bold;
  color: #464342;
  margin-bottom: 1rem; }
  @media (max-width: 1199.98px) {
    h2, .h2 {
      font-size: calc(3.125rem/1.3 + 1.5vw); } }

h3, .h3 {
  font-weight: normal; }
  @media (max-width: 1199.98px) {
    h3, .h3 {
      font-size: calc(2.5rem/2 + 1vw); } }

h4, .h4 {
  font-weight: normal;
  margin-bottom: 0.5em; }
  @media (max-width: 1199.98px) {
    h4, .h4 {
      font-size: calc(1.875rem/1.325 + 1vw); } }

h5, .h5 {
  font-weight: bold; }
  @media (max-width: 1199.98px) {
    h5, .h5 {
      font-size: calc(1.5rem/1.4 + 1vw); } }

h6, .h6 {
  font-weight: bold; }

p {
  line-height: 1.875em;
  margin-bottom: 2em; }

ul {
  margin-bottom: 1.5em;
  margin-left: 0;
  padding-left: 1.1em; }

li {
  margin-bottom: 0.5em;
  padding-left: 0.75em; }

i, em {
  font-size: .9375%;
  font-weight: 300; }

.font-bold {
  font-weight: bold !important; }

.label {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: bold; }
  .label.blue {
    color: #7fc3ba; }
  .label.blue-dark {
    color: #50928b; }
  .label.gray {
    color: #939393; }
  .label.red {
    color: #d3636b; }
  .label.orange {
    color: #eca688; }

.heading {
  font-weight: bolder;
  line-height: 1.1;
  font-size: 3.5em; }
  @media (max-width: 991.98px) {
    .heading {
      font-size: 3em; } }
  .heading.lg {
    font-size: 3em; }
  .heading.blue {
    color: #50928b; }
  .heading.blue-light {
    color: #7fc3ba; }
  .heading.pink {
    color: #d3636b; }
  .heading.gray {
    color: #939393; }

.body-text {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  color: #939393;
  font-size: 1.2em; }

/*
|--------------------------------------------------------------------------
| Blocks
|
| Each block should be saved in it’s own file.
|--------------------------------------------------------------------------
*/
.about__section--main {
  font-size: 1.25rem;
  margin-bottom: 2rem; }
  .about__section--main h2 {
    color: #50928b; }
  .about__section--main h4 {
    font-size: inherit;
    font-weight: bold;
    color: #7fc3ba;
    text-transform: uppercase; }

.about__section--guarantee h2 {
  padding-top: 4rem; }

.about__section--guarantee h3 {
  font-weight: bold; }

.about__section--guarantee ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .about__section--guarantee ul > li {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1.5rem; }
    @media (min-width: 576px) {
      .about__section--guarantee ul > li {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .about__section--guarantee ul > li {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
    .about__section--guarantee ul > li strong {
      font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
      font-size: 1.5rem;
      color: #006a7c;
      display: block; }

.about__section--custom {
  background: #f4f4f4;
  padding-bottom: 2rem; }

.about__section--our-team {
  padding-top: calc(3rem + 4vh);
  margin-bottom: calc(2rem + 2vh); }

.about__section--refer-a-friend {
  background-color: #7fc3ba;
  color: #353535; }
  .about__section--refer-a-friend h3 {
    margin-bottom: 1rem; }

.about__content-wrap {
  padding-top: calc(1rem + 4vh);
  margin-bottom: calc(1rem + 2vh); }

.about__section--refer-a-friend .about__content-wrap {
  text-align: center; }
  @media (min-width: 768px) {
    .about__section--refer-a-friend .about__content-wrap {
      text-align: right; } }

.about__section--guarantee .about__content-wrap {
  position: relative; }

.about__flower {
  position: absolute;
  max-width: 50%; }
  .about__flower--1 {
    bottom: 0;
    right: 0;
    transform: translate(20%, 55%); }
  .about__flower--2 {
    position: relative;
    margin-top: -37%; }
    @media (min-width: 768px) {
      .about__flower--2 {
        max-width: 275px;
        margin-top: -55%; } }
  .about__flower--3 {
    top: 0;
    right: 0;
    transform: translate(20%, -42%);
    z-index: 2; }

.about__branch {
  width: 320px;
  max-width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-23%, 62%);
  z-index: -1; }
  @media (min-width: 768px) {
    .about__branch {
      max-width: 75%; } }
  @media (min-width: 1200px) {
    .about__branch {
      transform: translate(-50%, 62%); } }

.aftercare {
  padding-bottom: 40px; }
  .aftercare__banner .page-banner__intro {
    padding-right: 0; }
  .aftercare__headings {
    text-align: center;
    max-width: 700px;
    margin: 0 auto 40px auto; }
    .aftercare__headings h4 {
      color: #006a7c; }
    .aftercare__headings .body-text p {
      line-height: 1.5; }
    .aftercare__headings .body-text strong, .aftercare__headings .body-text b {
      color: #575755; }
    .aftercare__headings p {
      margin-bottom: 15px; }
  .aftercare__intro {
    margin-top: 40px;
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px; }
    @media (max-width: 991.98px) {
      .aftercare__intro {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    .aftercare__intro .aftercare__headings {
      text-align: left;
      max-width: auto;
      margin-bottom: 10px; }
      .aftercare__intro .aftercare__headings h3 {
        margin-bottom: 20px; }
    .aftercare__intro-content {
      padding-right: 40px; }
    .aftercare__intro-profile {
      display: flex;
      align-items: center; }
      .aftercare__intro-profile-img {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 100px;
        overflow: hidden;
        margin-right: 20px; }
        .aftercare__intro-profile-img img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .aftercare__intro .signature {
      width: 300px; }
  .aftercare__gradient {
    padding-top: 60px;
    background: linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(244, 244, 244, 0) 100%); }
  .aftercare .guarantee {
    background-color: transparent; }
  .aftercare__download {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    margin-bottom: 40px; }
    @media (max-width: 991.98px) {
      .aftercare__download {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    .aftercare__download--card {
      text-align: center;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
      padding: 40px; }
      .aftercare__download--card .label {
        margin-bottom: 10px; }
      .aftercare__download--card h3 {
        font-weight: bold;
        font-size: 1.75em;
        margin-bottom: 15px; }
      .aftercare__download--card .btn, .aftercare__download--card .about__section--refer-a-friend a, .about__section--refer-a-friend .aftercare__download--card a, .aftercare__download--card .careers-callout__content-wrap a, .careers-callout__content-wrap .aftercare__download--card a {
        margin-top: 15px; }
      .aftercare__download--card a:hover {
        color: #575755; }
      .aftercare__download--card:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        transition: all ease .2s;
        transform: translateY(-1px);
        cursor: pointer; }
  .aftercare__packages {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    margin-bottom: 100px; }
    @media (max-width: 991.98px) {
      .aftercare__packages {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    .aftercare__packages-box {
      display: grid;
      padding: 50px;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
      @media (max-width: 991.98px) {
        .aftercare__packages-box {
          padding: 30px; } }
      .aftercare__packages-box.blue {
        background-color: #d8ece9; }
      .aftercare__packages-box h3 {
        font-weight: bold; }
      .aftercare__packages-box .bold {
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 15px; }
      .aftercare__packages-box ul {
        margin-bottom: 0;
        list-style: none; }
        .aftercare__packages-box ul li::before {
          content: "\2022";
          color: #b9c882;
          font-weight: bold;
          display: inline-block;
          font-size: 16px;
          width: 1.75em;
          margin-left: -1.7em; }
    .aftercare__packages-button {
      padding: 60px 0;
      text-align: center; }
  .aftercare__services .home__services-section {
    padding-top: 0; }

.article-box {
  position: relative;
  margin-top: 2rem; }
  @media (min-width: 768px) {
    .article-box {
      margin-bottom: 75px; } }
  .article-box__wrap {
    background-color: #f4f4f4;
    display: inline-block;
    padding: 2rem 1.5rem;
    text-align: left; }
    @media (min-width: 576px) {
      .article-box__wrap {
        padding-right: 5rem; } }
  .article-box__title {
    margin-bottom: 1rem; }
  .article-box__list {
    margin-left: 0;
    padding-left: 1.25em;
    font-size: 1.25rem; }
  .article-box__icon {
    display: none;
    max-width: 50%;
    position: absolute;
    bottom: 0;
    right: 1.5rem;
    transform: translateY(50%); }
    @media (min-width: 768px) {
      .article-box__icon {
        display: block; } }
    @media (min-width: 992px) {
      .article-box__icon--gloves {
        bottom: -30px; } }
    @media (min-width: 1200px) {
      .article-box__icon--gloves {
        bottom: -50px; } }

.article__body {
  color: #464342;
  padding-top: calc(1rem + 4vh);
  margin-bottom: calc(2rem + 5vh); }
  @media (min-width: 768px) {
    .article__body {
      padding-top: calc(3rem + 4vh); } }

.article__pl40 {
  padding-left: 0px; }
  @media (min-width: 768px) {
    .article__pl40 {
      padding-left: 40px; } }

.article__category {
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #353535; }

.article__intro {
  font-size: 1.25rem; }

.automower-faq__wrap {
  padding: 60px 0;
  background-color: white; }
  @media (min-width: 992px) {
    .automower-faq__wrap {
      padding: 100px 0; } }

.automower-faq__heading {
  margin-bottom: 1em;
  text-align: center; }

@media (min-width: 768px) {
  .automower-faq__list {
    columns: 2;
    page-break-inside: avoid; } }

.automower-faq__item {
  position: relative;
  padding-left: 50px;
  padding-top: 5px; }
  .automower-faq__item strong {
    display: block; }

.automower-faq__digit {
  left: 5px;
  position: absolute;
  top: 5px;
  height: 1.65em;
  width: 1.65em;
  font-size: 20px;
  border: 1px solid #b9c882;
  border-radius: 50%;
  color: #b9c882;
  text-align: center; }

.automower-value {
  position: relative;
  margin-bottom: 60px; }
  @media (min-width: 992px) {
    .automower-value {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .automower-value:nth-child(2) .automower-value__copy {
    order: 2; }
  @media (min-width: 992px) {
    .automower-value:last-child {
      padding-bottom: 60px; } }
  .automower-value__wrap {
    padding-top: 30px; }
    @media (min-width: 576px) {
      .automower-value__wrap {
        background-color: #fff; } }
    @media (min-width: 992px) {
      .automower-value__wrap {
        background-color: transparent;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), white 150px); } }
  .automower-value__copy h3 {
    font-weight: bold; }
  @media (min-width: 992px) {
    .automower-value__copy {
      width: calc(50% - 15px); } }
  .automower-value__image-wrap {
    position: relative; }
    @media (min-width: 992px) {
      .automower-value__image-wrap {
        width: calc(50% - 15px); } }
  .automower-value__image {
    border-radius: 10px; }
  .automower-value__flower {
    display: none; }
    @media (min-width: 992px) {
      .automower-value__flower {
        top: -120px;
        right: -70px;
        position: absolute;
        width: 250px;
        right: -90px;
        width: 300px;
        display: block; } }

.automowers-landing-form-wrapper {
  width: 100%; }
  @media (min-width: 992px) {
    .automowers-landing-form-wrapper {
      position: relative;
      margin-top: -220px;
      background-color: #fff; } }
  @media (min-width: 992px) {
    .automowers-landing-form-wrapper {
      margin-bottom: 80px;
      margin-top: -400px; } }

.awards__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.awards__item {
  padding: 0;
  margin: 0 20px 15px; }

.awards__heading {
  text-align: center;
  font-weight: bold;
  font-size: 24px; }

.backyard h3.heading {
  color: #eca688; }

.backyard__gallery {
  text-align: center;
  color: #353535;
  padding-top: calc(2rem + 2vh);
  margin-bottom: calc(1rem + 2vh); }

.backyard__gallery-title {
  margin-bottom: calc(1rem + 2vh); }

.bg-color--green {
  background-color: #b9c882; }

.bg-color--blue {
  background-color: #006a7c; }

.bg-color--blue-dark {
  background-color: #272f39; }

.bg-color--green-dark {
  background-color: #8fb04e; }

.bg-color--teal {
  background-color: #50928b; }

.bg-color--teal-light {
  background-color: #7fc3ba; }

.bg-color--orange {
  background-color: #eca688; }

.bg-color--red {
  background-color: #d3636b; }

.bg-color--yellow {
  background-color: #eadf6e; }

.bg-color--black {
  background-color: #353535; }

.bg-color--gray-dark {
  background-color: #464342; }

.bg-color--gray {
  background-color: #939393; }

.bg-color--gray-medium {
  background-color: #575755; }

.bg-color--gray-light {
  background-color: #bbbdc0; }

.bg-color--gray-lighter {
  background-color: #f4f4f4; }

.blog-article-card {
  position: relative;
  display: block;
  height: 100%;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  transition: all .2s ease-in;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
  .blog-article-card:hover {
    color: #464342;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: all ease .2s;
    transform: translateY(-1px);
    cursor: pointer; }
  .blog-article-card__feature-image-wrap {
    position: relative;
    display: block; }
    .blog-article-card__feature-image-wrap img {
      border-radius: 10px 10px 0 0; }
  .blog-article-card__author-photo {
    bottom: 0;
    left: 50%;
    position: absolute;
    width: 80px;
    border-radius: 50% !important;
    transform: translate(-50%, 50%); }
  .blog-article-card__title {
    color: #575755;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    transition: all .1s ease-in;
    padding-left: 20px;
    padding-right: 20px; }
    .blog-article-card__title a:hover {
      color: #939393; }
  .blog-article-card__metadata {
    color: #575755;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    padding-top: 58px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
  .blog-article-card__content {
    padding: 20px; }

.blog-post__title-link {
  text-decoration: none; }
  .blog-post__title-link:hover, .blog-post__title-link:focus {
    outline: 0;
    text-decoration: none; }

.blog-post__download {
  display: flex;
  justify-content: space-between;
  text-decoration: none; }
  .blog-post__download:hover, .blog-post__download:focus {
    outline: 0;
    text-decoration: none; }

.blog-post__date {
  display: block; }

.blog-header {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  background: #ccc;
  color: #fff;
  text-decoration: none;
  text-align: center; }
  @media (min-width: 768px) {
    .blog-header {
      margin-bottom: 180px; } }
  .blog-header__image-wrap {
    overflow: hidden; }
    @media (min-width: 576px) {
      .blog-header__image-wrap {
        max-height: calc(90vh - 150px);
        min-height: 260px; } }
  .blog-header__image {
    display: block;
    height: auto;
    margin-bottom: 0;
    max-width: 100%; }
  .blog-header__copy {
    position: relative;
    border-radius: 10px 10px 0 0;
    background-color: rgba(255, 255, 255, 0.9);
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.9) 100px, rgba(255, 255, 255, 0));
    border-top: 6px solid white; }
    @media (min-width: 768px) {
      .blog-header__copy {
        bottom: -180px;
        left: 50%;
        position: absolute;
        z-index: 2;
        transform: translateX(-50%);
        width: 690px; } }
    @media (min-width: 992px) {
      .blog-header__copy {
        width: 930px; } }
    @media (min-width: 1200px) {
      .blog-header__copy {
        width: 1160px; } }
  .blog-header__heading {
    margin-bottom: 20px;
    margin-top: 14px;
    width: 100%;
    line-height: 1.2;
    transition: all .2s ease-out; }
    .blog-header__heading a:hover {
      color: #b9c882; }
  .blog-header__button {
    margin: 0 auto;
    background-color: #939393;
    color: #f8f8f8; }
    .blog-header__button:hover, .blog-header__button:focus {
      background-color: #c6c6c6;
      color: #fff; }
  .blog-header__category {
    position: relative;
    z-index: 2;
    display: block;
    padding-bottom: 8px;
    padding-top: 10px;
    background-color: #dfe9e8;
    color: #353535;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase; }
  .blog-header__author-name {
    padding-right: 20px;
    font-size: 12px;
    text-transform: uppercase;
    color: #373a3c; }
  .blog-header__author-photo {
    left: 50%;
    position: absolute;
    top: -50px;
    z-index: 2;
    width: 100px;
    font-size: 12px;
    border-radius: 50%;
    transform: translateX(-50%); }
  .blog-header__metadata {
    position: relative;
    margin-top: -16px;
    padding-bottom: 20px;
    padding-top: 58px;
    background-image: url(../img/icons/branches/small-branch@2x.png);
    background-repeat: no-repeat;
    background-position: center calc(50% + 14px);
    background-size: 128px auto;
    color: #353535;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 10px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .blog-header__metadata {
        margin-top: -36px; } }

.blog-post-list {
  background-color: #fff; }
  .blog-post-list__link {
    display: block;
    text-decoration: none; }
    .blog-post-list__link:hover, .blog-post-list__link:focus {
      outline: 0;
      text-decoration: none; }
  .blog-post-list__date {
    font-size: 12px; }

.blog-post {
  overflow: hidden; }
  .blog-post--full {
    margin: 0 auto;
    max-width: 1160px;
    padding: 0 15px 40px;
    border-radius: 0 0 10px 10px;
    background-color: #fff; }
    @media (min-width: 576px) {
      .blog-post--full {
        padding: 0 30px 40px; } }
    @media (min-width: 1200px) {
      .blog-post--full {
        padding: 0 60px 60px; } }
  .blog-post__body:first-child h2:first-child + p,
  .blog-post__body:first-child h3:first-child + p,
  .blog-post__body:first-child p:first-child {
    font-size: 150%;
    margin-bottom: 20px; }
  .blog-post__body i {
    font-size: inherit; }
  .blog-post__body a {
    text-decoration: underline; }
  .blog-post__body h2 {
    font-size: 36px; }
  .blog-post__body h3 {
    font-size: 24px; }
  .blog-post__body blockquote {
    max-width: 900px;
    margin-left: 5%;
    margin-bottom: 3%;
    margin-top: 3%;
    padding-left: 2%;
    padding-bottom: 2%;
    padding-top: 2%;
    border-left: 4px solid #ccc;
    font-size: 150%; }
  .blog-post__body h3 {
    margin-top: 44px;
    font-size: 30px; }

.blog-wrap {
  background-image: linear-gradient(#fff 0px, rgba(80, 146, 139, 0.8) 0px, #aad1cd), url(../img/icons/branches/4.png), linear-gradient(#fff 0px, #50928b 0px, #aad1cd);
  background-repeat: no-repeat;
  background-blend-mode: normal screen normal;
  background-position: center top, calc(50% - 300px) 380px, center top; }
  @media (min-width: 1200px) {
    .blog-wrap {
      background-position: center top, calc(50% - 700px) 640px, center top; } }
  .blog-wrap__radius {
    border-radius: 10px; }
  .blog-wrap__white {
    background-color: white; }

.blueridge__section.featured-projects-callout {
  padding-top: 60px; }
  .blueridge__section.featured-projects-callout .page-hero__category {
    color: #575755;
    font-size: 14px;
    text-shadow: none; }
  .blueridge__section.featured-projects-callout #services-subsection h2 {
    color: #d3636b;
    padding-bottom: 25px; }

.career-gallery__intro {
  color: #F6F6F6;
  margin: auto;
  max-width: 930px;
  text-align: center; }

.career-gallery__intro h1 {
  color: #F6F6F6;
  margin: 30px auto 60px;
  max-width: 600px; }

.career-gallery__gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.career-gallery__more {
  display: block;
  margin-top: 20px;
  width: 100%;
  text-align: center; }

.career-gallery__img {
  width: calc(25% - 15px);
  margin-bottom: 20px; }
  @media (max-width: 767.98px) {
    .career-gallery__img {
      width: calc(50% - 10px); } }
  .career-gallery__img:first-of-type {
    width: 100%; }

.career-gallery__img-link {
  outline: none; }

.career-gallery__img.hidden {
  display: none; }

.career-gallery__intro-bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 250px; }
  @media (min-width: 992px) {
    .career-gallery__intro-bg {
      top: -30px; } }
  @media (min-width: 1200px) {
    .career-gallery__intro-bg {
      width: 350px; } }

#career-gallery__more {
  display: inline;
  position: relative;
  color: #6BC2BB;
  text-transform: uppercase;
  cursor: pointer; }
  #career-gallery__more.less {
    margin: 1.875em 0 0; }
  #career-gallery__more.less::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border-bottom: 3px solid #6BC2BB;
    border-left: 3px solid #6BC2BB;
    position: absolute;
    left: 50%;
    top: -15px;
    transform: translateX(-50%) rotate(135deg); }
  #career-gallery__more.less .span-more {
    display: none; }
  #career-gallery__more.more {
    margin: 0 0 1.875em; }
  #career-gallery__more.more::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border-bottom: 3px solid #6BC2BB;
    border-left: 3px solid #6BC2BB;
    position: absolute;
    left: 50%;
    top: 25px;
    transform: translateX(-50%) rotate(-45deg); }
  #career-gallery__more.more .span-less {
    display: none; }

.careers__banner {
  height: 440px;
  background-image: linear-gradient(150deg, transparent, #50928b 120%), url(../img/careers/careers-banner-bg@2x.jpg);
  background-size: cover;
  position: relative; }
  @media (min-width: 576px) {
    .careers__banner {
      height: 650px; } }
  @media (min-width: 1200px) {
    .careers__banner {
      height: 800px; } }
  .careers__banner__heading.initial {
    opacity: 0; }
  .careers__banner__heading {
    position: relative;
    bottom: 127px;
    height: 243px;
    z-index: 1; }
    @media (min-width: 576px) {
      .careers__banner__heading {
        height: 400px; } }
    @media (min-width: 1200px) {
      .careers__banner__heading {
        height: 500px; } }
  .careers__banner__img {
    height: 390px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-155px);
    transition: opacity 0.2s ease-out; }
    @media (min-width: 576px) {
      .careers__banner__img {
        height: 550px;
        transform: translateX(-225px); } }
    @media (min-width: 1200px) {
      .careers__banner__img {
        height: 670px;
        transform: translateX(-270px); } }
  .careers__banner__img.hidden {
    opacity: 0; }
  .careers__banner__img img {
    max-height: 100%;
    width: auto;
    max-width: none; }
  .careers__banner__img-text {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  .careers__banner__h1 {
    color: #F2F2F2;
    font-size: 120px;
    line-height: 0.8;
    height: 90px;
    width: 300px;
    position: absolute;
    left: 50%;
    text-transform: uppercase;
    text-align: left;
    transform: translateX(-50%);
    transition: opacity 0.3s ease-in; }
    @media (min-width: 576px) {
      .careers__banner__h1 {
        font-size: 200px;
        height: 160px;
        width: 490px; } }
    @media (min-width: 1200px) {
      .careers__banner__h1 {
        font-size: 220px;
        width: 560px; } }
  .careers__banner__h1.hidden {
    opacity: 0; }
  .careers__banner__h1--small {
    color: #F2F2F2;
    font-size: 60px;
    line-height: 0.8;
    text-transform: uppercase;
    text-align: center;
    height: 96px; }
    @media (min-width: 576px) {
      .careers__banner__h1--small {
        font-size: 110px; } }
    @media (min-width: 1200px) {
      .careers__banner__h1--small {
        font-size: 130px; } }
  .careers__banner__h1 .h1--first-span {
    margin-left: 70px; }
    @media (min-width: 576px) {
      .careers__banner__h1 .h1--first-span {
        margin-left: 110px; } }
    @media (min-width: 1200px) {
      .careers__banner__h1 .h1--first-span {
        margin-left: 144px; } }
  .careers__banner__h1--small.h1--second {
    width: 290px;
    position: absolute;
    top: 105px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left; }
    @media (max-width: 575.98px) {
      .careers__banner__h1--small.h1--second {
        font-size: 68px; } }
    @media (min-width: 576px) {
      .careers__banner__h1--small.h1--second {
        top: 175px;
        width: 470px; } }
    @media (min-width: 1200px) {
      .careers__banner__h1--small.h1--second {
        width: 555px; } }
  .careers__banner__h1--small.typewrite--repeat {
    position: absolute;
    top: 170px;
    left: 50%;
    transform: translateX(-50%); }
    @media (min-width: 576px) {
      .careers__banner__h1--small.typewrite--repeat {
        top: 285px; } }
  .careers__banner__h1--small.typewrite--repeat.hidden {
    opacity: 0; }
  .careers__banner__h1--small.typewrite--repeat .repeat--line1,
  .careers__banner__h1--small.typewrite--repeat .repeat--line2,
  .careers__banner__h1--small.typewrite--repeat .repeat--line3 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .careers__banner__btn-link {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    outline: none; }
    @media (min-width: 576px) {
      .careers__banner__btn-link {
        bottom: 60px; } }
    @media (min-width: 1200px) {
      .careers__banner__btn-link {
        bottom: 120px; } }
  .careers__banner__btn-link.hidden {
    opacity: 0; }
  .careers__banner__btn {
    background: #d3636a;
    border: none;
    border-radius: 38px;
    color: white;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 10px 28px 6px;
    text-transform: none;
    cursor: pointer; }
    .careers__banner__btn:hover, .careers__banner__btn:focus {
      background: #b6333d;
      outline: 0; }
    @media (min-width: 576px) {
      .careers__banner__btn {
        font-size: 22px;
        padding: 10px 50px 6px; } }

.letter-span,
.letter-repeat {
  opacity: 1;
  transition: opacity .4s ease-out; }
  .letter-span.hidden,
  .letter-repeat.hidden {
    opacity: 0; }

.careers-callout {
  background-size: cover;
  overflow: hidden;
  background-image: url("../img/home/careers-bg.jpg"); }
  @media (max-width: 575.98px) {
    .careers-callout {
      text-align: center; } }
  .careers-callout__image {
    max-width: 275px; }
    @media (max-width: 575.98px) {
      .careers-callout__image {
        width: 50%;
        margin-bottom: -19%; } }
  @media (min-width: 768px) {
    .careers-callout__content-wrap {
      padding-top: 3rem;
      margin-bottom: 3rem; } }
  .careers-callout__content-wrap a {
    min-width: 230px; }

.careers-form__heading {
  font-size: 50px;
  font-weight: bold; }

.careers-form__img-wrap, .careers-form__img-wrap--opposite, .careers-form__img-wrap--second, .careers-form__img-wrap--second--opposite, .careers-form__img-wrap--flower, .careers-form__img-wrap--flower--opposite {
  z-index: 2;
  height: 440px;
  position: absolute;
  bottom: 0;
  left: 0; }
  @media (max-width: 767.98px) {
    .careers-form__img-wrap, .careers-form__img-wrap--opposite, .careers-form__img-wrap--second, .careers-form__img-wrap--second--opposite, .careers-form__img-wrap--flower, .careers-form__img-wrap--flower--opposite {
      display: none; } }
  @media (min-width: 992px) {
    .careers-form__img-wrap, .careers-form__img-wrap--opposite, .careers-form__img-wrap--second, .careers-form__img-wrap--second--opposite, .careers-form__img-wrap--flower, .careers-form__img-wrap--flower--opposite {
      height: 540px; } }

.careers-form__img-wrap, .careers-form__img-wrap--opposite {
  height: 430px;
  left: -80px; }
  @media (min-width: 992px) {
    .careers-form__img-wrap, .careers-form__img-wrap--opposite {
      height: 500px;
      left: -50px; } }

.careers-form__img-wrap--second, .careers-form__img-wrap--second--opposite {
  z-index: 1;
  height: 497px;
  left: 90px; }
  @media (min-width: 992px) {
    .careers-form__img-wrap--second, .careers-form__img-wrap--second--opposite {
      height: 601px;
      left: 130px; } }
  @media (min-width: 1200px) {
    .careers-form__img-wrap--second, .careers-form__img-wrap--second--opposite {
      left: 210px; } }

.careers-form__img-wrap--flower, .careers-form__img-wrap--flower--opposite {
  height: 180px;
  left: -95px;
  z-index: 3; }
  @media (min-width: 992px) {
    .careers-form__img-wrap--flower, .careers-form__img-wrap--flower--opposite {
      bottom: 35px; } }
  @media (min-width: 1200px) {
    .careers-form__img-wrap--flower, .careers-form__img-wrap--flower--opposite {
      left: -125px; } }

.careers-form__img-wrap--opposite {
  height: 410px;
  left: -85px; }
  @media (min-width: 992px) {
    .careers-form__img-wrap--opposite {
      height: 500px;
      left: -30px; } }
  @media (min-width: 1200px) {
    .careers-form__img-wrap--opposite {
      left: 30px; } }

@media (min-width: 992px) {
  .careers-form__img-wrap--second--opposite {
    left: 200px; } }

@media (min-width: 1200px) {
  .careers-form__img-wrap--second--opposite {
    left: 250px; } }

.careers-form__img-wrap--flower--opposite {
  left: auto;
  right: -60px;
  bottom: 10px; }
  @media (min-width: 992px) {
    .careers-form__img-wrap--flower--opposite {
      right: -30px; } }

.careers-form__img {
  height: 100%;
  max-width: none;
  width: auto;
  max-height: 100%; }

.careers-form__form {
  margin-bottom: 40px;
  z-index: 10; }

.careers-listing__title {
  color: #50928D;
  text-align: center; }

.careers-listing #BambooHR-Footer {
  display: none !important; }

.careers-listing .BambooHR-ATS-board {
  color: #575855;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px; }
  .careers-listing .BambooHR-ATS-board h2 {
    display: none !important; }
  @media (max-width: 991.98px) {
    .careers-listing .BambooHR-ATS-board {
      font-size: 20px; } }

.careers-listing .BambooHR-ATS-Department-Header {
  font-size: 20px; }

.careers-listing .BambooHR-ATS-Department-Item {
  margin: 0;
  padding: 0; }

.careers-listing .BambooHR-ATS-Jobs-List {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0; }

.careers-listing .BambooHR-ATS-Jobs-Item {
  border-top: 1px solid #939393;
  display: flex;
  justify-content: space-between;
  align-items: baseline; }

.careers-listing .BambooHR-ATS-Jobs-Item a {
  display: inline-block;
  padding: 1rem 0;
  text-decoration: none;
  color: #006A7C;
  font-size: 18px; }

.careers-listing__aside {
  margin-top: 0; }
  @media (min-width: 992px) {
    .careers-listing__aside {
      margin-left: 50px; } }
  @media (min-width: 1200px) {
    .careers-listing__aside {
      margin-left: 100px; } }

.careers-listing__aside-wrap {
  background-color: #006A7C;
  color: white; }
  @media (max-width: 575.98px) {
    .careers-listing__aside-wrap {
      padding: 2rem 1rem; } }
  @media (min-width: 768px) {
    .careers-listing__aside-wrap {
      padding-right: 2em; } }
  @media (min-width: 992px) {
    .careers-listing__aside-wrap {
      padding-right: 5em; } }

.careers-listing__aside-img {
  width: 165px;
  position: absolute;
  top: -10px;
  right: -80px; }
  @media (max-width: 575.98px) {
    .careers-listing__aside-img {
      width: 140px; } }
  @media (max-width: 767.98px) {
    .careers-listing__aside-img {
      right: -50px; } }
  @media (min-width: 992px) {
    .careers-listing__aside-img {
      width: 200px; } }

.careers-listing__aside-wrap h3 {
  margin: 25px 10px; }
  @media (max-width: 575.98px) {
    .careers-listing__aside-wrap h3 {
      margin-right: 70px; } }
  @media (min-width: 768px) {
    .careers-listing__aside-wrap h3 {
      margin: 25px 50px 25px 10px; } }
  @media (min-width: 992px) {
    .careers-listing__aside-wrap h3 {
      margin: 25px 40px 25px 10px; } }

.careers-listing__aside-wrap ul {
  margin-left: 16px; }
  @media (max-width: 575.98px) {
    .careers-listing__aside-wrap ul {
      margin-left: 0; } }

.careers-listing__aside-wrap li {
  font-weight: 200;
  padding-left: 5px; }

.careers-page__center {
  margin: 0 auto 70px;
  max-width: 650px;
  text-align: center; }
  .careers-page__center h2 {
    color: #786F6C; }
  .careers-page__center h3 {
    color: #786F6C;
    font-weight: bold;
    margin-bottom: 20px; }

.careers-page__h3 {
  color: #786F6C;
  font-size: 3rem;
  font-weight: bold; }

.careers-page__intro {
  margin-top: 70px;
  margin-bottom: 40px; }
  .careers-page__intro-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
    padding: 0 0 40px 0px;
    text-align: center; }
    @media (max-width: 991.98px) {
      .careers-page__intro-list {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    .careers-page__intro-list img {
      margin-bottom: 15px; }
    @media (max-width: 991.98px) {
      .careers-page__intro-list div {
        margin-bottom: 20px; } }
    .careers-page__intro-list div:nth-child(1) h3 {
      color: #8fb04e; }
    .careers-page__intro-list div:nth-child(2) h3 {
      color: #50928b;
      max-width: 225px;
      margin-left: auto;
      margin-right: auto; }
      @media (max-width: 991.98px) {
        .careers-page__intro-list div:nth-child(2) h3 {
          max-width: 100%; } }
    .careers-page__intro-list div:nth-child(3) h3 {
      color: #d3636b;
      max-width: 290px;
      margin-left: auto;
      margin-right: auto; }
      @media (max-width: 991.98px) {
        .careers-page__intro-list div:nth-child(3) h3 {
          max-width: 100%; } }
    .careers-page__intro-list div h3 {
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 20px; }

.careers-page__mission {
  padding: 80px 0;
  background: rgba(0, 106, 124, 0.1);
  background: linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -moz-linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%); }
  .careers-page__mission-columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 80px; }
    @media (max-width: 991.98px) {
      .careers-page__mission-columns {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 40px; } }
    .careers-page__mission-columns h4 {
      font-weight: bold;
      color: #786F6C;
      font-size: 2rem;
      margin-bottom: 20px; }
    .careers-page__mission-columns h5 {
      font-weight: bold;
      color: #7fc3ba; }
    .careers-page__mission-columns.mb70 {
      margin-bottom: 70px; }
    .careers-page__mission-columns--content {
      padding: 50px 0; }
      @media (max-width: 991.98px) {
        .careers-page__mission-columns--content {
          padding: 0; } }
      .careers-page__mission-columns--content div {
        margin-bottom: 20px; }
      .careers-page__mission-columns--content a {
        margin-top: 10px; }
    .careers-page__mission-columns--image {
      position: relative;
      width: 100%;
      height: 100%; }
      @media (max-width: 991.98px) {
        .careers-page__mission-columns--image {
          padding-bottom: 70%; } }
      .careers-page__mission-columns--image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .careers-page__mission-columns--image .page-banner__play-btn {
        width: 100px;
        height: 100px; }
      .careers-page__mission-columns--image .flower {
        position: absolute; }
        .careers-page__mission-columns--image .flower img {
          object-fit: contain; }
        .careers-page__mission-columns--image .flower-mission {
          width: 256px;
          height: 215px;
          right: -114px;
          bottom: -91px;
          z-index: 20; }
    .careers-page__mission-columns .play-button {
      z-index: 20;
      width: 100px;
      height: 100px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

.careers-page__team, .careers-page__awards, .careers-page__paths {
  padding: 70px 0; }

.careers-page__team .testimonials {
  padding-bottom: 0; }
  .careers-page__team .testimonials__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: block;
    position: relative; }
    .careers-page__team .testimonials__list:before, .careers-page__team .testimonials__list:after {
      content: '';
      display: table; }
    .careers-page__team .testimonials__list:after {
      clear: both; }
  .careers-page__team .testimonials__item {
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left; }
  .careers-page__team .testimonials__dots {
    position: relative;
    top: 0px;
    height: 40px; }
    .careers-page__team .testimonials__dots:before, .careers-page__team .testimonials__dots:after {
      content: '';
      display: table; }
    .careers-page__team .testimonials__dots:after {
      clear: both; }
    @media (max-width: 575.98px) {
      .careers-page__team .testimonials__dots {
        top: 0px; } }
  .careers-page__team .testimonials__content {
    position: relative; }
    @media (min-width: 768px) {
      .careers-page__team .testimonials__content {
        width: 500px; } }
    @media (min-width: 992px) {
      .careers-page__team .testimonials__content {
        width: 600px; } }
    @media (min-width: 1200px) {
      .careers-page__team .testimonials__content {
        width: 800px; } }
  .careers-page__team .testimonials__columns {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 40px;
    padding: 0 0 40px 0px;
    text-align: left; }
    @media (max-width: 991.98px) {
      .careers-page__team .testimonials__columns {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 40px;
        text-align: center; } }
    .careers-page__team .testimonials__columns-video {
      position: relative; }
      @media (max-width: 991.98px) {
        .careers-page__team .testimonials__columns-video {
          padding-bottom: 60%; } }
      .careers-page__team .testimonials__columns-video img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
      .careers-page__team .testimonials__columns-video .page-banner__play-btn {
        width: 100%;
        height: 100%; }
        .careers-page__team .testimonials__columns-video .page-banner__play-btn .play-button img {
          z-index: 20;
          width: 100px;
          height: 100px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
  .careers-page__team .testimonials__name {
    color: #363636;
    font-size: 12px;
    font-weight: 400;
    margin-top: 30px;
    text-transform: uppercase; }
  .careers-page__team .testimonials__quote {
    line-height: 1.8;
    font-size: 16px;
    font-style: italic;
    font-family: Helvetica Neue; }
  .careers-page__team .testimonials__title {
    font-size: 10px;
    text-transform: uppercase;
    color: #363636; }
  .careers-page__team .testimonials__item.slick-slide.slick-current.slick-active .testimonials__img {
    opacity: 1; }

.careers-page__team .slick-dots li button:before {
  color: #006a7c;
  opacity: .5; }

.careers-page__team .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #006a7c; }

.careers-page__gradient-1 {
  background: rgba(0, 106, 124, 0.1);
  background: linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -moz-linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%); }

.careers-page__paths {
  background: rgba(0, 106, 124, 0.1);
  background: linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -moz-linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%); }
  .careers-page__paths-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 40px;
    text-align: center;
    list-style: none;
    margin: 0; }
    @media (max-width: 767.98px) {
      .careers-page__paths-list {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
  .careers-page__paths-box {
    background-color: white;
    overflow: hidden;
    transition: all ease-in .2s; }
    .careers-page__paths-box:hover {
      transform: translateY(-5px); }
      .careers-page__paths-box:hover h4 {
        color: #006a7c; }
    .careers-page__paths-box--img {
      position: relative;
      width: 100%;
      padding-bottom: 60%; }
      .careers-page__paths-box--img img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
    .careers-page__paths-box--content {
      padding: 20px;
      color: #006a7c; }
      .careers-page__paths-box--content h4 {
        font-size: 1.3em;
        font-weight: 600;
        color: #786F6C;
        transition: all ease-in .2s; }

.careers-page__benefits {
  padding-bottom: 70px; }
  .careers-page__benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 40px;
    padding: 0 0 40px 0px;
    text-align: center; }
    @media (max-width: 991.98px) {
      .careers-page__benefits-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    @media (max-width: 767.98px) {
      .careers-page__benefits-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
  .careers-page__benefits-box {
    background-color: white;
    padding: 25px; }
    .careers-page__benefits-box img {
      margin-bottom: 20px;
      height: 60px; }
    .careers-page__benefits-box h4 {
      font-weight: bold;
      color: #50928b;
      font-size: 1.5rem; }

.careers-page__internship {
  padding: 70px 0;
  background-color: #d8ece9; }
  .careers-page__internship .flower {
    position: absolute; }
    .careers-page__internship .flower img {
      object-fit: contain; }
    .careers-page__internship .flower-intern {
      width: 256px;
      height: 215px;
      left: -142px;
      bottom: -196px;
      z-index: 20; }
      @media (max-width: 991.98px) {
        .careers-page__internship .flower-intern {
          width: 199px;
          height: 115px;
          left: -106px;
          bottom: -40px; } }

.careers-page__culture {
  padding: 70px 0 100px; }
  .careers-page__culture-btn {
    color: #006a7c !important;
    padding-right: 4rem; }
    .careers-page__culture-btn:before {
      width: 10px !important;
      height: 10px !important;
      left: auto !important;
      right: 38px !important;
      top: 34% !important; }

.careers-page__positions {
  padding: 70px 0; }
  .careers-page__positions-list {
    max-width: 800px;
    margin: 0 auto; }

.careers-page__apply {
  padding-top: 40px; }
  .careers-page__apply-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
    padding: 0 0 40px 0px;
    text-align: center; }
    @media (max-width: 991.98px) {
      .careers-page__apply-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    .careers-page__apply-grid .plan-card .number {
      width: 90%; }
    .careers-page__apply-grid .plan-card__heading {
      font-size: 35px !important;
      opacity: 1 !important; }
    .careers-page__apply-grid .plan-card:nth-child(1) .plan-card__image {
      background-color: #7fc3ba; }
    .careers-page__apply-grid .plan-card:nth-child(2) .plan-card__image {
      background-color: #b9c882; }
    .careers-page__apply-grid .plan-card:nth-child(3) .plan-card__image {
      background-color: #eca688; }

.triangle {
  width: 0;
  height: 0;
  margin-left: 5px;
  margin-bottom: -1px;
  display: inline-block;
  border-width: 5.5px 0 5.5px 10px;
  border-color: transparent transparent transparent #7fc3ba;
  border-style: solid; }

.careers-page {
  padding-top: calc(1rem + 4vh);
  margin-bottom: calc(1rem + 4vh); }

.career-icon {
  padding-bottom: 30px;
  margin-bottom: 20px;
  position: relative; }

.career-icon:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 100px;
  background-color: #8bc63f; }

.career-gradient {
  background: #3376b7;
  background: linear-gradient(0deg, #3376b7 0%, #124185 80%);
  background: -webkit-linear-gradient(0deg, #3376b7 0%, #124185 80%);
  background: -moz-linear-gradient(0deg, #3376b7 0%, #124185 80%); }

.career-container-sm {
  max-width: 1200px;
  margin: 0 auto; }

.career-team {
  background-image: url(../img/team.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 12rem; }

@screen sm {
  .career-team {
    padding-bottom: 26rem; } }

.text-green-dark {
  color: #537A24; }

.career-position {
  border-bottom: solid 1px #E1E1E1; }

.career-posting h1,
.career-posting h2,
.career-posting h3,
.career-posting h4,
.career-posting h5,
.career-posting h6 {
  color: #124387; }

.career-posting ul {
  list-style: none; }

.career-posting li::before {
  content: "•";
  color: #8bc63f;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

/* CAREER PATHS */
.career-paths {
  padding-bottom: 50px; }
  .career-paths__list {
    position: relative;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 640px) {
      .career-paths__list {
        display: grid;
        grid-template-columns: 1fr; } }
  .career-paths__item {
    flex: 1;
    margin: 0 8px;
    position: relative;
    cursor: pointer;
    transition: all ease-in .25s;
    padding: 0;
    margin: 0 20px 40px;
    list-style-type: none;
    width: calc(50% - 40px);
    text-align: center; }
    .career-paths__item:hover {
      opacity: 0.95; }
    @media (max-width: 575.98px) {
      .career-paths__item {
        margin: 0 0px 20px;
        width: calc(50% - 10px); } }
    .career-paths__item-link {
      display: block;
      background-color: white;
      overflow: hidden; }
      .career-paths__item-link--image {
        position: relative;
        padding-bottom: 60%; }
        .career-paths__item-link--image img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0; }
      .career-paths__item-link--content {
        background-color: white;
        color: #50928b;
        padding: 20px; }
        .career-paths__item-link--content h4 {
          color: #939393;
          margin: 0 0 10px;
          font-size: 24px;
          font-weight: bold; }
  .career-paths__link {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1; }

/* CAREER PATHS POPUP STAGE */
.career-paths-stage {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: #3376b7;
  background: linear-gradient(0deg, #3376b7 0%, #124185 80%);
  background: -webkit-linear-gradient(0deg, #3376b7 0%, #124185 80%);
  background: -moz-linear-gradient(0deg, #3376b7 0%, #124185 80%);
  color: #fff;
  display: none; }
  @media (min-width: 1000px) {
    .career-paths-stage {
      min-height: 720px;
      overflow: auto; } }
  .career-paths-stage__close {
    position: absolute;
    top: 20px;
    right: 8px;
    z-index: 1000;
    display: block;
    height: 1em;
    width: 1em;
    color: rgba(255, 255, 255, 0);
    cursor: pointer;
    text-decoration: none;
    font-size: 30px;
    opacity: 0.95;
    transition: 0.2s all ease-out; }
    @media (min-width: 768px) {
      .career-paths-stage__close {
        right: 14px;
        top: 35px; } }
    @media (min-width: 1200px) {
      .career-paths-stage__close {
        right: 30px;
        top: 35px;
        font-size: 40px; } }
    .career-paths-stage__close:hover {
      opacity: 1;
      color: transparent; }
    .career-paths-stage__close:after, .career-paths-stage__close:before {
      left: 0;
      position: absolute;
      top: 0;
      content: '';
      height: 3px;
      width: 100%;
      background-color: #50928b; }
    .career-paths-stage__close:before {
      transform: rotate(-45deg); }
    .career-paths-stage__close:after {
      transform: rotate(45deg); }
  .career-paths-stage__video-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    mix-blend-mode: multiply;
    background-color: rgba(0, 0, 0, 0.8); }
  .career-paths-stage__video-overlay-2 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.25); }
  .career-paths-stage__video {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; }

/* CAREER PATHS DETAIL CARD */
.career-path-detail-card {
  position: relative;
  z-index: 2000;
  margin: 40px 0;
  padding: 20px;
  min-height: 114px;
  height: 114px;
  width: 172px;
  overflow: hidden;
  background-color: #006a7c;
  border: solid 2px #006a7c;
  text-align: left;
  transition: border-color 0.2s ease-out; }
  @media (min-width: 1000px) {
    .career-path-detail-card {
      min-height: 170px;
      height: 170px;
      min-width: 260px;
      width: 260px;
      font-size: 20px; } }
  .career-path-detail-card:hover {
    background-color: #50928b;
    border-color: #50928b; }
  .career-path-detail-card .btn, .career-path-detail-card .about__section--refer-a-friend a, .about__section--refer-a-friend .career-path-detail-card a, .career-path-detail-card .careers-callout__content-wrap a, .careers-callout__content-wrap .career-path-detail-card a {
    margin-top: 20px; }
  .career-path-detail-card .sr-hidden {
    display: none; }
  .career-path-detail-card__group {
    position: relative;
    padding-left: 35px;
    background-image: url(../img/career-path-connector-1-to-1.svg);
    background-position: center center;
    background-size: 1px 100%;
    background-repeat: repeat-x; }
    .career-path-detail-card__group:first-of-type {
      padding-left: 0; }
    .career-path-detail-card__group--two-cards {
      padding: 20px 35px;
      /* background-image: url(../img/career-path-connector-1-to-2.svg), url(../img/career-path-connector-2-to-1.svg);
            background-size: auto;
            background-repeat: no-repeat;
            background-position: 0 center, right center; */ }
      .career-path-detail-card__group--two-cards:first-of-type {
        padding-left: 0;
        /* background-image: url(../img/career-path-connector-2-to-1.svg);
                background-position: right center; */ }
      .career-path-detail-card__group--two-cards + .career-path-detail-card__group {
        padding-left: 0; }
  .career-path-detail-card__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    transition: border-color 0.2s ease-out; }
    .career-path-detail-card__link-text {
      font-weight: bold;
      font-size: 1.3em;
      transition: all 0.2s ease-out; }
    .career-path-detail-card__link:hover {
      color: white; }
  .career-path-detail-card--active {
    border-color: white;
    color: black; }
    .career-path-detail-card--active .career-path-detail-card__link {
      display: none;
      z-index: 1; }
    .career-path-detail-card--active .career-path-detail-card__link-text {
      opacity: 0; }
  .career-path-detail-card__position-card-neighbour-group .career-path-detail-card:first-of-type {
    margin-bottom: 30px; }
  .career-path-detail-card__content {
    position: relative;
    z-index: 2;
    display: none;
    background-color: rgba(255, 255, 255, 0.9); }
  .career-path-detail-card__close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1000;
    display: block;
    opacity: 0.65;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-out; }
    .career-path-detail-card__close:hover {
      opacity: 1; }
  .career-path-detail-card__inner {
    height: calc(100vh - 80px);
    max-height: 600px;
    overflow: scroll; }
  .career-path-detail-card__image {
    max-width: 100%;
    min-height: 360px;
    background-size: cover;
    background-position: center; }
  .career-path-detail-card__expand-icon {
    position: absolute;
    right: 4px;
    transform: rotate(-5deg);
    top: 5px;
    opacity: 0.95;
    transform: all 2s ease-out; }
    .career-path-detail-card__expand-icon:hover {
      opacity: 1; }
  .career-path-detail-card__copy {
    min-height: 150px;
    padding: 30px 20px;
    font-size: 14px;
    line-height: 1.5; }
    .career-path-detail-card__copy h3 {
      margin-bottom: 0.25em;
      font-size: 30px; }
    .career-path-detail-card__copy ul {
      padding: 12px 0 12px 20px; }
    .career-path-detail-card__copy li {
      position: relative;
      margin-bottom: 0.5em; }
      .career-path-detail-card__copy li:before {
        left: -16px;
        position: absolute;
        top: 6px;
        content: '';
        display: block;
        width: 0.5em;
        height: 0.5em;
        background-color: #8CC63F;
        border-radius: 50%;
        opacity: 0.5; }
    .career-path-detail-card__copy p {
      margin-bottom: 0.5em; }
  .career-path-detail-card__border {
    width: 100%;
    height: 1px;
    background-color: #8CC63F;
    margin: 20px 0; }
  .career-path-detail-card__expectations {
    margin-top: 20px;
    padding-top: 20px; }
  .career-path-detail-card__actions {
    position: relative;
    padding: 5px 20px 12px; }
    @media (min-width: 768px) {
      .career-path-detail-card__actions {
        margin-left: -20px; } }
    @media (min-width: 768px) {
      .career-path-detail-card__actions {
        margin-left: -20px; } }
  .career-path-detail-card__prev, .career-path-detail-card__next {
    position: absolute;
    right: 8px;
    top: -28px;
    width: 20px;
    overflow: hidden;
    opacity: 0.25;
    transition: all 0.2s ease-out;
    transform: scale(1.5, 0.85); }
    .career-path-detail-card__prev:hover, .career-path-detail-card__next:hover {
      opacity: 1; }
    .career-path-detail-card__prev.career-path-detail-card__arrow, .career-path-detail-card__next.career-path-detail-card__arrow {
      transform: rotate(-45deg); }
  .career-path-detail-card__prev {
    right: 50px; }
    .career-path-detail-card__prev.career-path-detail-card__arrow {
      right: -10px; }
  .career-path-detail-card__next.career-path-detail-card__arrow {
    left: -10px; }
  .career-path-detail-card__arrow {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    margin: 0.5em 0;
    background-color: #607B87; }

/* CAREER PATHS POPUP DETAIL */
.career-path-detail {
  position: relative;
  z-index: 3;
  display: none;
  height: 100%;
  min-height: 100vh;
  text-align: center;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-color: #d8ece9; }
  .career-path-detail--active {
    display: flex; }
  .career-path-detail__positions {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    overflow: scroll;
    user-select: none;
    cursor: pointer; }
    .career-path-detail__positions.active {
      cursor: grabbing;
      cursor: -webkit-grabbing; }
    .career-path-detail__positions-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-height: 100%; }
  .career-path-detail__heading {
    padding: 30px;
    color: #50928b;
    font-size: 35px; }
    @media (max-width: 600px) {
      .career-path-detail__heading {
        font-size: 20px !important; } }

@media (min-width: 576px) {
  .careers-post__email-link {
    font-size: 1.5rem; } }

@media (min-width: 1200px) {
  .careers-post__email-link {
    font-size: 1.875rem; } }

.careers-post__heading {
  font-size: 24px;
  font-weight: bold; }

.careers-post__closing {
  font-size: 12px;
  font-weight: 200; }

.carousel {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 30px; }
  .carousel__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: block;
    overflow: hidden; }
    .carousel__list:before, .carousel__list:after {
      content: '';
      display: table; }
    .carousel__list:after {
      clear: both; }
  .carousel__item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    float: left; }
  .carousel__arrow, .carousel__arrow--prev, .carousel__arrow--next {
    border-bottom: 1px solid #9C9C9C;
    border-left: 1px solid #9C9C9C;
    width: 63px;
    height: 63px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .carousel__arrow:hover, .carousel__arrow--prev:hover, .carousel__arrow--next:hover {
      border-color: dimgray; }
    .carousel__arrow--prev {
      left: -40px;
      transform: translateY(-50%) rotate(45deg); }
    .carousel__arrow--next {
      right: -40px;
      transform: translateY(-50%) rotate(-135deg); }

.community-gallery__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  display: block; }
  .community-gallery__list:before, .community-gallery__list:after {
    content: '';
    display: table; }
  .community-gallery__list:after {
    clear: both; }

.community-gallery__item {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  margin: 0 0 10px 0;
  padding-left: 5px;
  padding-right: 5px;
  width: 50%; }
  .community-gallery__item--lg {
    width: 100%; }
  @media (min-width: 768px) {
    .community-gallery__item {
      width: 25%;
      margin: 0 0 20px 0;
      padding-left: 10px;
      padding-right: 10px; }
      .community-gallery__item--lg {
        width: 50%; } }

.community-gallery__link {
  cursor: zoom-in; }

.community-partners {
  max-width: 700px;
  margin: auto; }
  .community-partners__title {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 2rem; }
  .community-partners__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    text-align: left; }
  .community-partners__item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .community-partners__item {
        flex: 0 0 50%;
        max-width: 50%; } }

.community-sections {
  text-align: center; }
  .community-sections__section {
    padding-top: calc(2rem + 5vh);
    padding-bottom: calc(2rem + 3vh); }
    .community-sections__section:nth-child(2n + 1) {
      background: #f4f4f4; }
  .community-sections__intro {
    margin-bottom: 3rem;
    max-width: 560px;
    margin: auto; }
  .community-sections__gallery {
    margin-bottom: 3rem; }

.community__flower--1 {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  transform: translate(27%, -51%); }

.contact-info {
  margin-left: 0;
  margin-bottom: calc(2rem + 4vh); }
  .contact-info__data {
    padding-left: 2em;
    margin-bottom: 1rem;
    position: relative; }
    @media (min-width: 576px) {
      .contact-info__data {
        font-size: 1.5rem; } }
    .contact-info__data:before {
      font-family: "Icomoon";
      font-style: normal;
      font-size: 1.5em;
      font-weight: normal;
      font-variant: normal;
      font-display: swap;
      speak: none;
      text-transform: none;
      text-decoration: none;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: transform .1s ease-out;
      transform: translateZ(0);
      font-size: 1.2em;
      line-height: 0.833em;
      position: absolute;
      left: 0;
      top: 0.25em; }
      .contact-info__data:before:hover {
        color: inherit;
        transform: scale(1.1);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: none; }
    .contact-info__data--phone:before {
      content: "";
      margin-left: 4px; }
    .contact-info__data--address:before {
      content: "";
      font-size: 1.2em; }
    .contact-info__data--email:before {
      content: ""; }

@media (min-width: 768px) {
  .contact-page__main-image {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    flex: 0 0 37.66667%;
    max-width: 37.66667%; } }

@media (min-width: 768px) {
  .contact-page__main-content {
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: 4%; } }

.contact-page__main-content .article__heading {
  color: #50928b; }

.contact-page__form-wrap {
  padding-top: calc(1rem + 5vh);
  margin-bottom: calc(1rem + 2vh); }

.contact-page__map-image {
  padding: 30px; }
  @media (min-width: 992px) {
    .contact-page__map-image {
      padding: 60px; } }

.cta {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  color: #fff;
  background-color: #7fc3ba;
  text-align: center;
  padding: 4rem 0;
  overflow: hidden; }
  .cta__icon {
    position: absolute;
    right: 10px;
    top: 0;
    max-width: 350px;
    opacity: 0.2;
    transform: translateY(-25%); }
  .cta__buttons {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .cta__buttons {
        display: flex;
        justify-content: space-around; } }
  .cta__button {
    margin-bottom: 1rem; }
  .cta h1, .cta h2, .cta h3 {
    color: inherit;
    margin: 0 auto;
    max-width: 740px; }
  .cta h2 {
    margin-bottom: 1.5rem; }
  .cta p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }

.design-package-content {
  text-align: center; }
  .design-package-content__block-1, .design-package-content__block-2 {
    position: relative;
    padding-bottom: 40px;
    padding-top: 60px; }
  .design-package-content__block-1 {
    background-image: linear-gradient(#fff, rgba(255, 255, 255, 0) 10%), url(/assets/img/landscapes/design-packages-upgrade-background.jpg);
    background-position: center center;
    background-size: cover;
    background-color: #f4f4f4; }
    @media (min-width: 768px) {
      .design-package-content__block-1 {
        padding-bottom: 100px;
        padding-top: 80px; } }
    .design-package-content__block-1 h2 {
      color: #7fc3ba; }
    .design-package-content__block-1 h4 + p {
      color: #adadad; }
    .design-package-content__block-1 ul {
      margin-bottom: 40px !important;
      margin: 0 auto;
      max-width: 760px;
      font-size: 20px; }
    .design-package-content__block-1 li {
      padding-bottom: 20px;
      border-bottom: solid 2px rgba(255, 255, 255, 0.5); }
  .design-package-content__block-2 {
    background-color: white; }
    .design-package-content__block-2 ul {
      max-width: 700px;
      margin: 30px auto 15px; }
      @media (min-width: 768px) {
        .design-package-content__block-2 ul {
          display: flex;
          justify-content: space-between; } }
    .design-package-content__block-2 li {
      padding-bottom: 20px; }
      @media (min-width: 768px) {
        .design-package-content__block-2 li {
          width: 30%; } }
  .design-package-content__flower {
    position: absolute;
    z-index: 1; }
    .design-package-content__flower--1 {
      left: -150px;
      bottom: -270px;
      max-width: 35%;
      transform: scale(0.85); }

.design-packages {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  color: #464342; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .design-packages {
      padding-top: 4px; } }
  .design-packages__banner-heading h2.page-banner__heading {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  .design-packages__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: flex; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .design-packages__list {
        padding: 0; } }
  .design-packages__item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    margin-bottom: 1rem;
    text-align: center; }
    @media (max-width: 991.98px) {
      .design-packages__item {
        padding: 0;
        display: block !important; } }
    @media (min-width: 992px) {
      .design-packages__item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
  .design-packages__highlight {
    width: 100%;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    background-color: #e5855c;
    color: #fff;
    letter-spacing: .1em;
    text-transform: uppercase;
    position: relative; }
    @media (min-width: 768px) {
      .design-packages__highlight {
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        width: 100%;
        transform: translateY(-100%); } }
  .design-packages__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 0px 28px 0px rgba(82, 116, 133, 0.2);
    transition: transform 0.2s; }
    .design-packages__inner--green {
      background-color: #b9c882; }
    .design-packages__inner--blue {
      background-color: #006a7c; }
    .design-packages__inner--blue-dark {
      background-color: #272f39; }
    .design-packages__inner--green-dark {
      background-color: #8fb04e; }
    .design-packages__inner--teal {
      background-color: #50928b; }
    .design-packages__inner--teal-light {
      background-color: #7fc3ba; }
    .design-packages__inner--orange {
      background-color: #eca688; }
    .design-packages__inner--red {
      background-color: #d3636b; }
    .design-packages__inner--yellow {
      background-color: #eadf6e; }
    .design-packages__inner--black {
      background-color: #353535; }
    .design-packages__inner--gray-dark {
      background-color: #464342; }
    .design-packages__inner--gray {
      background-color: #939393; }
    .design-packages__inner--gray-medium {
      background-color: #575755; }
    .design-packages__inner--gray-light {
      background-color: #bbbdc0; }
    .design-packages__inner--gray-lighter {
      background-color: #f4f4f4; }
    .design-packages__inner--noradius {
      border-radius: 0; }
  .design-packages__inner--teal, .design-packages__inner--green, .design-packages__inner--orange {
    padding: 20px 30px; }
  .design-packages__inner--teal, .design-packages__inner--green {
    border-radius: 10px 10px 0 0; }
  .design-packages__inner--teal {
    color: #fff; }
  .design-packages__header {
    border-radius: 20px 20px 0 0; }
    .design-packages__header--noradius {
      border-radius: 0; }
  .design-packages__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    line-height: 1.3;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 0; }
  .design-packages__subtitle {
    font-size: 20px;
    line-height: 1.4;
    margin: 10px 0; }
  .design-packages__details {
    font-size: .9375rem;
    padding: 20px 30px; }
  .design-packages__details-wrap {
    text-align: left;
    color: #575755; }
    .design-packages__details-wrap:first-of-type {
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-top: 20px; }
  .design-packages__number {
    font-size: 26px;
    margin-bottom: 20px; }
    @media (min-width: 1200px) {
      .design-packages__number {
        font-size: 30px; } }
    .design-packages__number--teal {
      color: #50928b; }
    .design-packages__number--green {
      color: #b9c882; }
    .design-packages__number--orange {
      color: #eca688; }
  .design-packages__price {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: auto;
    padding-top: 30px;
    padding-bottom: 40px;
    line-height: 1.1;
    border-top: solid 2px #e7e7e7;
    background-color: #f8f8f8; }
    .design-packages__price small {
      font-size: 0.875rem; }
  .design-packages__item--highlighted .design-packages__price {
    font-size: 30px; }
    @media (min-width: 1200px) {
      .design-packages__item--highlighted .design-packages__price {
        font-size: 36px; } }
  .design-packages__button {
    margin: 0 auto;
    display: inline-block;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border: solid 2px; }
    .design-packages__button--teal {
      border-color: #50928b; }
    .design-packages__button--green {
      border-color: #b9c882; }
    .design-packages__button--orange {
      border-color: #eca688; }
  .design-packages__view-gallery-button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    margin-top: 15px;
    border: solid 1px #939393;
    padding: 10px 20px 6px 40px;
    font-weight: bold;
    background-image: url(../img/icons/magnifying-glass-black.svg);
    background-repeat: no-repeat;
    background-position: 12px center;
    text-transform: uppercase;
    opacity: .8; }
    .design-packages__view-gallery-button:hover {
      color: inherit;
      opacity: 1; }
  .design-packages__inner--teal .design-packages__view-gallery-button {
    background-image: url(../img/icons/magnifying-glass-white.svg); }

.design-steps__step {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .design-steps__step {
      flex-wrap: nowrap; } }

.design-steps__gallery {
  padding: 40px 0; }

.design-steps__content {
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 100px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  order: 2; }
  @media (max-width: 991.98px) {
    .design-steps__content {
      padding-top: 50px; } }
  @media (min-width: 768px) {
    .design-steps__content {
      width: 50%;
      max-width: 360px;
      order: initial; }
      .design-steps__content:not(:last-child) {
        padding-left: 0;
        margin-left: auto; }
      .design-steps__content:last-child {
        padding-right: 0;
        margin-right: auto; } }
  @media (min-width: 992px) {
    .design-steps__content {
      max-width: 480px; } }
  @media (min-width: 1200px) {
    .design-steps__content {
      max-width: 595px; } }
  .design-steps__content a:not(.btn) {
    text-decoration: underline; }

@media (min-width: 576px) {
  .design-steps__step--2 .design-steps__title-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; } }

.design-steps__title-wrap {
  margin-bottom: 2rem; }

.design-steps__category {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #006a7c; }

.design-steps__title {
  color: inherit;
  font-size: 36px;
  margin-bottom: 0.6em; }
  .design-steps__title strong {
    font-weight: inherit;
    color: #7fc3ba; }

.design-steps__step--3 .design-steps__title {
  margin-bottom: 8px; }

.design-steps__image-wrap {
  position: relative;
  background-color: #eceeef;
  overflow: hidden;
  width: 100%;
  order: 1; }
  .design-steps__image-wrap:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%; }
  .design-steps__image-wrap > * {
    position: absolute;
    top: 0;
    display: block;
    width: 100%; }
  @media (min-width: 768px) {
    .design-steps__image-wrap {
      width: 50%;
      order: initial; } }

.design-steps__image {
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: auto; }
  @media (min-width: 768px) {
    .design-steps__image {
      width: auto; } }

.design-steps__full-content {
  width: 100%;
  max-width: 975px;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 30px;
  text-align: center; }

.employee-detail {
  margin-bottom: 4rem;
  margin-top: 2rem; }
  .employee-detail__image {
    margin-bottom: 2rem; }
  .employee-detail__gallery {
    background-color: #c6c6c6;
    background-image: url(../img/icons/branches/1-flipped-y.png);
    background-position: right -100px bottom;
    background-repeat: no-repeat;
    background-size: 360px auto; }
    @media (min-width: 1200px) {
      .employee-detail__gallery {
        background-position: calc(50% + 600px) bottom; } }
  .employee-detail__gallery-header {
    margin-bottom: 16px;
    padding: 20px 0;
    background-image: linear-gradient(rgba(198, 198, 198, 0.4), rgba(198, 198, 198, 0.4)), url("/assets/img/pages/icons/Leaves-white.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat; }

.employees__intro {
  text-align: center;
  max-width: 560px;
  margin: auto;
  margin-bottom: 3rem; }

.employees__heading {
  color: #50928b;
  font-weight: bold;
  margin-bottom: 20px; }

.employees__subheading {
  font-weight: bold; }

.employees__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.employees__employee {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 4rem; }
  @media (min-width: 576px) {
    .employees__employee {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 768px) {
    .employees__employee {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .employees__employee {
      flex: 0 0 25%;
      max-width: 25%; } }

.employees__image-wrap {
  margin-bottom: 1rem; }

.employees__image {
  width: 100%; }

.employees__name {
  margin-bottom: 0; }

.employees__position {
  margin-bottom: 0;
  line-height: 1.5; }

.employees__email {
  margin-bottom: 0;
  line-height: 1.5; }

.feature-cards {
  text-align: center;
  padding-top: calc(1rem + 3vh);
  margin-bottom: calc(1rem + 3vh); }
  @media (max-width: 575.98px) {
    .feature-cards {
      padding-top: 0;
      margin-bottom: 0; } }
  .feature-cards__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .feature-cards__item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%; }
    @media (min-width: 768px) {
      .feature-cards__item {
        flex: 0 0 25%;
        max-width: 25%; } }
  .feature-cards__link {
    display: block; }
    .feature-cards__link:hover, .feature-cards__link:focus, .feature-cards__link:active {
      color: inherit; }
  .feature-cards__image-wrap {
    height: 100px;
    max-width: 205px;
    margin: 0 auto;
    position: relative; }
    @media (min-width: 768px) {
      .feature-cards__image-wrap {
        height: 160px; } }
  .feature-cards__image {
    max-height: 100%;
    max-height: 140px;
    padding: 20px;
    width: auto;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media (min-width: 768px) {
      .feature-cards__image {
        padding: 10px; } }
    @media (min-width: 1200px) {
      .feature-cards__image {
        padding: 0; } }
  .feature-cards__title {
    font-size: 19px;
    font-weight: bold;
    padding-top: 1rem;
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      .feature-cards__title {
        font-size: 33px; } }
  .feature-cards__button {
    width: 100%;
    max-width: 175px;
    padding-left: 0;
    padding-right: 0; }

.featured-projects-callout {
  padding: 80px 0 60px;
  background-color: #f4f4f4;
  text-align: center; }
  .featured-projects-callout__heading {
    margin-bottom: 40px;
    color: #939393;
    text-transform: uppercase; }

.featured-projects__branch {
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 0; }
  @media (max-width: 575.98px) {
    .featured-projects__branch {
      width: 50%;
      transform: translate(-48%, 10%) rotate(10deg); } }

.featured-projects__flower {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  transform: translateY(-50%);
  z-index: 1; }

.featured-projects__heading {
  padding-top: calc(1rem + 5vh);
  margin-bottom: calc(1rem + 3vh);
  position: relative;
  z-index: 2; }
  @media (max-width: 575.98px) {
    .featured-projects__heading {
      padding-top: 5rem; } }

.flower {
  height: auto; }
  .flower__wrap {
    position: relative; }

.fluid-container {
  max-width: 2030px;
  /* temporary, until I can think of a better fix for large screens */ }
  @media (min-width: 768px) {
    .fluid-container {
      width: 100% !important; } }
  @media (min-width: 768px) {
    .col-md:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md .fluid-container__content-wrap {
      max-width: 330px; }
    .col-md:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-1:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-1:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-1:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-1 .fluid-container__content-wrap {
      max-width: 30px; }
    .col-md-1:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-1:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-2:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-2:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-2:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-2 .fluid-container__content-wrap {
      max-width: 90px; }
    .col-md-2:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-2:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-3:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-3:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-3:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-3 .fluid-container__content-wrap {
      max-width: 150px; }
    .col-md-3:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-3:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-4:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-4:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-4:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-4 .fluid-container__content-wrap {
      max-width: 210px; }
    .col-md-4:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-4:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-5:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-5:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-5:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-5 .fluid-container__content-wrap {
      max-width: 270px; }
    .col-md-5:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-5:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-6:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-6:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-6:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-6 .fluid-container__content-wrap {
      max-width: 330px; }
    .col-md-6:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-6:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-7:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-7:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-7:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-7 .fluid-container__content-wrap {
      max-width: 390px; }
    .col-md-7:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-7:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-8:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-8:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-8:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-8 .fluid-container__content-wrap {
      max-width: 450px; }
    .col-md-8:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-8:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-9:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-9:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-9:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-9 .fluid-container__content-wrap {
      max-width: 510px; }
    .col-md-9:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-9:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-10:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-10:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-10:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-10 .fluid-container__content-wrap {
      max-width: 570px; }
    .col-md-10:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-10:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-11:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-11:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-11:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-11 .fluid-container__content-wrap {
      max-width: 630px; }
    .col-md-11:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-11:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-12:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-12:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-12:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-12 .fluid-container__content-wrap {
      max-width: 690px; }
    .col-md-12:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-12:last-child .fluid-container__content-wrap {
      margin-right: auto; } }
  @media (min-width: 992px) {
    .col-md:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md .fluid-container__content-wrap {
      max-width: 450px; }
    .col-md:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-1:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-1:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-1:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-1 .fluid-container__content-wrap {
      max-width: 50px; }
    .col-md-1:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-1:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-2:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-2:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-2:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-2 .fluid-container__content-wrap {
      max-width: 130px; }
    .col-md-2:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-2:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-3:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-3:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-3:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-3 .fluid-container__content-wrap {
      max-width: 210px; }
    .col-md-3:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-3:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-4:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-4:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-4:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-4 .fluid-container__content-wrap {
      max-width: 290px; }
    .col-md-4:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-4:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-5:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-5:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-5:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-5 .fluid-container__content-wrap {
      max-width: 370px; }
    .col-md-5:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-5:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-6:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-6:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-6:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-6 .fluid-container__content-wrap {
      max-width: 450px; }
    .col-md-6:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-6:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-7:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-7:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-7:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-7 .fluid-container__content-wrap {
      max-width: 530px; }
    .col-md-7:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-7:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-8:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-8:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-8:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-8 .fluid-container__content-wrap {
      max-width: 610px; }
    .col-md-8:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-8:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-9:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-9:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-9:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-9 .fluid-container__content-wrap {
      max-width: 690px; }
    .col-md-9:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-9:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-10:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-10:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-10:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-10 .fluid-container__content-wrap {
      max-width: 770px; }
    .col-md-10:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-10:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-11:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-11:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-11:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-11 .fluid-container__content-wrap {
      max-width: 850px; }
    .col-md-11:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-11:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-12:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-12:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-12:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-12 .fluid-container__content-wrap {
      max-width: 930px; }
    .col-md-12:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-12:last-child .fluid-container__content-wrap {
      margin-right: auto; } }
  @media (min-width: 1200px) {
    .col-md:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md .fluid-container__content-wrap {
      max-width: 565px; }
    .col-md:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-1:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-1:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-1:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-1 .fluid-container__content-wrap {
      max-width: 69.16667px; }
    .col-md-1:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-1:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-2:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-2:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-2:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-2 .fluid-container__content-wrap {
      max-width: 168.33333px; }
    .col-md-2:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-2:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-3:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-3:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-3:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-3 .fluid-container__content-wrap {
      max-width: 267.5px; }
    .col-md-3:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-3:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-4:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-4:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-4:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-4 .fluid-container__content-wrap {
      max-width: 366.66667px; }
    .col-md-4:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-4:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-5:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-5:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-5:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-5 .fluid-container__content-wrap {
      max-width: 465.83333px; }
    .col-md-5:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-5:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-6:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-6:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-6:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-6 .fluid-container__content-wrap {
      max-width: 565px; }
    .col-md-6:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-6:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-7:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-7:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-7:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-7 .fluid-container__content-wrap {
      max-width: 664.16667px; }
    .col-md-7:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-7:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-8:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-8:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-8:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-8 .fluid-container__content-wrap {
      max-width: 763.33333px; }
    .col-md-8:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-8:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-9:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-9:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-9:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-9 .fluid-container__content-wrap {
      max-width: 862.5px; }
    .col-md-9:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-9:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-10:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-10:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-10:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-10 .fluid-container__content-wrap {
      max-width: 961.66667px; }
    .col-md-10:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-10:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-11:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-11:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-11:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-11 .fluid-container__content-wrap {
      max-width: 1060.83333px; }
    .col-md-11:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-11:last-child .fluid-container__content-wrap {
      margin-right: auto; }
    .col-md-12:first-child .fluid-container__image-wrap {
      margin-left: -15px;
      text-align: right; }
    .col-md-12:last-child .fluid-container__image-wrap {
      margin-right: -15px; }
    .col-md-12:first-child .fluid-container__image {
      left: auto;
      right: 0; }
    .col-md-12 .fluid-container__content-wrap {
      max-width: 1160px; }
    .col-md-12:first-child .fluid-container__content-wrap {
      margin-left: auto; }
    .col-md-12:last-child .fluid-container__content-wrap {
      margin-right: auto; } }
  .fluid-container__image-wrap {
    overflow: hidden;
    position: relative; }
    @media (min-width: 768px) {
      .fluid-container__image-wrap--match-height {
        height: 100%; } }
    @media (max-width: 767.98px) {
      .fluid-container__image-wrap {
        margin-left: -15px;
        margin-right: -15px; } }
    @media (max-width: 575.98px) {
      .fluid-container__image-wrap {
        margin-left: -7.5px;
        margin-right: -7.5px; } }
  @media (min-width: 768px) {
    .fluid-container__image-wrap--match-height .fluid-container__image {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 100%;
      max-width: none;
      height: 100%;
      width: auto; } }
  .fluid-container__mt40 {
    margin-top: 40px; }

@media (min-width: 768px) {
  .col-md:last-child .fluid-container__image-wrap {
    margin-left: -15px;
    text-align: right; }
  .col-md:first-child .fluid-container__image-wrap {
    margin-right: -15px; }
  .col-md:last-child .fluid-container__image {
    left: auto;
    right: 0; }
  .col-md .fluid-container__content-wrap {
    max-width: 330px; }
  .col-md:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md:first-child .fluid-container__content-wrap--reversed {
    margin-right: auto;
    margin-left: 40px; }
  .col-md-1:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-1:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-1:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-1 .fluid-container__content-wrap--reversed {
    max-width: 30px; }
  .col-md-1:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-1:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-2:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-2:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-2:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-2 .fluid-container__content-wrap--reversed {
    max-width: 90px; }
  .col-md-2:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-2:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-3:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-3:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-3:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-3 .fluid-container__content-wrap--reversed {
    max-width: 150px; }
  .col-md-3:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-3:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-4:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-4:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-4:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-4 .fluid-container__content-wrap--reversed {
    max-width: 210px; }
  .col-md-4:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-4:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-5:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-5:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-5:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-5 .fluid-container__content-wrap--reversed {
    max-width: 270px; }
  .col-md-5:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-5:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-6:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-6:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-6:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-6 .fluid-container__content-wrap--reversed {
    max-width: 330px; }
  .col-md-6:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-6:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-7:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-7:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-7:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-7 .fluid-container__content-wrap--reversed {
    max-width: 390px; }
  .col-md-7:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-7:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-8:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-8:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-8:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-8 .fluid-container__content-wrap--reversed {
    max-width: 450px; }
  .col-md-8:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-8:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-9:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-9:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-9:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-9 .fluid-container__content-wrap--reversed {
    max-width: 510px; }
  .col-md-9:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-9:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-10:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-10:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-10:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-10 .fluid-container__content-wrap--reversed {
    max-width: 570px; }
  .col-md-10:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-10:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-11:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-11:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-11:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-11 .fluid-container__content-wrap--reversed {
    max-width: 630px; }
  .col-md-11:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-11:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-12:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-12:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-12:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-12 .fluid-container__content-wrap--reversed {
    max-width: 690px; }
  .col-md-12:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-12:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; } }

@media (min-width: 992px) {
  .col-md:last-child .fluid-container__image-wrap {
    margin-left: -15px;
    text-align: right; }
  .col-md:first-child .fluid-container__image-wrap {
    margin-right: -15px; }
  .col-md:last-child .fluid-container__image {
    left: auto;
    right: 0; }
  .col-md .fluid-container__content-wrap {
    max-width: 450px; }
  .col-md:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md:first-child .fluid-container__content-wrap--reversed {
    margin-right: auto;
    margin-left: 40px; }
  .col-md-1:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-1:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-1:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-1 .fluid-container__content-wrap--reversed {
    max-width: 50px; }
  .col-md-1:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-1:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-2:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-2:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-2:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-2 .fluid-container__content-wrap--reversed {
    max-width: 130px; }
  .col-md-2:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-2:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-3:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-3:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-3:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-3 .fluid-container__content-wrap--reversed {
    max-width: 210px; }
  .col-md-3:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-3:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-4:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-4:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-4:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-4 .fluid-container__content-wrap--reversed {
    max-width: 290px; }
  .col-md-4:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-4:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-5:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-5:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-5:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-5 .fluid-container__content-wrap--reversed {
    max-width: 370px; }
  .col-md-5:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-5:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-6:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-6:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-6:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-6 .fluid-container__content-wrap--reversed {
    max-width: 450px; }
  .col-md-6:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-6:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-7:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-7:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-7:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-7 .fluid-container__content-wrap--reversed {
    max-width: 530px; }
  .col-md-7:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-7:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-8:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-8:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-8:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-8 .fluid-container__content-wrap--reversed {
    max-width: 610px; }
  .col-md-8:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-8:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-9:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-9:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-9:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-9 .fluid-container__content-wrap--reversed {
    max-width: 690px; }
  .col-md-9:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-9:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-10:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-10:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-10:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-10 .fluid-container__content-wrap--reversed {
    max-width: 770px; }
  .col-md-10:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-10:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-11:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-11:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-11:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-11 .fluid-container__content-wrap--reversed {
    max-width: 850px; }
  .col-md-11:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-11:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-12:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-12:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-12:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-12 .fluid-container__content-wrap--reversed {
    max-width: 930px; }
  .col-md-12:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-12:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; } }

@media (min-width: 1200px) {
  .col-md:last-child .fluid-container__image-wrap {
    margin-left: -15px;
    text-align: right; }
  .col-md:first-child .fluid-container__image-wrap {
    margin-right: -15px; }
  .col-md:last-child .fluid-container__image {
    left: auto;
    right: 0; }
  .col-md .fluid-container__content-wrap {
    max-width: 565px; }
  .col-md:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md:first-child .fluid-container__content-wrap--reversed {
    margin-right: auto;
    margin-left: 40px; }
  .col-md-1:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-1:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-1:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-1 .fluid-container__content-wrap--reversed {
    max-width: 69.16667px; }
  .col-md-1:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-1:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-2:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-2:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-2:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-2 .fluid-container__content-wrap--reversed {
    max-width: 168.33333px; }
  .col-md-2:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-2:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-3:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-3:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-3:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-3 .fluid-container__content-wrap--reversed {
    max-width: 267.5px; }
  .col-md-3:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-3:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-4:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-4:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-4:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-4 .fluid-container__content-wrap--reversed {
    max-width: 366.66667px; }
  .col-md-4:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-4:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-5:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-5:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-5:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-5 .fluid-container__content-wrap--reversed {
    max-width: 465.83333px; }
  .col-md-5:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-5:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-6:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-6:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-6:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-6 .fluid-container__content-wrap--reversed {
    max-width: 565px; }
  .col-md-6:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-6:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-7:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-7:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-7:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-7 .fluid-container__content-wrap--reversed {
    max-width: 664.16667px; }
  .col-md-7:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-7:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-8:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-8:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-8:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-8 .fluid-container__content-wrap--reversed {
    max-width: 763.33333px; }
  .col-md-8:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-8:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-9:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-9:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-9:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-9 .fluid-container__content-wrap--reversed {
    max-width: 862.5px; }
  .col-md-9:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-9:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-10:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-10:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-10:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-10 .fluid-container__content-wrap--reversed {
    max-width: 961.66667px; }
  .col-md-10:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-10:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-11:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-11:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-11:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-11 .fluid-container__content-wrap--reversed {
    max-width: 1060.83333px; }
  .col-md-11:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-11:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; }
  .col-md-12:last-child .fluid-container__image-wrap--reversed {
    margin-left: -15px;
    margin-right: 40px;
    text-align: right; }
  .col-md-12:first-child .fluid-container__image-wrap--reversed {
    margin-right: -15px; }
  .col-md-12:last-child .fluid-container__image--reversed {
    left: auto;
    right: 0; }
  .col-md-12 .fluid-container__content-wrap--reversed {
    max-width: 1160px; }
  .col-md-12:last-child .fluid-container__content-wrap--reversed {
    margin-left: auto;
    margin-right: 40px; }
  .col-md-12:first-child .fluid-container__content-wrap--reversed {
    margin-left: 40px;
    margin-right: auto; } }

.footer-nav {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  padding: 1rem 0;
  font-weight: 600; }
  @media (max-width: 991.98px) {
    .footer-nav {
      padding-bottom: 40px; } }
  .footer-nav__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 0px; }
    @media (max-width: 1199.98px) {
      .footer-nav__list {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 991.98px) {
      .footer-nav__list {
        grid-template-columns: repeat(3, 1fr); } }
    @media (max-width: 575.98px) {
      .footer-nav__list {
        display: inline;
        grid-template-columns: none; } }
  .footer-nav__item {
    padding: 5px 0; }
    @media (max-width: 575.98px) {
      .footer-nav__item {
        display: inline-block; } }
  @media (max-width: 575.98px) {
    .footer-nav__link {
      padding: 0px 10px; } }

.form {
  margin-bottom: 0;
  font-size: 1.25rem; }
  .form__form {
    width: 100%; }
  .form__heading {
    width: 100%;
    font-weight: bold;
    color: #50928b; }
  .form__field-list {
    width: 100%;
    margin-bottom: 1rem; }
  .form__form-group {
    position: relative;
    padding-top: 20px; }
  .form__form-group--select {
    position: relative;
    font-size: 1.375rem;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif; }
    .form__form-group--select select {
      position: relative;
      z-index: 2;
      padding-right: 40px; }
    .form__form-group--select:after {
      position: absolute;
      right: 18px;
      top: 31px;
      z-index: 1;
      content: '';
      display: block;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid;
      border-bottom: 8px solid;
      transform: rotate(-45deg); }
  .form__form-group--select .form__field {
    border-color: #b9c882;
    border-width: 2px;
    color: #464342;
    height: auto !important;
    font-weight: bold; }
    .form__form-group--select .form__field:focus {
      border-color: #a5b85f; }
  .form__label {
    left: 0.75rem;
    position: absolute;
    top: 20px;
    color: #55595c;
    opacity: 0;
    font-size: 0.6875rem;
    transition: top 0.3s ease, opacity 0.3s ease;
    text-transform: uppercase;
    margin-bottom: 0; }
  .form__label--visible {
    opacity: 1;
    top: 10px;
    padding: 2px 4px;
    margin-left: -4px;
    background-color: #f4f4f4; }
  .form--get-started .form__label--visible {
    background-color: #fff; }
  .form__field {
    appearance: none;
    border: 1px solid #464342;
    background-color: transparent;
    font-size: inherit; }
    .form__field:focus {
      border-color: #939393;
      background-color: transparent; }
    .form__field::placeholder {
      color: inherit; }
  .form__field--textarea {
    height: 120px;
    padding: 1rem;
    border: 1px solid #55595c; }
  .form__submit {
    display: inline-block;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .form__field--comment {
    position: absolute;
    left: -1000000px;
    opacity: 0.001;
    height: 1px;
    width: 1px;
    padding: 0 !important;
    margin: 0; }
  .form__errors {
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f0ad4e;
    color: #fff; }
    .form__errors p:last-child {
      margin-bottom: 0; }
  .form__label--resume {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: space-between;
    position: relative;
    width: 100%;
    background: #464342;
    color: white;
    font-size: 16px;
    font-weight: 300;
    padding-right: 20px;
    text-align: left;
    cursor: pointer; }
  .form__field--resume {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1; }
  .form-field__display {
    background: white;
    border: 1px solid;
    border-right: none;
    color: #55595b;
    content: ' ';
    display: inline-block;
    height: 50px;
    width: calc(100% - 115px);
    font-weight: 500;
    font-size: 20px;
    padding: 10px 0 10px 20px; }
  .form__form-group--design-package {
    position: relative;
    padding-right: 50px; }
    .form__form-group--design-package:after {
      right: 65px; }
  .form__design-info-button {
    position: absolute;
    right: 0;
    top: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 45px;
    width: 40px;
    background-color: #939393;
    color: #fff;
    font-size: 10px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center; }
    .form__design-info-button:hover, .form__design-info-button:active {
      background-color: #bbbdc0;
      color: white; }
  .form__design-info-list {
    display: none;
    list-style: none;
    margin: 14px -50px 0 0;
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 2px; }
    .form__design-info-list:after {
      content: '';
      display: block;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid #f4f4f4;
      border-bottom: 8px solid #f4f4f4;
      position: absolute;
      right: 10px;
      top: 73px;
      transform: rotate(135deg); }
  .form__design-info-item {
    margin: 0;
    padding: 10px;
    font-size: 14px; }
  .form--automowers-landing {
    padding-top: 24px; }
    @media (min-width: 992px) {
      .form--automowers-landing {
        padding: 24px; } }

.front-yard-page-banner {
  margin-bottom: 70px; }
  .front-yard-page-banner__box-content {
    padding: 70px 15px 0px 15px; }
  .front-yard-page-banner .body-text {
    max-width: 800px;
    margin: 0 auto;
    color: #575755; }
  .front-yard-page-banner__image {
    position: relative;
    width: 100%;
    padding-bottom: 40%; }
    .front-yard-page-banner__image img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
    .front-yard-page-banner__image--gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      z-index: 10;
      background: rgba(244, 244, 244, 0.1);
      background: linear-gradient(180deg, #f4f4f4 0%, rgba(244, 244, 244, 0) 100%); }

.front-yard h3.heading {
  color: #d3636b; }

.front-yard__gallery {
  text-align: center;
  color: #353535;
  padding-top: calc(2rem + 2vh);
  margin-bottom: calc(1rem + 2vh); }

.front-yard__gallery-title {
  margin-bottom: calc(1rem + 2vh); }

.fw-bg {
  background-color: #edeeee; }
  .fw-bg--light-grey {
    background-color: #fafafa;
    position: relative; }
  .fw-bg--green {
    background-color: #50928D; }

.gallery {
  margin-bottom: calc(1rem + 4vh); }
  .gallery__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .gallery__item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px; }
    @media (min-width: 576px) {
      .gallery__item {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 768px) {
      .gallery__item {
        flex: 0 0 25%;
        max-width: 25%; } }
    @media (min-width: 1200px) {
      .gallery__item {
        flex: 0 0 25%;
        max-width: 25%;
        margin-bottom: 20px; } }
    .gallery__item--extra {
      display: none; }
  .gallery__link {
    display: block;
    cursor: zoom-in; }
    .gallery__link:focus {
      outline: 0; }
    .gallery__link--video {
      position: relative; }
      .gallery__link--video:before {
        content: "▶";
        color: #fff;
        background: #464342;
        border-radius: 50%;
        font-size: 1.5625rem;
        text-align: center;
        line-height: 60px;
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.2s; }
      .gallery__link--video:hover:before {
        transform: translate(-50%, -50%) scale(1.1); }
  .gallery__image-wrap {
    position: relative;
    background-color: #eceeef; }
    .gallery__image-wrap:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 50%; }
    .gallery__image-wrap > * {
      position: absolute;
      top: 0;
      display: block;
      width: 100%; }
  .gallery__image {
    height: auto;
    max-width: 100%; }
  .gallery__show-more {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #D8D8D8;
    margin: auto;
    position: relative;
    transition: background-color 0.2s; }
    .gallery__show-more:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      transition: all 0.2s; }
    .gallery__show-more:hover {
      background-color: #bfbfbf; }
      .gallery__show-more:hover:before {
        transform: translateX(-50%) rotate(-45deg) scale(1.2); }
    .gallery__show-more span {
      display: none; }
    .gallery__show-more--open:before {
      top: 18px;
      transform: translateX(-50%) rotate(135deg); }
    .gallery__show-more--open:hover:before {
      transform: translateX(-50%) rotate(135deg) scale(1.2); }

@media (min-width: 768px) {
  .get-started-page__form-col {
    order: 2; } }

.get-started-page .article__heading {
  color: #50928b; }

.get-started-page .article__intro h4 {
  font-weight: bold;
  font-size: 1.5em;
  color: #575755; }

.get-started-page__overlay {
  width: 100%;
  background: #7fc3ba;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0; }
  @media (min-width: 2000px) {
    .get-started-page__overlay {
      left: auto;
      right: 0; } }

.get-started-page__overlay-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 1rem 0;
  margin-right: 55%;
  text-align: right; }
  .get-started-page__overlay-text h5, .get-started-page__overlay-text p {
    margin-bottom: 0;
    line-height: 1; }

.get-started-page__overlay-image {
  width: 55%;
  position: absolute;
  bottom: 0;
  right: 0; }

.get-started-page__icon-wrap {
  position: relative;
  margin-left: -30px; }

.get-started-page__icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }

.get-started-page__sidebar-wrap {
  text-align: right; }

.get-started-page__sidebar {
  display: inline-block;
  text-align: left;
  margin-top: 3rem; }
  @media (min-width: 768px) {
    .get-started-page__sidebar {
      max-width: 65%;
      padding-right: 1rem; } }
  .get-started-page__sidebar a {
    color: #006a7c;
    transition: color 0.2s; }
    .get-started-page__sidebar a:hover, .get-started-page__sidebar a:focus {
      color: #002930;
      outline: 0; }
    .get-started-page__sidebar a:active {
      color: black; }
  .get-started-page__sidebar h3, .get-started-page__sidebar h4, .get-started-page__sidebar h5 {
    font-weight: bold;
    line-height: 1.5;
    color: #575755; }

.get-started-page__guarantee {
  color: #A7A9AC;
  margin-top: 3rem; }

.get-started-page__form-wrap {
  margin-bottom: 3rem; }

.guarantee {
  padding: 60px 0;
  background-color: white;
  background-repeat: no-repeat;
  background-position: top center;
  text-align: center; }
  @media (min-width: 768px) {
    .guarantee {
      background-position: calc(50% - 250px) top; } }
  .guarantee .link {
    font-weight: bold; }
  .guarantee__image {
    margin-bottom: 20px; }
  .guarantee__copy {
    margin: 0 auto;
    max-width: 700px; }

.parallax .pSection-1,
.parallax .pSection-2,
.parallax .pSection-3 {
  position: relative; }

.home__main-flower-wrap {
  position: relative;
  z-index: -1; }
  @media (max-width: 575.98px) {
    .home__main-flower-wrap {
      padding-bottom: 10%; } }

.home__main-flower {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50%; }
  @media (min-width: 768px) {
    .home__main-flower {
      transform: translateX(10%); } }

.home__value {
  padding-top: 100px; }
  @media (max-width: 991.98px) {
    .home__value {
      padding-top: 20px; } }
  .home__value .home-stack-item {
    padding: 0 0 150px 0; }
    @media (max-width: 991.98px) {
      .home__value .home-stack-item {
        padding: 30px 0; } }
    .home__value .home-stack-item .container, .home__value .home-stack-item .fluid-container, .home__value .home-stack-item .products-listing, .home__value .home-stack-item .project-detail, .home__value .home-stack-item .project-detail__finished-photos {
      max-width: 1400px; }
    .home__value .home-stack-item__flex {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 991.98px) {
        .home__value .home-stack-item__flex {
          display: block; } }
    .home__value .home-stack-item__box {
      position: relative;
      flex-direction: column;
      display: flex;
      justify-content: center; }
      .home__value .home-stack-item__box.image {
        width: 50%; }
        @media (max-width: 991.98px) {
          .home__value .home-stack-item__box.image {
            width: 70%;
            margin: 0 auto; } }
        @media (max-width: 767.98px) {
          .home__value .home-stack-item__box.image {
            width: 100%; } }
      .home__value .home-stack-item__box.content {
        width: 50%; }
        @media (max-width: 991.98px) {
          .home__value .home-stack-item__box.content {
            width: 100%; } }
      .home__value .home-stack-item__box-image {
        position: relative;
        width: 100%;
        display: block; }
        .home__value .home-stack-item__box-image:after {
          content: "";
          display: block;
          padding-bottom: 100%; }
        .home__value .home-stack-item__box-image img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto; }
      .home__value .home-stack-item__box-content {
        margin-top: -20px;
        padding: 0px 0 70px 120px; }
        @media (max-width: 1199.98px) {
          .home__value .home-stack-item__box-content {
            padding: 0px 0 70px 70px; } }
        @media (max-width: 991.98px) {
          .home__value .home-stack-item__box-content {
            margin-top: 0;
            padding: 40px 0; } }
    .home__value .home-stack-item--2 .flower-parallax {
      position: absolute;
      z-index: 2;
      content: '';
      bottom: -350px;
      right: -110px;
      width: 373px;
      height: 408px;
      background-image: url(../img/icons/flowers/3.svg);
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 991.98px) {
        .home__value .home-stack-item--2 .flower-parallax {
          bottom: -100px;
          right: -90px;
          width: 223px;
          height: 248px; } }
    @media (min-width: 768px) {
      .home__value .home-stack-item--2 .image {
        order: 2; } }
    @media (min-width: 768px) {
      .home__value .home-stack-item--2 .content {
        order: 1; } }
    .home__value .home-stack-item--2 .content .home-stack-item__box-content {
      margin-top: -20px;
      padding: 0px 120px 70px 0px; }
      @media (max-width: 1199.98px) {
        .home__value .home-stack-item--2 .content .home-stack-item__box-content {
          padding: 70px 70px 70px 0px; } }
      @media (max-width: 991.98px) {
        .home__value .home-stack-item--2 .content .home-stack-item__box-content {
          margin-top: 0;
          padding: 40px 0; } }
    .home__value .home-stack-item__play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      display: inline-block;
      transform: translate(-50%, -50%); }

.title-space {
  padding: 60px 0; }
  .title-space.center {
    text-align: center; }
  .title-space.pt {
    padding-top: 0; }
  .title-space.pb {
    padding-bottom: 0; }

.home__services {
  padding: 60px 0 100px 0;
  text-align: center; }
  .home__services .container, .home__services .fluid-container, .home__services .products-listing, .home__services .project-detail, .home__services .project-detail__finished-photos {
    max-width: 1400px; }
  .home__services-section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    padding: 40px 0px; }
    @media (max-width: 991.98px) {
      .home__services-section {
        grid-template-columns: none;
        padding: 0; } }
  .home__services .services-card {
    width: 100%;
    text-align: center;
    padding: 0;
    border-radius: 10px;
    color: #939393;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
    .home__services .services-card:hover {
      color: #464342;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      transition: all ease .2s;
      transform: translateY(-1px);
      cursor: pointer; }
    .home__services .services-card__image {
      width: 100%;
      padding-bottom: 50%;
      background-color: #7fc3ba;
      border-radius: 10px 10px 0 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .home__services .services-card__content {
      font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
      line-height: 1.8;
      padding: 15px;
      font-size: 15px; }
      .home__services .services-card__content span {
        display: block;
        font-size: 20px;
        font-weight: 600; }

.home__projects {
  padding-top: 80px; }
  @media (max-width: 991.98px) {
    .home__projects {
      padding-top: 60px; } }
  .home__projects .testimonial-box {
    padding: 30px 0; }
    .home__projects .testimonial-box__quote {
      font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
      color: #50928b;
      font-style: italic;
      font-size: 1.5em;
      font-weight: 500; }
      .home__projects .testimonial-box__quote cite {
        display: block;
        color: #939393;
        padding: 10px 0; }

body {
  transition: background-color 1s ease; }

.panel {
  min-height: 100vh; }

.color-white {
  background-color: #ffffff; }

.color-ligray {
  background-color: rgba(225, 225, 226, 0.1); }

.color-liblue {
  background-color: rgba(0, 106, 24, 0.1); }

.color-mdblue {
  background-color: rgba(124, 232, 224, 0.2); }

.color-beige {
  background-color: rgba(185, 200, 130, 0.25); }

.gradient-1 {
  background: #ffffff;
  background: linear-gradient(180deg, #ffffff 0%, rgba(0, 106, 124, 0.05) 10%, rgba(0, 106, 124, 0.05) 90%, #f4f4f4 100%);
  background: -webkit-linear-gradient(180deg, #ffffff 0%, rgba(0, 106, 124, 0.05) 10%, rgba(0, 106, 124, 0.05) 90%, #f4f4f4 100%);
  background: -moz-linear-gradient(180deg, #ffffff 0%, rgba(0, 106, 124, 0.05) 10%, rgba(0, 106, 124, 0.05) 90%, #f4f4f4 100%); }

.gradient-2 {
  background: #f4f4f4;
  background: linear-gradient(180deg, #f4f4f4 0%, rgba(185, 200, 130, 0.1) 10%, rgba(185, 200, 130, 0.1) 100%);
  background: -webkit-linear-gradient(180deg, #f4f4f4 0%, rgba(185, 200, 130, 0.1) 10%, rgba(185, 200, 130, 0.1) 100%);
  background: -moz-linear-gradient(180deg, #f4f4f4 0%, rgba(185, 200, 130, 0.1) 10%, rgba(185, 200, 130, 0.1) 100%); }

.home__plan {
  padding-bottom: 60px; }
  @media (max-width: 991.98px) {
    .home__plan {
      padding-bottom: 0px; } }
  .home__plan .container, .home__plan .fluid-container, .home__plan .products-listing, .home__plan .project-detail, .home__plan .project-detail__finished-photos {
    max-width: 1400px; }
  .home__plan .title-space h3 {
    margin-bottom: 30px; }
  .home__plan .title-space .body-text {
    max-width: 750px;
    margin: 0 auto; }
  .home__plan-cta {
    text-align: center;
    padding: 40px 0; }
  .home__plan-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px; }
    @media (max-width: 767.98px) {
      .home__plan-section {
        grid-template-columns: none; } }

.home__blog .container, .home__blog .fluid-container, .home__blog .products-listing, .home__blog .project-detail, .home__blog .project-detail__finished-photos {
  max-width: 1400px; }

.home__blog-section {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px; }
  @media (max-width: 767.98px) {
    .home__blog-section {
      grid-template-columns: none; } }

.home__careers {
  padding-top: 100px; }
  @media (max-width: 991.98px) {
    .home__careers {
      padding-top: 50px; } }
  .home__careers .regular-stack-item {
    padding: 0; }
    .home__careers .regular-stack-item__box.image {
      order: 1; }
      @media (max-width: 991.98px) {
        .home__careers .regular-stack-item__box.image {
          order: 2; } }
    .home__careers .regular-stack-item__box.content {
      order: 2; }
      @media (max-width: 991.98px) {
        .home__careers .regular-stack-item__box.content {
          order: 1;
          text-align: center; } }

.home__serving {
  background-color: #7fc3ba;
  position: relative; }
  .home__serving .image-edge__flower.right {
    right: -153px;
    top: -230px;
    width: 373px;
    transform: rotate(183deg); }
    @media (max-width: 991.98px) {
      .home__serving .image-edge__flower.right {
        right: -57px;
        top: -120px;
        width: 223px; } }
  .home__serving-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center; }
    @media (max-width: 991.98px) {
      .home__serving-grid {
        grid-template-columns: repeat(1, 1fr); } }
    .home__serving-grid--map {
      margin: 15px auto;
      padding: 0 50px;
      order: 2; }
      @media (max-width: 1199.98px) {
        .home__serving-grid--map {
          padding: 60px 0 0 0;
          order: 1; } }
  .home__serving .title-space {
    max-width: 750px;
    margin: 0 auto;
    padding: 100px 0;
    color: #fff;
    order: 1; }
    @media (max-width: 991.98px) {
      .home__serving .title-space {
        order: 2;
        text-align: center;
        padding: 20px 0 60px; } }
    .home__serving .title-space h3 {
      color: #fff;
      margin-bottom: 30px; }
    .home__serving .title-space .body-text {
      color: #fff; }

.home__blueridge .regular-stack-item {
  padding: 0 0 150px 0; }
  @media (max-width: 991.98px) {
    .home__blueridge .regular-stack-item {
      padding: 150px 0 50px; } }

.homepage-blog-articles {
  background-color: #f4f4f4;
  background-image: url(../img/icons/branches/6.svg);
  background-repeat: no-repeat;
  background-size: 380px auto !important;
  background-position: calc(50% - 660px) -60px; }

.image-edge {
  padding: 50px 0 150px 0; }
  @media (max-width: 991.98px) {
    .image-edge {
      padding: 0; } }
  .image-edge .fluid-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0; }
  .image-edge__flower {
    position: absolute;
    z-index: 2; }
    .image-edge__flower.left {
      left: -220px;
      bottom: -160px;
      width: 430px;
      transform: rotate(-5deg); }
      @media (max-width: 991.98px) {
        .image-edge__flower.left {
          left: -137px;
          bottom: -100px;
          width: 300px; } }
    .image-edge__flower.right {
      right: -110px;
      bottom: -210px;
      width: 373px;
      transform: rotate(-5deg); }
      @media (max-width: 991.98px) {
        .image-edge__flower.right {
          right: -57px;
          bottom: -120px;
          width: 223px; } }
  .image-edge__intro {
    color: #939393;
    padding-bottom: 30px; }
  .image-edge__space {
    padding: 0 0 60px 0; }
    @media (max-width: 991.98px) {
      .image-edge__space {
        padding: 0; } }
  .image-edge__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991.98px) {
      .image-edge__flex {
        display: block; } }
  .image-edge__box {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 50%; }
    @media (max-width: 991.98px) {
      .image-edge__box {
        width: 100%; } }
    .image-edge__box-image {
      position: relative;
      width: 100%;
      display: block; }
      .image-edge__box-image:after {
        content: "";
        display: block;
        padding-bottom: 80%; }
      .image-edge__box-image img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto; }
        @media only screen and (max-width: 1500px) {
          .image-edge__box-image img {
            border-radius: 10px 0 0 10px; } }
        @media (max-width: 991.98px) {
          .image-edge__box-image img {
            border-radius: 0; } }
        @media only screen and (max-width: 1500px) {
          .image-edge__box-image img.left {
            border-radius: 0 10px 10px 0; } }
        @media (max-width: 991.98px) {
          .image-edge__box-image img.left {
            border-radius: 0; } }
    .image-edge__box-content {
      padding: 70px 130px 70px 70px; }
      @media (max-width: 1199.98px) {
        .image-edge__box-content {
          padding: 70px 100px 70px 70px; } }
      @media (max-width: 991.98px) {
        .image-edge__box-content {
          text-align: center;
          padding: 70px; } }
      @media (max-width: 575.98px) {
        .image-edge__box-content {
          text-align: center;
          padding: 70px 20px; } }
      .image-edge__box-content.right {
        padding: 70px 70px 70px 130px; }
        @media (max-width: 1199.98px) {
          .image-edge__box-content.right {
            padding: 70px 70px 70px 100px; } }
        @media (max-width: 991.98px) {
          .image-edge__box-content.right {
            text-align: center;
            padding: 70px; } }
        @media (max-width: 575.98px) {
          .image-edge__box-content.right {
            text-align: center;
            padding: 70px 20px; } }

.inner__value {
  padding-top: 100px; }
  @media (max-width: 991.98px) {
    .inner__value {
      padding-top: 0; } }
  .inner__value .inner-stack-item {
    padding: 0 0 150px 0; }
    @media (max-width: 991.98px) {
      .inner__value .inner-stack-item {
        padding: 30px 0; } }
    .inner__value .inner-stack-item .container, .inner__value .inner-stack-item .fluid-container, .inner__value .inner-stack-item .products-listing, .inner__value .inner-stack-item .project-detail, .inner__value .inner-stack-item .project-detail__finished-photos {
      max-width: 1400px; }
    .inner__value .inner-stack-item__flex {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 991.98px) {
        .inner__value .inner-stack-item__flex {
          display: block; } }
    .inner__value .inner-stack-item__box {
      position: relative;
      flex-direction: column;
      display: flex;
      justify-content: center; }
      .inner__value .inner-stack-item__box.image {
        width: 50%; }
        @media (max-width: 991.98px) {
          .inner__value .inner-stack-item__box.image {
            width: 70%;
            margin: 0 auto; } }
        @media (max-width: 767.98px) {
          .inner__value .inner-stack-item__box.image {
            width: 100%; } }
      .inner__value .inner-stack-item__box.content {
        width: 50%; }
        @media (max-width: 991.98px) {
          .inner__value .inner-stack-item__box.content {
            width: 100%; } }
      .inner__value .inner-stack-item__box-image {
        position: relative;
        width: 100%;
        display: block; }
        .inner__value .inner-stack-item__box-image:after {
          content: "";
          display: block;
          padding-bottom: 100%; }
        .inner__value .inner-stack-item__box-image img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto; }
      .inner__value .inner-stack-item__box-content {
        position: relative;
        z-index: 2;
        padding: 70px 0 70px 120px; }
        @media (max-width: 1199.98px) {
          .inner__value .inner-stack-item__box-content {
            padding: 70px 0 70px 70px; } }
        @media (max-width: 991.98px) {
          .inner__value .inner-stack-item__box-content {
            margin-top: 0;
            padding: 40px 0 0; } }
    .inner__value .inner-stack-item--1 .flower {
      position: absolute;
      z-index: 2;
      content: '';
      bottom: -137px;
      left: -170px;
      width: 393px;
      height: 377px;
      transform: rotate(186deg) !important;
      background-image: url(../img/icons/flowers/2.svg);
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 991.98px) {
        .inner__value .inner-stack-item--1 .flower {
          bottom: -45px;
          left: -100px;
          width: 253px;
          height: 245px; } }
    .inner__value .inner-stack-item--2 .flower {
      position: absolute;
      z-index: 2;
      content: '';
      bottom: -200px;
      right: -110px;
      width: 373px;
      height: 408px;
      background-image: url(../img/icons/flowers/3.svg);
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 991.98px) {
        .inner__value .inner-stack-item--2 .flower {
          bottom: -100px;
          right: -90px;
          width: 223px;
          height: 248px; } }
    @media (min-width: 768px) {
      .inner__value .inner-stack-item--2 .image {
        order: 2; } }
    @media (min-width: 768px) {
      .inner__value .inner-stack-item--2 .content {
        order: 1; } }
    .inner__value .inner-stack-item--2 .content .inner-stack-item__box-content {
      padding: 70px 120px 70px 0px; }
      @media (max-width: 1199.98px) {
        .inner__value .inner-stack-item--2 .content .inner-stack-item__box-content {
          padding: 70px 70px 70px 0px; } }
      @media (max-width: 991.98px) {
        .inner__value .inner-stack-item--2 .content .inner-stack-item__box-content {
          margin-top: 0;
          padding: 40px 0; } }
    .inner__value .inner-stack-item__play-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      display: inline-block;
      transform: translate(-50%, -50%); }

.intake-form-gallery__card {
  position: relative;
  height: 300px;
  background-size: cover;
  transition: box-shadow .2s ease; }
  .intake-form-gallery__card:before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    content: '';
    height: 100%;
    width: 100%;
    opacity: .9;
    background-image: linear-gradient(#fff, transparent 40%); }
  .intake-form-gallery__card:hover {
    box-shadow: 4px 4px 14px -1px rgba(72, 73, 75, 0.72); }

.intake-form-gallery__card-title {
  position: relative;
  z-index: 2;
  padding-top: 10px;
  color: #353535;
  font-size: 24px;
  font-weight: 700; }

.intake-form-gallery__card-btn {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.75);
  color: #353535;
  text-align: center;
  transition: background .2s ease; }
  .intake-form-gallery__card-btn:hover {
    background-color: white;
    color: #353535; }

.intake-form-gallery__indicator {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 3;
  height: 80px;
  width: 70px;
  background-image: linear-gradient(-50deg, transparent 50%, #797B7F 50%);
  opacity: .7;
  transition: opacity .2s ease; }
  .intake-form-gallery__indicator--on {
    background-image: linear-gradient(-50deg, transparent 50%, #d3636b 50%); }
  .intake-form-gallery__indicator:hover {
    opacity: .9; }

.intake-form-gallery__indicator-off {
  left: 10px;
  position: absolute;
  top: 10px;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%; }

.intake-form-gallery__indicator-on {
  left: 14px;
  position: absolute;
  top: 8px;
  display: none;
  height: 28px;
  width: 18px;
  border-right: 5px solid #fff;
  border-bottom: 5px solid #fff;
  transform: rotate(40deg); }

.intake-form-intro {
  position: relative;
  padding-top: calc(60px + 8vh);
  background-position: center bottom;
  background-size: cover; }
  .intake-form-intro:before {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(40, 167, 69, 0) 75%);
    mix-blend-mode: multiply; }
  .intake-form-intro:after {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    background-image: linear-gradient(to top, white, white 195px, rgba(255, 255, 255, 0) 195px); }
  .intake-form-intro__branch {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    height: 200px;
    overflow: hidden;
    width: 300px;
    transform: scale(0.5) rotate(185deg) translate(-45%, 150%); }
    @media (min-width: 768px) {
      .intake-form-intro__branch {
        display: block; } }
    @media (min-width: 992px) {
      .intake-form-intro__branch {
        transform: scale(0.8) rotate(180deg) translate(-40%, 112%); } }
    @media (min-width: 1200px) {
      .intake-form-intro__branch {
        transform: scale(0.9) rotate(180deg) translate(-40%, 105%); } }
  .intake-form-intro__branch-img {
    left: 0;
    position: absolute;
    bottom: 0;
    max-width: auto; }
  .intake-form-intro__p {
    margin: 0 auto;
    max-width: 680px; }
  .intake-form-intro__fields {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    max-width: 1340px;
    padding-top: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    background-image: linear-gradient(to top, white, white 195px, rgba(255, 255, 255, 0) 295px); }
    @media (min-width: 1200px) {
      .intake-form-intro__fields {
        padding-top: 70px; } }

.intake-form-recommended-design-packages {
  display: none;
  max-width: 485px;
  margin-top: 40px;
  text-align: center; }
  .intake-form-recommended-design-packages__card {
    display: none;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 14px; }
    @media (min-width: 992px) {
      .intake-form-recommended-design-packages__card {
        padding: 28px; } }
    .intake-form-recommended-design-packages__card--teal {
      background-color: #50928b; }
      .intake-form-recommended-design-packages__card--teal p {
        color: #fff; }
    .intake-form-recommended-design-packages__card--green {
      background-color: #b9c882; }
    .intake-form-recommended-design-packages__card--orange {
      background-color: #eca688; }
    .intake-form-recommended-design-packages__card p {
      font-size: 14px;
      max-width: 400px;
      margin: 0 auto 20px; }
  .intake-form-recommended-design-packages__heading {
    color: #818a91;
    font-size: 24px; }
  .intake-form-recommended-design-packages__card-title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    line-height: 1.3;
    font-size: 36px;
    font-weight: bold; }
  .intake-form-recommended-design-packages__price {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.1; }
    @media (min-width: 1200px) {
      .intake-form-recommended-design-packages__price {
        font-size: 30px; } }
    .intake-form-recommended-design-packages__price small {
      font-size: 0.875rem; }

.intake-form-dialog {
  left: 50%;
  position: fixed;
  top: 50%;
  z-index: 10000;
  max-width: 800px;
  max-height: 90vh;
  overflow: auto;
  padding: 20px;
  width: 90%;
  box-shadow: 5px 5px 150px rgba(0, 0, 0, 0.25), 1px 1px 40px rgba(0, 0, 0, 0.5), 0 0 1000000px 1000000px rgba(255, 255, 255, 0.8);
  background-color: #fff;
  transform: translate(-50%, -50%);
  text-align: center; }
  @media (min-width: 992px) {
    .intake-form-dialog {
      padding: 50px; } }
  .intake-form-dialog h1, .intake-form-dialog p {
    color: #353535; }

.intake-form-scope-selection {
  background-color: #EEEEEE;
  text-align: center; }
  .intake-form-scope-selection__icon {
    position: absolute;
    left: 50%;
    top: -50px;
    z-index: 1;
    opacity: .75;
    transform: translate(-50%, 0); }
    @media (min-width: 992px) {
      .intake-form-scope-selection__icon {
        top: 0; } }
  .intake-form-scope-selection__heading {
    position: relative;
    z-index: 10; }
  .intake-form-scope-selection__card {
    align-self: stretch;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 500px;
    justify-content: center;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.95);
    transition: .2s background-color ease-out; }
    .intake-form-scope-selection__card.deactivated {
      background-color: rgba(255, 255, 255, 0.5); }
    .intake-form-scope-selection__card:hover {
      color: inherit; }
    .intake-form-scope-selection__card--teal .intake-form-scope-selection__card-heading {
      color: #50928b; }
    .intake-form-scope-selection__card--teal.selected .intake-form-scope-selection__card-heading {
      color: #fff; }
    .intake-form-scope-selection__card--teal.selected .intake-form-scope-selection__card-description {
      color: #fff; }
    .intake-form-scope-selection__card--teal.selected {
      background-color: #50928b; }
    .intake-form-scope-selection__card--green .intake-form-scope-selection__card-heading {
      color: #b9c882; }
    .intake-form-scope-selection__card--green.selected .intake-form-scope-selection__card-heading {
      color: #fff; }
    .intake-form-scope-selection__card--green.selected {
      background-color: #b9c882; }
    .intake-form-scope-selection__card--orange .intake-form-scope-selection__card-heading {
      color: #eca688; }
    .intake-form-scope-selection__card--orange.selected .intake-form-scope-selection__card-heading {
      color: #fff; }
    .intake-form-scope-selection__card--orange.selected {
      background-color: #eca688; }
  .intake-form-scope-selection__btn {
    color: #fff;
    transition: none; }
  .intake-form-scope-selection__card.selected .intake-form-scope-selection__btn {
    padding: 0;
    font-size: 24px; }
  .intake-form-scope-selection__card--teal .intake-form-scope-selection__btn {
    background: #50928b; }
  .intake-form-scope-selection__card--green .intake-form-scope-selection__btn {
    background: #b9c882; }
  .intake-form-scope-selection__card--orange .intake-form-scope-selection__btn {
    background: #eca688; }
  .intake-form-scope-selection--heading {
    font-size: 32px; }

.intake-form {
  position: relative;
  margin-bottom: 0;
  font-size: 1.25rem; }
  .intake-form__assessment-heading {
    display: none; }
  .intake-form__section {
    position: relative;
    margin: 0 auto;
    max-width: 1340px;
    display: none; }
    .intake-form__section--red {
      color: #d3636b; }
    .intake-form__section--orange {
      color: #eca688; }
    .intake-form__section--teal {
      color: #50928b; }
    .intake-form__section--green-dark {
      color: #8fb04e; }
    .intake-form__section--blue {
      color: #006a7c; }
    .intake-form__section--black {
      color: #353535; }
  .intake-form__section-heading {
    position: relative;
    z-index: 2;
    font-weight: 700;
    text-transform: uppercase; }
    .intake-form__section-heading--small {
      margin-bottom: 0;
      color: #353535;
      font-size: 12px; }
    .intake-form__section-heading--large {
      position: sticky;
      padding: 10px; }
    .intake-form__section-heading--red {
      background-color: #d3636b; }
    .intake-form__section-heading--orange {
      background-color: #eca688; }
    .intake-form__section-heading--teal {
      background-color: #50928b; }
    .intake-form__section-heading--green-dark {
      background-color: #8fb04e; }
    .intake-form__section-heading--blue {
      background-color: #006a7c; }
    .intake-form__section-heading--black {
      background-color: #353535; }
  .intake-form__form {
    width: 100%; }
  .intake-form__heading {
    width: 100%; }
  .intake-form__field-wrap-site_plan_exists:before {
    position: absolute;
    left: -190px;
    top: -80px;
    z-index: -1;
    content: '';
    height: 200px;
    width: 300px;
    background-image: url(../img/icons/branches/4.png);
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(61deg); }
  .intake-form__field-wrap-pets:before {
    position: absolute;
    left: -100px;
    bottom: -80px;
    z-index: -1;
    content: '';
    height: 200px;
    width: 300px;
    background-image: url(../img/icons/branches/4.png);
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(5deg);
    bottom: -110px;
    left: -150px; }
  .intake-form__field-wrap-kitchen_and_dining:before {
    position: absolute;
    left: -150px;
    top: -170px;
    z-index: -1;
    content: '';
    height: 270px;
    width: 450px;
    background-image: url(../img/icons/branches/2.png);
    background-size: contain;
    background-repeat: no-repeat;
    transform: scaleX(-1) rotate(215deg); }
    @media (min-width: 1200px) {
      .intake-form__field-wrap-kitchen_and_dining:before {
        bottom: -110px;
        left: -150px; } }
  .intake-form__field-wrap-fire:before {
    position: absolute;
    left: auto;
    right: -100px;
    bottom: -120px;
    z-index: -1;
    content: '';
    height: 270px;
    width: 450px;
    background-image: url(../img/icons/branches/2.png);
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(-110deg); }
    @media (min-width: 1600px) {
      .intake-form__field-wrap-fire:before {
        right: -140px; } }
  .intake-form__field-wrap-water_management:before {
    position: absolute;
    left: -150px;
    top: -170px;
    z-index: -1;
    content: '';
    height: 270px;
    width: 450px;
    background-image: url(../img/icons/branches/1.png);
    background-size: contain;
    background-repeat: no-repeat;
    transform: scaleX(-1) rotate(200deg); }
    @media (min-width: 1200px) {
      .intake-form__field-wrap-water_management:before {
        top: -180px;
        left: -225px; } }
  .intake-form__aside {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
    .intake-form__aside--site-details {
      right: 0;
      top: -20px;
      width: 150px; }
      @media (min-width: 576px) {
        .intake-form__aside--site-details {
          top: 20px; } }
      @media (min-width: 768px) {
        .intake-form__aside--site-details {
          top: -20px;
          width: 250px;
          transform: scaleX(-1) rotate(-50deg); } }
      @media (min-width: 1200px) {
        .intake-form__aside--site-details {
          right: -100px;
          top: -70px;
          width: 350px;
          transform: scaleX(-1) rotate(-55deg); } }
      @media (min-width: 1500px) {
        .intake-form__aside--site-details {
          right: -100px; } }
    .intake-form__aside--lifestyle {
      top: 30px;
      width: 300px; }
      @media (min-width: 992px) {
        .intake-form__aside--lifestyle {
          top: 90px;
          width: 445px; } }
      @media (min-width: 1200px) {
        .intake-form__aside--lifestyle {
          right: -80px; } }
    .intake-form__aside--design-style {
      right: -50px;
      top: -80px;
      height: 200px;
      width: 200px;
      background-image: url(../img/icons/flowers/9.svg);
      background-position: 0 0;
      background-size: contain;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .intake-form__aside--design-style {
          height: 250px;
          width: 250px; } }
      @media (min-width: 992px) {
        .intake-form__aside--design-style {
          top: -100px;
          height: 300px;
          width: 320px; } }
      @media (min-width: 1200px) {
        .intake-form__aside--design-style {
          right: -80px;
          top: -100px; } }
      @media (min-width: 1600px) {
        .intake-form__aside--design-style {
          left: -280px;
          right: auto;
          z-index: -1;
          height: 400px;
          width: 280px;
          background-size: 380px auto; } }
    .intake-form__aside--wishlist {
      top: 300px; }
      @media (min-width: 992px) {
        .intake-form__aside--wishlist {
          right: -100px;
          transform: scale(0.75); } }
      @media (min-width: 1200px) {
        .intake-form__aside--wishlist {
          top: 670px;
          transform: scale(1); } }
      @media (min-width: 1600px) {
        .intake-form__aside--wishlist {
          right: -100px; } }
    .intake-form__aside--plantings {
      right: -100px;
      transform: scale(0.5); }
      @media (min-width: 992px) {
        .intake-form__aside--plantings {
          right: -100px;
          top: 100px;
          transform: scale(0.75); } }
      @media (min-width: 1200px) {
        .intake-form__aside--plantings {
          top: 150px;
          transform: scale(1); } }
      @media (min-width: 1600px) {
        .intake-form__aside--plantings {
          right: -100px; } }
    .intake-form__aside--budget {
      right: 0;
      top: -20px;
      width: 150px;
      transform: rotate(-50deg); }
      @media (min-width: 576px) {
        .intake-form__aside--budget {
          top: 20px; } }
      @media (min-width: 768px) {
        .intake-form__aside--budget {
          right: 50px;
          top: -20px;
          width: 350px; } }
      @media (min-width: 992px) {
        .intake-form__aside--budget {
          right: 80px;
          top: -70px;
          width: 350px; } }
      @media (min-width: 1200px) {
        .intake-form__aside--budget {
          right: 200px;
          top: -130px;
          width: 450px; } }
      @media (min-width: 1500px) {
        .intake-form__aside--budget {
          right: 150px; } }
  .intake-form__aside-img {
    position: relative;
    z-index: 101; }
  .intake-form__aside-branch {
    position: absolute;
    right: 0;
    top: 0; }
    .intake-form__aside-branch--lifestyle {
      bottom: -140px;
      top: auto;
      z-index: 100;
      width: 280px;
      transform: scaleX(-1) rotate(-10deg); }
      @media (min-width: 1200px) {
        .intake-form__aside-branch--lifestyle {
          right: 0px;
          bottom: -250px;
          width: 350px; } }
    .intake-form__aside-branch--lifestyle {
      bottom: -140px;
      top: auto;
      z-index: 100;
      width: 280px;
      transform: scaleX(-1) rotate(-10deg); }
      @media (min-width: 1200px) {
        .intake-form__aside-branch--lifestyle {
          right: 0px;
          bottom: -250px;
          width: 350px; } }
    .intake-form__aside-branch--wishlist {
      bottom: -140px;
      top: auto;
      z-index: 100;
      width: 280px;
      transform: scaleX(-1) rotate(-10deg); }
      @media (min-width: 1200px) {
        .intake-form__aside-branch--wishlist {
          right: 0px;
          bottom: -150px;
          width: 280px; } }
    .intake-form__aside-branch--plantings {
      bottom: -140px;
      top: auto;
      z-index: 100;
      width: 280px;
      transform: scaleY(-1) rotate(80deg); }
      @media (min-width: 1200px) {
        .intake-form__aside-branch--plantings {
          right: 0px;
          bottom: -220px;
          width: 300px; } }
    .intake-form__aside-branch--plantings-flower {
      right: -180px;
      top: -120px;
      z-index: 400;
      transform: rotate(-15deg); }
  .intake-form__field-list {
    width: 100%;
    margin-bottom: 1rem; }
  .intake-form__form-group {
    position: relative;
    padding-top: 20px;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-weight: 200; }
    .intake-form__form-group--select:after {
      content: "";
      display: block;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid;
      border-bottom: 8px solid;
      position: absolute;
      right: 18px;
      bottom: 24px;
      transform: rotate(-45deg); }
    .intake-form__form-group--focus {
      color: #55595c; }
  .intake-form__label {
    left: 0.75rem;
    position: absolute;
    top: 26px;
    color: inherit;
    opacity: 0;
    font-size: 9px;
    letter-spacing: .5px;
    transition: top 0.3s ease, opacity 0.3s ease;
    text-transform: uppercase;
    margin-bottom: 0; }
  .intake-form__label--visible {
    opacity: 1; }
  .intake-form__label-standard {
    color: #55595c; }
  .intake-form__large-label {
    font-weight: 700;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 18px; }
    .intake-form__large-label--design-style {
      color: #eca688; }
  .intake-form__field {
    appearance: none;
    padding: 20px 14px 10px;
    border: none;
    background-color: #55595c;
    color: inherit;
    font-size: 20px;
    font-weight: 200;
    transition: all 0.15s ease; }
    .intake-form__field:focus {
      background-color: #fff;
      box-shadow: 4px 4px 14px 0 rgba(121, 123, 127, 0.32); }
    .intake-form__field::placeholder {
      color: #ccc; }
    .intake-form__field--select {
      position: relative;
      height: 55px !important;
      padding-bottom: 5px;
      padding-top: 5px; }
    .intake-form__field--textarea {
      height: 120px;
      border: none;
      color: #fff; }
  .intake-form__field-simple {
    appearance: none;
    padding: 20px 14px 10px;
    border: none;
    background-color: #55595c;
    color: #fff;
    font-size: 18px;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-weight: 200;
    transition: all 0.15s ease; }
    .intake-form__field-simple::placeholder {
      color: #ccc; }
    .intake-form__field-simple--textarea {
      height: 120px;
      border: none;
      color: #fff; }
  .intake-form__conclusion {
    display: none;
    background-color: #006a7c;
    background-image: url(../img/icons/branches/4.png);
    background-size: 200px auto;
    background-blend-mode: multiply;
    background-position: right -20px;
    background-repeat: no-repeat; }
    @media (min-width: 992px) {
      .intake-form__conclusion {
        background-size: 300px auto;
        background-position: calc(50% + 500px) 0; } }
    @media (min-width: 1350px) {
      .intake-form__conclusion {
        margin-top: -120px;
        padding-top: 120px;
        background-position: calc(50% + 500px) 70px; } }
    .intake-form__conclusion * {
      color: #fff; }
  .intake-form__conclusion-btn {
    padding: 15px 30px;
    background-color: #8fb04e; }
    .intake-form__conclusion-btn:hover {
      background-color: #a5c071;
      color: #fff; }
  .intake-form__label-question {
    position: relative;
    right: -6px;
    top: -6px;
    display: inline-flex;
    align-items: center;
    padding: 0;
    height: 1.65em;
    width: 1.8em;
    background-color: #939393;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    line-height: 1.1;
    text-align: center; }
    .intake-form__label-question:hover {
      background-color: #b9c882;
      color: #fff; }
    .intake-form__label-question span {
      display: block;
      width: 100%; }

.internship__center {
  margin: 0 auto 70px;
  max-width: 650px;
  text-align: center; }
  .internship__center h2 {
    color: #786F6C; }
  .internship__center h3 {
    color: #786F6C;
    font-weight: bold;
    margin-bottom: 20px; }

.internship__h3 {
  color: #786F6C;
  font-size: 3rem;
  font-weight: bold; }

.internship__intro {
  margin-top: 70px;
  margin-bottom: 40px; }

.internship__columns {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 80px; }
  @media (max-width: 991.98px) {
    .internship__columns {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 40px; } }
  .internship__columns h4 {
    font-weight: bold;
    color: #786F6C;
    font-size: 2rem;
    margin-bottom: 20px; }
  .internship__columns h4 {
    font-weight: bold;
    color: #7fc3ba;
    font-size: 1.5rem;
    margin-bottom: 10px; }
  .internship__columns.mb70 {
    margin-bottom: 70px; }
  .internship__columns--content {
    padding: 50px 0; }
    @media (max-width: 991.98px) {
      .internship__columns--content {
        padding: 0; } }
    .internship__columns--content div {
      margin-bottom: 20px; }
    .internship__columns--content a {
      margin-top: 10px; }
    .internship__columns--content.content-padding {
      padding: 60px 0 0 0; }
      @media (max-width: 991.98px) {
        .internship__columns--content.content-padding {
          text-align: center;
          padding: 30px 0; } }
    @media (max-width: 991.98px) {
      .internship__columns--content {
        order: 1; } }
  .internship__columns--model.model-padding {
    position: relative;
    padding: 0; }
  .internship__columns--model .model {
    position: relative;
    display: block;
    width: 280px;
    margin: 0 0 0 auto;
    z-index: 10; }
    @media (max-width: 991.98px) {
      .internship__columns--model .model {
        width: 170px;
        margin: 0 auto; } }
  @media (max-width: 991.98px) {
    .internship__columns--model {
      order: 2; } }
  .internship__columns--image {
    position: relative;
    width: 100%;
    height: 100%; }
    @media (max-width: 991.98px) {
      .internship__columns--image {
        padding-bottom: 70%; } }
    .internship__columns--image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media (max-width: 991.98px) {
      .internship__columns--image.reverse {
        order: -1; } }
    .internship__columns--image .flower {
      position: absolute; }
      .internship__columns--image .flower img {
        object-fit: contain; }
      .internship__columns--image .flower-intro {
        width: 256px;
        height: 215px;
        left: -114px;
        bottom: -120px;
        z-index: 20; }
        @media (max-width: 991.98px) {
          .internship__columns--image .flower-intro {
            width: 139px;
            height: 215px;
            left: -84px;
            bottom: -82px;
            transform: rotate(135deg); } }

.internship__requirements, .internship__fine {
  padding: 70px 0; }

.internship__fine .flower-fine {
  width: 256px;
  height: 215px;
  right: -114px;
  top: -95px;
  z-index: 20; }
  @media (max-width: 991.98px) {
    .internship__fine .flower-fine {
      width: 139px;
      height: 215px;
      right: -64px; } }

.internship__modules {
  position: relative;
  padding: 90px 0 0;
  background: #d8ece9;
  background: linear-gradient(180deg, #d8ece9 0%, rgba(255, 255, 255, 0.05) 50%, #d8ece9 100%);
  background: -webkit-linear-gradient(180deg, #d8ece9 0%, rgba(255, 255, 255, 0.05) 50%, #d8ece9 100%);
  background: -moz-linear-gradient(180deg, #d8ece9 0%, rgba(255, 255, 255, 0.05) 50%, #d8ece9 100%);
  overflow: hidden; }
  .internship__modules-branch {
    position: absolute;
    right: -50px;
    top: -127px;
    max-width: 350px; }
  .internship__modules-branch-bottom {
    position: absolute;
    right: 100px;
    transform: rotate(84deg);
    bottom: -120px;
    max-width: 350px; }
  .internship__modules .internship__center {
    margin-bottom: 40px; }

.internship__module {
  padding: 30px 0 60px; }
  .internship__module-heading {
    position: relative;
    padding: 30px 0; }
    .internship__module-heading .number {
      position: absolute;
      top: -45px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 8rem;
      color: #464342;
      opacity: .1; }
  .internship__module:nth-child(2) .label, .internship__module:nth-child(5) .label, .internship__module:nth-child(8) .label {
    color: #006a7c; }
  .internship__module:nth-child(2) .internship__module-box--heading, .internship__module:nth-child(5) .internship__module-box--heading, .internship__module:nth-child(8) .internship__module-box--heading {
    background-color: #006a7c; }
  .internship__module:nth-child(3) .label, .internship__module:nth-child(6) .label, .internship__module:nth-child(9) .label {
    color: #d3636b; }
  .internship__module:nth-child(3) .internship__module-box--heading, .internship__module:nth-child(6) .internship__module-box--heading, .internship__module:nth-child(9) .internship__module-box--heading {
    background-color: #d3636b; }
  .internship__module:nth-child(4) .label, .internship__module:nth-child(7) .label, .internship__module:nth-child(10) .label {
    color: #50928b; }
  .internship__module:nth-child(4) .internship__module-box--heading, .internship__module:nth-child(7) .internship__module-box--heading, .internship__module:nth-child(10) .internship__module-box--heading {
    background-color: #50928b; }
  .internship__module h3 {
    font-size: 1.875rem; }
  .internship__module-content strong {
    color: #786F6C;
    padding-right: 3px; }
  .internship__module-content span {
    padding: 0 10px; }
  .internship__module-box {
    background-color: white;
    overflow: hidden;
    margin: 0 60px; }
    @media (max-width: 991.98px) {
      .internship__module-box {
        margin: 0; } }
    .internship__module-box--heading {
      background-color: #006a7c;
      text-align: center;
      color: white;
      padding: 15px;
      font-weight: bold;
      letter-spacing: 1px; }
    .internship__module-box--content {
      padding: 40px 50px; }
      @media (max-width: 991.98px) {
        .internship__module-box--content {
          padding: 20px; } }
      .internship__module-box--content ul {
        margin-bottom: 0;
        list-style: none;
        column-count: 2;
        column-gap: 20px; }
        @media (max-width: 991.98px) {
          .internship__module-box--content ul {
            column-count: 1; } }
        .internship__module-box--content ul li::before {
          content: "\2022";
          color: #7fc3ba;
          font-weight: bold;
          display: inline-block;
          width: 1.5em;
          margin-left: -1.5em; }
      .internship__module-box--content div {
        text-align: center; }

.intro {
  margin: auto;
  margin-bottom: 100px;
  max-width: 785px;
  text-align: center; }
  .intro__container {
    margin-top: 100px;
    padding-bottom: 60px;
    position: relative; }
  .intro h1 {
    color: #7FC3BA; }
  .intro--2 {
    position: relative;
    max-width: 650px; }
    @media (min-width: 768px) {
      .intro--2 {
        left: 50%;
        width: calc(50% - 20px); } }
    @media (min-width: 992px) {
      .intro--2 {
        left: 45%;
        width: calc(55% - 20px); } }
  .intro--2 h1 {
    color: #D3636B; }
  .intro--2 p:first-of-type {
    color: #d3636a;
    font-weight: bold; }
  .intro__img--model {
    height: 500px;
    position: absolute;
    bottom: -60px;
    right: 55%; }
    @media (max-width: 767.98px) {
      .intro__img--model {
        display: none; } }
    @media (min-width: 992px) {
      .intro__img--model {
        right: 60%; } }
  .intro__img--model img {
    max-height: 100%;
    width: auto;
    max-width: none; }
  .intro__img--flower {
    position: absolute;
    bottom: 0;
    right: 10px;
    height: 141px;
    width: 150px;
    transition: opacity 0.2s ease-out; }
    @media (max-width: 575.98px) {
      .intro__img--flower {
        height: 113px;
        width: 120px; } }
    @media (min-width: 992px) {
      .intro__img--flower {
        height: 187px;
        width: 200px;
        right: 70px; } }
    @media (min-width: 1200px) {
      .intro__img--flower {
        height: 208px;
        width: 220px;
        right: calc((100% - 1190px) / 2); } }
  .intro__img--flower.hidden {
    opacity: 0; }
  .intro__img--branch--left {
    position: absolute;
    left: 0;
    z-index: -1;
    top: 180px;
    width: 120px; }
    @media (min-width: 768px) {
      .intro__img--branch--left {
        top: 170px;
        width: 200px; } }
    @media (min-width: 992px) {
      .intro__img--branch--left {
        top: 100px; } }
    @media (min-width: 1200px) {
      .intro__img--branch--left {
        top: 0;
        width: 300px; } }
  .intro__img--branch--right {
    position: absolute;
    right: 0;
    top: 210px;
    z-index: -1;
    width: 120px; }
    @media (min-width: 768px) {
      .intro__img--branch--right {
        top: 150px;
        width: 150px; } }
    @media (min-width: 992px) {
      .intro__img--branch--right {
        top: 100px; } }

.irrigation-packages {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  color: #464342;
  margin-bottom: calc(1rem + 4vh); }
  .irrigation-packages__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .irrigation-packages__item {
    position: relative;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 1rem; }
    @media (min-width: 992px) {
      .irrigation-packages__item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
  .irrigation-packages__highlight {
    padding: 10px;
    background-color: #3e847b;
    color: #e7f4f2;
    letter-spacing: .1em;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .irrigation-packages__highlight {
        left: 15px;
        position: absolute;
        right: 15px;
        top: 0;
        margin: 0;
        width: calc(100% - 30px);
        transform: translateY(-100%); } }
  .irrigation-packages__link {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 30px 15px;
    transition: transform 0.2s; }
    .irrigation-packages__link--green {
      background-color: #b9c882; }
    .irrigation-packages__link--blue {
      background-color: #006a7c; }
    .irrigation-packages__link--blue-dark {
      background-color: #272f39; }
    .irrigation-packages__link--green-dark {
      background-color: #8fb04e; }
    .irrigation-packages__link--teal {
      background-color: #50928b; }
    .irrigation-packages__link--teal-light {
      background-color: #7fc3ba; }
    .irrigation-packages__link--orange {
      background-color: #eca688; }
    .irrigation-packages__link--red {
      background-color: #d3636b; }
    .irrigation-packages__link--yellow {
      background-color: #eadf6e; }
    .irrigation-packages__link--black {
      background-color: #353535; }
    .irrigation-packages__link--gray-dark {
      background-color: #464342; }
    .irrigation-packages__link--gray {
      background-color: #939393; }
    .irrigation-packages__link--gray-medium {
      background-color: #575755; }
    .irrigation-packages__link--gray-light {
      background-color: #bbbdc0; }
    .irrigation-packages__link--gray-lighter {
      background-color: #f4f4f4; }
  .irrigation-packages__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    width: 100%;
    line-height: 1;
    text-align: center; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .irrigation-packages__title {
        font-size: 2.75rem; } }
  .irrigation-packages__details {
    font-size: .9375rem;
    width: 100%;
    margin-bottom: auto;
    margin-top: 0;
    color: #464342; }
    .irrigation-packages__details h5 {
      font-size: 1.5rem; }
  .irrigation-packages__link--blue .irrigation-packages__details {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #80B4BD; }
  .irrigation-packages__details-wrap {
    margin-bottom: auto; }
    .irrigation-packages__details-wrap ul {
      list-style: none; }
    .irrigation-packages__details-wrap li {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
      .irrigation-packages__details-wrap li:last-child {
        border-bottom: none; }
  .irrigation-packages__price {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 1.1;
    color: #fff;
    margin-top: auto;
    padding-top: 12px;
    width: 100%; }
    @media (min-width: 1200px) {
      .irrigation-packages__price {
        font-size: 30px; } }
    .irrigation-packages__price small {
      font-size: 0.875rem; }
  .irrigation-packages__button {
    height: 40px;
    margin: 0 auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem; }

.landscape-detail-page__featured-image-wrap {
  position: relative;
  margin-bottom: calc(1rem + 4vh); }
  .landscape-detail-page__featured-image-wrap:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%; }
  .landscape-detail-page__featured-image-wrap > * {
    position: absolute;
    top: 0;
    display: block;
    width: 100%; }

.landscapes-listing {
  position: relative;
  padding-bottom: calc(1rem + 4vh); }
  .landscapes-listing__wrap {
    background-image: url("../img/landscapes/background.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom; }
  .landscapes-listing__list {
    position: relative; }
  .landscapes-listing__item {
    margin-bottom: 20px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 517px; }
    @media (min-width: 768px) {
      .landscapes-listing__item {
        flex-wrap: nowrap; } }
    .landscapes-listing__item--design {
      min-height: 0;
      display: block; }
  .landscapes-listing__content {
    text-align: center;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    order: 2;
    width: 100%; }
    @media (min-width: 768px) {
      .landscapes-listing__content {
        width: 60%;
        order: 1; } }
  .landscapes-listing__item--design-packages .landscapes-listing__content {
    color: white;
    background: #b9c882; }
  .landscapes-listing__item--frontyards .landscapes-listing__content {
    color: white;
    background: #7fc3ba; }
  .landscapes-listing__item--backyards .landscapes-listing__content {
    color: white;
    background: #eca688; }
  .landscapes-listing__item--design .landscapes-listing__content {
    width: 100%;
    max-width: 675px;
    margin: auto; }
  .landscapes-listing__item--design-packages .landscapes-listing__icon {
    max-width: 150px; }
  .landscapes-listing__category {
    width: 100%;
    font-size: 18px;
    margin-top: 2rem;
    margin-bottom: 1rem; }
  .landscapes-listing__link {
    text-decoration: none; }
  .landscapes-listing__title {
    color: #464342;
    font-weight: bold;
    margin-bottom: 0.6em;
    font-size: calc(3.75rem/1.75 + 2vw); }
  .landscapes-listing__image-wrap {
    position: relative;
    background-color: #eceeef;
    overflow: hidden;
    width: 100%;
    order: 1; }
    .landscapes-listing__image-wrap:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 50%; }
    .landscapes-listing__image-wrap > * {
      position: absolute;
      top: 0;
      display: block;
      width: 100%; }
    @media (min-width: 768px) {
      .landscapes-listing__image-wrap {
        width: 50%;
        order: 2; } }
  .landscapes-listing__image {
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: auto; }
    @media (min-width: 768px) {
      .landscapes-listing__image {
        width: auto; } }

/*
|--------------------------------------------------------------------------
| Main Nav
|
| Using the label and input as for the mobile version.
|--------------------------------------------------------------------------
*/
#olark-wrapper .olark-launch-button {
  width: 60px !important;
  height: 60px !important; }

.main-nav {
  display: none;
  background-color: #fff;
  text-align: left;
  font-size: 18px; }
  @media (max-width: 575.98px) {
    .main-nav {
      display: block;
      left: 0;
      position: fixed;
      top: 0;
      z-index: 3000;
      height: 60px;
      width: 60px; } }
  @media (min-width: 576px) {
    .main-nav__inner {
      margin-left: -10px;
      margin-right: -10px; } }
  @media (min-width: 992px) {
    .main-nav__inner {
      margin-left: -20px;
      margin-right: -20px; } }
  .main-nav__list {
    margin: 0;
    background-color: #fff; }
    @media (max-width: 575.98px) {
      .main-nav__list {
        position: fixed;
        margin-top: -12px;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        transition: transform .25s ease-out;
        transform: scaleY(0);
        transform-origin: 75% top; } }
    @media (min-width: 576px) and (max-width: 991.98px) {
      .main-nav__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    @media (min-width: 576px) {
      .main-nav__list {
        width: 100%; } }
    @media (min-width: 992px) {
      .main-nav__list {
        margin-bottom: -15px;
        background-color: transparent; } }
  .main-nav__item {
    display: block; }
    @media (max-width: 575.98px) {
      .main-nav__item:not(:last-child) {
        border-bottom: 1px solid #bbbdc0; } }
    @media (min-width: 576px) {
      .main-nav__item {
        display: inline-block; } }
    @media (min-width: 992px) {
      .main-nav__item {
        margin: 0 1rem; } }
    .main-nav__item-parent {
      padding-bottom: 10px; }
    .main-nav__item-child {
      list-style: none;
      margin: 0;
      padding: 0 0 0 24px; }
      .main-nav__item-child li {
        font-size: 14px;
        padding: 0; }
  .main-nav__link {
    color: #464342;
    transition: color 0.2s;
    display: inline-block;
    width: 100%;
    padding: 10px 1.5rem; }
    .main-nav__link:hover, .main-nav__link:focus {
      color: #939393;
      outline: 0; }
    .main-nav__link:active {
      color: #6d6d6d; }
    @media (min-width: 576px) {
      .main-nav__link {
        width: auto;
        font-size: 16px;
        padding: 10px 5px; } }
    @media (min-width: 768px) {
      .main-nav__link {
        font-size: inherit; } }
  .main-nav__item--active .main-nav__link {
    color: #b9c882; }
  .main-nav__trigger {
    position: relative;
    margin: 0;
    padding: 20px 15px;
    width: 100%; }
    @media (min-width: 576px) {
      .main-nav__trigger {
        display: none; } }
  .main-nav__bullet {
    left: -99999px;
    position: absolute;
    top: -99999px;
    appearance: none;
    outline: 0 !important; }
    @media (min-width: 576px) {
      .main-nav__bullet {
        display: none; } }
  .main-nav__bullet:checked ~ .main-nav__list {
    display: block;
    transform: scaleY(1); }

.main-nav-mega {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  width: 100%;
  position: absolute;
  bottom: 30px; }
  @media (max-width: 991.98px) {
    .main-nav-mega {
      z-index: 900;
      background-color: #fff;
      bottom: 0px; } }
  @media (max-width: 575.98px) {
    .main-nav-mega {
      display: none; } }
  .main-nav-mega nav {
    text-align: center;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 60px; }
    .main-nav-mega nav ul {
      list-style: none;
      padding: 0 20px;
      margin: 0;
      height: 60px; }
      .main-nav-mega nav ul li {
        display: inline-block;
        padding-bottom: 6px;
        font-size: 18px;
        padding: 0 8px;
        transition: all ease .5s; }
        .main-nav-mega nav ul li a {
          color: #464342;
          padding: 0px 40px;
          text-decoration: none;
          height: 60px;
          line-height: 60px; }
          @media (max-width: 991.98px) {
            .main-nav-mega nav ul li a {
              padding: 0px 20px; } }
          .main-nav-mega nav ul li a:after {
            content: "›";
            position: absolute;
            transform: rotate(90deg);
            margin-left: 10px;
            font-size: 22px;
            font-family: auto; }
        .main-nav-mega nav ul li:hover {
          color: #939393;
          transition: all ease .5s; }
        .main-nav-mega nav ul li:hover > a {
          color: #939393; }
        .main-nav-mega nav ul li:hover > ul {
          visibility: visible;
          opacity: 1;
          transition: all ease .5s;
          z-index: 900; }
      .main-nav-mega nav ul ul {
        background: #ffffff;
        padding: 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: all ease .5s;
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0px;
        height: auto; }
        @media (max-width: 991.98px) {
          .main-nav-mega nav ul ul {
            top: 60px; } }
        .main-nav-mega nav ul ul.menu-dropdown {
          display: flex;
          padding: 40px;
          box-shadow: -1px 17px 14px 4px rgba(0, 0, 0, 0.05);
          -webkit-box-shadow: -1px 17px 14px 4px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: -1px 17px 14px 4px rgba(0, 0, 0, 0.05); }
          @media (max-width: 991.98px) {
            .main-nav-mega nav ul ul.menu-dropdown {
              padding: 20px; } }
        .main-nav-mega nav ul ul li {
          flex: 1 1 0%; }
          .main-nav-mega nav ul ul li a {
            width: 100%;
            padding: 0; }
            .main-nav-mega nav ul ul li a:after {
              content: none; }
          .main-nav-mega nav ul ul li .menu-card {
            width: 100%;
            text-align: center;
            border: solid 1px #e7e7e7;
            padding: 0;
            border-radius: 10px;
            background-color: #fff; }
            .main-nav-mega nav ul ul li .menu-card:hover {
              box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
              transition: all ease .2s;
              transform: translateY(-1px);
              cursor: pointer; }
            .main-nav-mega nav ul ul li .menu-card__image {
              position: relative;
              width: 100%;
              padding-bottom: 50%;
              background-color: #7fc3ba;
              border-radius: 10px 10px 0 0; }
              .main-nav-mega nav ul ul li .menu-card__image img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; }
            .main-nav-mega nav ul ul li .menu-card__content {
              font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
              line-height: 1.8;
              padding: 15px;
              font-size: 15px; }
              .main-nav-mega nav ul ul li .menu-card__content span {
                display: block;
                font-size: 20px;
                font-weight: 600; }
            .main-nav-mega nav ul ul li .menu-card.blue {
              background-color: #7fc3ba; }
            .main-nav-mega nav ul ul li .menu-card.green {
              background-color: #b9c882; }
            .main-nav-mega nav ul ul li .menu-card.peach {
              background-color: #eca688; }

.maintenance-add-ons {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  color: #464342;
  padding-bottom: calc(1rem + 3vh); }
  .maintenance-add-ons__title {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .maintenance-add-ons__title h4 {
      margin-bottom: 0.5rem; }
    .maintenance-add-ons__title small {
      font-family: "Neue Helvetica eText", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
      font-size: 0.75rem;
      font-weight: 300; }
    @media (min-width: 992px) {
      .maintenance-add-ons__title {
        flex: 0 0 25%;
        max-width: 25%; } }
  .maintenance-add-ons__list-wrap {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 992px) {
      .maintenance-add-ons__list-wrap {
        flex: 0 0 75%;
        max-width: 75%; } }
  .maintenance-add-ons__list > li {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
    @media (min-width: 576px) {
      .maintenance-add-ons__list > li {
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: 2rem;
        margin-bottom: 2rem; } }
    @media (min-width: 992px) {
      .maintenance-add-ons__list > li {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
    .maintenance-add-ons__list > li:before {
      content: '+';
      color: #006a7c;
      border: 0;
      font-size: 3rem;
      line-height: 1.4rem;
      transform: none; }
    .maintenance-add-ons__list > li strong {
      font-size: 1rem;
      display: block; }
    .maintenance-add-ons__list > li > :last-child {
      margin-bottom: 0; }

.maintenance-callout {
  align-self: stretch;
  color: white; }
  .maintenance-callout__inner {
    text-align: center;
    border-radius: 10px; }
  @media (max-width: 767px) {
    .maintenance-callout--1 {
      margin-bottom: 30px; } }
  .maintenance-callout--1 .maintenance-callout__inner {
    background-color: #50928b;
    background-image: url(../img/hydrate-bg@2x.png);
    background-size: cover;
    background-position: center; }
  .maintenance-callout--1 .maintenance-callout__image {
    margin-bottom: 20px;
    max-width: 233px; }
  .maintenance-callout--2 .maintenance-callout__inner {
    background-color: #7fc3ba;
    overflow: hidden; }
    .maintenance-callout--2 .maintenance-callout__inner:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.3;
      width: 100%;
      height: 100%;
      background-image: url(../img/billy-dashes.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
    .maintenance-callout--2 .maintenance-callout__inner .btn, .maintenance-callout--2 .maintenance-callout__inner .about__section--refer-a-friend a, .about__section--refer-a-friend .maintenance-callout--2 .maintenance-callout__inner a, .maintenance-callout--2 .maintenance-callout__inner .careers-callout__content-wrap a, .careers-callout__content-wrap .maintenance-callout--2 .maintenance-callout__inner a {
      position: relative !important;
      z-index: 2 !important; }
  .maintenance-callout--2 .maintenance-callout__image {
    max-width: 240px; }
  .maintenance-callout h3 {
    font-size: 28px; }
  .maintenance-callout p {
    margin-bottom: 10px;
    line-height: 1.5; }
    @media (min-width: 768px) {
      .maintenance-callout p {
        max-width: 320px;
        padding-bottom: 0;
        width: 75%; } }
  .maintenance-callout .link {
    font-weight: bold; }
  .maintenance-callout__inner {
    height: 100%;
    padding: 40px;
    background-size: cover;
    background-position: bottom right; }

.maintenance-electric {
  background-color: #eca688;
  border-radius: 10px;
  padding: 80px 20px;
  color: #fff;
  position: relative;
  overflow: hidden; }
  .maintenance-electric:after {
    content: '';
    position: absolute;
    bottom: -60px;
    opacity: 0.3;
    left: 30px;
    width: 280px;
    height: 240px;
    transform: rotate(74deg);
    background-image: url(../img/icons/branches/1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  .maintenance-electric h3 {
    font-weight: bold; }
  .maintenance-electric p {
    margin-bottom: 0; }

.maintenance-garden-gloves {
  padding: 80px 20px;
  background-color: #b9c882;
  background-image: url(../img/icons/branches/6.png);
  background-repeat: no-repeat;
  background-position: -20px -60px;
  background-size: 295px auto;
  color: #353535;
  text-align: center;
  border-radius: 10px; }
  .maintenance-garden-gloves h2 {
    color: #fff; }
  .maintenance-garden-gloves p {
    margin-bottom: 18px;
    line-height: 1.75; }
  .maintenance-garden-gloves__content-wrap {
    margin: 0 auto;
    max-width: 700px; }

@media (min-width: 992px) {
  .maintenance-items__wrap {
    padding-bottom: 30px;
    padding-top: 280px;
    margin-top: -250px;
    background-image: linear-gradient(to right, white 50%, white 50%); } }

@media (min-width: 992px) {
  .maintenance-items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px; } }

@media (min-width: 1200px) {
  .maintenance-items {
    margin-bottom: 60px; } }

.maintenance-items--included h2 {
  color: #50928b; }

@media (min-width: 992px) {
  .maintenance-items--included .maintenance-items__copy {
    padding-right: 30px; } }

@media (min-width: 1200px) {
  .maintenance-items--included .maintenance-items__aside {
    height: 500px;
    max-height: 500px; } }

@media (min-width: 1200px) {
  .maintenance-items--included .maintenance-items__image-wrap {
    height: 500px;
    max-height: 500px; } }

.maintenance-items--addons h2 {
  color: #d3636b; }

@media (min-width: 992px) {
  .maintenance-items--addons .maintenance-items__copy {
    padding-left: 30px;
    order: 2; } }

.maintenance-items--addons .maintenance-items__aside {
  order: 1; }
  @media (min-width: 1200px) {
    .maintenance-items--addons .maintenance-items__aside {
      height: 350px; } }

@media (min-width: 992px) {
  .maintenance-items__copy {
    width: 45%; } }

.maintenance-items__copy p {
  margin-bottom: 16px;
  font-size: 110%; }

.maintenance-items__copy li {
  position: relative;
  list-style: none; }
  .maintenance-items__copy li:before {
    left: -10px;
    position: absolute;
    top: 8px;
    content: '';
    height: 8px;
    width: 8px;
    background-color: #b9c882;
    border-radius: 50%; }

.maintenance-items__copy strong {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif; }

.maintenance-items__aside {
  position: relative;
  margin-bottom: 40px;
  max-height: 400px;
  max-width: 615px; }
  @media (min-width: 992px) {
    .maintenance-items__aside {
      width: 55%;
      height: 320px; } }

.maintenance-items__image-wrap {
  position: relative;
  overflow: hidden;
  max-height: 400px; }

.maintenance-items__image {
  width: 100%; }

.maintenance-items__flower {
  bottom: 0;
  position: absolute; }
  .maintenance-items__flower--left {
    width: 250px;
    left: 0;
    transform: translate(-70%, 30%) rotate(60deg); }
    @media (min-width: 992px) {
      .maintenance-items__flower--left {
        left: 60px; } }
  .maintenance-items__flower--right {
    right: 0;
    max-width: 330px;
    transform: translate(50%, 33%); }

.maintenance-landing__header {
  position: relative;
  z-index: 30;
  height: auto !important; }

.maintenance-landing__site-logo svg {
  position: relative;
  width: 150px;
  margin-bottom: 20px; }

.maintenance-landing__page-banner {
  padding-top: 0px; }
  @media (max-width: 767.98px) {
    .maintenance-landing__page-banner {
      padding-top: 90px; } }

.maintenance-landing__banner {
  display: flex;
  gap: 1em; }
  @media (max-width: 991.98px) {
    .maintenance-landing__banner {
      display: block; } }
  .maintenance-landing__banner > * {
    flex: 1; }
  .maintenance-landing__banner-flower {
    position: absolute;
    right: -67px;
    top: -28px;
    width: 187px;
    transform: rotate(28deg);
    z-index: 20; }
    @media (max-width: 991.98px) {
      .maintenance-landing__banner-flower {
        right: -40px;
        top: -59px;
        width: 154px; } }
    @media (max-width: 767.98px) {
      .maintenance-landing__banner-flower {
        top: -80px; } }
  .maintenance-landing__banner-image {
    position: relative;
    text-align: center; }
    .maintenance-landing__banner-image img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 85%;
      object-fit: cover; }
      @media (max-width: 991.98px) {
        .maintenance-landing__banner-image img {
          position: relative;
          width: 93%;
          height: auto;
          margin: 0 auto 40px;
          top: 0;
          transform: translateY(0%); } }

.maintenance-landing__form {
  max-width: 550px;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
  @media (max-width: 991.98px) {
    .maintenance-landing__form {
      margin: 0 auto; } }

.maintenance-landing__packages {
  margin-top: 80px; }
  @media (max-width: 991.98px) {
    .maintenance-landing__packages {
      margin-top: 40px; } }
  .maintenance-landing__packages-heading {
    text-align: center;
    margin-bottom: 40px; }

@media (max-width: 991.98px) {
  .maintenance-landing__plan {
    padding: 40px 0; } }

.maintenance-landing__plan-card {
  padding: 15px 20px !important;
  font-size: 16px !important; }

.maintenance-landing__gradient-3 {
  background: #fff;
  background: linear-gradient(180deg, rgba(185, 200, 130, 0.1) 0%, rgba(185, 200, 130, 0.1) 90%, #fff 100%);
  background: -webkit-linear-gradient(180deg, rgba(185, 200, 130, 0.1) 0%, rgba(185, 200, 130, 0.1) 90%, #fff 100%);
  background: -moz-linear-gradient(180deg, rgba(185, 200, 130, 0.1) 0%, rgba(185, 200, 130, 0.1) 90%, #fff 100%); }

.maintenance-landing__nurture h2 {
  color: #50928b; }

.maintenance-landing__cta {
  padding-top: 140px;
  padding-bottom: 20px;
  overflow: visible;
  background: #fff;
  background: linear-gradient(180deg, #fff 0%, rgba(185, 200, 130, 0.1) 30%, rgba(185, 200, 130, 0.1) 100%);
  background: -webkit-linear-gradient(180deg, #fff 0%, rgba(185, 200, 130, 0.1) 30%, rgba(185, 200, 130, 0.1) 100%);
  background: -moz-linear-gradient(180deg, #fff 0%, rgba(185, 200, 130, 0.1) 30%, rgba(185, 200, 130, 0.1) 100%); }

.maintenance-landing__form-bottom {
  text-align: center;
  margin-top: -50px; }

.maintenance-landing__logo {
  width: 100px;
  margin: 40px auto; }

@media (max-width: 991.98px) {
  .maintenance-landing__flower {
    width: 143px !important;
    height: 155px !important; } }

.maintenance-packages {
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
  color: #464342;
  margin-bottom: calc(1rem + 4vh); }
  .maintenance-packages__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .maintenance-packages__item {
    position: relative;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 1rem; }
    @media (min-width: 992px) {
      .maintenance-packages__item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
  .maintenance-packages__highlight {
    padding: 10px;
    background-color: #3e847b;
    color: #e7f4f2;
    letter-spacing: .1em;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .maintenance-packages__highlight {
        left: 15px;
        position: absolute;
        right: 15px;
        top: 0;
        margin: 0;
        width: calc(100% - 30px);
        transform: translateY(-100%); } }
  .maintenance-packages__link {
    display: block;
    padding: 30px 15px;
    transition: transform 0.2s; }
    .maintenance-packages__link--green {
      background-color: #b9c882; }
    .maintenance-packages__link--blue {
      background-color: #006a7c; }
    .maintenance-packages__link--blue-dark {
      background-color: #272f39; }
    .maintenance-packages__link--green-dark {
      background-color: #8fb04e; }
    .maintenance-packages__link--teal {
      background-color: #50928b; }
    .maintenance-packages__link--teal-light {
      background-color: #7fc3ba; }
    .maintenance-packages__link--orange {
      background-color: #eca688; }
    .maintenance-packages__link--red {
      background-color: #d3636b; }
    .maintenance-packages__link--yellow {
      background-color: #eadf6e; }
    .maintenance-packages__link--black {
      background-color: #353535; }
    .maintenance-packages__link--gray-dark {
      background-color: #464342; }
    .maintenance-packages__link--gray {
      background-color: #939393; }
    .maintenance-packages__link--gray-medium {
      background-color: #575755; }
    .maintenance-packages__link--gray-light {
      background-color: #bbbdc0; }
    .maintenance-packages__link--gray-lighter {
      background-color: #f4f4f4; }
  .maintenance-packages__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    margin-left: -15px;
    margin-right: -15px;
    line-height: 1; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .maintenance-packages__title {
        font-size: 2.75rem; } }
  .maintenance-packages__details {
    font-size: .9375rem;
    width: 100%;
    display: table;
    color: #464342; }
    @media (min-width: 1200px) {
      .maintenance-packages__details {
        height: 130px; } }
    .maintenance-packages__details h5 {
      font-size: 1.5rem; }
  .maintenance-packages__link--blue .maintenance-packages__details {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #80B4BD; }
  .maintenance-packages__details-wrap {
    display: table-cell;
    vertical-align: middle; }
    .maintenance-packages__details-wrap > :last-child {
      margin-bottom: 0; }
  .maintenance-packages__price {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 1.1;
    color: #fff;
    margin-bottom: 1.5rem; }
    @media (min-width: 1200px) {
      .maintenance-packages__price {
        font-size: 30px; } }
    .maintenance-packages__price small {
      font-size: 0.875rem; }
  .maintenance-packages__button {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }

.maintenance__services {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px; }
  @media (max-width: 991.98px) {
    .maintenance__services {
      grid-template-columns: repeat(1, minmax(0, 1fr)); } }
  .maintenance__services-box {
    display: grid;
    padding: 50px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
    @media (max-width: 991.98px) {
      .maintenance__services-box {
        padding: 30px; } }
    .maintenance__services-box.blue {
      background-color: #d8ece9; }
    .maintenance__services-box h3 {
      font-weight: bold; }
    .maintenance__services-box ul {
      margin-bottom: 0;
      list-style: none; }
      .maintenance__services-box ul li::before {
        content: "\2022";
        color: #b9c882;
        font-weight: bold;
        display: inline-block;
        font-size: 16px;
        width: 1.75em;
        margin-left: -1.7em; }
  .maintenance__services-button {
    padding: 60px 0;
    text-align: center; }

.map {
  margin-bottom: calc(1rem + 2vh); }
  .map__iframe {
    max-width: 100%; }

.menu-icon {
  width: 30px;
  height: 24px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer; }
  .menu-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #353535;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    transform-origin: left center; }
    .menu-icon span:nth-child(1) {
      top: 0px; }
    .menu-icon span:nth-child(2) {
      top: 9px; }
    .menu-icon span:nth-child(3) {
      top: 18px; }

:checked ~ * .menu-icon span {
  background: #006a7c; }
  :checked ~ * .menu-icon span:nth-child(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 5px; }
  :checked ~ * .menu-icon span:nth-child(2) {
    width: 0%;
    opacity: 0; }
  :checked ~ * .menu-icon span:nth-child(3) {
    transform: rotate(-45deg);
    top: 21px;
    left: 5px; }

.nourish-banner {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
  line-height: 1.75em;
  padding: 70px 0 70px 0;
  background-color: #b9c882; }
  .nourish-banner::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 450px;
    height: 500px;
    background-image: url(../img/nourish-grass.svg);
    background-size: contain;
    background-repeat: no-repeat; }
  .nourish-banner__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .nourish-banner__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .nourish-banner .fluid-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
    z-index: 1; }
  .nourish-banner__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991.98px) {
      .nourish-banner__flex {
        display: block;
        padding: 0 10px; } }
  .nourish-banner__box {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 50%; }
    @media (max-width: 991.98px) {
      .nourish-banner__box {
        width: 100%; } }
    .nourish-banner__box-content p:last-child {
      margin-bottom: 0; }
    .nourish-banner__box-full {
      width: 100%; }
    .nourish-banner__box .width-sm {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto; }
    .nourish-banner__box .btn, .nourish-banner__box .about__section--refer-a-friend a, .about__section--refer-a-friend .nourish-banner__box a, .nourish-banner__box .careers-callout__content-wrap a, .careers-callout__content-wrap .nourish-banner__box a {
      margin-top: 30px; }
  .nourish-banner__logo {
    width: 250px;
    margin-bottom: 20px; }
  .nourish-banner__heading {
    color: white;
    font-size: 4em;
    max-width: 1000px;
    margin: 0 auto 20px auto; }
    @media (max-width: 991.98px) {
      .nourish-banner__heading {
        font-size: 3.5em; } }
  .nourish-banner__intro {
    color: white;
    font-size: 1.25rem;
    line-height: 1.5; }

.nourish-flower .inner-stack-item--3 .flower {
  position: absolute;
  z-index: 2;
  content: '';
  bottom: -207px;
  left: -230px;
  width: 493px;
  height: 437px;
  transform: rotate(227deg) !important;
  background-image: url(../img/icons/flowers/4.svg);
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 991.98px) {
    .nourish-flower .inner-stack-item--3 .flower {
      bottom: -113px;
      left: -200px;
      width: 343px;
      height: 245px; } }

@media (max-width: 767.98px) {
  .nourish-flower .inner-stack-item {
    padding: 0; } }

.nourish-blue-box {
  padding: 80px 20px;
  background-color: #006a7c;
  background-image: url(../img/icons/branches/6.png);
  background-repeat: no-repeat;
  background-position: -20px -60px;
  background-size: 295px auto;
  color: #353535;
  text-align: center;
  border-radius: 10px; }
  .nourish-blue-box h2 {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 30px; }
  .nourish-blue-box p {
    margin-bottom: 18px;
    line-height: 1.75; }
  .nourish-blue-box__content-wrap {
    margin: 0 auto;
    max-width: 1000px; }

.package-table {
  margin-bottom: 60px;
  border-width: 0;
  border: none;
  border-spacing: 0;
  background-color: #fff;
  width: 100%; }
  .package-table .bg-color--blue {
    background-color: #006a7c !important; }
  .package-table__width-1 {
    width: 34%; }
  .package-table__width-2 {
    width: 23%; }
  .package-table__no-border-bottom {
    border-bottom: none !important; }
  .package-table__no-padding {
    padding: 0 !important; }
  .package-table * {
    border-color: #fff; }
  .package-table h2 {
    font-size: 30px; }
  .package-table thead, .package-table tbody {
    background-color: #fff; }
  .package-table tr {
    background-color: #fff;
    border-bottom: #ebeaea;
    border-width: 2px;
    border-style: solid; }
  .package-table td {
    border-bottom: 0;
    max-width: 300px;
    padding: 8px;
    text-align: center;
    vertical-align: center; }
  .package-table td:first-of-type {
    max-width: 400px;
    text-align: left; }
    .package-table td:first-of-type p {
      margin-bottom: auto;
      margin-top: auto;
      vertical-align: center;
      padding-bottom: 0;
      padding-right: 20px; }
  .package-table td:nth-child(2) {
    background-color: #f4f4f4; }
  .package-table td:nth-child(4) {
    background-color: #f4f4f4; }
  .package-table tr:last-of-type td {
    padding: 0; }
  .package-table tr:last-of-type {
    border: none; }

.packages {
  margin-bottom: 100px;
  font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif; }
  .packages input[type="radio"] {
    display: none; }
  .packages__box {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    padding: 60px; }
    @media (max-width: 991.98px) {
      .packages__box {
        padding: 60px 20px; } }
    .packages__box-heading {
      text-align: center;
      margin-bottom: 60px;
      color: #939393; }
      @media (max-width: 991.98px) {
        .packages__box-heading {
          margin-bottom: 40px; } }
      .packages__box-heading h3 {
        font-weight: bold;
        font-size: 2em;
        margin-bottom: 15px; }
      .packages__box-heading .step-label {
        text-transform: uppercase;
        font-size: 0.8em;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center; }
    .packages__box-form--btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 60px; }
      .packages__box-form--btn .btn.sm, .packages__box-form--btn .about__section--refer-a-friend a.sm, .about__section--refer-a-friend .packages__box-form--btn a.sm, .packages__box-form--btn .careers-callout__content-wrap a.sm, .careers-callout__content-wrap .packages__box-form--btn a.sm {
        color: #939393;
        margin-top: 10px;
        letter-spacing: normal; }
        .packages__box-form--btn .btn.sm:after, .packages__box-form--btn .about__section--refer-a-friend a.sm:after, .about__section--refer-a-friend .packages__box-form--btn a.sm:after, .packages__box-form--btn .careers-callout__content-wrap a.sm:after, .careers-callout__content-wrap .packages__box-form--btn a.sm:after {
          content: ' ›'; }
    .packages__box-form .form__card {
      display: flex;
      gap: 30px; }
      @media (max-width: 991.98px) {
        .packages__box-form .form__card {
          flex-direction: column; } }
      .packages__box-form .form__card.mb60 {
        margin-bottom: 60px; }
      .packages__box-form .form__card-box {
        display: flex;
        width: 100%;
        margin-bottom: 100px; }
        @media (max-width: 991.98px) {
          .packages__box-form .form__card-box {
            flex-direction: column; } }
      .packages__box-form .form__card label {
        position: relative; }
      .packages__box-form .form__card label:before {
        content: url("../img/pricing/unchecked.svg");
        position: absolute;
        z-index: 100;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%); }
      .packages__box-form .form__card :checked + label:before {
        content: url("../img/pricing/checked.svg"); }
      .packages__box-form .form__card input[type="checkbox"] {
        display: none; }
      .packages__box-form .form__card input[type="radio"] {
        display: none !important; }
      .packages__box-form .form__card-item {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 10px;
        color: #939393;
        background-color: #fff;
        border: solid 1px #e7e7e7;
        transition: all 0.2s ease; }
        .packages__box-form .form__card-item:hover {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
        .packages__box-form .form__card-item--image {
          width: 100%;
          padding-bottom: 70%;
          border-radius: 10px 10px 0 0;
          background-size: cover;
          background-position: center bottom;
          background-repeat: no-repeat;
          position: relative;
          background-color: #b9c882; }
          .packages__box-form .form__card-item--image.personality {
            padding-bottom: 100%; }
        .packages__box-form .form__card-item .property-lawn {
          background-image: url(../img/pricing/pricing-property-lawn.svg); }
        .packages__box-form .form__card-item .property-trees {
          background-image: url(../img/pricing/pricing-property-trees.svg); }
        .packages__box-form .form__card-item .property-garden {
          background-image: url(../img/pricing/pricing-property-garden.svg); }
        .packages__box-form .form__card-item .personality-entertainer {
          background-image: url(../img/pricing/pricing-personality-entertainer.svg); }
        .packages__box-form .form__card-item .personality-perfectionist {
          background-image: url(../img/pricing/pricing-personality-perfectionist.svg); }
        .packages__box-form .form__card-item .personality-naturalist {
          background-image: url(../img/pricing/pricing-personality-naturalist.svg); }
        .packages__box-form .form__card-item--content {
          padding: 30px;
          font-size: 18px;
          text-align: center; }
          .packages__box-form .form__card-item--content strong {
            display: block;
            color: #353535;
            margin-bottom: 10px; }
    .packages__box-form .package__card {
      display: flex;
      gap: 30px;
      justify-content: center;
      margin-bottom: 60px; }
      @media (max-width: 991.98px) {
        .packages__box-form .package__card {
          flex-direction: column; } }
      .packages__box-form .package__card-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        color: #939393;
        background-color: #fff;
        border: solid 1px #e7e7e7;
        transition: all 0.2s ease;
        position: relative; }
        .packages__box-form .package__card-item.top-radius {
          border-radius: 0 0 10px 10px; }
        .packages__box-form .package__card-item .checked-border {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: solid 5px #b9c882;
          border-radius: 5px; }
        .packages__box-form .package__card-item--header {
          padding: 20px 40px;
          text-align: center;
          border-radius: 5px 5px 0 0;
          margin-bottom: 40px; }
          .packages__box-form .package__card-item--header .type h4 {
            color: white;
            font-weight: bold;
            margin: 0; }
          .packages__box-form .package__card-item--header.green {
            background-color: #b9c882; }
          .packages__box-form .package__card-item--header.red {
            background-color: #d3636b; }
          .packages__box-form .package__card-item--header.teal {
            background-color: #50928b; }
        .packages__box-form .package__card-item--price {
          color: #353535;
          font-weight: bold;
          font-size: 1.5em;
          border-top: solid 1px #e7e7e7;
          border-bottom: solid 1px #e7e7e7;
          background-color: #f4f4f4;
          padding: 10px 30px;
          text-align: center;
          margin-top: auto; }
        .packages__box-form .package__card-item--list {
          padding: 0 40px 20px; }
          .packages__box-form .package__card-item--list strong {
            display: block;
            color: #353535;
            margin-bottom: 10px; }
        .packages__box-form .package__card-item--automower {
          padding: 20px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          /* Rounded sliders */ }
          @media (max-width: 767.98px) {
            .packages__box-form .package__card-item--automower {
              flex-direction: column; } }
          .packages__box-form .package__card-item--automower label {
            margin-bottom: 0; }
            .packages__box-form .package__card-item--automower label:before {
              content: none; }
          .packages__box-form .package__card-item--automower .switch {
            position: relative;
            display: inline-block;
            width: 55px;
            height: 25px;
            margin-right: 10px; }
            @media (max-width: 1199.98px) {
              .packages__box-form .package__card-item--automower .switch {
                width: 75px; } }
            @media (max-width: 991.98px) {
              .packages__box-form .package__card-item--automower .switch {
                width: 55px; } }
            @media (max-width: 767.98px) {
              .packages__box-form .package__card-item--automower .switch {
                margin: 0 0 10px; } }
            .packages__box-form .package__card-item--automower .switch input {
              opacity: 0;
              width: 0;
              height: 0; }
          .packages__box-form .package__card-item--automower .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s; }
            .packages__box-form .package__card-item--automower .slider:before {
              position: absolute;
              content: "";
              height: 16px;
              width: 16px;
              left: 7px;
              bottom: 4px;
              background-color: white;
              -webkit-transition: .4s;
              transition: .4s; }
          .packages__box-form .package__card-item--automower input:checked + .slider {
            background-color: #b9c882; }
            .packages__box-form .package__card-item--automower input:checked + .slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px); }
          .packages__box-form .package__card-item--automower input:focus + .slider {
            box-shadow: 0 0 1px #b9c882; }
          .packages__box-form .package__card-item--automower .slider.round {
            border-radius: 34px; }
            .packages__box-form .package__card-item--automower .slider.round:before {
              border-radius: 50%; }
    .packages__box-form .automower-description {
      padding: 50px;
      margin: 0 auto;
      border: solid 1px #e7e7e7;
      border-radius: 10px;
      margin-bottom: 60px;
      background-color: #f4f4f4; }
      .packages__box-form .automower-description h4 {
        font-weight: bold; }
      .packages__box-form .automower-description strong {
        font-size: 1.1em;
        display: block;
        margin-bottom: 20px; }
      .packages__box-form .automower-description p:last-child {
        margin-bottom: 0; }
      .packages__box-form .automower-description__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 40px; }
        .packages__box-form .automower-description__grid-billy {
          padding: 40px; }
    .packages__box-form .maintenance {
      margin-bottom: 60px; }
      .packages__box-form .maintenance__services-box {
        border: solid 1px #e7e7e7;
        box-shadow: none; }
        .packages__box-form .maintenance__services-box h4 {
          font-weight: bold; }
    .packages__box-form .contact-info {
      max-width: 400px;
      margin: 0 auto;
      text-align: center; }
      .packages__box-form .contact-info strong {
        font-size: 1.6em; }
      .packages__box-form .contact-info ul {
        margin-top: 30px;
        margin-bottom: 0; }
      .packages__box-form .contact-info__label {
        float: left;
        color: #939393;
        padding-left: 1px;
        margin-bottom: 3px; }

.pricing-slider {
  /* ::::::::::::::::::::Range Slider Styles::::::::::::::::::::::::: */
  /* Remove Range Sliders Default Styles*/
  /* Track */
  /* Thumb */
  /* JS Stykes */
  /* Changes Thumb color to darker green when mousedownn */ }
  .pricing-slider .product-range-wrapper {
    margin: 0px auto; }
    .pricing-slider .product-range-wrapper .product-range-block {
      width: 100%;
      margin-bottom: 60px; }
      @media (max-width: 767.98px) {
        .pricing-slider .product-range-wrapper .product-range-block {
          margin-bottom: 0px; } }
      .pricing-slider .product-range-wrapper .product-range-block .size-chart-block {
        text-align: center; }
        .pricing-slider .product-range-wrapper .product-range-block .size-chart-block #img {
          height: 300px; }
      .pricing-slider .product-range-wrapper .product-range-block #slider_count {
        margin: 0px auto;
        padding: 40px;
        text-align: center;
        color: #939393; }
        .pricing-slider .product-range-wrapper .product-range-block #slider_count strong {
          font-size: 1.2em;
          color: #353535; }
  .pricing-slider .range-slider-block {
    margin: 0px auto;
    width: 100%;
    text-align: center;
    position: relative; }
    .pricing-slider .range-slider-block .price-label {
      display: flex;
      color: #939393;
      padding-top: 36px; }
      @media (max-width: 767.98px) {
        .pricing-slider .range-slider-block .price-label {
          flex-direction: column; } }
      .pricing-slider .range-slider-block .price-label p {
        flex: 1;
        text-align: center;
        padding: 20px;
        position: relative;
        line-height: 1.5; }
        @media (max-width: 767.98px) {
          .pricing-slider .range-slider-block .price-label p {
            text-align: left;
            padding: 0 0 0 40px;
            margin-bottom: 10px; } }
        .pricing-slider .range-slider-block .price-label p:before {
          content: '';
          position: absolute;
          top: -40px;
          left: 50%;
          transform: translateX(-50%);
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #E1E1E2; }
          @media (max-width: 767.98px) {
            .pricing-slider .range-slider-block .price-label p:before {
              top: 10px;
              left: 0;
              transform: translateX(0%); } }
        .pricing-slider .range-slider-block .price-label p strong {
          margin-bottom: 10px;
          display: block; }
          @media (max-width: 767.98px) {
            .pricing-slider .range-slider-block .price-label p strong {
              margin-bottom: 0px; } }
      .pricing-slider .range-slider-block .price-label.active {
        color: #353535; }
  .pricing-slider #range-slider {
    padding: 0;
    width: 89%;
    background-color: transparent;
    z-index: 22;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0; }
    @media (max-width: 767.98px) {
      .pricing-slider #range-slider {
        bottom: -11px;
        left: -152px;
        transform: translateX(0%);
        top: 0;
        transform: rotate(90deg);
        width: 320px; } }
  .pricing-slider input[type=range] {
    -webkit-appearance: none; }
  .pricing-slider input[type=range]::-webkit-slider-runnable-track {
    height: 8px;
    background: #E1E1E2;
    border: none;
    border-radius: 6px; }
  .pricing-slider input[type=range]:focus {
    outline: none; }
  .pricing-slider input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 60px;
    width: 60px;
    z-index: 90;
    border-radius: 50%;
    background-image: url(../img/pricing/slider-button.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -25px;
    transition: box-shadow 0.5s; }
    @media (max-width: 767.98px) {
      .pricing-slider input[type=range]::-webkit-slider-thumb {
        height: 40px;
        width: 40px;
        margin-top: -16px; } }
  .pricing-slider input[type=range]:hover::-webkit-slider-thumb {
    box-shadow: 0 0 0 10pt rgba(190, 190, 190, 0.4);
    cursor: pointer; }
  .pricing-slider input[type=range].hover-ring::-webkit-slider-thumb {
    box-shadow: 0 0 0 6pt rgba(0, 0, 0, 0.15);
    cursor: pointer; }
  .pricing-slider input[type=range].hover-ring-out::-webkit-slider-thumb {
    box-shadow: 0 0 0 0pt rgba(0, 0, 0, 0);
    cursor: pointer; }

/* Tabs */
.step-tab {
  display: none;
  overflow: visible; }

.prevBtn {
  position: relative;
  background-color: #f4f4f4;
  width: 25px;
  height: 25px;
  margin-top: -4px;
  margin-right: 10px;
  border: none;
  border-radius: 50px; }
  .prevBtn:after {
    content: '‹';
    position: absolute;
    font-size: 2em;
    color: #939393;
    font-weight: bold;
    top: 46%;
    left: 48%;
    transform: translate(-50%, -50%); }

#error-message {
  left: 50%;
  transform: translateX(-50%); }

.page-banner {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.75em;
  background: rgba(0, 106, 124, 0.1);
  background: linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(244, 244, 244, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(244, 244, 244, 0) 100%);
  background: -moz-linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(244, 244, 244, 0) 100%); }
  @media (max-width: 575.98px) {
    .page-banner {
      margin-bottom: 1rem; } }
  .page-banner .fluid-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0; }
  .page-banner__flower {
    position: absolute;
    left: -107px;
    top: -57px;
    width: 291px;
    transform: rotate(-5deg); }
    @media (max-width: 991.98px) {
      .page-banner__flower {
        left: -47px;
        top: -50px;
        width: 201px; } }
    @media (max-width: 575.98px) {
      .page-banner__flower {
        left: -47px;
        top: 10px; } }
  .page-banner__flower-right {
    position: absolute;
    right: -67px;
    top: -97px;
    width: 291px;
    transform: rotate(38deg); }
    @media (max-width: 991.98px) {
      .page-banner__flower-right {
        right: -47px;
        top: -70px;
        width: 201px; } }
    @media (max-width: 575.98px) {
      .page-banner__flower-right {
        right: -47px;
        top: -70px; } }
  .page-banner__space {
    padding: 130px 0; }
    @media (max-width: 991.98px) {
      .page-banner__space {
        padding: 60px 0 0; } }
  .page-banner__space-sm {
    padding: 20px 0 0 0; }
  .page-banner__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991.98px) {
      .page-banner__flex {
        display: block; } }
  .page-banner__box {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 50%; }
    @media (max-width: 991.98px) {
      .page-banner__box {
        width: 100%; } }
    .page-banner__box-image {
      position: relative;
      width: 100%;
      display: block; }
      .page-banner__box-image:after {
        content: "";
        display: block;
        padding-bottom: 80%; }
      .page-banner__box-image img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto; }
        @media only screen and (max-width: 1500px) {
          .page-banner__box-image img {
            border-radius: 10px 0 0 10px; } }
        @media (max-width: 991.98px) {
          .page-banner__box-image img {
            border-radius: 0; } }
    .page-banner__box-content {
      padding: 70px 15px 70px 15px; }
      .page-banner__box-content p:last-child {
        margin-bottom: 0; }
      @media (max-width: 1199.98px) {
        .page-banner__box-content {
          padding: 70px 15px 70px 15px; } }
      @media (max-width: 991.98px) {
        .page-banner__box-content {
          text-align: center;
          padding: 70px; } }
      @media (max-width: 767.98px) {
        .page-banner__box-content {
          text-align: center;
          padding: 70px 20px 20px; } }
    .page-banner__box-full {
      width: 100%; }
    .page-banner__box .width-sm {
      max-width: 750px;
      margin-left: auto;
      margin-right: auto; }
    .page-banner__box .btn, .page-banner__box .about__section--refer-a-friend a, .about__section--refer-a-friend .page-banner__box a, .page-banner__box .careers-callout__content-wrap a, .careers-callout__content-wrap .page-banner__box a {
      margin-top: 20px; }
  .page-banner__play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    width: 150px;
    height: 150px;
    display: inline-block;
    transform: translate(-50%, -50%); }
  .page-banner__heading {
    color: #50928b;
    font-size: 4em;
    max-width: 1000px;
    margin: 0 auto 20px auto; }
    @media (max-width: 991.98px) {
      .page-banner__heading {
        font-size: 3.5em; } }
  .page-banner__intro {
    color: #939393;
    padding-right: 60px; }
    @media (max-width: 991.98px) {
      .page-banner__intro {
        padding-right: 0; } }

.vimeo-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

.vimeo-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; }

.vimeo-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold; }
  .vimeo-close:hover, .vimeo-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer; }

.page-hero {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.75em;
  text-align: center; }
  @media (max-width: 575.98px) {
    .page-hero {
      margin-bottom: 1rem; } }
  .page-hero--colored-background {
    color: #464342; }
  .page-hero__wrapper, .project-header__wrapper {
    padding-top: 190px; }
  .page-hero--overlap-none .page-hero__wrapper, .page-hero--overlap-none .project-header__wrapper {
    padding-bottom: 80px; }
  .page-hero--overlap-sm .page-hero__wrapper, .page-hero--overlap-sm .project-header__wrapper {
    padding-bottom: 10%; }
  .page-hero--overlap-md .page-hero__wrapper, .page-hero--overlap-md .project-header__wrapper {
    padding-bottom: 15%; }
  .page-hero--overlap-lg .page-hero__wrapper, .page-hero--overlap-lg .project-header__wrapper {
    padding-bottom: 20%; }
  .page-hero__icon {
    position: absolute;
    top: 50px;
    left: 50%;
    max-height: 90px;
    transform: translateX(-50%); }
  .page-hero__category {
    font-size: inherit;
    text-transform: uppercase;
    text-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
    color: white;
    margin-bottom: 8px; }
  .page-hero--colored-background .page-hero__category {
    text-shadow: none; }
  .page-hero--careers-post .page-hero__category {
    text-shadow: none; }
  .page-hero__heading, .project-header__heading {
    color: white;
    max-width: 640px;
    margin: auto;
    margin-bottom: 1rem; }
  .page-hero--homepage .page-hero__heading, .page-hero--homepage .project-header__heading {
    position: relative;
    z-index: 2;
    margin-bottom: 2rem;
    font-style: italic;
    text-shadow: 0 2px 34px rgba(0, 0, 0, 0.5); }
    @media (max-width: 575.98px) {
      .page-hero--homepage .page-hero__heading, .page-hero--homepage .project-header__heading {
        font-size: 32px;
        text-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
        /* fixes bug on Android chrome browsers that bumps the shadow down to a new line if the shadow is too big for the container */ } }
  @media (min-width: 1200px) {
    .page-hero--homepage .page-hero__wrapper, .page-hero--homepage .project-header__wrapper {
      padding-bottom: 355px;
      padding-top: 245px; } }
  .page-hero--projects .page-hero__heading, .page-hero--projects .project-header__heading {
    margin-top: -8%; }
  .page-hero__intro {
    max-width: 640px;
    margin: auto; }
    .page-hero__intro p {
      line-height: 1.3; }
  .page-hero__flower--homepage {
    width: 200px;
    left: -50px;
    top: -50px;
    position: absolute; }
    @media (min-width: 768px) {
      .page-hero__flower--homepage {
        left: -200px;
        top: -100px;
        width: auto; } }
  .page-hero--landscapes, .page-hero--blueridge {
    background-color: #006a7c;
    color: white; }
  .page-hero--landscapes .page-hero__heading, .page-hero--landscapes .project-header__heading {
    max-width: 500px; }
  .page-hero--design-packages {
    color: #fff; }
  .page-hero--design-packages .page-hero__intro {
    margin-bottom: 160px; }
  .page-hero--careers-post .page-hero__wrapper, .page-hero--careers-post .project-header__wrapper {
    padding-top: 120px;
    padding-bottom: 30px;
    text-align: left; }
    @media (max-width: 575.98px) {
      .page-hero--careers-post .page-hero__wrapper, .page-hero--careers-post .project-header__wrapper {
        padding-top: 80px; } }
  .page-hero--careers-post .page-hero__category {
    text-transform: none;
    font-size: 30px; }
  .page-hero--careers-post .page-hero__heading, .page-hero--careers-post .project-header__heading {
    margin: 10px 0 0;
    max-width: 700px; }
  .page-hero--pay {
    padding-bottom: 70px;
    padding-top: 70px; }
    @media (min-width: 992px) {
      .page-hero--pay {
        padding-top: 100px;
        padding-bottom: 100px; } }
  .page-hero--pay .page-hero__heading, .page-hero--pay .project-header__heading {
    margin-bottom: 28px; }
  .page-hero--pay .page-hero__wrapper, .page-hero--pay .project-header__wrapper {
    padding-top: 0; }
  .page-hero--irrigation {
    background-color: #006a7c; }
    .page-hero--irrigation * {
      color: #fff; }
  .page-hero--irrigation .page-hero__category {
    text-shadow: none; }
  .page-hero--irrigation .page-hero__icon {
    top: 70px; }
  .page-hero--irrigation .page-hero__wrapper, .page-hero--irrigation .project-header__wrapper {
    padding-bottom: 6%;
    padding-top: calc(4% + 140px); }
  .page-hero--irrigation-landing {
    background-color: #006a7c; }
    .page-hero--irrigation-landing * {
      color: #fff; }
  .page-hero--irrigation-landing .page-hero__category {
    text-shadow: none; }
  .page-hero--irrigation-landing .page-hero__icon {
    top: 70px; }
  .page-hero--irrigation-landing .page-hero__wrapper, .page-hero--irrigation-landing .project-header__wrapper {
    padding-bottom: 20%;
    padding-top: calc(4% + 140px); }
  .page-hero--automowers-landing {
    position: relative;
    background-color: #000;
    background-position: -50px 60px; }
    @media (min-width: 576px) {
      .page-hero--automowers-landing {
        background-position: center 60px; } }
    @media (min-width: 768px) {
      .page-hero--automowers-landing {
        background-position: center center; } }
    @media (min-width: 1750px) {
      .page-hero--automowers-landing {
        background-position: center calc(50% - 75px); } }
    @media (min-width: 2200px) {
      .page-hero--automowers-landing {
        background-position: center calc(50% - 150px); } }
    .page-hero--automowers-landing:before {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 60px;
      content: '';
      display: block;
      background-image: linear-gradient(black, rgba(0, 0, 0, 0));
      mix-blend-mode: multiply; }
      @media (min-width: 768px) {
        .page-hero--automowers-landing:before {
          top: 0; } }
  .page-hero--automowers {
    background-color: #006a7c; }
  .page-hero--automowers .page-hero__wrapper, .page-hero--automowers .project-header__wrapper, .page-hero--irrigation .page-hero__wrapper, .page-hero--irrigation .project-header__wrapper {
    padding-bottom: 5%; }
  .page-hero--automowers-landing .page-hero__wrapper, .page-hero--automowers-landing .project-header__wrapper {
    padding-bottom: 40%;
    padding-top: 60px; }
    @media (min-width: 768px) {
      .page-hero--automowers-landing .page-hero__wrapper, .page-hero--automowers-landing .project-header__wrapper {
        padding-top: 120px; } }
  .page-hero--automowers-landing .page-hero__icon {
    max-width: 320px; }
    @media (min-width: 992px) {
      .page-hero--automowers-landing .page-hero__icon {
        left: 0;
        transform: translateX(0); } }
  @media (min-width: 992px) {
    .page-hero--automowers-landing .page-hero__heading, .page-hero--automowers-landing .project-header__heading {
      margin-left: 0;
      line-height: 1;
      text-align: left; } }
  .page-hero--automowers-landing .page-hero__intro p {
    max-width: 500px;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700; }
  @media (min-width: 992px) {
    .page-hero--automowers-landing .page-hero__intro {
      margin-left: 0;
      text-align: left; } }

.plan-card {
  width: 100%;
  text-align: center;
  padding: 0;
  border-radius: 10px;
  color: #939393;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
  .plan-card__image {
    width: 100%;
    padding-bottom: 50%;
    border-radius: 10px 10px 0 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative; }
    .plan-card__image .number {
      font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #fff;
      font-size: 3em;
      font-weight: 700;
      line-height: 1; }
      @media (max-width: 991.98px) {
        .plan-card__image .number {
          font-size: 2em; } }
      @media (max-width: 767.98px) {
        .plan-card__image .number {
          font-size: 3em; } }
      .plan-card__image .number span {
        display: block;
        opacity: 0.8;
        margin: 0 0 5px 0;
        font-size: 1.2em; }
    .plan-card__image.blue {
      background-color: #7fc3ba; }
    .plan-card__image.green {
      background-color: #b9c882; }
    .plan-card__image.peach {
      background-color: #eca688; }
  .plan-card__content {
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    line-height: 1.8;
    padding: 15px 30px;
    font-size: 18px; }

.product-price {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  background: #7fc3ba;
  color: white;
  font-size: 1rem;
  font-weight: normal;
  padding: 10px 15px; }
  .product-price--orange {
    background: #eca688; }
  .product-price--green {
    background-color: #b9c882; }
  .product-price--blue {
    background-color: #006a7c; }
  .product-price--blue-dark {
    background-color: #272f39; }
  .product-price--green-dark {
    background-color: #8fb04e; }
  .product-price--teal {
    background-color: #50928b; }
  .product-price--teal-light {
    background-color: #7fc3ba; }
  .product-price--orange {
    background-color: #eca688; }
  .product-price--red {
    background-color: #d3636b; }
  .product-price--yellow {
    background-color: #eadf6e; }
  .product-price--black {
    background-color: #353535; }
  .product-price--gray-dark {
    background-color: #464342; }
  .product-price--gray {
    background-color: #939393; }
  .product-price--gray-medium {
    background-color: #575755; }
  .product-price--gray-light {
    background-color: #bbbdc0; }
  .product-price--gray-lighter {
    background-color: #f4f4f4; }

.products-listing-v2 .inner-stack-item-v2 {
  padding: 0 0 120px 0; }
  @media (max-width: 991.98px) {
    .products-listing-v2 .inner-stack-item-v2 {
      padding: 30px 0; } }
  .products-listing-v2 .inner-stack-item-v2__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991.98px) {
      .products-listing-v2 .inner-stack-item-v2__flex {
        display: block; } }
  .products-listing-v2 .inner-stack-item-v2__box {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center; }
    .products-listing-v2 .inner-stack-item-v2__box.image {
      width: 50%; }
      @media (max-width: 991.98px) {
        .products-listing-v2 .inner-stack-item-v2__box.image {
          width: 70%;
          margin: 0 auto; } }
      @media (max-width: 767.98px) {
        .products-listing-v2 .inner-stack-item-v2__box.image {
          width: 100%; } }
    .products-listing-v2 .inner-stack-item-v2__box.content {
      width: 50%; }
      @media (max-width: 991.98px) {
        .products-listing-v2 .inner-stack-item-v2__box.content {
          width: 100%; } }
    .products-listing-v2 .inner-stack-item-v2__box-image {
      position: relative;
      width: 100%;
      display: block; }
      .products-listing-v2 .inner-stack-item-v2__box-image:after {
        content: "";
        display: block;
        padding-bottom: 80%; }
      .products-listing-v2 .inner-stack-item-v2__box-image img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto; }
    .products-listing-v2 .inner-stack-item-v2__box-content {
      position: relative;
      z-index: 2;
      padding: 40px 0 40px 120px; }
      @media (max-width: 1199.98px) {
        .products-listing-v2 .inner-stack-item-v2__box-content {
          padding: 70px 0 70px 70px; } }
      @media (max-width: 991.98px) {
        .products-listing-v2 .inner-stack-item-v2__box-content {
          margin-top: 0;
          padding: 40px 0 0; } }
      .products-listing-v2 .inner-stack-item-v2__box-content .body-text p {
        margin-bottom: 20px;
        color: #575755; }
      .products-listing-v2 .inner-stack-item-v2__box-content ul {
        list-style-type: disc;
        margin-bottom: 30px;
        color: #575755; }
  .products-listing-v2 .inner-stack-item-v2--2 .flower {
    position: absolute;
    z-index: 2;
    content: '';
    bottom: -150px;
    right: -80px;
    width: 273px;
    height: 288px;
    background-image: url(../img/icons/flowers/3.svg);
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 991.98px) {
      .products-listing-v2 .inner-stack-item-v2--2 .flower {
        bottom: -100px;
        right: -90px;
        width: 223px;
        height: 248px; } }
  @media (min-width: 768px) {
    .products-listing-v2 .inner-stack-item-v2--2 .image,
    .products-listing-v2 .inner-stack-item-v2--4 .image,
    .products-listing-v2 .inner-stack-item-v2--6 .image,
    .products-listing-v2 .inner-stack-item-v2--8 .image,
    .products-listing-v2 .inner-stack-item-v2--10 .image,
    .products-listing-v2 .inner-stack-item-v2--12 .image {
      order: 2; } }
  @media (min-width: 768px) {
    .products-listing-v2 .inner-stack-item-v2--2 .content,
    .products-listing-v2 .inner-stack-item-v2--4 .content,
    .products-listing-v2 .inner-stack-item-v2--6 .content,
    .products-listing-v2 .inner-stack-item-v2--8 .content,
    .products-listing-v2 .inner-stack-item-v2--10 .content,
    .products-listing-v2 .inner-stack-item-v2--12 .content {
      order: 1; } }
  .products-listing-v2 .inner-stack-item-v2--2 .content .inner-stack-item-v2__box-content,
  .products-listing-v2 .inner-stack-item-v2--4 .content .inner-stack-item-v2__box-content,
  .products-listing-v2 .inner-stack-item-v2--6 .content .inner-stack-item-v2__box-content,
  .products-listing-v2 .inner-stack-item-v2--8 .content .inner-stack-item-v2__box-content,
  .products-listing-v2 .inner-stack-item-v2--10 .content .inner-stack-item-v2__box-content,
  .products-listing-v2 .inner-stack-item-v2--12 .content .inner-stack-item-v2__box-content {
    padding: 70px 120px 70px 0px; }
    @media (max-width: 1199.98px) {
      .products-listing-v2 .inner-stack-item-v2--2 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--4 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--6 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--8 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--10 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--12 .content .inner-stack-item-v2__box-content {
        padding: 70px 70px 70px 0px; } }
    @media (max-width: 991.98px) {
      .products-listing-v2 .inner-stack-item-v2--2 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--4 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--6 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--8 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--10 .content .inner-stack-item-v2__box-content,
      .products-listing-v2 .inner-stack-item-v2--12 .content .inner-stack-item-v2__box-content {
        margin-top: 0;
        padding: 40px 0; } }

.products-listing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white; }
  @media (max-width: 575.98px) {
    .products-listing {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px; } }
  .products-listing__flower {
    position: absolute;
    right: 20px;
    top: 0;
    max-width: 25%;
    transform: translateY(-40%);
    z-index: 2; }
    @media (max-width: 575.98px) {
      .products-listing__flower {
        right: 0;
        transform: translateY(-50%); } }
  .products-listing__item {
    position: relative;
    margin-bottom: 3rem; }
    .products-listing__item--green {
      background-color: #b9c882; }
    .products-listing__item--blue {
      background-color: #006a7c; }
    .products-listing__item--blue-dark {
      background-color: #272f39; }
    .products-listing__item--green-dark {
      background-color: #8fb04e; }
    .products-listing__item--teal {
      background-color: #50928b; }
    .products-listing__item--teal-light {
      background-color: #7fc3ba; }
    .products-listing__item--orange {
      background-color: #eca688; }
    .products-listing__item--red {
      background-color: #d3636b; }
    .products-listing__item--yellow {
      background-color: #eadf6e; }
    .products-listing__item--black {
      background-color: #353535; }
    .products-listing__item--gray-dark {
      background-color: #464342; }
    .products-listing__item--gray {
      background-color: #939393; }
    .products-listing__item--gray-medium {
      background-color: #575755; }
    .products-listing__item--gray-light {
      background-color: #bbbdc0; }
    .products-listing__item--gray-lighter {
      background-color: #f4f4f4; }
    @media (min-width: 992px) {
      .products-listing__item {
        margin-bottom: 2rem; } }
    @media (min-width: 992px) {
      .products-listing__item--with-features {
        padding-bottom: 43.38488%; } }
  .products-listing__background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    z-index: 0; }
    .products-listing__background:before {
      left: 0;
      position: absolute;
      top: 0;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)); }
  .products-listing__additional-image {
    height: 50%;
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 2px solid #fff;
    box-sizing: content-box; }
    .products-listing__additional-image:last-child {
      top: auto;
      bottom: 0;
      border-top: 2px solid #fff; }
    @media (max-width: 767.98px) {
      .products-listing__additional-image {
        display: none; } }
  .products-listing__content-wrap {
    padding: 8.5%;
    padding-top: 2rem;
    position: relative;
    z-index: 1; }
  .products-listing__item--with-features .products-listing__content-wrap {
    padding-bottom: 1rem; }
    @media (min-width: 992px) {
      .products-listing__item--with-features .products-listing__content-wrap {
        position: absolute;
        top: 10%;
        left: 0; } }
    @media (min-width: 1200px) {
      .products-listing__item--with-features .products-listing__content-wrap {
        top: 25%; } }
  @media (min-width: 992px) {
    .products-listing__item--with-features:nth-child(2n) .products-listing__content-wrap {
      left: 16.5%; } }
  .products-listing__title {
    color: inherit;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.25), 0 0 40px rgba(0, 0, 0, 0.125); }
    @media (min-width: 1200px) {
      .products-listing__title {
        font-size: 60px; } }
  .products-listing__content p {
    line-height: 1.5;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 0, 0, 0.25); }
  @media (min-width: 768px) {
    .products-listing__content {
      width: 60%; } }
  .products-listing__features {
    width: 100%;
    padding-left: 8.5%;
    padding-right: 2rem;
    background-color: inherit;
    position: relative; }
    @media (min-width: 768px) {
      .products-listing__features {
        display: flex;
        align-items: center;
        justify-content: space-between; } }
    @media (min-width: 992px) {
      .products-listing__features {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 75%; } }
  @media (min-width: 992px) {
    .products-listing__features--with-2-children {
      max-width: 50%; } }
  @media (min-width: 992px) {
    .products-listing__item:nth-child(2n) .products-listing__features {
      left: auto;
      right: 0;
      padding-right: 8.5%;
      padding-left: 2rem; } }
  .products-listing__item--blue-dark .products-listing__features {
    color: #9CB0CA; }
  .products-listing__item--yellow .products-listing__features {
    color: #464342; }
  .products-listing__feature {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem; }
    @media (min-width: 768px) {
      .products-listing__feature {
        width: 33.33333%; } }
  @media (min-width: 768px) {
    .products-listing__features--with-2-children .products-listing__feature {
      width: 50%; } }
  .products-listing__feature-image-wrap {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    width: 50px;
    height: 50px; }
    @media (min-width: 768px) {
      .products-listing__feature-image-wrap {
        width: 70px;
        height: 70px; } }
    @media (min-width: 1200px) {
      .products-listing__feature-image-wrap {
        width: 90px;
        height: 90px; } }
  .products-listing__feature-image {
    width: 100%; }
  .products-listing__feature-title {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.3;
    text-transform: uppercase;
    margin-left: 10px;
    padding-left: 50px;
    padding-right: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
    @media (min-width: 768px) {
      .products-listing__feature-title {
        padding-left: 70px; } }
    @media (min-width: 1200px) {
      .products-listing__feature-title {
        padding-left: 90px; } }
  .products-listing__link {
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 18px;
    color: #575755;
    padding: 5px 8.5%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1; }
    @media (min-width: 992px) {
      .products-listing__link {
        padding: 1rem 1.5rem;
        top: auto;
        bottom: 0;
        color: #fff; } }
  @media (min-width: 992px) {
    .products-listing__item--with-features:nth-child(2n+1) .products-listing__link {
      left: auto;
      right: 0; } }

.project-header {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.75em;
  background: rgba(0, 106, 124, 0.1);
  background: linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(244, 244, 244, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(244, 244, 244, 0) 100%);
  background: -moz-linear-gradient(180deg, rgba(0, 106, 124, 0.1) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(244, 244, 244, 0) 100%); }
  .project-header__wrapper {
    padding-bottom: 0;
    padding-top: 50px; }
    @media (max-width: 575.98px) {
      .project-header__wrapper {
        padding-bottom: 2rem; } }
    @media (min-width: 768px) {
      .project-header__wrapper {
        padding-top: 65px; } }
  .project-header__label {
    text-align: center;
    margin-bottom: 1rem; }
  .project-header__heading {
    color: #50928b;
    text-align: center;
    padding-bottom: 1.625rem; }
  .project-header__icon {
    opacity: 0.2;
    position: absolute;
    right: 0;
    width: 250px;
    transform: translate(40%, -40%) rotateY(180deg) rotate(65deg); }
  .project-header__buttons {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    text-align: right; }
    @media (max-width: 575.98px) {
      .project-header__buttons {
        bottom: auto;
        top: 100%;
        right: 0;
        width: 100%;
        padding-top: 10px;
        text-align: center; } }
    .project-header__buttons .btn, .project-header__buttons .about__section--refer-a-friend a, .about__section--refer-a-friend .project-header__buttons a, .project-header__buttons .careers-callout__content-wrap a, .careers-callout__content-wrap .project-header__buttons a {
      position: relative;
      padding-left: 55px;
      padding-right: 30px;
      font-size: 14px; }
      .project-header__buttons .btn:before, .project-header__buttons .about__section--refer-a-friend a:before, .about__section--refer-a-friend .project-header__buttons a:before, .project-header__buttons .careers-callout__content-wrap a:before, .careers-callout__content-wrap .project-header__buttons a:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 32px;
        transform: translateY(-48%);
        width: 15px;
        height: 15px;
        background-image: url(/site/themes/main/img/icon-photo.svg);
        background-size: contain;
        background-repeat: no-repeat; }
  .project-header__video-btn {
    left: 50%;
    position: absolute;
    top: 50%;
    max-width: 25%;
    transform: translate(-50%, -50%); }
    .project-header__video-btn svg {
      max-width: 100%; }
  .project-header__awards {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    display: flex; }
    @media (min-width: 768px) {
      .project-header__awards {
        bottom: 1rem;
        left: 1rem; } }
  .project-header__award {
    margin-right: 0.5rem; }
    .project-header__award img {
      height: 40px;
      width: auto; }
      @media (min-width: 768px) {
        .project-header__award img {
          height: 71px; } }
  .project-header__banner-image-wrap {
    background-color: #eceeef;
    position: relative;
    z-index: 2;
    border-radius: 10px;
    overflow: hidden; }

.project-detail {
  padding-bottom: calc(1rem + 4vh);
  margin-bottom: calc(1rem + 9vh); }
  .project-detail-wrap {
    position: relative;
    padding-top: calc(1rem + 4vh);
    background: rgba(244, 244, 244, 0);
    background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
    background: -moz-linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%); }
  .project-detail__video {
    position: relative; }
  .project-detail__leaves {
    position: absolute;
    left: 0;
    top: -70px;
    z-index: -1; }
    .project-detail__leaves__image {
      width: 275px;
      height: auto; }
  .project-detail__content {
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .project-detail__content {
        flex: 0 0 50%;
        max-width: 50%; } }
    .project-detail__content h2, .project-detail__content h3, .project-detail__content h4, .project-detail__content h5 {
      font-weight: bold;
      color: #50928b; }
  .project-detail__galleries {
    padding-top: 1rem;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .project-detail__galleries {
        padding-top: 0;
        flex: 0 0 50%;
        max-width: 50%; } }
  @media (min-width: 768px) {
    .project-detail__gallery {
      padding-left: 20px; } }
  .project-detail__gallery__title {
    margin-left: -10px;
    margin-top: 30px;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase; }
  .project-detail__gallery__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .project-detail__gallery__item {
    margin: 0;
    padding: 5px;
    width: 50%; }
  .project-detail__gallery__link {
    display: block; }
  .project-detail__gallery__image {
    width: 100%;
    border-radius: 5px; }
  .project-detail__finished-photos {
    margin-bottom: calc(1rem + 2vh); }
    .project-detail__finished-photos:before, .project-detail__finished-photos:after {
      content: '';
      display: table; }
    .project-detail__finished-photos:after {
      clear: both; }
    .project-detail__finished-photos__title {
      text-align: center;
      color: #50928b;
      margin-bottom: 3rem; }
    .project-detail__finished-photos__item {
      padding-left: 0;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      float: left;
      margin: 0 0 10px 0;
      padding-left: 5px;
      padding-right: 5px;
      width: 50%; }
      .project-detail__finished-photos__item--lg {
        width: 100%; }
      @media (min-width: 768px) {
        .project-detail__finished-photos__item {
          width: 25%;
          margin: 0 0 20px 0;
          padding-left: 10px;
          padding-right: 10px; }
          .project-detail__finished-photos__item--lg {
            width: 50%; }
          .project-detail__finished-photos__item:nth-child(6), .project-detail__finished-photos__item:nth-child(5n + 11) {
            clear: left; }
          .project-detail__finished-photos__item:nth-child(8), .project-detail__finished-photos__item:nth-child(10n + 8) {
            clear: both; }
          .project-detail__finished-photos__item:nth-child(10n + 10) {
            margin-top: -25%; } }
    .project-detail__finished-photos__link {
      cursor: zoom-in; }
    .project-detail__finished-photos__image {
      border-radius: 5px; }

.projects-listing {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px 60px; }
  @media (min-width: 1450px) {
    .projects-listing {
      padding: 0; } }
  @media (min-width: 768px) {
    .projects-listing__list {
      display: grid;
      column-gap: 30px; } }
  @media (min-width: 768px) {
    .projects-listing__list--3-cols {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (min-width: 768px) {
    .projects-listing__list--2-cols {
      grid-template-columns: 1fr 1fr;
      row-gap: 40px; } }
  .projects-listing__link {
    background-position: center center;
    background-size: cover;
    display: block;
    margin-bottom: 1rem;
    padding: 0;
    border-radius: 10px;
    color: #939393;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1); }
  .projects-listing__link:hover {
    color: #464342;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: all ease .2s;
    transform: translateY(-1px);
    cursor: pointer; }
  .projects-listing__image {
    margin-bottom: 16px;
    border-radius: 10px 10px 0 0;
    border-bottom: 8px solid #939393;
    transition: all 0.25s ease-out; }
  .projects-listing__item {
    padding-left: 0; }
  .projects-listing__item:nth-child(3n + 1) .projects-listing__image {
    border-color: #d3636b; }
  .projects-listing__item:nth-child(3n + 2) .projects-listing__image {
    border-color: #8fb04e; }
  .projects-listing__item:nth-child(3n + 3) .projects-listing__image {
    border-color: #7fc3ba; }
  .projects-listing__title-card {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 15px; }
  .projects-listing__title-label {
    color: #939393;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 0 10px; }
  .projects-listing--blueridge .projects-listing__title-card {
    color: #575755; }

.home-callout {
  position: relative;
  z-index: 2; }
  @media (max-width: 575.98px) {
    .home-callout {
      position: absolute;
      width: 100%; } }

.promo-callout {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #d3636b;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: bold; }
  .promo-callout__content {
    padding: 1rem 0;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif; }
    .promo-callout__content p {
      line-height: 1.15em; }
    .promo-callout__content a {
      color: #fff;
      margin: 0.5em 1rem;
      position: relative; }
      .promo-callout__content a:after {
        content: "›";
        padding-left: 3px; }
      .promo-callout__content a:hover {
        opacity: .75; }
    .promo-callout__content > :last-child {
      margin-bottom: 0; }

.promo-page {
  text-align: center; }
  .promo-page__header-wrap {
    background: #7fc3ba;
    color: #fff;
    position: relative; }
  .promo-page__header {
    padding-top: calc(2rem + 6vh);
    padding-bottom: 20%; }
  .promo-page__icon {
    position: absolute;
    right: 0;
    top: 0; }
  .promo-page__title {
    color: inherit;
    margin: auto;
    margin-bottom: calc(2rem + 4vh);
    max-width: 960px; }
  .promo-page__main-image {
    margin-bottom: 4rem; }
  .promo-page__content {
    font-size: 1.25rem;
    margin: auto;
    margin-bottom: calc(2rem + 8vh);
    max-width: 775px; }

@media (min-width: 576px) {
  .referral-offer-list {
    display: flex;
    justify-content: space-between; } }

.referral-offer-list__item {
  color: #006a7c;
  font-weight: bold; }

.referrals-page__form-wrap {
  background: #f4f4f4;
  padding-top: calc(1rem + 5vh);
  padding-bottom: calc(1rem + 2vh);
  position: relative; }
  @media (max-width: 767.98px) {
    .referrals-page__form-wrap {
      margin-top: 20%;
      /* space for flower */ } }

.referrals-page__icon-wrap {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
  transform: translateY(-60%); }
  .referrals-page__icon-wrap svg {
    max-width: 100%;
    height: auto; }

.regular-stack-item {
  padding: 0 0 200px 0; }
  @media (max-width: 991.98px) {
    .regular-stack-item {
      padding: 60px 0; } }
  .regular-stack-item .container, .regular-stack-item .fluid-container, .regular-stack-item .products-listing, .regular-stack-item .project-detail, .regular-stack-item .project-detail__finished-photos {
    max-width: 1400px; }
  .regular-stack-item__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991.98px) {
      .regular-stack-item__flex {
        flex-direction: column; } }
  .regular-stack-item__box {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center; }
    .regular-stack-item__box.image {
      width: 50%; }
      @media (max-width: 991.98px) {
        .regular-stack-item__box.image {
          width: 70%;
          margin: 0 auto; } }
      @media (max-width: 767.98px) {
        .regular-stack-item__box.image {
          width: 100%; } }
    .regular-stack-item__box.content {
      width: 50%; }
      @media (max-width: 991.98px) {
        .regular-stack-item__box.content {
          width: 100%; } }
    .regular-stack-item__box-image {
      position: relative;
      width: 100%;
      display: block; }
      .regular-stack-item__box-image:after {
        content: "";
        display: block;
        padding-bottom: 100%; }
      .regular-stack-item__box-image img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto; }
    .regular-stack-item__box-content {
      padding: 70px 0 70px 120px; }
      @media (max-width: 1199.98px) {
        .regular-stack-item__box-content {
          padding: 70px 0 70px 70px; } }
      @media (max-width: 991.98px) {
        .regular-stack-item__box-content {
          padding: 40px 0; } }

.service-area-callout {
  padding: 80px 0 160px;
  background-image: url("../img/atlanta-sky-shot@2x.jpg");
  background-position: center;
  background-size: cover; }
  @media (min-width: 1200px) {
    .service-area-callout {
      padding: 80px 0 220px; } }

.site-credit {
  width: 100%;
  padding: 20px 0;
  margin-top: 40px;
  background-color: #005d6d; }
  @media (max-width: 575.98px) {
    .site-credit {
      margin-top: 20px; } }
  .site-credit__flex {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 575.98px) {
      .site-credit__flex {
        flex-direction: column;
        justify-content: flex-start; } }
  .site-credit__list {
    width: 100%; }
  .site-credit__item {
    display: inline-block;
    margin-right: 10px; }
  .site-credit__link {
    font-size: 75%;
    text-decoration: none; }
  .site-credit__social {
    width: 50%;
    text-align: right; }
    @media (max-width: 575.98px) {
      .site-credit__social {
        width: 100%;
        text-align: left;
        padding-top: 20px; } }

.site-footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #006a7c;
  color: white;
  padding: 40px 0 0; }
  .site-footer .footer-flex {
    display: flex;
    flex-direction: row; }
    @media (max-width: 991.98px) {
      .site-footer .footer-flex {
        flex-direction: column; } }
    .site-footer .footer-flex .footer-menu {
      display: flex;
      width: 68%;
      margin-right: 5%; }
      @media (max-width: 991.98px) {
        .site-footer .footer-flex .footer-menu {
          width: 100%;
          margin-right: 0;
          flex-direction: column; } }
      .site-footer .footer-flex .footer-menu .logos img {
        width: 150px;
        max-height: 70px;
        margin-right: 60px; }
      @media (max-width: 991.98px) {
        .site-footer .footer-flex .footer-menu .logos {
          margin-bottom: 20px; } }
    .site-footer .footer-flex .footer-contact {
      display: flex;
      flex-direction: column;
      width: 24%;
      padding-left: 3%;
      border-left: solid 1px #fff; }
      @media (max-width: 991.98px) {
        .site-footer .footer-flex .footer-contact {
          width: 100%;
          padding-left: 0;
          border-left: none;
          border-top: solid 1px #fff;
          padding-top: 40px;
          margin-bottom: 20px; } }
      @media (min-width: 992px) {
        .site-footer .footer-flex .footer-contact__phone {
          grid-template-columns: repeat(1, 1fr); } }
      @media (max-width: 991.98px) {
        .site-footer .footer-flex .footer-contact__phone {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-column-gap: 40px;
          grid-row-gap: 0px; } }
      @media (max-width: 575.98px) {
        .site-footer .footer-flex .footer-contact__phone {
          grid-template-columns: repeat(1, 1fr); } }
      .site-footer .footer-flex .footer-contact .phone-label {
        font-size: 0.7em;
        text-transform: uppercase; }
      .site-footer .footer-flex .footer-contact .phone {
        font-size: 1.7em;
        font-weight: bold;
        color: #b9c882; }
        .site-footer .footer-flex .footer-contact .phone.mb10 {
          margin-bottom: 20px; }
      .site-footer .footer-flex .footer-contact .phone,
      .site-footer .footer-flex .footer-contact .email {
        margin-bottom: 15px; }

.site-header {
  position: relative;
  padding: 30px 0 1px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.1); }
  @media (max-width: 575.98px) {
    .site-header {
      height: 60px;
      position: fixed;
      top: 0;
      z-index: 2000;
      width: 100%;
      padding: 0; } }
  .site-header__inner {
    position: relative;
    min-height: 102px; }
    @media (max-width: 991.98px) {
      .site-header__inner {
        min-height: 142px; } }
    .site-header__inner .container, .site-header__inner .fluid-container, .site-header__inner .products-listing, .site-header__inner .project-detail, .site-header__inner .project-detail__finished-photos {
      max-width: 1400px; }
  .site-header__contact-info {
    text-align: right;
    margin-right: 20px;
    display: none; }
    @media (max-width: 575.98px) {
      .site-header__contact-info {
        position: absolute;
        display: block;
        z-index: 2;
        right: 0; } }
  .site-header__location {
    font-size: 12px;
    display: none; }
    @media (min-width: 576px) {
      .site-header__location {
        display: inline-block; } }
  @media (max-width: 575.98px) {
    .site-header__phone-link {
      position: relative;
      z-index: 10;
      display: block;
      height: 60px;
      width: 60px;
      background-image: url(../img/phone-icon.svg);
      background-size: auto 60%;
      background-repeat: no-repeat;
      background-position: center center; } }
  @media (min-width: 992px) {
    .site-header__phone-link {
      display: inline-block; } }
  .site-header__phone-digits {
    display: none; }
    @media (min-width: 576px) {
      .site-header__phone-digits {
        color: #006a7c;
        transition: color 0.2s;
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        line-height: 1; }
        .site-header__phone-digits:hover, .site-header__phone-digits:focus {
          color: #002930;
          outline: 0; }
        .site-header__phone-digits:active {
          color: black; } }
  .site-header__get-started {
    float: right;
    position: relative;
    z-index: 9000;
    margin-top: 15px;
    margin-bottom: 15px; }
    .site-header__get-started .phone {
      margin-top: 20px;
      margin-bottom: 10px; }
      @media (max-width: 767.98px) {
        .site-header__get-started .phone {
          margin-top: 15px;
          margin-bottom: 15px; } }
    @media (max-width: 575.98px) {
      .site-header__get-started {
        display: none; } }
    .site-header__get-started--phone {
      position: absolute;
      top: -33px;
      width: 100%;
      text-align: center;
      left: 0; }
      @media (max-width: 767.98px) {
        .site-header__get-started--phone {
          position: relative;
          display: inline;
          top: 0;
          margin-right: 15px; } }
      .site-header__get-started--phone img {
        transform: rotate(110deg); }
      .site-header__get-started--phone a {
        color: #464342; }
        .site-header__get-started--phone a:hover {
          color: #939393; }

.site-logo {
  position: absolute;
  top: -5px;
  left: 15px;
  z-index: 9000;
  max-width: 150px;
  width: 100%;
  text-decoration: none;
  transition: transform 0.2s ease-in-out; }
  @media (max-width: 575.98px) {
    .site-logo {
      margin-left: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 105px; } }
  @media (max-width: 575.98px) {
    .site-logo__leaf {
      opacity: 0; } }

.site-main {
  overflow: hidden; }
  @media (max-width: 575.98px) {
    .site-main {
      margin-top: 60px;
      /* space for fixed nav */ } }

.smart-landscapes__gradient-top {
  background: #ddcce3;
  background: linear-gradient(180deg, #ddcce3 0%, #d8cedb 22%, rgba(255, 255, 255, 0) 100%); }

.smart-landscapes__purple {
  background: #ddcce3; }

.smart-landscapes__gradient-bottom {
  background: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(225, 225, 226, 0.503239) 25%, #eaeaeb 50%, rgba(246, 246, 246, 0.503239) 75%, rgba(255, 255, 255, 0) 100%); }

.smart-landscapes__banner {
  position: relative;
  text-align: center; }
  .smart-landscapes__banner-leaf, .smart-landscapes__banner-splash, .smart-landscapes__banner-dashes {
    position: relative; }
    .smart-landscapes__banner-leaf:before, .smart-landscapes__banner-splash:before, .smart-landscapes__banner-dashes:before {
      content: '';
      position: absolute;
      background-size: contain;
      background-position: top right;
      background-repeat: no-repeat; }
  .smart-landscapes__banner-leaf:before {
    width: 350px;
    height: 370px;
    right: -60px;
    top: -140px;
    background-image: url(/site/themes/main/img/leaf-purple.svg); }
  .smart-landscapes__banner-splash:before {
    width: 630px;
    height: 370px;
    right: -110px;
    top: -10px;
    background-image: url(/site/themes/main/img/hydrate-splash.svg); }
  .smart-landscapes__banner-dashes:before {
    width: 450px;
    height: 470px;
    right: -20px;
    top: -30px;
    background-image: url(/site/themes/main/img/automower-dashes.svg); }
  .smart-landscapes__banner .page-hero__icon {
    display: block;
    position: relative;
    top: 0;
    margin-bottom: 30px;
    max-height: 140px; }
  .smart-landscapes__banner-wrapper {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 80px 0;
    z-index: 10; }
    .smart-landscapes__banner-wrapper.automower {
      text-align: left;
      margin: 0;
      max-width: 45%; }
    .smart-landscapes__banner-wrapper .connect-logo {
      max-width: 400px;
      margin: 0 auto 40px auto; }
  .smart-landscapes__banner h1 {
    color: #88788E; }
  .smart-landscapes__banner h2 {
    color: #464342; }
  .smart-landscapes__banner .btn, .smart-landscapes__banner .about__section--refer-a-friend a, .about__section--refer-a-friend .smart-landscapes__banner a, .smart-landscapes__banner .careers-callout__content-wrap a, .careers-callout__content-wrap .smart-landscapes__banner a {
    margin-top: 30px; }

.smart-landscapes__intro {
  margin-bottom: 100px; }
  .smart-landscapes__intro .inner-stack-item .container, .smart-landscapes__intro .inner-stack-item .fluid-container, .smart-landscapes__intro .inner-stack-item .products-listing, .smart-landscapes__intro .inner-stack-item .project-detail, .smart-landscapes__intro .inner-stack-item .project-detail__finished-photos {
    max-width: 1400px; }
  .smart-landscapes__intro .inner-stack-item__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991.98px) {
      .smart-landscapes__intro .inner-stack-item__flex {
        display: block; } }
  .smart-landscapes__intro .inner-stack-item__box {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center; }
    .smart-landscapes__intro .inner-stack-item__box.image {
      width: 50%; }
      @media (max-width: 991.98px) {
        .smart-landscapes__intro .inner-stack-item__box.image {
          width: 70%;
          margin: 0 auto; } }
      @media (max-width: 767.98px) {
        .smart-landscapes__intro .inner-stack-item__box.image {
          width: 100%; } }
    .smart-landscapes__intro .inner-stack-item__box.content {
      width: 50%; }
      @media (max-width: 991.98px) {
        .smart-landscapes__intro .inner-stack-item__box.content {
          width: 100%; } }
    .smart-landscapes__intro .inner-stack-item__box-image {
      position: relative;
      width: 100%;
      display: block; }
      .smart-landscapes__intro .inner-stack-item__box-image:after {
        content: "";
        display: block;
        padding-bottom: 70%; }
      .smart-landscapes__intro .inner-stack-item__box-image img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto; }
    .smart-landscapes__intro .inner-stack-item__box-content {
      position: relative;
      z-index: 2;
      padding: 90px 0 90px 120px; }
      @media (max-width: 1199.98px) {
        .smart-landscapes__intro .inner-stack-item__box-content {
          padding: 70px 0 70px 70px; } }
      @media (max-width: 991.98px) {
        .smart-landscapes__intro .inner-stack-item__box-content {
          margin-top: 20px;
          padding: 40px 0 0; } }
      .smart-landscapes__intro .inner-stack-item__box-content h3 {
        color: #464342;
        font-weight: bold;
        margin-bottom: 20px; }
  .smart-landscapes__intro .inner-stack-item .flower {
    position: absolute;
    z-index: 2;
    content: '';
    bottom: -137px;
    left: -140px;
    width: 238px;
    height: 257px;
    transform: rotate(-4deg) !important;
    background-image: url(../img/icons/flowers/3.svg);
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 991.98px) {
      .smart-landscapes__intro .inner-stack-item .flower {
        bottom: -80px;
        left: -100px;
        width: 171px;
        height: 193px; } }

.smart-landscapes__services {
  padding: 0; }
  .smart-landscapes__services .services-card__image {
    padding-bottom: 65%; }

.smart-landscapes__audio {
  position: relative; }
  .smart-landscapes__audio-notes {
    position: absolute;
    top: -280px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/music-notes.svg);
    background-repeat: no-repeat;
    background-size: 105% auto;
    background-position: top center;
    opacity: 0.3; }
    @media (max-width: 1199.98px) {
      .smart-landscapes__audio-notes {
        top: -200px; } }
    @media (max-width: 991.98px) {
      .smart-landscapes__audio-notes {
        top: -100px; } }
  .smart-landscapes__audio-img {
    position: relative;
    width: 100%;
    padding-bottom: 30%; }
    .smart-landscapes__audio-img .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
      .smart-landscapes__audio-img .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top; }

.smart-landscapes__lighting {
  padding-bottom: 20%;
  position: relative; }
  .smart-landscapes__lighting-gradient {
    position: absolute;
    width: 100%;
    height: 60%;
    top: 0;
    left: 0;
    z-index: 1;
    background: #ddcce3;
    background: linear-gradient(180deg, #ddcce3 0%, rgba(255, 255, 255, 0) 100%); }
    @media (max-width: 991.98px) {
      .smart-landscapes__lighting-gradient {
        height: 75%; } }
  .smart-landscapes__lighting-img {
    position: absolute;
    width: 100%;
    height: 70%;
    bottom: 0;
    left: 0;
    padding-bottom: 30%;
    background-color: #ccc; }
    @media (max-width: 991.98px) {
      .smart-landscapes__lighting-img {
        height: 60%; } }
    .smart-landscapes__lighting-img .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
      .smart-landscapes__lighting-img .image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top; }

.smart-landscapes__product {
  background-color: #f4f4f4; }
  .smart-landscapes__product-grid {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr; }
    @media (max-width: 991.98px) {
      .smart-landscapes__product-grid {
        grid-template-columns: 1fr; } }
    .smart-landscapes__product-grid .image {
      position: relative;
      margin-bottom: -80px; }
      .smart-landscapes__product-grid .image .product {
        position: absolute;
        left: 0;
        top: -140px;
        width: 100%;
        height: 100%;
        z-index: 10; }
        @media (max-width: 991.98px) {
          .smart-landscapes__product-grid .image .product {
            position: relative;
            top: -90px; } }
        .smart-landscapes__product-grid .image .product img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center; }
          @media (max-width: 991.98px) {
            .smart-landscapes__product-grid .image .product img {
              height: 400px; } }
    .smart-landscapes__product-grid .content {
      padding: 80px 20px; }
      @media (max-width: 991.98px) {
        .smart-landscapes__product-grid .content {
          padding: 0 0 80px 0; } }
      .smart-landscapes__product-grid .content h3 {
        color: #939393;
        font-weight: bold;
        margin-bottom: 20px; }
      .smart-landscapes__product-grid .content p:last-of-type {
        margin-bottom: 0; }

.smart-landscapes__value-item {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
  grid-column: 1 / -1;
  padding: 60px 0; }
  @media (max-width: 991.98px) {
    .smart-landscapes__value-item {
      grid-template-columns: 1fr; } }
  .smart-landscapes__value-item--left .image {
    order: 2; }
  .smart-landscapes__value-item--left .content {
    order: 1; }
  @media (max-width: 991.98px) {
    .smart-landscapes__value-item--left .image {
      order: 1; }
    .smart-landscapes__value-item--left .content {
      order: 2; } }
  .smart-landscapes__value-item--right .image {
    order: 1; }
  .smart-landscapes__value-item--right .content {
    order: 2; }
  .smart-landscapes__value-item .image {
    position: relative; }
    @media (max-width: 991.98px) {
      .smart-landscapes__value-item .image {
        padding-bottom: 70%; } }
    .smart-landscapes__value-item .image .full {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
      .smart-landscapes__value-item .image .full img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .smart-landscapes__value-item .image .product {
      position: absolute;
      right: 20px;
      bottom: -60px;
      width: 190px;
      height: 130px; }
      .smart-landscapes__value-item .image .product img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center; }
      @media (min-width: 768px) {
        .smart-landscapes__value-item .image .product {
          right: -50px;
          bottom: -70px;
          width: 290px;
          height: 150px; } }
  .smart-landscapes__value-item .content {
    padding: 100px 30px; }
    @media (max-width: 991.98px) {
      .smart-landscapes__value-item .content {
        padding: 0; } }
    .smart-landscapes__value-item .content h3 {
      color: #939393;
      font-weight: bold;
      margin-bottom: 20px; }
    .smart-landscapes__value-item .content p:last-of-type {
      margin-bottom: 0; }

.smart-landscapes__form {
  position: relative;
  padding: 70px 15px; }
  .smart-landscapes__form:after {
    content: '';
    position: absolute;
    width: 500px;
    height: 430px;
    left: 0%;
    bottom: -140px;
    background-image: url(/site/themes/main/img/leaf-green.svg);
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat; }
  .smart-landscapes__form-content {
    position: relative;
    z-index: 10;
    text-align: center;
    max-width: 600px;
    margin: 0 auto; }
    .smart-landscapes__form-content h3 {
      color: #939393;
      font-weight: bold;
      margin-bottom: 20px; }

.social-profile-links {
  width: 100%; }
  .social-profile-links__item {
    position: relative;
    display: inline-block; }
  .social-profile-links__link {
    display: block;
    padding: 0;
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
    line-height: 30px;
    color: inherit;
    font-family: "Icomoon";
    font-style: normal;
    font-size: 20px;
    font-weight: normal;
    font-variant: normal;
    speak: none;
    text-transform: none;
    text-decoration: none;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: transform .1s ease-out;
    transform: translateZ(0); }
    .social-profile-links__link:hover {
      color: inherit;
      transform: scale(1.1);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none; }
  .social-profile-links__link--facebook:before {
    content: ""; }
  .social-profile-links__link--googleplus:before {
    content: ""; }
  .social-profile-links__link--houzz:before {
    content: ""; }
  .social-profile-links__link--linkedin:before {
    content: ""; }
  .social-profile-links__link--pinterest:before {
    content: ""; }
  .social-profile-links__link--twitter:before {
    content: ""; }
  .social-profile-links__link--youtube:before {
    content: ""; }
  .social-profile-links__link--instagram:before {
    content: ""; }
  .social-profile-links__text {
    display: inline-block;
    text-indent: -5000em; }
  .social-profile-links__item--pinterest, .social-profile-links__item--houzz {
    top: 0; }

.testimonial {
  padding-left: 75px;
  background: url("/site/themes/main/img/icons/quote.svg") no-repeat left 10px;
  margin-bottom: calc(1rem + 4vh);
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 768px) {
    .testimonial {
      width: 75%; } }
  .testimonial__content {
    display: block;
    font-size: 1.375rem; }
    .testimonial__content p:last-child {
      margin-bottom: 0; }
    @media (max-width: 991.98px) {
      .testimonial__content {
        font-size: calc(1.375rem/3 + 2vw); } }
  .testimonial__client-name {
    display: block;
    font-size: 1rem;
    text-align: right; }
    .testimonial__client-name:before {
      content: '— '; }

@media (min-width: 576px) {
  .testimonials {
    padding-bottom: 40px; } }

.testimonials__header {
  font-size: 40px;
  text-align: center; }
  @media (max-width: 575.98px) {
    .testimonials__header {
      margin-bottom: 40px !important;
      font-size: 35px; } }

.testimonials__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  display: block;
  position: relative; }
  .testimonials__list:before, .testimonials__list:after {
    content: '';
    display: table; }
  .testimonials__list:after {
    clear: both; }

.testimonials__item {
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left; }

.testimonials__dots {
  position: relative;
  top: 80px;
  height: 40px; }
  .testimonials__dots:before, .testimonials__dots:after {
    content: '';
    display: table; }
  .testimonials__dots:after {
    clear: both; }
  @media (max-width: 575.98px) {
    .testimonials__dots {
      top: 40px; } }

.testimonials__content {
  position: relative; }
  @media (min-width: 768px) {
    .testimonials__content {
      width: 500px; } }
  @media (min-width: 992px) {
    .testimonials__content {
      width: 600px; } }
  @media (min-width: 1200px) {
    .testimonials__content {
      width: 800px; } }

.testimonials__name {
  color: #363636;
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;
  text-transform: uppercase; }

.testimonials__quote {
  line-height: 1.8;
  font-size: 16px;
  font-style: italic;
  font-family: Helvetica Neue; }

.testimonials__title {
  font-size: 10px;
  text-transform: uppercase;
  color: #363636; }

.testimonials__img {
  position: absolute;
  margin-left: 500px;
  bottom: -461px;
  width: 260px;
  opacity: 0;
  z-index: 1; }
  @media (max-width: 767.98px) {
    .testimonials__img {
      display: none; } }
  @media (min-width: 992px) {
    .testimonials__img {
      margin-left: 650px;
      bottom: -433px; } }
  @media (min-width: 1200px) {
    .testimonials__img {
      margin-left: 900px;
      bottom: -377px; } }

.testimonials__item.slick-slide.slick-current.slick-active .testimonials__img {
  opacity: 1; }

.slick-dots {
  position: absolute;
  bottom: 10px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 22px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .5;
  color: black; }

.slick-slide {
  opacity: 0; }

.slick-active {
  opacity: 1; }

.dark-bg {
  background: #E1E1E2;
  height: 40px; }

.thank-you-page {
  position: relative;
  overflow: hidden; }
  .thank-you-page__wrap {
    font-size: 1.25rem;
    text-align: center;
    padding-top: calc(2rem + 10vh);
    padding-bottom: calc(1rem + 5vh);
    max-width: 560px;
    margin: auto; }
  .thank-you-page__icon-wrap {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50%;
    transform: translateY(-35%);
    z-index: -1; }
  .thank-you-page__link {
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-size: 1.625rem; }
    .thank-you-page__link:before {
      font-family: "Icomoon";
      font-style: normal;
      font-size: 1.5em;
      font-weight: normal;
      font-variant: normal;
      font-display: swap;
      speak: none;
      text-transform: none;
      text-decoration: none;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: transform .1s ease-out;
      transform: translateZ(0);
      content: "\e904";
      color: #39579A;
      vertical-align: -15%;
      margin-right: 1rem; }
      .thank-you-page__link:before:hover {
        color: inherit;
        transform: scale(1.1);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-decoration: none; }
    .thank-you-page__link:after {
      content: "›";
      margin-left: 0.5rem; }

.uniform__gradient-top {
  background: #ddcce3;
  background: linear-gradient(180deg, #ddcce3 0%, #d8cedb 22%, rgba(255, 255, 255, 0) 100%); }

.uniform__purple {
  background: #ddcce3; }

.uniform__gradient-bottom {
  background: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(225, 225, 226, 0.503239) 25%, #eaeaeb 50%, rgba(246, 246, 246, 0.503239) 75%, rgba(255, 255, 255, 0) 100%); }

.uniform__banner {
  position: relative;
  text-align: center; }
  .uniform__banner-leaf {
    position: relative; }
    .uniform__banner-leaf:before {
      content: '';
      position: absolute;
      width: 350px;
      height: 370px;
      right: -60px;
      top: -140px;
      background-image: url(/site/themes/main/img/leaf-purple.svg);
      background-size: contain;
      background-position: top right;
      background-repeat: no-repeat; }
  .uniform__banner-wrapper {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 80px 0;
    z-index: 10; }
    .uniform__banner-wrapper.automower {
      text-align: left;
      margin: 0;
      max-width: 45%; }
  .uniform__banner h1 {
    color: #88788E; }
  .uniform__banner h2 {
    color: #464342; }
  .uniform__banner .btn, .uniform__banner .about__section--refer-a-friend a, .about__section--refer-a-friend .uniform__banner a, .uniform__banner .careers-callout__content-wrap a, .careers-callout__content-wrap .uniform__banner a {
    margin-top: 30px; }

.uniform__products {
  background: rgba(0, 106, 124, 0.1);
  background: linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  background: -moz-linear-gradient(180deg, rgba(0, 106, 124, 0) 0%, rgba(10, 111, 129, 0.05) 50%, rgba(0, 106, 124, 0.1) 100%);
  padding-bottom: 60px; }
  .uniform__products-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 40px;
    list-style: none;
    margin: 0; }
    @media (max-width: 1199.98px) {
      .uniform__products-list {
        grid-template-columns: repeat(2, minmax(0, 1fr)); } }
    @media (max-width: 767.98px) {
      .uniform__products-list {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
  .uniform__products-box {
    background-color: white;
    overflow: hidden;
    transition: all ease-in .2s; }
    .uniform__products-box--img {
      position: relative;
      width: 100%;
      padding-bottom: 90%; }
      .uniform__products-box--img img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
    .uniform__products-box--content {
      padding: 30px; }
      .uniform__products-box--content h4 {
        font-size: 2em;
        font-weight: 600;
        color: #006a7c; }
      .uniform__products-box--content .quantity {
        display: flex;
        align-items: center; }
        .uniform__products-box--content .quantity strong {
          margin-right: 8px; }

.uniform__form {
  padding: 40px 0 60px;
  background: rgba(0, 106, 124, 0.1); }
  .uniform__form-box {
    max-width: 600px;
    margin: 0 auto;
    background-color: white;
    padding: 40px;
    border-radius: 5px; }
    .uniform__form-box h3 {
      color: #006a7c;
      font-weight: 600; }
  .uniform__form .form-flex {
    display: flex; }
    .uniform__form .form-flex strong {
      display: block;
      margin-bottom: 6px; }
    @media (max-width: 767.98px) {
      .uniform__form .form-flex {
        flex-wrap: wrap; } }
  .uniform__form .w-2-5 {
    width: 56%; }
    @media (max-width: 767.98px) {
      .uniform__form .w-2-5 {
        width: 100%;
        margin-bottom: 3px; } }
  .uniform__form .w-1-5 {
    width: 20%;
    margin-left: 2%; }
    @media (max-width: 767.98px) {
      .uniform__form .w-1-5 {
        width: 50%;
        margin-left: 0; } }
  .uniform__form .form-placeholder ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #ccc; }
  .uniform__form .form-placeholder :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #ccc;
    opacity: 1; }
  .uniform__form .form-placeholder ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #ccc;
    opacity: 1; }
  .uniform__form .form-placeholder :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc; }
  .uniform__form .form-placeholder ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc; }
  .uniform__form .form-placeholder ::placeholder {
    /* Most modern browsers support this now. */
    color: #ccc; }
  .uniform__form .form-placeholder .dollar {
    padding: 0 5px; }
  .uniform__form .form__form-group--select .form__field {
    font-weight: normal;
    font-size: 0.8em; }
  .uniform__form .form__form-group--select:after {
    top: 15px;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-left: 4px solid;
    border-bottom: 4px solid; }
  .uniform__form .form-control:disabled,
  .uniform__form .form-control[readonly] {
    border: none;
    position: relative;
    min-width: 80px; }
    .uniform__form .form-control:disabled::before,
    .uniform__form .form-control[readonly]::before {
      content: '$'; }
  .uniform__form .price-flex {
    display: flex;
    align-items: center; }
  .uniform__form .price-total {
    float: right;
    justify-content: end;
    margin-bottom: 10px; }
    .uniform__form .price-total span {
      padding: 0 5px; }

@media (min-width: 768px) {
  .value-stack-item {
    min-height: 400px; } }

.value-stack-item__radius {
  border-radius: 10px; }

@media (min-width: 768px) {
  .value-stack-item__image-wrap {
    margin-bottom: 40px;
    padding-right: 20px;
    width: 50%; } }

@media (min-width: 1024px) {
  .value-stack-item__image-wrap {
    margin-bottom: 60px; } }

@media (min-width: 768px) {
  .value-stack-item__content-wrap {
    min-height: 565px;
    padding-top: 40px;
    width: 50%; } }

.value-stack-item__content-wrap h2 {
  color: #d3636b; }

.value-stack-item--1 {
  background-image: url(../img/icons/flowers/10.svg);
  background-repeat: no-repeat;
  background-position: calc(100% + 250px) calc(100% + 150px); }
  @media (min-width: 1450px) {
    .value-stack-item--1 {
      background-position: calc(50% + 574px) calc(100% + 150px); } }

.value-stack-item--1 p {
  max-width: 570px; }

@media (min-width: 768px) {
  .value-stack-item--2 .value-stack-item__image-wrap {
    order: 2;
    padding-left: 20px;
    padding-right: 0; } }

@media (min-width: 768px) {
  .value-stack-item--2 .value-stack-item__content-wrap {
    order: 1; } }

.value-stack-item--3 {
  background-image: url(../img/icons/branches/5.svg);
  background-repeat: no-repeat;
  background-position: calc(50% + 700px) calc(100% + 100px);
  background-size: 380px auto; }
  .value-stack-item--3 h2 {
    max-width: 450px; }

.value-stack-item__play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  display: inline-block;
  transform: translate(-50%, -50%); }

.video-testimonial {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px 40px;
  text-align: center; }
  @media (min-width: 1450px) {
    .video-testimonial {
      padding-left: 0;
      padding-right: 0; } }
  .video-testimonial__heading {
    margin-bottom: 40px;
    color: #50928b;
    font-weight: bold;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif; }
  .video-testimonial__image-wrap {
    position: relative; }
  .video-testimonial .d-md-flex {
    align-items: center; }
    @media (max-width: 991.98px) {
      .video-testimonial .d-md-flex {
        align-items: start; } }
  .video-testimonial__play-btn {
    left: 50%;
    top: 50%;
    position: absolute;
    max-width: 25%;
    transform: translate(-50%, -50%); }
    .video-testimonial__play-btn svg {
      max-width: 100%; }
  .video-testimonial__img {
    width: 49%; }
    @media (max-width: 991.98px) {
      .video-testimonial__img {
        width: 100%; } }
  .video-testimonial__image {
    max-width: 100%;
    height: auto;
    border-radius: 10px; }
  .video-testimonial__quote {
    display: block;
    max-width: 1400px;
    font-family: "WoodfordBournePro", "Arial Black", Helvetica, "Segoe UI", Arial, sans-serif;
    font-style: italic;
    font-size: 1.875rem;
    color: #50928b;
    font-weight: 500;
    padding: 0 0 30px 0; }
    @media (max-width: 767.98px) {
      .video-testimonial__quote {
        padding-top: 40px; } }
    .video-testimonial__quote cite {
      margin-top: 20px;
      display: block;
      color: #939393;
      font-size: 24px; }
  .video-testimonial__copy {
    text-align: left;
    padding-left: 40px;
    width: 50%; }
    @media (max-width: 767.98px) {
      .video-testimonial__copy {
        padding-left: 0px;
        width: 100%; } }

.branch {
  background-repeat: no-repeat; }
  .branch__7 {
    background-image: url(../img/icons/branches/7.svg); }

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }
