.blog-post {

    overflow: hidden;

    &--full {
        margin: 0 auto;
        max-width: 1160px;
        padding: 0 15px 40px;
        border-radius: 0 0 10px 10px;
        background-color: #fff;

        @include media-breakpoint-up(sm) {
            padding: 0 30px 40px;
        }


        @include media-breakpoint-up(xl) {
            padding: 0 60px 60px;
        }
    }

    &__title {


    }

    &__title-link {


    }

    &__feature {

    }

    &__feature-image {


    }

    &__large-image-wrap {


    }

    &__large-image-link {

    }

    &__large-image {

    }

    &__body {

        &:first-child() h2:first-child() + p,
        &:first-child() h3:first-child() + p,
        &:first-child() p:first-child() {
            font-size: 150%;
            margin-bottom: 20px;
        }

        i {
            font-size: inherit;
        }

        a {
            text-decoration: underline;
        }

        h2 {
            font-size: 36px;
        }

        h3 {
            font-size: 24px;
        }

        blockquote {

            max-width: 900px;
            margin-left: 5%;
            margin-bottom: 3%;
            margin-top: 3%;
            padding-left: 2%;
            padding-bottom: 2%;
            padding-top: 2%;

            border-left: 4px solid #ccc;
            font-size: 150%;
        }

        h3 {
            margin-top: 44px;

            font-size: 30px;
        }
    }

    &__button {
    }

}