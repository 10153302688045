.employee-detail {

	margin-bottom: $spacer*4;
	margin-top: $spacer*2;

	&__image {
		margin-bottom: $spacer*2;
	}

	&__gallery {
		background-color: darken($color-gray-lighter, 18);
		background-image: url(../img/icons/branches/1-flipped-y.png);
		background-position: right -100px bottom;
		background-repeat: no-repeat;
		background-size: 360px auto;

		@include media-breakpoint-up(xl) {
			background-position: calc(50% + 600px) bottom;
		}
	}

	&__gallery-header {
		margin-bottom: 16px;
		padding: 20px 0;

		background-image:
		linear-gradient(rgba(darken($color-gray-lighter, 18), .4), rgba(darken($color-gray-lighter, 18), .4)),
		url("/assets/img/pages/icons/Leaves-white.svg");
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
	}
}