.promo-page {
    text-align: center;

    &__header-wrap {
        background: $color-teal-light;
        color: #fff;

        position: relative;
    }

    &__header {
        padding-top: calc(#{$spacer*2} + 6vh);
        padding-bottom: 20%;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__title {
        color: inherit;

        margin: auto;
        margin-bottom: calc(#{$spacer*2} + 4vh);

        max-width: 960px;
    }

    &__main {
        @extend %overlap-banner;
    }

    &__main-image {
        margin-bottom: $spacer*4;
    }

    &__content {
        font-size: $font-size-lg;

        margin: auto;
        margin-bottom: calc(#{$spacer*2} + 8vh);

        max-width: 775px;
    }
}
