.aftercare {
    padding-bottom: 40px;
    &__banner {
        .page-banner__intro {
            padding-right: 0;
        }
    }
    &__headings {
        text-align: center;
        max-width: 700px;
        margin: 0 auto 40px auto;
        h4 {
            color: $color-blue;
        }
        .body-text {
            p {
                line-height: 1.5;
            }
            strong, b {
                color: $color-gray-medium;
            }
        }
        p {
            margin-bottom: 15px;
        }
    }

    &__intro {
        margin-top: 40px;
        margin-bottom: 60px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 40px;
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        .aftercare__headings {
            text-align: left;
            max-width: auto;
            margin-bottom: 10px;
            h3 {
                margin-bottom: 20px;
            }
        }
        &-content {
            padding-right: 40px;
        }
        &-profile {
            display: flex;
            align-items: center;
            &-img {
                position: relative;
                width: 80px;
                height: 80px;
                border-radius: 100px;
                overflow: hidden;
                margin-right: 20px;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .signature {
            width: 300px;
        }
        
    }

    &__gradient {
        padding-top: 60px;
        background: linear-gradient(180deg, 
        rgba(0, 106, 124, 0.1) 0%, 
        rgba(10, 111, 129, 0.05) 50%, 
        rgba(244, 244, 244, 0) 100%);
    }

    .guarantee {
        background-color: transparent;
    }

    &__download {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 40px;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        &--card {
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
            padding: 40px;
            .label {
                margin-bottom: 10px;
            }
            h3 {
                font-weight: bold;
                font-size: 1.75em;
                margin-bottom: 15px;
            }
            .btn {
                margin-top: 15px;
            }
            a:hover {
                color: $color-gray-medium;
            }
            &:hover {
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                transition: all ease .2s;
                transform: translateY(-1px);
                cursor: pointer;
                
            }
        }
    }

    &__packages {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 40px;
        margin-bottom: 100px;
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        &-box {
            display: grid;
            padding: 50px;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
            @include media-breakpoint-down(md) {
                padding: 30px;
            }
            &.blue {
                background-color: $color-teal-lighter;
            }
            h3 {
                font-weight: bold;
            }
            .bold {
                font-weight: bold;
                margin-top: 15px;
                margin-bottom: 15px;
            }
            ul {
                margin-bottom: 0;
                list-style: none;
                li::before {
                    content: "\2022";
                    color: $color-green;
                    font-weight: bold;
                    display: inline-block; 
                    font-size: 16px;
                    width: 1.75em;
                    margin-left: -1.7em;
                }
            }
        }
        &-button {
            padding: 60px 0;
            text-align: center;
        }
    }
    &__services {
        .home__services-section {
            padding-top: 0;
        }
    }
}