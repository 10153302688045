.intro {
    margin:  auto;
    margin-bottom: 100px;
    max-width: 785px;
    text-align:  center;

    &__container {
        margin-top:  100px;
        padding-bottom: 60px;
        position:  relative;
    }

    & h1 {
        color: #7FC3BA;
    }
    
    &--2 {

        position: relative;
        max-width: 650px;

        @include media-breakpoint-up(md) {
            left: 50%;
            width: calc(50% - 20px);
        }
        @include media-breakpoint-up(lg) {
            left: 45%;
            width: calc(55% - 20px);
        }
    }
    &--2 h1 {
        color:  #D3636B;
    }

    &--2 p:first-of-type {
        color: #d3636a;
        font-weight:  bold;
    }
    
    &__img--model {
        height: 500px;
        position:  absolute;
        bottom: -60px;
        right: 55%;

        @include media-breakpoint-down(sm) {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            right: 60%;
        }
    }
    &__img--model img {
        max-height:  100%;
        width:  auto;
        max-width:  none;
    }

    &__img--flower {
        position:  absolute;
        bottom:  0;
        right: 10px;
        height: 141px;
        width: 150px;

        transition: opacity 0.2s ease-out;

        @include media-breakpoint-down(xs) {
            height: 113px;
            width: 120px;
        }
        @include media-breakpoint-up(lg) {
            height: 187px;
            width: 200px;
            right: 70px;
        }
        @include media-breakpoint-up(xl) {
            height: 208px;
            width: 220px;
            right: calc((100% - 1190px) / 2);
        }
    }

    &__img--flower.hidden {
        opacity: 0;
    }

    &__img--branch--left {
        position:  absolute;
        left: 0;
        
        z-index:  -1;
        

        top: 180px;
        width: 120px;
           
        @include media-breakpoint-up(md) {
            top: 170px;
            width: 200px;
        }
        @include media-breakpoint-up(lg) {
            top: 100px;
        }
        @include media-breakpoint-up(xl) {
            top: 0;
            width: 300px;
        }
    }

    &__img--branch--right {
        position:  absolute;
        right:  0;
        top: 210px;
        z-index:  -1;
        width: 120px;


        @include media-breakpoint-up(md) {
            top: 150px;
            width: 150px;
        }
        @include media-breakpoint-up(lg) {
            top: 100px;
        }
    }
}



