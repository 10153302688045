.intake-form-dialog {
    left: 50%;
    position: fixed;
    top: 50%;
    z-index: 10000;

    max-width: 800px;
    max-height: 90vh;
    overflow: auto;
    padding: 20px;
    width: 90%;

    box-shadow: 5px 5px 150px rgba(#000, .25), 1px 1px 40px rgba(#000, .5), 0 0 1000000px 1000000px rgba(#fff, .8);
    background-color: #fff;
    transform: translate(-50%, -50%);
    text-align: center;

    @include media-breakpoint-up(lg) {
        padding: 50px;
    }

    h1, p {
        color: $color-black;
    }
}