.contact-page {

    &__main-image {
        @include media-breakpoint-up(md) {
            @include make-col(5);

            // Adjust width to slightly less than 5 columns
            flex: 0 0 37.66667%;
            max-width: 37.66667%;
        }
    }

    &__main-content {
        @include media-breakpoint-up(md) {
            @include make-col(6);
            margin-left: 4%;
        }
        .article__heading {
            color: $color-teal;
        }
    }

    &__form-wrap {
        padding-top: calc(#{$spacer} + 5vh);
        margin-bottom: calc(#{$spacer} + 2vh);
    }

    &__map-image {
        padding: 30px;

        @include media-breakpoint-up(lg) {
            padding: 60px;
        }
    }
}
