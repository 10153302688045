.automower-faq {

    &__wrap {
        padding: 60px 0;

        background-color: lighten($color-gray-light, 60);

        @include media-breakpoint-up(lg) {
            padding: 100px 0;
        }
    }

    &__heading {
        margin-bottom: 1em;

        text-align: center;
    }

    &__list {
        @include media-breakpoint-up(md) {
            columns: 2;
            page-break-inside: avoid;
            // break-inside: avoid-column;
        }
    }

    &__item {
        position: relative;
        padding-left: 50px;
        padding-top: 5px;

        strong {
            display: block;
        }
    }

    &__digit {
        left: 5px;
        position: absolute;
        top: 5px;

        height: 1.65em;
        width: 1.65em;
        font-size: 20px;

        border: 1px solid $color-green;
        border-radius: 50%;
        color: $color-green;
        text-align: center;
    }
}