.intake-form-recommended-design-packages {

    display: none;
    max-width: 485px;
    margin-top: 40px;
    text-align: center;

    &__card {
        display: none;
        margin-bottom: 20px;
        padding: 20px;

        font-size: 14px;

        @include media-breakpoint-up(lg) {
            padding: 28px;
        }

        &--teal {
            background-color: $color_teal;

            p {
                color: #fff;
            }
        }

        &--green {
            background-color: $color_green;
        }

        &--orange {
            background-color: $color_orange;
        }

        p {
            font-size: 14px;
            max-width: 400px;
            margin: 0 auto 20px;
        }
    }

    &__heading {
        color: $gray_light;
        font-size: 24px;
    }

    &__card-title {
        @include antialias;
        color: #fff;

        line-height: 1.3;
        font-size: 36px;
        font-weight: bold;
    }

    &__price {
        @include antialias;

        color: #fff;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.1;

        @include media-breakpoint-up(xl) {
            font-size: 30px;
        }

        small {
            font-size: 0.875rem;
        }
    }
}