/*
|--------------------------------------------------------------------------
| Buttons
|
| Various button styles that can be used. Apply this class directly
| in HTML. Then BEM syntax can override if needed.
|
| Example:
|
| <div class="block">
|     <a class="block__button button" href=""></a>
| </div>
|
|--------------------------------------------------------------------------
*/

@mixin button-style($background: $color-red, $color: #fff, $border-color: $background) {
  background-color: $background;
  border: 2px solid $border-color;
  color: $color;
  transition: color 0.2s, background 0.2s, opacity 0.2s, border 0.2s;

  &:hover,
  &:focus {
    @if $background == transparent {
      background-color: transparentize(#000, 0.95);
    } @else {
      background-color: darken($background, 5);
    }
    border-color: darken($border-color, 5);
    outline: 0;
    color: $color;
  }

  &:active {
    @if $background == transparent {
      background-color: transparentize(#000, 0.6);
    } @else {
      background-color: darken($background, 10);
    }
    border-color: darken($border-color, 10);
    color: $color;
  }
}

.btn {
  @include antialias;

  font-family: $font-headings;
  padding-top: $btn-padding-y-top;
  padding-bottom: $btn-padding-y-bottom;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;

  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.75px;

  @media (max-width: 374px) {
    padding-left: 16px;
    padding-right: 16px;
    letter-spacing: 0;

    font-size: 15px;
  }

  @include media-breakpoint-down(xs) {
    white-space: initial;
    &-lg {
      padding-left: 20px;
      padding-right: 20px;
      display: block;
    }
  }

  &--green {
    @include button-style($color-green, $color-gray-dark);
  }

  &--red {
    @include button-style($color-red, white);
  }

  &--cta {
    @include button-style($color-red, white);
  }

  &--black {
    @include button-style($color-gray-dark, #fff, $color-gray-dark);
  }

  &--white {
    @include button-style(#fff, $color-gray-dark, #fff);
  }

  &--black-outline {
    @include button-style(transparent, $color-black, $color-black);
    line-height: 0.8;
    padding-top: $btn-padding-y-top * 1.45;
    padding-bottom: $btn-padding-y-bottom * 1.45;
  }

  &--white-outline {
    @include button-style(transparent, #fff, #fff);
    line-height: 0.8;
    padding-top: $btn-padding-y-top * 1.45;
    padding-bottom: $btn-padding-y-bottom * 1.45;
  }

  &--blue-outline {
    @include button-style(transparent, $color-blue, $color-blue);
    line-height: 0.8;
    padding-top: $btn-padding-y-top * 1.45;
    padding-bottom: $btn-padding-y-bottom * 1.45;
  }
}
