.products-listing-v2 {
    .inner-stack-item-v2 {
        padding: 0 0 120px 0;
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        &__flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
        &__box {
            position: relative;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            &.image {
                width: 50%;
                @include media-breakpoint-down(md) {
                    width: 70%;
                    margin: 0 auto;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            &.content {
                width: 50%;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            &-image {
                position: relative;
                width: 100%;
                display: block;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 80%;
                }
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            &-content {
                position: relative;
                z-index: 2;
                padding: 40px 0 40px 120px;
                @include media-breakpoint-down(lg) {
                    padding: 70px 0 70px 70px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                    padding: 40px 0 0;
                }
                .body-text {
                    p {
                        margin-bottom: 20px;
                        color: $color-gray-medium;
                    }
                }
                ul {
                    list-style-type: disc;
                    margin-bottom: 30px;
                    color: $color-gray-medium;
                }
            }
        }

        &--2 .flower {
            position: absolute;
            z-index: 2;
            content: '';
            bottom: -150px;
            right: -80px;
            width: 273px;
            height: 288px;
            background-image: url(../img/icons/flowers/3.svg);
            background-repeat: no-repeat;
            background-size: cover;
            @include media-breakpoint-down(md) {
                bottom: -100px;
                right: -90px;
                width: 223px;
                height: 248px;
            }
        }


        &--2 .image,
        &--4 .image,
        &--6 .image,
        &--8 .image,
        &--10 .image,
        &--12 .image {
            @media (min-width: 768px) {
                order: 2;
            }
        }

        &--2 .content,
        &--4 .content,
        &--6 .content,
        &--8 .content,
        &--10 .content,
        &--12 .content {
            @media (min-width: 768px) {
                order: 1;
            }
            .inner-stack-item-v2__box-content {
                padding: 70px 120px 70px 0px;
                @include media-breakpoint-down(lg) {
                    padding: 70px 70px 70px 0px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 0;
                    padding: 40px 0;
                }
            }
        }

    }
}