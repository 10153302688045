%overlap-banner {
    @include media-breakpoint-up(sm) {
        position: relative;

        margin-top: -20%;
        padding-top: 20px;

        &:before {
            content: "";
            display: block;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            padding-bottom: 20.5%;

            margin-left:  -20px;
            margin-right: -20px;

            background-image: linear-gradient(-180deg, rgba(255,255,255,0.80) 0%, #FFFFFF 100%);

            z-index: 0;
        }

        > :first-child {
            position: relative;
            z-index: 1;
        }
    }

    &--small {
        @extend %overlap-banner;

        @include media-breakpoint-up(sm) {
            margin-top: -10%;
        }
    }
}
