.careers-page {
    &__center {
        margin: 0 auto 70px;
        max-width: 650px;
        text-align: center;
        h2 {
            color:$color-brown;
        }
        h3 {
            color:$color-brown;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    &__h3 {
        color:$color-brown;
        font-size: 3rem;
        font-weight: bold;
        
    }
    &__intro {
        margin-top: 70px;
        margin-bottom: 40px;
        &-list {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 20px;
            padding: 0 0 40px 0px;
            text-align: center;
            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            img {
                margin-bottom: 15px;
            }
            div {
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
                &:nth-child(1) {
                    h3 {
                        color: $color-green-dark;
                    }
                }
                &:nth-child(2) {
                    h3 {
                        color: $color-teal;
                        max-width: 225px;
                        margin-left: auto;
                        margin-right: auto;
                        @include media-breakpoint-down(md) {
                            max-width: 100%;
                        }
                    }
                }
                &:nth-child(3) {
                    h3 {
                        color: $color-red;
                        max-width: 290px;
                        margin-left: auto;
                        margin-right: auto;
                        @include media-breakpoint-down(md) {
                            max-width: 100%;
                        }
                    }
                }
                h3 {
                    font-weight: bold;
                    font-size: 30px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__mission {
        padding: 80px 0;
        background: rgba(0,106,124,0.1);
        background: linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -webkit-linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -moz-linear-gradient(180deg,rgba(0,106,124,0.1) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        &-columns {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 80px;
            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: 40px;
            }
            h4 {
                font-weight: bold;
                color: $color-brown;
                font-size: 2rem;
                margin-bottom: 20px;
            }
            h5 {
                font-weight: bold;
                color: $color-teal-light;
            }
            &.mb70 {
                margin-bottom: 70px;
            }
            &--content {
                padding: 50px 0;
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
                div {
                    margin-bottom: 20px;
                }
                a {
                    margin-top: 10px;
                }
            }
            &--image {
                position: relative;
                width: 100%;
                height: 100%;
                @include media-breakpoint-down(md) {
                    padding-bottom: 70%;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .page-banner__play-btn {
                    width: 100px;
                    height: 100px;
                }
                .flower {
                    position: absolute;
                    img {
                        object-fit: contain;
                    }
                    &-mission {
                        width: 256px;
                        height: 215px;
                        right: -114px;
                        bottom: -91px;
                        z-index: 20;
                    }
                }
                
            }
            .play-button {
                z-index: 20;
                width: 100px;
                height: 100px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    &__team,
    &__awards,
    &__paths {
        padding: 70px 0;
    }
    &__team {
        .testimonials {
            padding-bottom: 0;
            &__list {
                @extend %list-clean;
                @include make-row;
                @include clearfix;
                display: block;
                position: relative;
            }
        
            &__item {
                @extend %list-clean;
                min-height: 1px;
                padding-right: 15px;
                padding-left: 15px;
                float: left;
            }
        
            &__dots {
                @include clearfix;
                position: relative;
                top: 0px;
                height: 40px;
        
                @include media-breakpoint-down(xs) {
                    top: 0px;
                }
            }
        
            &__content {
                position:  relative;
        
                @include media-breakpoint-up(md) {
                    width: 500px;
                }
                @include media-breakpoint-up(lg) {
                    width: 600px;
                }
                @include media-breakpoint-up(xl) {
                    width: 800px;
                }
            }

            &__columns {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-gap: 40px;
                padding: 0 0 40px 0px;
                text-align: left;
                @include media-breakpoint-down(md) {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    gap: 40px;
                    text-align: center;
                }
                &-video {
                    position: relative;
                    @include media-breakpoint-down(md) {
                        padding-bottom: 60%;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                    .page-banner__play-btn {
                        width: 100%;
                        height: 100%;
                        .play-button {
                            img {
                                z-index: 20;
                                width: 100px;
                                height: 100px;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        
            &__name {
                color: #363636;
                font-size:  12px;
                font-weight: 400;
                margin-top: 30px;
                text-transform:  uppercase;
            }
        
            &__quote {
                line-height:  1.8;
                font-size:  16px;
                font-style: italic;
                font-family: Helvetica Neue;
            }
        
            &__title {
                font-size:  10px;
                text-transform:  uppercase;
                color: #363636;
            }
        
            &__item.slick-slide.slick-current.slick-active .testimonials__img {
                opacity: 1;
            }
        }
        .slick-dots li button:before {
            color: $color-blue;
            opacity: .5;
        }
        .slick-dots li.slick-active button:before {
            opacity: 1;
            color: $color-blue;
        }
    }
    &__gradient-1 {
        background: rgba(0,106,124,0.1);
        background: linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -webkit-linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -moz-linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
    }
    &__paths {
        background: rgba(0,106,124,0.1);
        background: linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -webkit-linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        background: -moz-linear-gradient(180deg,rgba(0,106,124,0) 0%, rgba(10,111,129,0.05) 50%, rgba(0,106,124,0.1) 100%);
        &-list {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 40px;
            text-align: center;
            list-style: none;
            margin: 0;
            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
        &-box {
            background-color: white;
            overflow: hidden;
            transition: all ease-in .2s;
            &:hover {
                transform: translateY( -5px);
                h4 {
                    color: $color-blue;
                }
            }
            &--img {
                position: relative;
                width: 100%;
                padding-bottom: 60%;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            } 
            &--content {
                padding: 20px;
                color: $color-blue;
                h4 {
                    font-size: 1.3em;
                    font-weight: 600;
                    color: $color-brown;
                    transition: all ease-in .2s;
                }
            }   
        }
    }
    &__benefits {
        padding-bottom: 70px;
        &-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 40px;
            padding: 0 0 40px 0px;
            text-align: center;
            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
        &-box {
            background-color: white;
            padding: 25px;
            img {
                margin-bottom: 20px;
                height: 60px;
            }
            h4 {
                font-weight: bold;
                color: $color-teal;
                font-size: 1.5rem;
            }
        }
    }
    &__internship {
        padding: 70px 0;
        background-color: $color-teal-lighter;
        .flower {
            position: absolute;
            img {
                object-fit: contain;
            }
            &-intern {
                width: 256px;
                height: 215px;
                left: -142px;
                bottom: -196px;
                z-index: 20;
                @include media-breakpoint-down(md) {
                    width: 199px;
                    height: 115px;
                    left: -106px;
                    bottom:-40px;
                }
            }
        }
    }
    &__culture {
        padding: 70px 0 100px;
        &-btn {
            color: $color-blue!important;
            padding-right: 4rem;
            &:before {
                width: 10px!important;
                height: 10px!important;
                left: auto!important;
                right: 38px!important;
                top: 34%!important;
            }
        }
    }
    &__positions {
        padding: 70px 0;
        &-list {
            max-width: 800px;
            margin: 0 auto;
        }
    }
    &__apply {
        padding-top: 40px;
        &-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            grid-gap: 20px;
            padding: 0 0 40px 0px;
            text-align: center;
            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            .plan-card {
                .number {
                    width: 90%;
                }
                &__heading {
                    font-size: 35px!important;
                    opacity: 1!important;
                }
                &:nth-child(1) {
                    .plan-card__image {
                        background-color: $color-teal-light;
                    }
                }
                &:nth-child(2) {
                    .plan-card__image {
                        background-color: $color-green;
                    }
                }
                &:nth-child(3) {
                    .plan-card__image {
                        background-color: $color-orange;
                    }
                }
            }
        }
    }
}

.triangle {
	width: 0;
	height: 0;
    margin-left: 5px;
    margin-bottom: -1px;
    display: inline-block;
	border-width: 5.5px 0 5.5px 10px;
	border-color: transparent transparent transparent $color-teal-light;
	border-style: solid;
}


// old codes?

.careers-page {
    padding-top: calc(#{$spacer} + 4vh);
    margin-bottom: calc(#{$spacer} + 4vh);
}

.career-icon {
    padding-bottom: 30px;
    margin-bottom: 20px;
    position: relative;
}

.career-icon:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 100px;
    background-color: hsla(86, 54%, 51%, 1);
}

.career-gradient {
    background: #3376b7;
    background: linear-gradient(0deg,#3376b7 0%, #124185 80%);
    background: -webkit-linear-gradient(0deg,#3376b7 0%, #124185 80%);
    background: -moz-linear-gradient(0deg,#3376b7 0%, #124185 80%);
}

.career-container-sm {
    max-width: 1200px;
    margin: 0 auto;
}

.career-team {
    background-image: url(../img/team.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 12rem;
    @screen sm {
        padding-bottom: 26rem;
    }
}

.text-green-dark {
    color: #537A24;
}

.career-position {
    border-bottom: solid 1px #E1E1E1;
}

.career-posting h1,
.career-posting h2,
.career-posting h3,
.career-posting h4,
.career-posting h5,
.career-posting h6 {
    color: #124387;
}

.career-posting ul {
    list-style: none;
}

.career-posting li::before {
    content: "•"; 
    color: #8bc63f;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}
