.site-credit {
    width: 100%;
    padding: 20px 0;
    margin-top: 40px;
    background-color: darken($color-blue, 3%);
    @include media-breakpoint-down(xs) {
        margin-top: 20px;
    }
    &__flex {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(xs) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__list {
        @extend %list-clean;
        width: 100%;
    }

    &__item {
        @extend %list-clean;
        display: inline-block;
        margin-right: 10px;
    }

    &__link {
        font-size: 75%;
        text-decoration: none;
    }

    &__social {
        width: 50%;
        text-align: right;
        @include media-breakpoint-down(xs) {
            width: 100%;
            text-align: left;
            padding-top: 20px;
        }
    }
}
