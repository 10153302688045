.careers-callout {
    background-size: cover;
    overflow: hidden;
    background-image: url('../img/home/careers-bg.jpg');

    @include media-breakpoint-down(xs) {
        text-align: center;
    }

    &__image {
        max-width: 275px;

        @include media-breakpoint-down(xs) {
            width: 50%;
            margin-bottom: -19%;
        }
    }

    &__content-wrap {
        @include media-breakpoint-up(md) {
            padding-top: $spacer*3;
            margin-bottom: $spacer*3;
        }

        a {
            @extend .btn;
            @extend .btn--black;

            min-width: 230px;
        }
    }
}
