.community {
    &__section {
    }

    &__flower {
        &--1 {
            position: absolute;
            top: 0;
            right: 0;

            max-width: 50%;

            transform: translate(27%, -51%);
        }
    }
}
