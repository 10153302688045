%list-checks {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 0;
    margin-bottom: $spacer;

    > li {
        padding-left: 30px;

        position: relative;

        &:before {
            content: "";
            display: inline-block;

            width: 20px;
            height: 12px;
            border-bottom: 3px solid $color-green;
            border-left: 3px solid $color-green;

            position: absolute;
            left: 0;
            top: 5px;

            transform: rotate(-45deg);
        }
    }
}

%list-checks-grid {
    @extend %list-checks;
    @include make-row;

    > li {
        @include make-col-ready;
    }

    @each $breakpoint, $size in $grid-breakpoints {
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($grid-gutter-widths, $breakpoint);
            > li {
                padding-left: $gutter/2 + 30px;

                &:before {
                    left: $gutter/2;
                }
            }
            &--lg > li {
                padding-left: $gutter/2 + 45px;
            }
        }
    }
}
