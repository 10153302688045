.feature-cards {
    @extend %overlap-banner--small;

    text-align: center;

    padding-top: calc(#{$spacer} + 3vh);
    margin-bottom: calc(#{$spacer} + 3vh);

    @include media-breakpoint-down(xs) {
        padding-top: 0;
        margin-bottom: 0;
    }

    &__list {
        @extend %list-clean;
        @include make-row();

    }

    &__item {
        @extend %list-clean;

        @include make-col-ready();
        @include make-col(6);

        @include media-breakpoint-up(md){
            @include make-col(3);
        }
    }

    &__link {
        display: block;

        &:hover,
        &:focus,
        &:active {
            color: inherit;
        }
    }

    &__image-wrap {
        height: 100px;
        max-width: 205px;

        margin: 0 auto;
        position: relative;

        @include media-breakpoint-up(md) {
            height: 160px;
        }
    }

    &__image {
        max-height: 100%;

        max-height: 140px;
        padding: 20px;
        width: auto;
        height: auto;

        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-50%,-50%);

        @include media-breakpoint-up(md) {
            padding: 10px;
        }

        @include media-breakpoint-up(xl) {
            padding: 0;
        }
    }

    &__title {
        font-size: 19px;
        font-weight: bold;

        padding-top: $spacer;
        margin-bottom: $spacer*2;

        @include media-breakpoint-up(md) {
            font-size: 33px;
        }
    }

    &__button {
        width: 100%;
        max-width: 175px;

        padding-left: 0;
        padding-right: 0;
    }
}
