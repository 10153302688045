.design-steps {
  &__step {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
  }

  &__gallery {
    padding: 40px 0;
  }

  &__content {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 100px;
    padding-bottom: 80px;
    @include media-breakpoint-down(md){
        padding-top: 50px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    width: 100%;
    order: 2;

    @include media-breakpoint-up(md) {
      width: 50%;
      max-width: map-get($container-max-widths, md) / 2;

      order: initial;

      &:not(:last-child) {
        padding-left: 0;
        margin-left: auto;
      }

      &:last-child {
        padding-right: 0;
        margin-right: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: map-get($container-max-widths, lg) / 2;
    }

    @include media-breakpoint-up(xl) {
      max-width: map-get($container-max-widths, xl) / 2;
    }

    a:not(.btn) {
      text-decoration: underline;
    }
  }

  &__step--2 &__title-wrap {
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__title-wrap {
    margin-bottom: $spacer * 2;
  }

  &__category {
    font-size: 19px;
    font-weight: bold;

    margin-bottom: 0.5rem;

    color: $color-blue;
  }

  &__title {
    color: inherit;
    font-size: 36px;

    margin-bottom: 0.6em;

    strong {
      font-weight: inherit;
      color: $color-teal-light;
    }
  }
  &__step--3 &__title {
    margin-bottom: 8px;
  }

  &__image-wrap {
    @include aspect-ratio(6, 3);

    background-color: $gray-lighter;

    overflow: hidden;

    width: 100%;
    order: 1;

    @include media-breakpoint-up(md) {
      width: 50%;
      order: initial;
    }
  }

  &__image {
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  &__full-content {
    width: 100%;
    max-width: 975px;

    margin: auto;
    padding-top: 100px;
    padding-bottom: 30px;

    text-align: center;
  }
}
