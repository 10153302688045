.automower-value {

    position: relative;

    margin-bottom: 60px;

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &:nth-child(2) &__copy {
        order: 2;
    }

    &:last-child {
        @include media-breakpoint-up(lg) {
            padding-bottom: 60px;
        }
    }

    &__wrap {


        padding-top: 30px;

        @include media-breakpoint-up(sm) {
            // margin-top: -100px;
            // padding: 30px 0 0;

            background-color: #fff;
        }

        @include media-breakpoint-up(lg) {
            // margin-top: -150px;

            background-color: transparent;
            background-image: linear-gradient(to bottom, rgba(white, .85), rgba(white, 1) 150px);
        }
    }

    &__copy {
        h3 {
            font-weight: bold;
        }

        @include media-breakpoint-up(lg) {
            width: calc(50% - 15px);
        }
    }

    &__image-wrap {
        position: relative;

        @include media-breakpoint-up(lg) {
            width: calc(50% - 15px);
        }
    }

    &__image {
        border-radius: 10px;
    }

    &__flower {
        display: none;


        @include media-breakpoint-up(lg) {
        top: -120px;
        right: -70px;
        position: absolute;
        width: 250px;
            // top: -40px;
            right: -90px;
            width: 300px;

        display: block;
        }
    }
}