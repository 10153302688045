.internship {
    &__center {
        margin: 0 auto 70px;
        max-width: 650px;
        text-align: center;
        h2 {
            color:$color-brown;
        }
        h3 {
            color:$color-brown;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    &__h3 {
        color:$color-brown;
        font-size: 3rem;
        font-weight: bold;
    }
    &__intro {
        margin-top: 70px;
        margin-bottom: 40px;
    }
    &__columns {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 80px;
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 40px;
        }
        h4 {
            font-weight: bold;
            color: $color-brown;
            font-size: 2rem;
            margin-bottom: 20px;
        }
        h4 {
            font-weight: bold;
            color: $color-teal-light;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
        &.mb70 {
            margin-bottom: 70px;
        }
        &--content {
            padding: 50px 0;
            @include media-breakpoint-down(md) {
                padding: 0;
            }
            div {
                margin-bottom: 20px;
            }
            a {
                margin-top: 10px;
            }
            &.content-padding {
                padding: 60px 0 0 0;
                @include media-breakpoint-down(md) {
                    text-align: center;
                    padding: 30px 0;
                }
            }

            @include media-breakpoint-down(md) {
                order:1;
            }
        }
        &--model {
            &.model-padding {
                position: relative;
                padding: 0;
            }
            .model {
                position: relative;
                display: block;
                width: 280px;
                margin: 0 0 0 auto;
                z-index: 10;
                @include media-breakpoint-down(md) {
                    width: 170px;
                    margin: 0 auto;
                }
            }
            @include media-breakpoint-down(md) {
                order:2;
            }
        }
        &--image {
            position: relative;
            width: 100%;
            height: 100%;
            @include media-breakpoint-down(md) {
                padding-bottom: 70%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.reverse {
                @include media-breakpoint-down(md) {
                    order:-1;
                }
            }
            .flower {
                position: absolute;
                img {
                    object-fit: contain;
                }
                &-intro {
                    width: 256px;
                    height: 215px;
                    left: -114px;
                    bottom: -120px;
                    z-index: 20;
                    @include media-breakpoint-down(md) {
                        width: 139px;
                        height: 215px;
                        left: -84px;
                        bottom: -82px;
                        transform: rotate(135deg);
                    }
                }
            } 
        }
    }
    &__requirements,
    &__fine {
        padding: 70px 0;
    }
    &__fine {
        .flower {
            &-fine {
                width: 256px;
                height: 215px;
                right: -114px;
                top: -95px;
                z-index: 20;
                @include media-breakpoint-down(md) {
                    width: 139px;
                    height: 215px;
                    right: -64px;
                }
            }
        } 
    }
    &__modules {
        position: relative;
        padding: 90px 0 0;
        background: rgba(216,236,233,1);
        background: linear-gradient(180deg,rgba(216,236,233,1) 0%, rgba(255,255,255,0.05) 50%, rgba(216,236,233,1) 100%);
        background: -webkit-linear-gradient(180deg,rgba(216,236,233,1) 0%, rgba(255,255,255,0.05) 50%, rgba(216,236,233,1) 100%);
        background: -moz-linear-gradient(180deg,rgba(216,236,233,1) 0%, rgba(255,255,255,0.05) 50%, rgba(216,236,233,1) 100%);
        overflow: hidden;
        &-branch {
            position: absolute;
            right: -50px;
            top: -127px;
            max-width: 350px;
        }
        &-branch-bottom {
            position: absolute;
            right: 100px;
            transform: rotate(84deg);
            bottom: -120px;
            max-width: 350px;
        }
        .internship__center {
            margin-bottom: 40px;
        }
    }
    &__module {
        padding: 30px 0 60px;
        &-heading {
            position: relative;
            padding: 30px 0;
            .number {
                position: absolute;
                top: -45px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 8rem;
                color: $color-gray-dark;
                opacity: .1;
            }
        }
        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8) {
            .label {
                color: $color-blue;
            }
            .internship__module-box--heading {
                background-color: $color-blue;
            }
        }
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9) {
            .label {
                color: $color-red;
            }
            .internship__module-box--heading {
                background-color: $color-red;
            }
        }
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(10) {
            .label {
                color: $color-teal;
            }
            .internship__module-box--heading {
                background-color: $color-teal;
            }
        }
        h3 {
            font-size: 1.875rem;
        }
        &-content {
            strong {
                color: $color-brown;
                padding-right: 3px;
            }
            span {
                padding: 0 10px;
            }
        }
        &-box {
            background-color: white;
            overflow: hidden;
            margin: 0 60px;
            @include media-breakpoint-down(md) {
                margin: 0;
            }
            &--heading {
                background-color: $color-blue;
                text-align: center;
                color: white;
                padding: 15px;
                font-weight: bold;
                letter-spacing: 1px;
            }
            &--content {
                padding: 40px 50px;
                @include media-breakpoint-down(md) {
                    padding: 20px;
                }
                ul {
                    margin-bottom: 0;
                    list-style: none;
                    column-count: 2;
                    column-gap: 20px;
                    @include media-breakpoint-down(md) {
                        column-count: 1;
                    }
                    li::before {
                        content: "\2022";
                        color: $color-teal-light; 
                        font-weight: bold; 
                        display: inline-block; 
                        width: 1.5em; 
                        margin-left: -1.5em;
                    }
                }
                div {
                    text-align: center;
                }
            }
        }
    }
}