.packages {
    margin-bottom: 100px;
    font-family: $font-headings;
    input[type="radio"] {
        display: none;
    }
    &__box {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
        padding: 60px;
        @include media-breakpoint-down(md) {
            padding: 60px 20px;
        }
        &-heading {
            text-align: center;
            margin-bottom: 60px;
            color: $color-gray;
            @include media-breakpoint-down(md) {
                margin-bottom: 40px;
            }
            h3 {
                font-weight: bold;
                font-size: 2em;
                margin-bottom: 15px;
            }
            .step-label {
                text-transform: uppercase;
                font-size: 0.8em;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &-form {
            &--btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding-top: 60px;
                .btn {
                    &.sm {
                        color: $color-gray;
                        margin-top: 10px;
                        letter-spacing: normal;
                        &:after {
                            content: ' ›';
                        }
                    }
                }
            }
            .form__card {
                display: flex;
                gap: 30px;
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }
                // display: grid;
                // grid-template-columns: repeat(3, minmax(0, 1fr));
                // gap: 40px;
                // @include media-breakpoint-down(md) {
                //     grid-template-columns: repeat(1, minmax(0, 1fr));
                // }
                &.mb60 {
                    margin-bottom: 60px;
                }
                &-box {
                    display: flex;
                    width: 100%;
                    margin-bottom: 100px;
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                }
                label {
                    position: relative;
                }
                label:before {
                    content: url("../img/pricing/unchecked.svg");
                    position: absolute;
                    z-index: 100;
                    bottom: -100px;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                :checked+label:before {
                    content: url("../img/pricing/checked.svg");
                }
                input[type="checkbox"] {
                    display: none;
                }
                input[type="radio"] {
                    display: none!important;
                }
                &-item {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    border-radius: 10px;
                    color: $color-gray;
                    background-color: #fff;
                    border: solid 1px $color-gray-border;
                    transition: all 0.2s ease;
                    &:hover {
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
                    }
                    &--image {
                        width: 100%;
                        padding-bottom: 70%;
                        border-radius: 10px 10px 0 0;
                        background-size: cover;
                        background-position: center bottom;
                        background-repeat: no-repeat;
                        position: relative;
                        background-color: $color-green;
                        &.personality {
                            padding-bottom: 100%;
                        }
                    }
                    .property-lawn { background-image: url(../img/pricing/pricing-property-lawn.svg); }
                    .property-trees { background-image: url(../img/pricing/pricing-property-trees.svg); }
                    .property-garden { background-image: url(../img/pricing/pricing-property-garden.svg); }
                    .personality-entertainer { background-image: url(../img/pricing/pricing-personality-entertainer.svg); }
                    .personality-perfectionist { background-image: url(../img/pricing/pricing-personality-perfectionist.svg); }
                    .personality-naturalist { background-image: url(../img/pricing/pricing-personality-naturalist.svg); }
                    &--content {
                        padding: 30px;
                        font-size: 18px;
                        text-align: center;
                        //min-height: 180px;
                        strong {
                            display: block;
                            color: $color-black;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            .package__card {
                display: flex;
                gap: 30px;
                justify-content: center;
                margin-bottom: 60px;
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }
                &-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    color: $color-gray;
                    background-color: #fff;
                    border: solid 1px $color-gray-border;
                    transition: all 0.2s ease;
                    position: relative;
                    &.top-radius {
                        border-radius: 0 0 10px 10px;
                    }
                    .checked-border {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: solid 5px $color-green;
                        border-radius: 5px;
                    }
                    &--header {
                        padding: 20px 40px;
                        text-align: center;
                        border-radius: 5px 5px 0 0;
                        margin-bottom: 40px;
                        .type {
                            h4 {
                                color: white;
                                font-weight: bold;
                                margin: 0;
                            }
                        }
                        &.green {
                            background-color: $color-green;
                        }
                        &.red {
                            background-color: $color-red;
                        }
                        &.teal {
                            background-color: $color-teal;
                        }
                    }

                    &--price {
                        color: $color-black;
                        font-weight: bold;
                        font-size: 1.5em;
                        border-top: solid 1px $color-gray-border;
                        border-bottom: solid 1px $color-gray-border;
                        background-color: $color-gray-lighter;
                        padding: 10px 30px;
                        text-align: center;
                        margin-top: auto;
                    }
                    &--list {
                        padding: 0 40px 20px;
                        strong {
                            display: block;
                            color: $color-black;
                            margin-bottom: 10px;
                        }
                    }
                    &--automower {
                        padding: 20px 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                        }
                        label {
                            margin-bottom: 0;
                            &:before {
                                content: none;
                            }
                        }
                        .switch {
                            position: relative;
                            display: inline-block;
                            width: 55px;
                            height: 25px;
                            margin-right: 10px;
                            @include media-breakpoint-down(lg) {
                                width: 75px;
                            }
                            @include media-breakpoint-down(md) {
                                width: 55px;
                            }
                            @include media-breakpoint-down(sm) {
                                margin:0 0 10px;
                            }
                            input { 
                                opacity: 0;
                                width: 0;
                                height: 0;
                              }
                          }
                          
                          .slider {
                            position: absolute;
                            cursor: pointer;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #ccc;
                            -webkit-transition: .4s;
                            transition: .4s;
                            &:before {
                                position: absolute;
                                content: "";
                                height: 16px;
                                width: 16px;
                                left: 7px;
                                bottom: 4px;
                                background-color: white;
                                -webkit-transition: .4s;
                                transition: .4s;
                              }
                          }
                          
                          input:checked + .slider {
                            background-color: $color-green;
                            &:before {
                                -webkit-transform: translateX(26px);
                                -ms-transform: translateX(26px);
                                transform: translateX(26px);
                            }
                          }
                          
                          input:focus + .slider {
                            box-shadow: 0 0 1px $color-green;
                          }
                          
                          /* Rounded sliders */
                          .slider.round {
                            border-radius: 34px;
                            &:before {
                                border-radius: 50%;
                              }
                          }
                    }
                }
            }
            .automower-description {
                padding: 50px;
                margin: 0 auto;
                border: solid 1px $color-gray-border;
                border-radius: 10px;
                margin-bottom: 60px;
                background-color: $color-gray-lighter;
                h4 {
                    font-weight: bold;
                }
                strong {
                    font-size: 1.1em;
                    display: block;
                    margin-bottom: 20px;
                }
                p:last-child {
                    margin-bottom: 0;
                }
                &__grid {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 40px;
                    &-billy {
                        padding: 40px;
                    }
                }
            }
            .maintenance {
                margin-bottom: 60px;
                &__services-box {
                    border: solid 1px $color-gray-border;
                    box-shadow: none;
                    h4 {
                        font-weight: bold;
                    }
                }
            }
            .contact-info {
                max-width: 400px;
                margin: 0 auto;
                text-align: center;
                strong {
                    font-size: 1.6em;
                }
                ul {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
                &__label {
                    float: left;
                    color: $color-gray;
                    padding-left: 1px;
                    margin-bottom: 3px;
                }
            }
        }
    }
    
}

.pricing-slider {
    .product-range-wrapper {
        margin:0px auto;
        .product-range-block {
            width:100%;
            margin-bottom: 60px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 0px;
            }
            .size-chart-block {
                text-align: center;
                #img {
                    height: 300px;
                }
            }
            #slider_count {
                margin:0px auto;
                padding:40px;
                text-align:center;
                color: $color-gray;
                strong {
                    font-size: 1.2em;
                    color: $color-black;
                }
            }
        }
    }
      
    /* ::::::::::::::::::::Range Slider Styles::::::::::::::::::::::::: */
    .range-slider-block {
        margin:0px auto;
        width:100%;
        text-align: center;
        position: relative;
            .price-label {
            display: flex;
            color: $color-gray;
            padding-top: 36px;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
            p {
                flex: 1;
                text-align: center;
                padding: 20px;
                position: relative;
                line-height: 1.5;
                @include media-breakpoint-down(sm) {
                    text-align: left;
                    padding:0 0 0 40px;
                    margin-bottom: 10px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: -40px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: #E1E1E2;
                    @include media-breakpoint-down(sm) {
                        top: 10px;
                        left: 0;
                        transform: translateX(0%);
                    }
                }
                strong {
                    margin-bottom: 10px;
                    display: block;
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 0px;
                    }
                }
                
            }
            &.active {
                color: $color-black;
            }
        }
          
    }
    
    #range-slider {
        padding:0;
        width:89%;
        background-color: transparent;
        z-index: 22;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        @include media-breakpoint-down(sm) {
            bottom: -11px;
            left: -152px;
            transform: translateX(0%);
            top: 0;
            transform: rotate(90deg);
            width: 320px;
        }
    }
    /* Remove Range Sliders Default Styles*/
    input[type=range]{
        -webkit-appearance: none;
    }
    /* Track */
    input[type=range]::-webkit-slider-runnable-track {
        height: 8px;
        background: #E1E1E2;
        border: none;
        border-radius: 6px;
    }
      input[type=range]:focus {
        outline: none;
    }
    /* Thumb */
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 60px;
        width: 60px;
        z-index: 90;
        border-radius: 50%;
        background-image: url(../img/pricing/slider-button.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: -25px;
        transition: box-shadow 0.5s;
        @include media-breakpoint-down(sm) {
            height: 40px;
            width: 40px;
            margin-top: -16px;
        }

    }
    input[type=range]:hover::-webkit-slider-thumb {
        box-shadow: 0 0 0 10pt rgba(190,190,190,0.4);
        cursor:pointer;
    }
      
    /* JS Stykes */
    /* Changes Thumb color to darker green when mousedownn */
    input[type=range].hover-ring::-webkit-slider-thumb {
        box-shadow: 0 0 0 6pt rgba(0,0,0,0.15);
        cursor:pointer;
    }
    input[type=range].hover-ring-out::-webkit-slider-thumb {
        box-shadow: 0 0 0 0pt rgba(0,0,0,0);
        cursor:pointer;
    }
}

/* Tabs */
.step-tab {
    display: none;
    overflow: visible;
}
      
.prevBtn {
    position: relative;
    background-color: $color-gray-lighter;
    width: 25px;
    height: 25px;
    margin-top: -4px;
    margin-right: 10px;
    border: none;
    border-radius: 50px;
    &:after {
        content:'‹';
        position: absolute;
        font-size: 2em;
        color: $color-gray;
        font-weight: bold;
        top: 46%;
        left: 48%;
        transform: translate(-50%,-50%);
    }
}

#error-message {
    left: 50%;
    transform: translateX(-50%);
}